import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SharedLayoutService {

  private _showSideMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showSideMenu$: Observable<boolean> = this._showSideMenu.asObservable().pipe(distinctUntilChanged());

  private _hideBackButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hideBackButton$: Observable<boolean> = this._hideBackButton.asObservable().pipe(distinctUntilChanged());

  private _transparentTopNavBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  transparentTopNavBar$: Observable<boolean> = this._transparentTopNavBar.asObservable().pipe(distinctUntilChanged());

  private _hideHamburgerButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hideHamburgerButton$: Observable<boolean> = this._hideHamburgerButton.asObservable().pipe(distinctUntilChanged());

  constructor() { }

  hideBackButton() {
    this._hideBackButton.next(true);
  }

  showBackButton() {
    this._hideBackButton.next(false);
  }

  hideHamburguerButton() {
    this._hideHamburgerButton.next(true);
  }

  showHamburguerButton() {
    this._hideHamburgerButton.next(false);
  }

  toggleSideMenu() {
    this._showSideMenu.next(!this._showSideMenu.getValue());
  }

  isShowSideMenu(){
    return this._showSideMenu.getValue();
  }

  hideSideMenu() {
    this._showSideMenu.next(false);
  }

  activateTransparentTopNavBar(){
    this._transparentTopNavBar.next(true);
  }

  deactivateTransparentTopNavBar(){
    this._transparentTopNavBar.next(false);
  }

}
