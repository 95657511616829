import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/shared-form/validation-service/custom-validators';
import { RegistrationFormsService } from '../services/registration-forms.service';

@Component({
  selector: 'edu-giz-form',
  templateUrl: './giz-form.component.html',
  styleUrls: ['./giz-form.component.scss']
})
export class GIZFormComponent implements OnInit {

  gizForm: UntypedFormGroup;
  isFormSubmitted: boolean = false;
  loading: boolean = true;
  textEnteredInFirstName: string = '';
  textEnteredInLastName: string = '';
  textEnteredInAddress: string = '';
  isMobileResolution: boolean;

  constructor(
    private registrationFormsService: RegistrationFormsService
  ) { }

  ngOnInit(): void {
    this.isMobileResolution = this.registrationFormsService.getResolution();
    this.createForm();
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  }

  createForm() {
    this.gizForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [
        Validators.required
      ]),
      lastName: new UntypedFormControl('', [
        Validators.required
      ]),
      age: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        this.registrationFormsService.noWhitespaceValidator
      ]),
      gender: new UntypedFormControl('', [
        Validators.required,
        this.registrationFormsService.noWhitespaceValidator
      ]),
      address: new UntypedFormControl('', [
        Validators.required
      ]),
      state: new UntypedFormControl('', [
        Validators.required
      ]),
      country: new UntypedFormControl('', [
        Validators.required,
        this.registrationFormsService.noWhitespaceValidator
      ]),
      countryCode: new UntypedFormControl('+234', [
        Validators.required,
        Validators.minLength(2),
        this.registrationFormsService.noWhitespaceValidator
      ]),
      phone: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(14),
        this.registrationFormsService.noWhitespaceValidator
      ]),
      email: new UntypedFormControl('', [
        Validators.required,
        CustomValidators.email,
        this.registrationFormsService.noWhitespaceValidator
      ]),
      checkInput: new UntypedFormControl('', [
        Validators.requiredTrue
      ])
    });
  }

  changeGender(e: any) {
    this.gender.setValue(e.target.value, {
      onlySelf: true
    })
  }

  get gender() {
    return this.gizForm.get('gender');
  }

  numberOnly(event: any): boolean {
    return this.registrationFormsService.numberOnly(event);
  }

  keepPlusSign(event: any) {
    this.registrationFormsService.keepPlusSign(event);
  }

  noSpaceAllowedInStart(event: any) {
    if (this.textEnteredInAddress.length < 1) {
      this.registrationFormsService.avoidSpaces(event);
    }
  }

  onlyAlphabets(event: any) {
    if (event.srcElement.id == "firstName") {
      if (this.textEnteredInFirstName.length < 1) {
        this.registrationFormsService.avoidSpaces(event);
      }
    } else {
      if (this.textEnteredInLastName.length < 1) {
        this.registrationFormsService.avoidSpaces(event);
      }
    }
    this.registrationFormsService.onlyAlphabets(event);
  }

  startWithAlphabetOnly(event: any) {
    if (event.target.id == "firstName") {
      this.textEnteredInFirstName = this.registrationFormsService.getValue(event);
    } else if (event.target.id == "lastName") {
      this.textEnteredInLastName = this.registrationFormsService.getValue(event);
    } else {
      this.textEnteredInAddress = this.registrationFormsService.getValue(event);
    }
  }

  avoidSpaces(event: any) {
    this.registrationFormsService.avoidSpaces(event);
  }

  submitForm() {
    this.gizForm.markAllAsTouched();
    if (this.gizForm.valid) {
      const firstName = this.gizForm?.value?.firstName?.trim();
      const lastName = this.gizForm?.value?.lastName?.trim();
      const age = this.gizForm?.value?.age?.trim();
      const gender = this.gizForm?.value?.gender?.trim();
      const address = this.gizForm?.value?.address?.trim();
      const state = this.gizForm?.value?.state?.trim();
      const country = this.gizForm?.value?.country?.trim();
      const countryCode = this.gizForm?.value?.countryCode?.trim();
      const phone = this.gizForm?.value?.phone?.trim();
      const email = this.gizForm?.value?.email?.trim();
      const checkInput = this.gizForm?.value?.checkInput;

      if (!!firstName && !!lastName && !!age && !!gender && !!address && !!state && !!country && !!countryCode && !!phone && !!email && checkInput) {
        if (age.length == 2) {
          this.submitFormToBackend(firstName, lastName, age, gender, address, state, country, countryCode, phone, email);
        }
      }
    } else {
      return;
    }
  }

  submitFormToBackend(firstName: any, lastName: any, age: any, gender: any, address: any, state: any, country: any, countryCode: any, phone: any, email: any) {
    this.isFormSubmitted = true;
    var phoneNumber = countryCode + phone;
    let formData = {
      firstName: firstName,
      lastName: lastName,
      age: age,
      gender: gender,
      address: address,
      state: state,
      country: country,
      phoneNumber: phoneNumber,
      email: email,
      formName: "giz",
      timeStamp: Date.now()
    };
    this.registrationFormsService.submitRegistrationForm(formData).subscribe(
      (response) =>
        (error: any) => console.log(error)
    )
  }
}
