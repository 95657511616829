import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from "../../../../../environments/environment";
import { catchError, map, tap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class AdminRegistrationRestService {

  constructor(private http: HttpClient) { }

  checkSimilarCompanyExists(companyName: any): Observable<any> {
    // const body = {"companyName" : "21cc" }
    
    const body2 = { body : { "companyName" :  companyName} };
    return this.http.post<any>(`${environment.apiUrl}/checkSimilarCompanyExists`,  body2)
  }
  checkEmailAndPhoneExists(phoneAndEmail:any): Observable<any> {
    const phoneAndEmailBody = { body:  phoneAndEmail};
    return this.http.post<any>(`${environment.apiUrl}/checkEmailAndPhoneExists`,  phoneAndEmail)
  }

  enrolCompanyUsers(dynamicArray: any, type: any): Observable<any> {
    // const body = {type:'BY_ADMIN_FOR_STUDENT', dynamicArray: dynamicArray}
    const body = { type: type, dynamicArray: dynamicArray }
    return this.http.post<any>(`${environment.apiUrl}/enrollTenantUsers`, body)
  }

  createLearners(dynamicArray: any, type: any): Observable<any> {
    // const body = {type:'BY_ADMIN_FOR_STUDENT', dynamicArray: dynamicArray}
    const body = { type: type, dynamicArray: dynamicArray }
    return this.http.post<any>(`${environment.apiUrl}/createLearners`, body)
  }

  getPurchaseHistory(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/getPurchaseHistory`)
  }


  bulkAssignPackage(transformedData: any): Observable<any> {
    // const body = {type:'BY_ADMIN_FOR_STUDENT', dynamicArray: dynamicArray}
    const body = {transformedData}
    console.log('transformedData',transformedData);
    console.log('body', body);
    // return;
    return this.http.post<any>(`${environment.apiUrl}/addUserAccessToStudentsUploadedByAdmin`, transformedData)
  }

  getCompanyDetails(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/getCompanyDetails`)
  }

  getFilterList(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/getFilterList`)
  }
  
  
  checkExactCompanyExists(memberId,companyName: any): Observable<any> {
    
    const body2 = { body: { memberId: null, companyName: companyName } };
    return this.http.post<any>(`${environment.apiUrl}/checkCompanyExists`,  body2)
  }

  checkCompanyWebsiteIstaken(websiteUrl: any): Observable<any> {
    
    const body2 = { body: {websiteUrl: websiteUrl } };
    return this.http.post<any>(`${environment.apiUrl}/checkWebsiteExists`,  body2)
  }

  createCompany(memberId: string, name: string, url: string, companyDescription: any,country:any, domainowner): Observable<any> {
    const body = {
      memberId: memberId, name: name, url: url, companyDescription: companyDescription, isThreePl: false,
      icon: null, moodleId: null, endpoint: null, groupInvitationCode: memberId, domainOwner: domainowner,country:country, defaultGallery: null, 
    };
    return this.http.post<any>(`${environment.apiUrl}/createCompany`, body)
  }

  
  editUserDetails(firstName: string, lastName: string, email: string, phone:any, userId: number, jobroleFromDD:any,departmentFromDD:any,locationdd:any,threeplFromDD:any): Observable<any> {
    const body = { firstName: firstName, lastName: lastName, email: email, userId: userId,phone: phone,telephone: phone,
      jobRoleId: jobroleFromDD, departmentId:departmentFromDD, locationId:locationdd, linked3plId:threeplFromDD};
    return this.http.post<any>(`${environment.apiUrl}/editUserDetails`, body)
  }

  deleteUser(profileId):Observable<any>{
    return this.http.delete<any>(`${environment.apiUrl}/deleteUser/${profileId}`)
  }
  

  getPlanDetails(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/plans`)
  }


  createCompanyAdmin(firstName: string, lastName: string, email: string, password:string, companyId: any, phone:string, ): Observable<any> {
    const body = {
      company: { companyId: companyId }, lAndDManager: {
        phone: phone, telephone:phone, email: email, firstName: firstName,
        lastName: lastName, password: password
      }
    };
    return this.http.post<any>(`${environment.apiUrl}/createLAndDManager`, body);
  }

  createSubscription(customerEmail, planId, razorpayPlanId, planTitle, planPrice, planLicenses, planPeriod, planCurrency): Observable<any> {
    const body = {
      email: customerEmail, planId: planId, razorpayPlanId: razorpayPlanId, planTitle: planTitle, planPrice: planPrice, planLicenses: planLicenses,
      planPeriod: planPeriod, planCurrency: planCurrency
    };
    return this.http.post<any>(`${environment.apiUrl}/createSubscription`, body);
  }

  savePayment(customerEmail, razorpayPaymentId,subscriptionId,planId): Observable<any> {
    const body = {
      customerEmail: customerEmail, razorpayPaymentId: razorpayPaymentId, subscriptionId: subscriptionId,planId: planId
    };
    return this.http.post<any>(`${environment.apiUrl}/savePayment`, body);
  }
}
