import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class FffaiRestService {

  constructor(
    private http: HttpClient,
  ) { }

  ssoLoginAdmin(username: string, password: string): Observable<any> {
    const body = { Username: username, Password: password };
    const httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, X-Auth-Token'
      })
    };
    return this.http.post<any>(`https://membership.fffai.org/FFFAIService.svc/GetSSOUserLogin`, body, httpHeaders);
  }

  ssoLoginLearner(username: string, password: string): Observable<any> {
    const body = { UserId: username, Password: password, ClientId: "ObInQe/vSrQ=" };
    const httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, X-Auth-Token',
        'Authorization': 'Basic RkZGQUkyMDIzOkZGdGNeY21AMzM0JCUhMkA='
    })
  };
    return this.http.post<any>(`https://membership.fffai.org/FFFAISSOService.svc/GetSecondaryMemberUserLogindetails`, body, httpHeaders);
  }

  updateSSOPassword(username: string, password: string): Observable<any> {
    const body = { username: username, password: password };
    return this.http.post<any>(`${environment.apiUrl}/updateSSOPassword`, body)
  }

  checkCompanyExists(memberId: string): Observable<any> {
    const body = { memberId: memberId };
    return this.http.get<any>(`${environment.apiUrl}/checkCompanyExists/${memberId}`)
  }

  createCompany(memberId: string, name: string, url: string): Observable<any> {
    const body = {
      memberId: memberId, name: name, url: url, companyDescription: name, isThreePl: false,
      icon: null, moodleId: null, endpoint: null, groupInvitationCode: memberId, defaultGallery: null, domainOwner: 1217657
    };
    return this.http.post<any>(`${environment.apiUrl}/createCompany`, body)
  }

  editCompany(memberId: string, name: string, url: string): Observable<any> {
    const body = {
      memberId: memberId, name: name, url: url, companyDescription: name, isThreePl: false,
      icon: null, moodleId: null, endpoint: null, groupInvitationCode: memberId, defaultGallery: null, domainOwner: 1217657
    };
    return this.http.post<any>(`${environment.apiUrl}/editCompany`, body)
  }

  createCompanyAdmin(firstName: string, lastName: string, email: string, password:string, companyId: any, phone:string, ): Observable<any> {
    const body = {
      company: { companyId: companyId }, lAndDManager: {
        phone: phone, telephone:phone, email: email, firstName: firstName,
        lastName: lastName, password: password
      }
    };
    return this.http.post<any>(`${environment.apiUrl}/createLAndDManager`, body);
  }

  fffaiLoginRegister(username,password,userType,firstName,lastName): Observable<any> {
    const body = {
      username: username, password: password, userType: userType, firstName: firstName,
        lastName: lastName
    };
    return this.http.post<any>(`${environment.apiUrl}/fffaiLoginRegister`, body);
  }

  assignGalleryToUser(username,galleryId): Observable<any> {
    const body = {
      username: username, galleryId: galleryId
    };
    return this.http.post<any>(`${environment.apiUrl}/assignGalleryToUser`, body);
  }

  createCompanyStudent(firstName: string, lastName: string, email: string, password:string, membershipNo: any, phone:string): Observable<any> {
    const body = {
        phone: phone, email: email, firstName: firstName,
        lastName: lastName, password: password, membershipNo: membershipNo
    };
    return this.http.post<any>(`${environment.apiUrl}/createFFFAIStudent`, body);
  }

  deleteCompanyAdmin(companyId: any): Observable<any> {
    const body = {
      companyUserId: companyId
    }
    return this.http.post<any>(`${environment.apiUrl}/deleteLAndDManager`, body);
  }

  // createCompanyAdminSSO(firstName: string, lastName: string, email: string, password: any, company: any): Observable<any> {
  //   const body = {
  //     company: { companyId: company.id }, lAndDManager: {
  //       phone: null, email: email, firstName: firstName,
  //       lastName: lastName, password: password
  //     }
  //   };
  //   return this.http.post<any>(`${environment.apiUrl}/createLAndDManager`, body)
  // }

  editUserDetails(firstName: string, lastName: string, email: string, userId: number): Observable<any> {
    const body = { firstName: firstName, lastName: lastName, email: email, userId: userId };
    return this.http.post<any>(`${environment.apiUrl}/editUserDetails`, body)
  }

  getCompanyDetails(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/getCompanyDetails`)
  }

  getCompanyUserDetails(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/getCompanyUserDetails`)
  }

  enrolCompanyUsers(dynamicArray: any, type: any): Observable<any> {
    // const body = {type:'BY_ADMIN_FOR_STUDENT', dynamicArray: dynamicArray}
    const body = { type: type, dynamicArray: dynamicArray }
    return this.http.post<any>(`${environment.apiUrl}/enrollTenantUsers`, body)
  }

  selfEnrolCompanyUsers(dynamicArray: any): Observable<any> {
    const body = { type: 'SELF', dynamicArray: dynamicArray }
    return this.http.post<any>(`${environment.apiUrl}/enrollTenantUsers`, body)
  }

  getPurchaseHistory(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/getPurchaseHistory`)
  }

  generateReport(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/requestReport`)
  }
}
