<div class="spinner" *ngIf="loading">
    <div class="overlay"></div>
    <div class="loader"></div>
</div>
<div class="form-container">
    <div class="fffai-container">
        <br /><br />
        <div class="fffai-form">
            <div class="top-image-container">
                <img class="top-image1" src="assets/images/fffai/fffai.jpg" height="24" alt="VET Toolbox">
                <img class="top-image2" src="assets/images/fffai/21cc-education.svg" width="55" height="23"
                    alt="21CC Education">
            </div>
        </div>
        <div class="fffai-form" *ngIf="!isFormSubmitted">
            <h1>
                <b>Register for ‘Skilling for Growth: Building a Stronger Freight Forwarding Industry’ orientation
                    session
                </b>
            </h1>
            <p>
                The Indian Institute of Freight Forwarders (IIFF), the training arm of the Federation of Freight
                Forwarders Associations of India (FFFAI), has partnered with 21CC Education to offer a unique skilling
                programme for freight forwarders across India.
                <br /><br />
                You are invited to our upcoming orientation session on
                skilling the next generation of freight forwarders. This virtual session is free and open to all. It
                will provide owners and managers of freight forwarding companies as well as jobseekers with a clear
                understanding of the skilling programme, its key features and the courses available for them and their
                teams.
                <br /><br />
                To register, simply fill out this form.
            </p>
            <form [formGroup]="fffaiForm" (ngSubmit)="submitForm()">
                <div class="form-group" id="firstNameDiv">
                    <label for="firstName">First name<span class="text-danger">*</span></label>
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': fffaiForm.get('firstName')?.invalid && (fffaiForm.get('firstName')?.dirty || fffaiForm.get('firstName')?.touched) }"
                        formControlName="firstName" id="firstName" aria-describedby="firstNameHelp"
                        (keypress)="onlyAlphabets($event)" (keydown)="startWithAlphabetOnly($event)"
                        placeholder="Type...">
                    <div class="form-text text-danger"
                        *ngIf="fffaiForm.get('firstName')?.errors && (fffaiForm.get('firstName')?.dirty || fffaiForm.get('firstName')?.touched)">
                        <div class="form-text text-danger" *ngIf="fffaiForm.get('firstName')?.errors.required">Please
                            complete
                            this required field.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lastName">Last name<span class="text-danger">*</span></label>
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': fffaiForm.get('lastName')?.invalid && (fffaiForm.get('lastName')?.dirty || fffaiForm.get('lastName')?.touched) }"
                        formControlName="lastName" id="lastName" aria-describedby="lastNameHelp"
                        (keypress)="onlyAlphabets($event)" (keydown)="startWithAlphabetOnly($event)"
                        placeholder="Type...">
                    <div class="form-text text-danger"
                        *ngIf="fffaiForm.get('lastName')?.errors && (fffaiForm.get('lastName')?.dirty || fffaiForm.get('lastName')?.touched)">
                        <div class="form-text text-danger" *ngIf="fffaiForm.get('lastName')?.errors.required">Please
                            complete
                            this required field.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="email">Work email<span class="text-danger">*</span></label>
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': fffaiForm.get('email')?.invalid && (fffaiForm.get('email')?.dirty || fffaiForm.get('email')?.touched) }"
                        formControlName="email" id="email" aria-describedby="emailHelp" (keypress)="avoidSpaces($event)"
                        placeholder="Type...">
                    <div class="form-text text-danger"
                        *ngIf="fffaiForm.get('email')?.errors && fffaiForm.get('email')?.touched">
                        <div class="form-text text-danger" *ngIf="fffaiForm.get('email')?.errors.required">Please
                            complete
                            this
                            required field.</div>
                        <div class="form-text text-danger" *ngIf="fffaiForm.get('email')?.errors?.invalidEmail">Please
                            enter a
                            valid email address format.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="phone">Phone number</label>
                    <div class="row">
                        <div *ngIf="!isMobileResolution" class="col-3">
                            <input type="tel" class="form-control"
                                [ngClass]="{ 'is-invalid': fffaiForm.get('countryCode')?.invalid && (fffaiForm.get('countryCode')?.dirty || fffaiForm.get('countryCode')?.touched) }"
                                formControlName="countryCode" id="countryCode" aria-describedby="countryCodeHelp"
                                (keypress)="numberOnly($event)" (keydown)="keepPlusSign($event)">
                        </div>
                        <div *ngIf="isMobileResolution" class="col-4">
                            <input type="tel" class="form-control"
                                [ngClass]="{ 'is-invalid': fffaiForm.get('countryCode')?.invalid && (fffaiForm.get('countryCode')?.dirty || fffaiForm.get('countryCode')?.touched) }"
                                formControlName="countryCode" id="countryCode" aria-describedby="countryCodeHelp"
                                (keypress)="numberOnly($event)" (keydown)="keepPlusSign($event)">
                        </div>
                        <div class="col" style="padding-left: 0 !important;">
                            <input type="tel" class="form-control"
                                [ngClass]="{ 'is-invalid': fffaiForm.get('phone')?.invalid && (fffaiForm.get('phone')?.dirty || fffaiForm.get('phone')?.touched) }"
                                formControlName="phone" id="phone" aria-describedby="phoneHelp"
                                (keypress)="numberOnly($event)" placeholder="Type...">
                        </div>
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="fffaiForm.get('phone')?.errors && fffaiForm.get('phone')?.touched; else alternateErrorMessage">
                        <div class="form-text text-danger" *ngIf="fffaiForm.get('phone')?.errors.whitespace">Please
                            enter
                            valid data.</div>
                        <div class="form-text text-danger" *ngIf="fffaiForm.get('phone')?.errors.minlength">Your phone
                            number
                            must contain 10 digits.</div>
                        <div class="form-text text-danger" *ngIf="fffaiForm.get('phone')?.errors.maxlength">Your phone
                            number
                            must contain 10 digits.</div>
                    </div>
                    <ng-template #alternateErrorMessage>
                        <div class="form-text text-danger"
                            *ngIf="fffaiForm.get('countryCode')?.errors && (fffaiForm.get('countryCode')?.dirty || fffaiForm.get('countryCode')?.touched)">
                            <div class="form-text text-danger" *ngIf="fffaiForm.get('countryCode')?.errors.minlength">
                                Please
                                complete
                                this
                                required field.</div>
                        </div>
                    </ng-template>
                </div>
                <div class="form-group">
                    <label for="company">Company</label>
                    <input type="text" class="form-control" formControlName="company" id="company"
                        aria-describedby="companyHelp" (keypress)="onlyAlphabets($event)"
                        (keydown)="startWithAlphabetOnly($event)" placeholder="Type...">
                </div>
                <div class="form-group">
                    <label for="membership">FFFAI membership number</label>
                    <input type="text" class="form-control" formControlName="membership" id="membership"
                        aria-describedby="membershipHelp" (keypress)="onlyAlphabets($event)"
                        (keydown)="startWithAlphabetOnly($event)" placeholder="Type...">
                </div>
                <div class="form-group">
                    <label for="job">Job title</label>
                    <input type="text" class="form-control" formControlName="job" id="job" aria-describedby="jobHelp"
                        (keypress)="onlyAlphabets($event)" (keydown)="startWithAlphabetOnly($event)"
                        placeholder="Type...">
                </div>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="checkInput" id="checkInput">
                    <label class="form-check-label" for="checkInput">I agree to the <a
                            href="https://21cceducation.com/privacy-policy" target="_blank">Privacy Policy</a> and <a
                            href="https://21cceducation.com/terms-of-use" target="_blank">Terms &
                            Conditions</a>.</label>
                    <div class="form-text text-danger"
                        *ngIf="fffaiForm.get('checkInput')?.errors && (fffaiForm.get('checkInput')?.dirty || fffaiForm.get('checkInput')?.touched)">
                        <div class="form-text text-danger" *ngIf="fffaiForm.get('checkInput')?.errors">Please
                            complete
                            this
                            required field.</div>
                    </div>
                </div>
                <button type="submit" class="submit-btn">Submit</button>
            </form>
        </div>
        <div class="thank-you-message" *ngIf="isFormSubmitted">
            <img class="bottom-image" src="assets/images/fffai/confirmation-checkmark.svg" width="120" height="120"
                alt="Confirmation Checkmark">
            <h1>Thank you for registering!</h1>
            <p>
                Keep an eye on your inbox for further details. Please reach out to us at <a
                    href="mailto:info@21cceducation.com">info@21cceducation.com</a> if you have any questions.
            </p>
        </div>
        <br /><br />
    </div>
</div>