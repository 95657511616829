import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import teamIndia from '../../../assets/data/team-india.json';
import teamNetherlands from '../../../assets/data/team-netherland.json';
import teamUsa from '../../../assets/data/team-usa.json';

@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.scss'],
})
export class WhoWeAreComponent implements OnInit {
  
  indianTeam: any = teamIndia;
  netherlandsTeam: any = teamNetherlands;
  usaTeam: any = teamUsa;

  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle("Who We Are - 21CC Recruitment & Training");
  }
}
