
<edu-default-layout>
  <edu-spinner *ngIf="loading"></edu-spinner>
  <!-- <div style="position: absolute;" class="container-fluid">
      <div class="row">
        <div style="text-align: right;" class="col-lg-12">
          <div style="text-align: right;" class="hamburger-align">
            <div style="z-index: 4;" class="hamburger-menu" (click)="openNav()" >
              <div class="icon-hamburger" [inlineSVG]="'assets/images/icons/icon-hamburger.svg'"></div>
            </div>
          </div>
        </div>

      </div>

    </div> -->

  <header class="position-relative">


      <div class="container ">
          <h1>My courses</h1>
          <!-- <nav class="nav tabnav inverted">
              <a class="nav-link {{ progressStatus }} pb-3 px-1 mr-3 text-white" (click)="progressOfCourses('progress')">In progress</a>
              <a class="nav-link {{ completedStatus }} pb-3 px-1 mr-3 text-white" (click)="progressOfCourses('completed')">Completed</a>
          </nav> -->
      </div>
  </header>
  <div class="container my-courses fill-height product-cards pt-5">
      <div class="d-flex flex-wrap">
          <div class="card flex-shrink-0" *ngFor="let myCourse of myCourses">
            <!-- [routerLink]="myCourse?.moduleSlug ? '/show/course/' + myCourse?.language + '/' + myCourse?.courseSlug + '/' + myCourse?.moduleSlug : '/show/course/' + myCourse?.id" -->
              <a (click)="navigateToCourse(myCourse)">
                  <img class="product-image" [src]="myCourse.imageUrl | toSanitizedUrl"/>
              </a>
              <section class="title">
                <!-- [routerLink]="myCourse?.moduleSlug ? '/show/course/' + myCourse?.language + '/' + myCourse?.courseSlug + '/' + myCourse?.moduleSlug : '/show/course/' + myCourse?.id" -->
                <a (click)="navigateToCourse(myCourse)">{{ myCourse?.name }}</a>
              </section>
              <br/>
              <section class="duration-skills small">
                  <span>{{ calculateTotalDuration(myCourse.modules,'dummy','courses') }}</span>
                  <span>{{ myCourse?.skills.length }} skills</span>
              </section>
              <section class="rating">
                  <span class="fa fa-star + ' ' + {{ rating }}"></span>
                  <span class="fa fa-star + ' ' + {{ rating }}"></span>
                  <span class="fa fa-star + ' ' + {{ rating }}"></span>
                  <span class="fa fa-star + ' ' + {{ rating }}"></span>
                  <span class="fa fa-star + ' ' + {{ rating }}"></span>
                  ({{ myCourse?.averageRatingScore?.toFixed(1) }})
              </section>
              <section class="price">
              </section>
              <section class="text-right text-info">
                <!-- [routerLink]="myCourse?.moduleSlug ? '/show/course/' + myCourse?.language + '/' + myCourse?.courseSlug + '/' + myCourse?.moduleSlug : '/show/course/' + myCourse?.id" -->
                <a (click)="navigateToCourse(myCourse)">Open course</a></section>
          </div>
          <div class="card flex-shrink-0" *ngFor="let myModule of myModules">
            <a (click)="navigateToModule(myModule)">
                <img class="product-image" [src]="myModule.banner | toSanitizedUrl"/>
            </a>
            <section class="title">
              <a (click)="navigateToModule(myModule)">{{ myModule?.title }}</a>
            </section>
            <br/>
            <section class="duration-skills small">
                <span>{{ calculateTotalDuration(myModule,myModule,'modules') }}</span>
                <span>{{ myModule?.skills.length }} skills</span>
            </section>
            <section class="rating">
                <span class="fa fa-star + ' ' + {{ rating }}"></span>
                <span class="fa fa-star + ' ' + {{ rating }}"></span>
                <span class="fa fa-star + ' ' + {{ rating }}"></span>
                <span class="fa fa-star + ' ' + {{ rating }}"></span>
                <span class="fa fa-star + ' ' + {{ rating }}"></span>
                ({{ myModule?.averageRatingScore?.toFixed(1) }})
            </section>
            <section class="price">
            </section>
            <section class="text-right text-info">
              <a (click)="navigateToModule(myModule)">Open course</a></section>
        </div>
          
      </div>
  </div>
  <div style="margin-bottom: 100px;" class="container fill-height checkout d-flex align-items-center" *ngIf="noCoursesPurchased">
      <div class="m-auto">
          <div class="text-center pt-5">
              <p style="text-align: center;">
                <img width="303px" height="157px" src="assets/images/icons/empty-illustration.svg">
              </p>
              <p style="text-align: center;color: #262D5F;font: normal normal normal 16px/25px Poppins;" class="my-3" [innerHTML]="message"></p>
              <p style="text-align: center;">
              <button class="btn btn-primary mt-4" style="background: #262D5F 0% 0% no-repeat padding-box;
              border-radius: 5px;font: normal normal normal 16px/25px Poppins;color: #FFFFFF;" routerLink="/webshop">Find Courses</button>
              </p>
          </div>
      </div>
  </div>
  <br/><br/><br/><br/><br/><br/>

  <div id="mySidenav" class="sidenav" *transloco="let t; read: 'shared.sidebar-menu'">
      <div class="menu-bar">
        <p id="menu">Menu</p>
        <div class="hamburger-menu" (click)="closeNav()">
          <span class="icon-hamburger" [inlineSVG]="'assets/images/icons/icon-hamburger.svg'"></span>
        </div>
      </div>
      <ul>
        <li class="menu-option" routerLink="/fffai/learners-account-details" (click)="closeNav()">
          <div id="profile" class="menu-option-wrapper">
            <div class="icon" [inlineSVG]="'assets/images/icons/icon-profile.svg'"></div>
            Account Details
          </div>
        </li>

        <li class="menu-option" routerLink="/fffai/user-certificates" (click)="closeNav()">
          <div id="certificates" class="menu-option-wrapper">
            <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-certificate.svg'"></div>
            {{t('certificates')}}
          </div>
        </li>
        <li  class="menu-option" routerLink="/fffai/my-courses" (click)="closeNav()">
          <div id="rewards" class="menu-option-wrapper">
            <div class="icon reward" [inlineSVG]="'assets/images/icons/icon-gift.svg'"></div>
            My Courses
          </div>
        </li>
        <li class="menu-option" routerLink="/fffai/skills" (click)="closeNav()">
          <div id="skills-passport" class="menu-option-wrapper">
            <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-skills.svg'"></div>
            {{t('skills-passport')}}
          </div>
        </li>
        <li class="menu-option" routerLink="/user-settings" (click)="closeNav()">
          <div id="icon-settings" class="menu-option-wrapper">
            <div class="icon" [inlineSVG]="'assets/images/icons/icon-settings.svg'"></div>
            {{t('settings')}}
          </div>
        </li>
        <li class="menu-option" routerLink="/fffai/contact-us" (click)="closeNav()">
          <div id="contact" class="menu-option-wrapper">
            <div class="icon" [inlineSVG]="'assets/images/icons/icon-phone.svg'"></div>
            {{t('contact')}}
          </div>
        </li>
        <li class="menu-option" routerLink="/fffai/blog" (click)="closeNav()">
          <div id="skills-passport" class="menu-option-wrapper">
            <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-skills.svg'"></div>
            Blog
          </div>
        </li>
        <li class="menu-option" routerLink="/fffai/scores" (click)="closeNav()">
          <div id="intro-tour" class="menu-option-wrapper">
            <div class="icon" [inlineSVG]="'assets/images/icons/icon-star.svg'"></div>
            Leaderboard
          </div>
        </li>
        <!-- <a [href]="link" target="_blank">
        <li class="menu-option" routerLink="/fffai/checkout" (click)="toggleSideMenu()">
          <a class="nav-link position-relative shopping-cart" >
            <img src="assets/images/box.svg" alt="box" />
            <div class="indicator badge-pill badge-danger small d-none" *ngIf="totalItems == 0">2</div>
            <div class="indicator badge-pill badge-danger small" *ngIf="totalItems > 0">{{ totalItems }}</div>
          </a>
        </li>
        </a> -->

      </ul>
  </div>
</edu-default-layout>
