<ng-container *ngIf="!fffai">
  <footer class="py-4 bg-white" style="margin-bottom: 50px;">
    <div class="container">
      <div class="row mt-4">
        <div class="col-12 col-md-8">
          <!-- First Column -->
          <div class="row">
            <div class="col-3 d-none d-md-block">
              <img style="width: 82px; height: 35px;" src="assets/images/logo.svg" alt="image" />
            </div>
          </div><br />
          <div class="row">
            <div class="col-12 col-md-3">
              <ul class="links text-center text-md-left">
                <li class="mt-3 mt-md-0">
                  &nbsp;Get 21CC Skilled<br />
                  <a href="https://apps.apple.com/in/app/the-21cc-app/id1512910768" target="_blank">iOS</a>
                  /
                  <a href="https://play.google.com/store/apps/details?id=com.education21cc.logistics&hl=en"
                    target="_blank">Android</a>
                </li>
                <li class="mt-3 mt-md-0">
                  <a routerLink="/contact-us">Contact Us</a>
                </li>
                <!-- <li class="mt-3 mt-md-0">
                <a routerLink="/industry/0" target="_blank">Corporate Academy</a>
              </li> -->
              </ul>
            </div>
            <div class="col-12 col-md-3">
              <ul class="links text-center text-md-left">
                <li class="mt-3 mt-md-0">
                  <a routerLink="/terms-of-use" target="_blank">Terms of Use</a>
                </li>
                <li class="mt-3 mt-md-0">
                  <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>
                </li>
                <li class="mt-3 mt-md-0">
                  <a routerLink="/refund-policy" target="_blank">Refund Policy</a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-3">
              <ul class="links text-center text-md-left">
                <!-- <li class="mt-3 mt-md-0">
                  <a routerLink="/career-contact">Careers</a>
                </li> -->
                <li class="mt-3 mt-md-0">
                  <a routerLink="/faq">FAQs</a>
                </li>
                <li class="mt-3 mt-md-0">
                  <a href="https://21cceducation.nl/" target="_blank">Dutch website</a>
                </li>
              </ul>
            </div>
          </div>
          <br /><br />
        </div>
        <div class="col-12 col-md-4 text-center px-0">
          <h4><b>Subscribe to our newsletter</b></h4>
          <div class="d-flex flex-column w-100 gap-2">
            <form [formGroup]="newsLetterSubscriptionForm" (ngSubmit)="submitNewsLetterSubscription()">
              <input type="email" class="form-control" id="emailAddress"
                [ngClass]="{ 'is-invalid': newsLetterSubscriptionForm.get('email')?.invalid && (newsLetterSubscriptionForm.get('email')?.dirty || newsLetterSubscriptionForm.get('email')?.touched) }"
                style="margin-bottom: 10px;" placeholder="Email address" formControlName="email">
              <div class="form-text text-danger mb-2"
                *ngIf="newsLetterSubscriptionForm.get('email')?.errors && newsLetterSubscriptionForm.get('email')?.touched">
                <div class="form-text text-danger" *ngIf="newsLetterSubscriptionForm.get('email')?.errors.required">Please
                  complete
                  this
                  required field.</div>
                <div class="form-text text-danger" *ngIf="newsLetterSubscriptionForm.get('email')?.errors?.invalidEmail">Please
                  enter a
                  valid email address format.</div>
              </div>
              <div class="container px-0">
                <div class="row align-items-center px-0 px-md-3">
                  <p class="col-lg-8 text-center text-md-left mb-2 mb-md-0 px-0" >By subscribing you agree with our terms and conditions</p>
                  <button class="btn btn-primary button2 col-lg-4" style="margin: auto;">Subscribe</button>
              </div>
              </div>
            </form>
            <br />
            <p style="font: normal normal normal 14px/21px Poppins;letter-spacing: 0px;color: #000000;"
              *ngIf="isFormSubmitted">Thanks for subscribing!</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-10">
          <ul class="social d-flex flex-wrap justify-content-center justify-content-md-start">
            <li class="linkedin">
              <a href="https://www.linkedin.com/company/21cc-education/" target="_blank" rel="noopener noreferrer"></a>
            </li>
            <li class="facebook">
              <a href="https://www.facebook.com/21CCeducation/" target="_blank" rel="noopener noreferrer"></a>
            </li>
            <li class="instagram">
              <a href="https://www.instagram.com/21cceducation/" target="_blank" rel="noopener noreferrer"></a>
            </li>
            <li class="twitter">
              <a href="https://twitter.com/21cceducation?lang=en/" target="_blank" rel="noopener noreferrer"></a>
            </li>
            <li class="youtube">
              <a href="https://www.youtube.com/channel/UCUL6YB5hoahEJ5xFDJ4CwWw" target="_blank" rel="noopener noreferrer"></a>
            </li>
          </ul>
          <div class="text-center d-md-flex justify-content-md-start">
            <span class="d-block text-center text-md-start mt-3 small">
              Copyright 2020-2023 21CC Recruitment & Training Pvt. Ltd.
            </span>
          </div>
        </div>
        <div class="col-12 col-md-2 text-center order-md-first">
          <img src="assets/images/sustainable-dev-goals.png" alt="image" class="image-class" />
        </div>                  
      </div>
    </div>
  </footer>
</ng-container>

<ng-container *ngIf="fffai">
  <footer class="py-4 bg-white" style="box-shadow: inset 0 2px #0000001a;" *ngIf="fffaiRole == 'ROLE_STUDENT'">
    <ul class="bottom-nav-bar" *ngIf="showIt == 'fffai'">
      <li class="nav-bar-item" routerLink="/fffai/industry/0" [routerLinkActive]="'active'">
        <div id="home" class="nav-bar-item-wrapper">
          <div class="icon-wrapper">
            <div class="icon" class="icon icon-home" [inlineSVG]="'assets/images/icons/icon-home.svg'"></div>
          </div>
          <p class="icon-text">Home</p>
        </div>
      </li>
    </ul>
    <ul class="bottom-nav-bar" *ngIf="showIt != 'fffai'">
      <li class="nav-bar-item" routerLink="/wai/industry/0" [routerLinkActive]="'active'">
        <div id="home" class="nav-bar-item-wrapper">
          <div class="icon-wrapper">
            <div class="icon" class="icon icon-home" [inlineSVG]="'assets/images/icons/icon-home.svg'"></div>
          </div>
          <p class="icon-text">Home</p>
        </div>
      </li>
    </ul>
  </footer>
</ng-container>