import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogRestService {

  constructor(
    private http: HttpClient
  ) { }

  getAllBlog(offset: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/allBlog/${offset}`);
  }

  getBlogByBlogUrl(data: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/blog/${data}`);
  }

  getBlogById(blogId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/blog/${blogId}`);
  }

  getAllCategories(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/allBlogCategories`);
  }
}
