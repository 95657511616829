import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { BottomNavBarComponent } from './bottom-nav-bar/bottom-nav-bar.component';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { FmLayoutComponent } from './fm-layout/fm-layout.component';
import { FullscreenLayoutComponent } from './fullscreen-layout/fullscreen-layout.component';
import { SidebarMenuComponent } from './sidebar-menu/sidebar-menu.component';
import { TopNavBarComponent } from './top-nav-bar/top-nav-bar.component';
import { TopbarLayoutComponent } from './topbar-layout/topbar-layout.component';
import { MsWebviewLayoutComponent } from './ms-webview-layout/ms-webview-layout.component';
import { RegistrationLayoutComponent } from './registration-layout/registration-layout.component';
import { ProgressStepsComponent } from '../shared/progress-steps/progress-steps.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FffaiNavbarComponent } from './fffai-navbar/fffai-navbar.component';
import { WaiNavbarComponent } from './wai-navbar/wai-navbar.component';
import { AdminWebshopNavbarComponent } from './admin-webshop-navbar/admin-webshop-navbar.component';
import { AdminRegistrationTopBarComponent } from './admin-registration-top-bar/admin-registration-top-bar.component';
import { AdminLoginRegistrationTopBarComponent } from './admin-login-registration-top-bar/admin-login-registration-top-bar.component';
import { EskillTopBarComponent } from './eskill-top-bar/eskill-top-bar.component';


@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    MatSlideToggleModule
  ],
  declarations: [
    DefaultLayoutComponent,
    FmLayoutComponent,
    FullscreenLayoutComponent,
    SidebarMenuComponent,
    TopNavBarComponent,
    FffaiNavbarComponent,
    BottomNavBarComponent,
    TopbarLayoutComponent,
    MsWebviewLayoutComponent,
    RegistrationLayoutComponent,
    ProgressStepsComponent,
    WaiNavbarComponent,
    AdminWebshopNavbarComponent,
    AdminRegistrationTopBarComponent,
    AdminLoginRegistrationTopBarComponent,
    EskillTopBarComponent
  ],
  exports: [
    DefaultLayoutComponent,
    FmLayoutComponent,
    FullscreenLayoutComponent,
    TopbarLayoutComponent,
    FffaiNavbarComponent,
    TopNavBarComponent,
    BottomNavBarComponent,
    MsWebviewLayoutComponent,
    RegistrationLayoutComponent,
    ProgressStepsComponent,
    WaiNavbarComponent,
    AdminWebshopNavbarComponent,
    AdminRegistrationTopBarComponent,
    AdminLoginRegistrationTopBarComponent,
    EskillTopBarComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class SharedLayoutModule { }
