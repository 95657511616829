import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
import { LocalStorageService } from '../core/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MeaningfulUrlService {

  constructor(private storageservice: LocalStorageService) { }

  //for Individual courses course URL
  private newCourseID = new BehaviorSubject<any>({
    courseID:''
  });

  setCourseID(CourseID: any) {
    
    this.newCourseID.next(CourseID);
    this.storageservice.storeInLocalStorage('courseIDStore', JSON.stringify(CourseID));
  }

  getNewCourseID() {
    return this.newCourseID.asObservable();
  }


    //for Team courses course URL
    private newCourseForTeamID = new BehaviorSubject<any>({
      courseForTeamID:''
    });
  
    setCourseForTeamID(CourseID: any) {
      this.newCourseForTeamID.next(CourseID);
      this.storageservice.storeInLocalStorage('courseForTeamIDStore', JSON.stringify(CourseID));
    }
  
    getNewCourseForTeamID() {
      
      return this.newCourseForTeamID.asObservable();
    }


    //for Blog Meaningful URL
    private newBlog = new BehaviorSubject<any>({
      blogId:''
    });
  
    setBlog(CourseID: any) {
      this.newBlog.next(CourseID);
    }
  
    getNewBlog() {
      
      return this.newBlog.asObservable();
    }

}
