import { Injectable } from '@angular/core';
import {Subject, Observable} from "rxjs";
import {filter} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DataSavingService {

  constructor() {
  }

  private _savedChanges = new Subject<boolean>();
  savedChanges$: Observable<boolean> = this._savedChanges.asObservable().pipe(filter(x => !!x));

  private _unsuccessfulSaveChanges = new Subject<boolean>();
  unsuccessfulSaveChanges$: Observable<boolean> = this._unsuccessfulSaveChanges.asObservable().pipe(filter(x => !!x));

  addSavedIndicator(){
    return this._savedChanges.next(true);
  }

  addUnsuccessfulSaveIndicator(){
    return this._unsuccessfulSaveChanges.next(true);
  }
}
