<edu-default-layout>
  <section class="homeLap">
    <section style="margin-top: 40px;" class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
          <div class="homeCsrLeft">
            <h1 class="home__title">
              Essential <span>skills</span> for a <span>dynamic job market</span>
            </h1>
            <p class="home__description">
              At 21CC Education, we believe in the transformative power of education
              and the immense social impact it can have on underprivileged
              communities. Our mission is to collaborate with like-minded companies,
              CSR teams, NGOs, and social development organisations to create
              innovative learning solutions that align with their CSR initiatives in
              bridging the skill gap. Together, we can empower underprivileged
              individuals, uplift communities, and foster sustainable social and
              economic development.
            </p>
            <a href="" class="csr__cta home__anchor nostyle">
              Learn how we can help <i class="fa fa-long-arrow-right"></i
            ></a>
          </div>
            
        </div>
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
          <img style="height: 100%; width: 100%; max-width: 100%;" src="assets/images/csr-landing-page/csrlanding.webp" />
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
          <img style="height: 100%; width: 100%; max-width: 100%;" src="assets/images/csr-landing-page/sdg.webp" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
          <div class="homeCsrRight">
            <h2 class="sdg__title">
              Our Commitment to <span>Sustainable Development Goals</span>
            </h2>
            <p class="sdg__description">
              We are dedicated to advancing the United Nations' Sustainable
              Development Goal (SDG) of Decent Work and Economic Growth. Through homeCsrRight
              partnership, we strive to promote inclusive and quality education,
              ensure access to lifelong learning opportunities, and equip individuals
              from marginalised backgrounds with the skills they need to secure
              gainful employment and contribute meaningfully to society.
            </p>
          </div>
        </div>
      </div>
    </section>
  </section>
  <section class="homeMob">
    <section style="margin-top: 40px;" class="container-fluid">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
          <img style="height: 100%; width: 100%; max-width: 100%;" src="assets/images/csr-landing-page/csrlanding.png" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
          <div class="homeCsrLeft">
            <h1 class="home__title">
              Essential <span>skills</span> for a <span>dynamic job market</span>
            </h1>
            <p class="home__description">
              At 21CC Education, we believe in the transformative power of education
              and the immense social impact it can have on underprivileged
              communities. Our mission is to collaborate with like-minded companies,
              CSR teams, NGOs, and social development organisations to create
              innovative learning solutions that align with their CSR initiatives in
              bridging the skill gap. Together, we can empower underprivileged
              individuals, uplift communities, and foster sustainable social and
              economic development.
            </p>
            <a href="" class="csr__cta home__anchor nostyle">
              Learn how we can help <i class="fa fa-long-arrow-right"></i
            ></a>
          </div>
            
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
          <img style="height: 100%; width: 100%; max-width: 100%;" src="assets/images/csr-landing-page/sdg.webp" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
          <div class="homeCsrRight">
            <h2 class="sdg__title">
              Our Commitment to <span>Sustainable Development Goals</span>
            </h2>
            <p class="sdg__description">
              We are dedicated to advancing the United Nations' Sustainable
              Development Goal (SDG) of Decent Work and Economic Growth. Through homeCsrRight
              partnership, we strive to promote inclusive and quality education,
              ensure access to lifelong learning opportunities, and equip individuals
              from marginalised backgrounds with the skills they need to secure
              gainful employment and contribute meaningfully to society.
            </p>
          </div>
        </div>
      </div>
    </section>
  </section>
  <section class="skilling">
    <h2 class="skilling__title">Why Partner With Us?</h2>
    <div class="row skilling__row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
        <div style="text-align: center;">
          <img class="skilling__image"
            src="../../../assets/images/csr-landing-page/WhySkilling/1.png"
            alt=""
          />
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
        <div class="skilling__description">
          <h3>Bridging the gap</h3>
          <p>
            Our skilling initiatives address the gap between what the industry
            wants and the skills that job seekers lack. With a focus on
            skills-first training, we strive to narrow the divide between
            industry demands and the available skills in the job market. Our
            team of instructional designers and developers possess a wealth of
            experience in developing innovative and customised learning
            solutions that cater to the unique needs of underprivileged
            jobseekers.
          </p>
        </div>
      </div>
    </div>
    <div class="row skilling__row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
        <div style="text-align: center;">
          <img class="skilling__image"
            src="../../../assets/images/csr-landing-page/WhySkilling/2.png"
            alt=""
          />
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
        <div class="skilling__description">
          <h3>Proven Social Impact</h3>
          <p>
            We firmly believe that skills are the new currency. By offering
            skilling opportunities to individuals, we empower them to venture
            into diverse industries and enhance their employability.  Our
            programs have already made a difference in many lives. We have
            successfully trained and empowered individuals from diverse
            backgrounds, enabling them to break barriers and achieve their full
            potential.
          </p>
        </div>
      </div>
    </div>
    <div class="row skilling__row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
        <div style="text-align: center;">
          <img class="skilling__image"
            src="../../../assets/images/csr-landing-page/WhySkilling/3.png"
            alt=""
          />
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
        <div class="skilling__description">
          <h3>Tailored CSR Support</h3>
          <p>
            We understand that every company and CSR initiative is different.
            That's why we work closely with our partners to design tailor-made
            skill development programs that align with your organisation's
            values and goals.
          </p>
        </div>
      </div>
    </div>
    <div class="row skilling__row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
        <div style="text-align: center;">
          <img class="skilling__image"
            src="../../../assets/images/csr-landing-page/WhySkilling/4.png"
            alt=""
          />
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
        <div class="skilling__description">
          <h3>Technology-Driven Approach</h3>
          <p>
            Leveraging technology intuitively, we bring learning to the
            fingertips of those who need it most. Our e-learning platform
            Skilled and digital course library enable remote access and promote
            self-paced learning for better outcomes.
          </p>
        </div>
      </div>
    </div>
    <div class="row skilling__row">
      <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
        <div style="text-align: center;">
          <img class="skilling__image"
            src="../../../assets/images/csr-landing-page/WhySkilling/5.png"
            alt=""
          />
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
        <div class="skilling__description">
          <h3>Synergising Academy and Industry</h3>
          <p>
            We promote collaboration between academic institutions and
            industries resulting in the creation of a highly skilled talent
            pool. Through our efforts, we assist academic institutions in
            aligning their curriculum with industry demands, while
            simultaneously generating an influx of skilled workforce for the
            industry, fuelling economic growth.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="offerings">
    <h2 class="offerings__title">Our Offerings</h2>
    <div class="offerings__offer">
      <div class="offerings__card">
        <figure class="card__image">
          <img
            src="../../../assets/images/csr-landing-page/Offerings/learning platform.png"
            alt="/"
          />
        </figure>
        <div class="card__wrapper">
          <h3 class="card__title">Learning Platform</h3>
          <p class="card__description">
            An intuitive skilling platform to facilitate, assess and track
            learning experiences.
          </p>
        </div>
      </div>
      <div class="offerings__card">
        <figure class="card__image">
          <img
            src="../../../assets/images/csr-landing-page/Offerings/curriculum design.jpg"
            alt="/"
          />
        </figure>
        <div class="card__wrapper">
          <h3 class="card__title">Training Curriculum Design</h3>
          <p class="card__description">
            Customised training plans tailored to specific requirements.
          </p>
        </div>
      </div>
      <div class="offerings__card">
        <figure class="card__image">
          <img
            src="../../../assets/images/csr-landing-page/Offerings/off the shelf.jpg"
            alt="/"
          />
        </figure>
        <div class="card__wrapper">
          <h3 class="card__title">Off-the-Shelf Content</h3>
          <p class="card__description">
            Ready-to-deploy industry specific training materials to fast-track
            training implementation.
          </p>
        </div>
      </div>
      <div class="offerings__card">
        <figure class="card__image">
          <img
            src="../../../assets/images/csr-landing-page/Offerings/tailored content.jpg"
            alt="/"
          />
        </figure>
        <div class="card__wrapper">
          <h3 class="card__title">Tailored Content for Industry Needs</h3>
          <p class="card__description">
            Content made to align with unique industry demands and job-specific
            skills.
          </p>
        </div>
      </div>
      <div class="offerings__card">
        <figure class="card__image">
          <img
            src="../../../assets/images/csr-landing-page/Offerings/ToT.png"
            alt="/"
          />
        </figure>
        <div class="card__wrapper">
          <h3 class="card__title">Train-the-Trainer Workshops</h3>
          <p class="card__description">
            Empowering trainers with specialised skills and knowledge.
          </p>
        </div>
      </div>
      <div class="offerings__card">
        <figure class="card__image">
          <img
            src="../../../assets/images/csr-landing-page/Offerings/marketing outreach.jpg"
            alt="/"
          />
        </figure>
        <div class="card__wrapper">
          <h3 class="card__title">Marketing Support</h3>
          <p class="card__description">
            Assistance in creating marketing collateral to promote training
            initiatives.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="projects">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
        <div>
          <h2 class="projects__title">Past Projects</h2>
          <p class="projects__description">
            We are proud to be the CSR Skilling partner for
            <span>DB Schenker</span>, one of world's leading global logistics
            providers. As a part of the initiative, we support women workers in the
            informal economy from the nothern and eastern parts of india to break
            economic and societal barriers. Together with training partner
            <span>SEWA Bharat</span>, we help women workers acquire the skills
            needed to be employed in the logistics and warehousing industry.
          </p>
        </div>
          
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <img style="height: auto; width: 100%; max-width: 100%;" src="assets/images/csr-landing-page/past-projects.png" />
      </div>
    </div>
  </section>
  <section class="collab">
    <h2 class="collab__title">Collaboration Process</h2>
    <div class="collab__container">
      <div class="collab__process">
        <div class="collab__process--left collab__process--left__yellow">
          <div class="collab__process--icon collab__process--icon__yellow">
            <img
              src="../../../assets/images/csr-landing-page/collab/consultation.svg"
              alt=""
            />
          </div>
          <h2 class="collab__process--title">Initial Consultation</h2>
        </div>
        <p class="collab__process--right collab__process--right__yellow">
          Reach out to us to schedule an initial consultation, where we will
          discuss your CSR goals and identify how our expertise aligns with your
          vision.
        </p>
      </div>
      <div class="collab__arrow collab__arrow--yellow"></div>
      <div class="collab__process">
        <div class="collab__process--left collab__process--left__orange">
          <div class="collab__process--icon collab__process--icon__orange">
            <img
              src="../../../assets/images/csr-landing-page/collab/proposal.svg"
              alt=""
            />
          </div>
          <h2 class="collab__process--title">Customised Proposal</h2>
        </div>
        <p class="collab__process--right collab__process--right__orange">
          Based on our discussions, we will create a personalised proposal
          outlining the partnership scope, program details, and expected social
          impact.
        </p>
      </div>
      <div class="collab__arrow collab__arrow--orange"></div>
      <div class="collab__process">
        <div class="collab__process--left collab__process--left__blue">
          <div class="collab__process--icon collab__process--icon__blue">
            <img
              src="../../../assets/images/csr-landing-page/collab/monitoring.svg"
              alt=""
            />
          </div>
          <h2 class="collab__process--title">Implementation and Monitoring</h2>
        </div>
        <p class="collab__process--right collab__process--right__blue">
          Once the partnership is established, we will work closely with your
          team to implement the skill training programs and provide regular
          updates on progress and outcomes.
        </p>
      </div>
      <div class="collab__arrow collab__arrow--blue"></div>
    </div>
  </section>
  <edu-popup-form 
    *ngIf="showCourseCataloguePopUp"
    (showCourseCataloguePopUpEvent)="closeCourseCataloguePopUp($event)"
  ></edu-popup-form>
  <section class="joinusLap">
    <section class="joinus">
      <div class="joinus__left">
        <h2 class="joinus__title">Join Us In Empowering Lives</h2>
        <p class="joinus__description">
          We invite you to partner with us on this transformative journey that
          makes a lasting impact on the lives of countless individuals. Contact us
          today to learn more about how 21CC Education can support your CSR
          initiatives and be your ideal partner in driving social change through
          education and skill training.
        </p>
  
        <a
          class="csr__cta joinus__anchor nostyle"
          (click)="showPopUpForm()"
        >
          Get in touch with us <i class="fa fa-long-arrow-right"> </i
        ></a>
  
        <div class="joinus__banner">
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/SGD.png"
              alt=""
            />
          </figure>
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/no poverty.png"
              alt=""
            />
          </figure>
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/zero hunger.png"
              alt=""
            />
          </figure>
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/quality education.png"
              alt=""
            />
          </figure>
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/gender equality.png"
              alt=""
            />
          </figure>
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/eco growth.png"
              alt=""
            />
          </figure>
  
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/inequalities.png"
              alt=""
            />
          </figure>
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/partnerships.png"
              alt=""
            />
          </figure>
        </div>
      </div>
      <div class="joinus__right"></div>
    </section>
  </section>
  <section class="joinusMob">
    <section class="joinus">
      <div class="joinus__right">
        <img style="height: 100%; width: 100%; max-width: 100%;" src="assets/images/csr-landing-page/getintouch.jpg" />
      </div>
      <div class="joinus__left">
        <h2 class="joinus__title">Join Us In Empowering Lives</h2>
        <p class="joinus__description">
          We invite you to partner with us on this transformative journey that
          makes a lasting impact on the lives of countless individuals. Contact us
          today to learn more about how 21CC Education can support your CSR
          initiatives and be your ideal partner in driving social change through
          education and skill training.
        </p>

        <a
          class="csr__cta joinus__anchor nostyle"
          (click)="showPopUpForm()"
        >
          Get in touch with us <i class="fa fa-long-arrow-right"> </i
        ></a>

        <div class="joinus__banner">
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/SGD.png"
              alt=""
            />
          </figure>
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/no poverty.png"
              alt=""
            />
          </figure>
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/zero hunger.png"
              alt=""
            />
          </figure>
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/quality education.png"
              alt=""
            />
          </figure>
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/gender equality.png"
              alt=""
            />
          </figure>
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/eco growth.png"
              alt=""
            />
          </figure>

          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/inequalities.png"
              alt=""
            />
          </figure>
          <figure>
            <img
              src="../../../assets/images/csr-landing-page/SDG Icons/partnerships.png"
              alt=""
            />
          </figure>
        </div>
      </div>
    
    </section>
  </section>

</edu-default-layout>
