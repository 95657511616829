<edu-default-layout>
<div class="info-container">
    <header>
      <h1 class="container">Terms of Use</h1>
    </header>
    <div class="container fill-height">
    <h2>Thank you for using 21CC Education!</h2>
    <p>
      21CC Education’s products and services are provided by 21CC Recruitment and Training, Pvt. Ltd. These Terms of Use (“Terms”) govern your use of 21CC’s website, apps, and other products and services (“Services”). As some of our Services may be software that is downloaded to your computer, phone, tablet, or another device, you agree that we may automatically update this software, and that these Terms will apply to such updates. Please read these Terms carefully, and contact us if you have any questions. By using our Services, you agree to be bound by these Terms, including the policies referenced in these Terms.
    </p>
    <h2>Using 21CC</h2>
    <h3>Who May Use our Services</h3>
    <p>
      You may use our Services only if you can form a binding contract with 21CC Education, and only in compliance with these Terms and all applicable laws. When you create your 21CC Education account, and subsequently when you use certain features, you must provide us with accurate and complete information, and you agree to update your information to keep it accurate and complete. Any use or access by anyone under the age of 13 is prohibited, and certain courses may have additional requirements and/or restrictions.
    </p>
    <h3>
      Our License to You
    </h3>
    <p>
      Subject to these Terms and our policies, we grant you a limited, personal, non-exclusive, non-transferable, and revocable license to use our Services. You also agree that you will create, access, and/or use only one user account, and you will not share with any third party access to or access information for your account. Using our Services does not give you ownership of any intellectual property rights in our Services or the content you access.
    </p>
    <h2>Our Courses</h2>
    <h3>Course Modifications</h3>
    <p>
      While we take pride in our world-class courses, unexpected events do occur. 21CC reserves the right to cancel, interrupt, or reschedule any course or to modify any course content or the point value or weight of any assignment, quiz, or other assessment. Courses offered are subject to the Disclaimers and Limitation of Liability sections below.
    </p>
    <h3>No Academic Credit</h3>
    <p>
      Unless otherwise explicitly indicated by a credit-granting institution, participation in or completion of a course does not confer any academic credit. Even if credit is awarded by one institution, there is no presumption that other institutions will accept that credit. You agree not to accept credit for completing a course unless you have earned a Course Certificate (or another equivalent 21CC credential) for that course. 21CC, the course instructors, and the associated participating institutions have no obligation to have a course recognized by any educational institution or accreditation organization.
    </p>
    <h3>Disclaimer of Student-University Relationship</h3>
    <p>
      Nothing in these Terms or otherwise with respect to your participation in any course: (a) establishes any relationship between you and any educational institution with which 21CC may be affiliated; (b) enrolls or registers you in any educational institution, or in any course offered by an educational institution; or (c) entitles you to use the resources of any educational institution beyond participation in the course.
    </p>
    <h2>Your Content</h2>
    <h3>Feedback</h3>
    <p>
      We welcome your suggestions, ideas, comments, and other feedback regarding the Services (“Feedback”). By submitting any Feedback, you grant us the right to use the Feedback without any restriction or any compensation to you. By accepting your Feedback, 21CC does not waive any rights to use similar or related Feedback previously known to 21CC, developed by its employees or contractors, or obtained from other sources.
    </p>
    <h2>Security</h2>
    <p>
      We care about the security of our users. While we work to protect the security of your account and related information, 21CC cannot guarantee that unauthorized third parties will not be able to defeat our security measures. Please notify us immediately of any compromise or unauthorized use of your account by emailing info@21cceducation.com.
    </p>
    <h2>Third Party Content</h2>
    <p>
      Through the Services, you will have the ability to access and/or use content provided by instructors, other users, and/or other third parties and links to websites and services maintained by third parties. 21CC cannot guarantee that such third party content, in the Services or elsewhere, will be free of material you may find objectionable or otherwise inappropriate or of malware or other contaminants that may harm your computer, mobile device, or any files therein. 21CC disclaims any responsibility or liability related to your access or use of such third-party content.
    </p>
    <h2>Copyright and Trademark Policy</h2>
    <p>
      21CC respects the intellectual property rights of our users, participating institutions, and other third parties and expects our users to do the same when using the Services.
    </p>
    <h2>Education Research</h2>
    <p>
      21CC is committed to advancing the science of learning and teaching, and records of your participation in courses may be used for education research. In the interest of this research, you may be exposed to variations in the course content. Research findings will typically be reported at the aggregate level. Your personal identity will not be publicly disclosed in any research findings without your express consent.
    </p>
    <h2>Paid Services</h2>
    <p>
      21CC offers paid Services (e.g., Course Certificates for certain courses) for a fee. Unless otherwise stated, all fees are quoted in INR and U.S. Dollars. You are responsible for paying all fees and applicable taxes in a timely manner with a payment mechanism associated with the applicable paid Services. If your payment method fails or your account is past due, we may collect fees using other collection mechanisms. Fees may vary based on your location and other factors, and 21CC reserves the right to change any fees at any time at its sole discretion. Any change, update, or modification will be effective immediately upon posting through the relevant Services. Refunds may be available for paid Services as described in our Refund Policy.
    </p>
    <h2>Modifying and Terminating our Services</h2>
    <p>
      We are constantly changing and improving our Services. We may add or remove functions, features, or requirements, and we may suspend or stop a Service altogether. Accordingly, 21CC may terminate your use of any Service for any reason. If your use of a paid Service is terminated, a refund may be available under our Refund Policy. None of the 21CC, its participating institutions and instructors, its contributors, sponsors, and other business partners, and their employees, contractors, and other agents (the “21CC Parties”) shall have any liability to you for any such action. You can stop using our Services at any time, although we’ll be sorry to see you go.
    </p>
    <h2>Disclaimers</h2>
    <p>
      The services and all included content are provided on an “as is” basis without warranty of any kind, whether express or implied. The 21cc parties specifically disclaim any and all warranties and conditions of merchantability, fitness for a particular purpose, and non-infringement, and any warranties arising out of course of dealing or usage of trade. The 21cc parties further disclaim any and all liability related to your access or use of the services or any related content. You acknowledge and agree that any access to or use of the services or such content is at your own risk.
    </p>
    <h2>Limitation of Liability</h2>
    <p>
      To the maximum extent permitted by law, the 21cc parties shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your access to or use of or inability to access or use the services; (b) any conduct or content of any party other than the applicable 21cc party, including without limitation, any defamatory, offensive, or illegal conduct; or (c) unauthorized access, use, or alteration of your content or information. In no event shall 21cc’s aggregate liability for all claims related to the services exceed twenty u.s. dollars ($20) or the total amount of fees received by 21cc from you for the use of paid services during the past six months, whichever is greater.
      You acknowledge and agree that the disclaimers and the limitations of liability set forth in this terms of use reflect a reasonable and fair allocation of risk between you and the 21cc parties and that these limitations are an essential basis of 21cc’s ability to make the services available to you on an economically feasible basis.
      You agree that any cause of action related to the services must commence within one (1) year after the cause of action accrues. Otherwise, such cause of action is permanently barred.
    </p>
    <h2>Indemnification</h2>
    <p>
      You agree to indemnify, defend, and hold harmless the 21CC Parties from any and all claims, liabilities, expenses, and damages, including reasonable attorneys’ fees and costs, made by any third party related to (a) your use or attempted use of the Services in violation of these Terms; (b) your violation of any law or rights of any third party; or (c) User Content, including without limitation any claim of infringement or misappropriation of intellectual property or other proprietary rights.
    </p>
    <h2>General Terms</h2>
    <h3>Revisions to the Terms</h3>
    <p>
      We reserve the right to revise the Terms at our sole discretion at any time. Any revisions to the Terms will be effective immediately upon posting by us. For any material changes to the Terms, we will take reasonable steps to notify you of such changes. In all cases, your continued use of the Services after the publication of such changes, with or without notification, constitutes binding acceptance of the revised Terms.
    </p>
    <h3>Severability; Waiver</h3>
    <p>
      If it turns out that a particular provision of these Terms is not enforceable, this will not affect any other terms. If you do not comply with these Terms, and we do not take immediate action, this does not indicate that we relinquish any rights that we may have (such as taking action in the future).
    </p>
    <h3>Participating Institutions</h3>
    <p>
      21CC’s participating institutions are third party beneficiaries of the Terms and may enforce those provisions of the Terms that relate to them.
    </p>
    <h2>Acceptable Use Policy</h2>
    <p>
      21CC’s mission is to provide universal access to the world’s best education. We believe strongly in preserving free speech and expression for our learners as well as academic freedom for our partner institutions and instructors. We also want to make sure that all of our learners and instructors feel safe and comfortable while using our Services. We have drafted these guidelines to ensure that people understand and follow the rules when participating in our online community and otherwise using our Services.
      Although we do not routinely screen or monitor content provided by users, we may remove or edit inappropriate content or activity reported to us or suspend, disable, or terminate a user’s access to all or part of the Services.
    </p>
    <h3>You are prohibited from using our Services to share content that:</h3>
    <ul style="list-style-type: none !important;">
      <li><p>- Contains illegal content or promotes illegal activities with the intent to commit such activities. Please keep in mind that learners who are as young as 13 use 21CC, and we do not allow content that is inappropriate for these younger learners.</p></li>
      <li><p>- Contains credible threats or organizes acts of real-world violence. We don’t allow content that creates a genuine risk of physical injury or property damage, credibly threatens people or public safety, or organizes or encourages harm.</p></li>
      <li><p>- Harasses others. We encourage commentary about people and matters of public interest, but abusive or otherwise inappropriate content directed at private individuals is not allowed.</p></li>
      <li><p>- Violates intellectual property, privacy, or other rights. Do not share content that you do not have the right to share, claim content that you did not create as your own, or otherwise infringe or misappropriate someone else’s intellectual property or other rights. Always attribute materials used or quoted by you to the original copyright owner.</p></li>
      <li><p>- Spams others. Do not share irrelevant or inappropriate advertising, promotional, or solicitation content.</p></li>
      <li><p>- Otherwise, violates the 21CC Terms of Use. Please note that specific courses may have additional rules and requirements.</p></li>
    </ul>
    <h3>You also aren’t allowed to:</h3>
    <ul style="list-style-type: none !important;">
      <li><p>- Do anything that violates local, state, national or international law or breaches any of your contractual obligations or fiduciary duties.</p></li>
      <li><p>- Share your password, let anyone access your account, or do anything that might put your account at risk.</p></li>
      <li><p>- Attempt to access any other user’s account.</p></li>
      <li><p>- Reproduce, transfer, sell, resell, or otherwise misuse any content from our Services, unless specifically authorized to do so.</p></li>
      <li><p>- Access, tamper with, or use non-public areas of our systems, unless specifically authorized to do so.</p></li>
      <li><p>- Break or circumvent our authentication or security measures or otherwise test the vulnerability of our systems or networks, unless specifically authorized to do so.</p></li>
      <li><p>- Try to reverse engineer any portion of our Services.</p></li>
      <li><p>- Try to interfere with any user, host, or network, for example by sending a virus, overloading, spamming, or mail-bombing.</p></li>
      <li><p>- Use our Services to distribute malware.</p></li>
      <li><p>- Impersonate or misrepresent your affiliation with any person or entity.</p></li>
      <li><p>- Encourage or help anyone do any of the things on this list.</p></li>
    </ul>
  </div>
</div>
<br/><br/><br/><br/><br/><br/>
</edu-default-layout>
  