import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationFormsRestService {

  constructor(
    private http: HttpClient
  ) { }

  fetchCountryData() {
    return this.http.get('https://restcountries.com/v2/all');
  }

  submitRegistrationForm(formData: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/companyRegistrationForm`, formData);
  }

  submitPopUpForm(formData: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/websitePopUpForm`, formData);
  }

  subscribeToNewsletter(formData: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/subscribeToNewsletter`, formData);
  }

}
