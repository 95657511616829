import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/shared-form/validation-service/custom-validators';
import { RegistrationFormsService } from '../services/registration-forms.service';

@Component({
  selector: 'edu-fffai-form',
  templateUrl: './fffai-form.component.html',
  styleUrls: ['./fffai-form.component.scss']
})
export class FffaiFormComponent implements OnInit {

  fffaiForm: UntypedFormGroup;
  isFormSubmitted: boolean = false;
  loading: boolean = true;
  textEnteredInFirstName: string = '';
  textEnteredInLastName: string = '';
  textEnteredInAddress: string = '';
  textEnteredInCompany: string = '';
  textEnteredInMembership: string = '';
  textEnteredInJob: string = '';
  isMobileResolution: boolean;

  constructor(
    private registrationFormsService: RegistrationFormsService
  ) { }

  ngOnInit(): void {
    this.isMobileResolution = this.registrationFormsService.getResolution();
    this.createForm();
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  }

  createForm() {
    this.fffaiForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [
        Validators.required
      ]),
      lastName: new UntypedFormControl('', [
        Validators.required
      ]),
      countryCode: new UntypedFormControl('+91', [
        Validators.minLength(2),
        this.registrationFormsService.noWhitespaceValidator
      ]),
      phone: new UntypedFormControl('', [
        Validators.minLength(10),
        Validators.maxLength(10),
        this.registrationFormsService.noWhitespaceValidator
      ]),
      email: new UntypedFormControl('', [
        Validators.required,
        CustomValidators.email,
        this.registrationFormsService.noWhitespaceValidator
      ]),
      company: new UntypedFormControl('', []),
      membership: new UntypedFormControl('', []),
      job: new UntypedFormControl('', [
      ]),
      checkInput: new UntypedFormControl('', [
        Validators.requiredTrue
      ])
    });
  }

  numberOnly(event: any): boolean {
    return this.registrationFormsService.numberOnly(event);
  }

  keepPlusSign(event: any) {
    this.registrationFormsService.keepPlusSign(event);
  }

  noSpaceAllowedInStart(event: any) {
    if (this.textEnteredInAddress.length < 1) {
      this.registrationFormsService.avoidSpaces(event);
    }
  }

  onlyAlphabets(event: any) {
    if (event.srcElement.id == "firstName") {
      if (this.textEnteredInFirstName.length < 1) {
        this.registrationFormsService.avoidSpaces(event);
      }
      this.registrationFormsService.onlyAlphabets(event);
    } else if (event.srcElement.id == "lastName") {
      if (this.textEnteredInLastName.length < 1) {
        this.registrationFormsService.avoidSpaces(event);
      }
      this.registrationFormsService.onlyAlphabets(event);
    } else if (event.srcElement.id == "company") {
      if (this.textEnteredInCompany.length < 1) {
        this.registrationFormsService.avoidSpaces(event);
      }
    } else if (event.srcElement.id == "membership") {
      if (this.textEnteredInMembership.length < 1) {
        this.registrationFormsService.avoidSpaces(event);
      }
    } else if (event.srcElement.id == "job") {
      if (this.textEnteredInJob.length < 1) {
        this.registrationFormsService.avoidSpaces(event);
      }
    }
  }

  startWithAlphabetOnly(event: any) {
    if (event.target.id == "firstName") {
      this.textEnteredInFirstName = this.registrationFormsService.getValue(event);
    } else if (event.target.id == "lastName") {
      this.textEnteredInLastName = this.registrationFormsService.getValue(event);
    } else if (event.target.id == "address") {
      this.textEnteredInAddress = this.registrationFormsService.getValue(event);
    }else if (event.target.id == "company") {
      this.textEnteredInCompany = this.registrationFormsService.getValue(event);
    }else if (event.target.id == "membership") {
      this.textEnteredInMembership = this.registrationFormsService.getValue(event);
    }else if (event.target.id == "job") {
      this.textEnteredInJob = this.registrationFormsService.getValue(event);
    }
  }

  avoidSpaces(event: any) {
    this.registrationFormsService.avoidSpaces(event);
  }

  submitForm() {
    this.fffaiForm.markAllAsTouched();
    if (this.fffaiForm.valid) {
      const firstName = this.fffaiForm?.value?.firstName?.trim();
      const lastName = this.fffaiForm?.value?.lastName?.trim();
      const countryCode = this.fffaiForm?.value?.countryCode?.trim();
      const phone = this.fffaiForm?.value?.phone?.trim();
      const email = this.fffaiForm?.value?.email?.trim();
      const company = this.fffaiForm?.value.company?.trim();
      const membership = this.fffaiForm?.value.membership?.trim();
      const jobTitle = this.fffaiForm?.value.job?.trim();
      const checkInput = this.fffaiForm?.value?.checkInput;

      if (!!firstName && !!lastName && !!email && checkInput) {
        if (phone) {
          if (phone.length == 10 && countryCode.length >= 2) {
            this.submitFormToBackend(firstName, lastName, company, membership, jobTitle, email, countryCode, phone);
          }
        } else {
          this.submitFormToBackend(firstName, lastName, company, membership, jobTitle, email, countryCode, phone);
        }
      }
    } else {
      return;
    }
  }

  submitFormToBackend(firstName: any, lastName: any, company: any, membership: any, jobTitle: any, email: any, countryCode: any, phone: any) {
    this.isFormSubmitted = true;
    var phoneNumber = countryCode + phone;
    let formData = {
      firstName: firstName,
      lastName: lastName,
      company: company,
      membershipNumber: membership,
      jobTitle: jobTitle,
      phoneNumber: phoneNumber,
      email: email,
      formName: "fffai",
      timeStamp: Date.now()
    };
    this.registrationFormsService.submitRegistrationForm(formData).subscribe(
      (response) =>
        (error: any) => console.log(error)
    )
  }
}
