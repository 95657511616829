import {Injectable} from '@angular/core';
// import {NetworkStatus, Plugins} from '@capacitor/core';
import {Network, ConnectionStatus} from '@capacitor/network';


import {BehaviorSubject, from, Observable} from "rxjs";
import {distinctUntilChanged, take} from "rxjs/operators";

// const { Network } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService {

  // private _connectionStatus: BehaviorSubject<NetworkStatus>;
  // connectionStatus$: Observable<NetworkStatus>;

  private _connectionStatus: BehaviorSubject<ConnectionStatus>;
  connectionStatus$: Observable<ConnectionStatus>;

  constructor() { }

  init() {
    this.setInitialNetworkState();
    this.listenForNetworkChange();
  }

  // public currentState(): NetworkStatus {
  //   return this._connectionStatus.getValue()
  // }

  public currentState(): ConnectionStatus {
    return this._connectionStatus.getValue()
  }

  private setInitialNetworkState() {
    from(Network.getStatus())
      .pipe(take(1))
      .subscribe((status) => {
        // this._connectionStatus = new BehaviorSubject<NetworkStatus>(status)
        this._connectionStatus = new BehaviorSubject<ConnectionStatus>(status)
        this.connectionStatus$ = this._connectionStatus.asObservable().pipe(distinctUntilChanged())
      })
  }

  private listenForNetworkChange() {
    Network.addListener('networkStatusChange', (status) => {
      this._connectionStatus.next(status)
    });
  }

}
