import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private displayCartIconSource = new BehaviorSubject<boolean>(true);
  displayCartIcon$ = this.displayCartIconSource.asObservable();

  setDisplayCartIcon(display: boolean): void {
    this.displayCartIconSource.next(display);
  }
}
