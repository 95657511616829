import { Injectable } from '@angular/core';
import { NetworkStatusService } from 'src/app/core/network-status.service';
import { TranslocoService } from '@ngneat/transloco';
// import { AuthDetails, GktAuthService } from 'gkt-auth-library';
import { AuthDetails, AngularAuthService } from 'angular-auth-library';
import { distinctUntilChanged, filter } from "rxjs/operators";
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { FffaiRestService } from './fffai.rest.service';
import data from '../../../../assets/data/fffai-course.json';
import { companyDetails, companyUserDetails, purchaseHistory, UserProfile } from "../../user-profile/models/models";

@Injectable({
  providedIn: 'root'
})
export class FffaiService {

  showCourse: any = data;
  userToAssign: any;

  private _companyDetails = new BehaviorSubject<companyDetails>(null);
  companyDetails$: Observable<companyDetails> = this._companyDetails.asObservable().pipe(filter(x => !!x), distinctUntilChanged());

  private _companyUserDetails = new BehaviorSubject<companyUserDetails>(null);
  companyUserDetails$: Observable<companyUserDetails> = this._companyUserDetails.asObservable().pipe(filter(x => !!x), distinctUntilChanged());

  private _purchaseHistory = new BehaviorSubject<purchaseHistory>(null);
  purchaseHistory$: Observable<purchaseHistory> = this._purchaseHistory.asObservable().pipe(filter(x => !!x), distinctUntilChanged());

  constructor(
    private networkStatus: NetworkStatusService,
    private gktAuthService: AngularAuthService,
    private transloco: TranslocoService,
    private fffaiRestService: FffaiRestService
  ) { }

  init() {
    if (this.isAlreadyInitialised()) { return; }

    this.gktAuthService.setLoginPageRoute('/fffai/login');

    this.userToAssign = this.fffaiRestService.getCompanyDetails()
      .subscribe(
        (companyDetails: companyDetails) => {
          this._companyDetails.next(companyDetails);
        }
      );

    this.fffaiRestService.getCompanyUserDetails()
      .subscribe(
        (companyUserDetails: companyUserDetails) => {
          this._companyUserDetails.next(companyUserDetails);
        }
      );

    this.fffaiRestService.getPurchaseHistory()
      .subscribe(
        (purchaseHistory: purchaseHistory) => {
          this._purchaseHistory.next(purchaseHistory);
        }
      );
  }

  isAlreadyInitialised() {
    return this._companyDetails.getValue() && this._companyUserDetails.getValue() && this._purchaseHistory.getValue();
  }

  getCompanyDetails() {
    return this.fffaiRestService.getCompanyDetails();
  }

  getCompanyUserDetails() {
    return this.fffaiRestService.getCompanyUserDetails();
  }

  getPurchaseHistory() {
    return this.fffaiRestService.getPurchaseHistory();
  }

  editUserDetails(firstName, lastName, email, userId) {
    return this.fffaiRestService.editUserDetails(firstName, lastName, email, userId);
  }

  editCompanyDetails(memberId, name, url) {
    return this.fffaiRestService.editCompany(memberId, name, url);
  }

  createNewAdminUserDetails(firstName: string, lastName: string, email: string, password: any, companyId: any, phone: any) {
    return this.fffaiRestService.createCompanyAdmin(firstName, lastName, email, password, companyId, phone);
  }

  deleteAdmin(companyId: any) {
    return this.fffaiRestService.deleteCompanyAdmin(companyId);
  }

  ssoLoginAdmin(username, password): any {
    return this.fffaiRestService.ssoLoginAdmin(username, password);
  }

  fffaiLoginRegister(username,password,userType,firstName,lastName ):any{
    return this.fffaiRestService.fffaiLoginRegister(username,password,userType,firstName,lastName);
  }

  assignGalleryToUser(username,galleryId ):any{
    return this.fffaiRestService.assignGalleryToUser(username,galleryId);
  }

  updateSSOPassword(username, password): any {
    return this.fffaiRestService.updateSSOPassword(username, password);
  }

  checkCompanyExists(memberId: string): any {
    return this.fffaiRestService.checkCompanyExists(memberId);
  }

  createCompany(memberId: string, companyName: string): any {
    return this.fffaiRestService.createCompany(memberId, companyName, 'fffai.com/' + memberId);
  }

  ssoLoginLearner(username: any, password: any): any {
    return this.fffaiRestService.ssoLoginLearner(username, password);
  }

  login(username, password): Observable<AuthDetails> {
    console.log("inside service");
    return this.gktAuthService.login(username, password)
      .pipe(
        this.checkConnection()
      );
  }

  getValueCompanyDetails(): companyDetails {
    return this._companyDetails.getValue();
  }

  getValueCompanyUserDetails(): companyUserDetails {
    return this._companyUserDetails.getValue();
  }

  getValuePurchaseHistory(): purchaseHistory {
    return this._purchaseHistory.getValue();
  }

  getcompanyAdminByUserId(userId: number): UserProfile {
    return this.getValueCompanyDetails().companyAdmins
      .find(i => i.user.id === userId)
  }

  getcompanyUserByUserId(userId: number): UserProfile {
    let result;
    this.getValueCompanyUserDetails().companyUsers.forEach((data) => {
      result = data.student.find(i => i.user.id === userId)
    });
    return result;
    // return this.getValueCompanyUserDetails().companyUsers.student
    //   .find(i => i.user.id === userId)
  }

  getGalleryFromCourseId(courseId: number): any {
    return this.showCourse.find(i => i.courseId === courseId)
  }

  createCompanyUsers(firstName: string, lastName: string, email: string, password: string, membershipNo: any, phone: string): any {
    return this.fffaiRestService.createCompanyStudent(firstName, lastName, email, password, membershipNo, phone);
  }

  enrolCompanyUsers(dynamicArray: any, type: any): any {
    return this.fffaiRestService.enrolCompanyUsers(dynamicArray, type);
  }

  selfEnrolCompanyUsers(dynamicArray: any): any {
    return this.fffaiRestService.selfEnrolCompanyUsers(dynamicArray);
  }

  generateReport(): any {
    return this.fffaiRestService.generateReport();
  }

  checkNetworkStatus(): boolean {
    return this.networkStatus.currentState().connected;
  }

  logout() {
    // Clear user data when the user logs out
    this._companyDetails.next(null);
    this._companyUserDetails.next(null);
    this._purchaseHistory.next(null);
  }

  private checkConnection() {
    const that = this;
    return function <T>(source: Observable<T>): Observable<T> {
      return new Observable(subscriber => {
        source.subscribe({
          next(value) {
            subscriber.next(value);
          },
          error(error) {
            if (!that.checkNetworkStatus()) {
              const message = that.transloco.translate('registration.check-connection');
              alert(message);
              subscriber.error(EMPTY);
            }
            subscriber.error(error);
          },
          complete() {
            subscriber.complete();
          }
        });
      });
    };
  }
}
