import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy-website',
  templateUrl: './privacy-policy-website.component.html',
  styleUrls: ['./privacy-policy-website.component.scss']
})
export class PrivacyPolicyWebsiteComponent implements OnInit {

  showIt: boolean = true;

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Privacy Policy - 21CC Recruitment & Training");
  }

  languageSwitch(language: string) {
    if(language == 'english'){
      this.showIt = true;
    }else if(language == 'dutch'){
      this.showIt = false;
    }
  }

}
