import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CoursesDataService } from '../courses/services/courses-data.service';
import { LocalStorageService } from 'src/app/core/local-storage.service';

@Component({
  selector: 'edu-my-courses',
  templateUrl: './my-courses.component.html',
  styleUrls: ['./my-courses.component.scss']
})
export class MyCoursesComponent implements OnInit {

  totalTime: number = 0;
  rating: string = 'unchecked';
  myCourses: any = [];
  myModules:any =[];
  enrolledCoursesDetails:any =[];
  rHours: any = 0;
  rMinutes: any = 0;
  loading: boolean = true;
  noCoursesPurchased: boolean;
  message: string = '';
  progressStatus: string = 'active';
  completedStatus: string = '';

  constructor(
    private courseDataService: CoursesDataService,
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute,
    private storageservice: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("My Courses - 21CC Recruitment & Training");
    this.progressOfCourses('progress');
    if (localStorage.getItem('authDetails')) {
      this.courseDataService.getEnrolledCourses().subscribe((enrolledCourses) => {
        this.enrolledCoursesDetails = enrolledCourses;
        this.myCourses = enrolledCourses.filter(item => item.developmentType === "course");
        this.myModules = enrolledCourses.filter(item => item.developmentType === "module");
        this.loading = false;
        this.noCoursesPurchased = false;
      },
        (err) => {
          if (err) {
            this.loading = false;
            this.noCoursesPurchased = true;
          }
        });
    } else {
      this.router.navigate(['/registration/start-with-login']);
    }
  }

  navigateToCourse(myCourse: any): void {
    const sanitizeName = (name) => {
      if (!name) return null;
  
      // Limit the name to 8 words
      const words = name.trim().split(/\s+/).slice(0, 8);
  
      // Convert to lowercase and join with dashes
      return words.map((word) => word.toLowerCase()).join("-");
    };

    const sanitizedCourseName = sanitizeName(myCourse?.name);
    const mycoursesFirstLang = myCourse?.modules?.[0]?.language || 'en';
    // const routeParams = myCourse?.moduleSlug
    //     ? ['/show/course', myCourse.language, myCourse.courseSlug, myCourse.moduleSlug]
    //     : ['/show/course', myCourse.id];
        const routeParams = myCourse?.moduleSlug
        ? ['/show/course', myCourse.language, myCourse.courseSlug, myCourse.moduleSlug]
        : ['/show/course/'+`${mycoursesFirstLang}`+'/'+ `${sanitizedCourseName}`];

    this.router.navigate(routeParams, { relativeTo: this.route });
    this.storageservice.storeInLocalStorage("showCourseContent",JSON.stringify(myCourse) ); 
  }

  navigateToModule(myModule: any): void {
    const sanitizeName = (name) => {
      if (!name) return null;
  
      // Limit the name to 8 words
      const words = name.trim().split(/\s+/).slice(0, 8);
  
      // Convert to lowercase and join with dashes
      return words.map((word) => word.toLowerCase()).join("-");
    };

    const sanitizedCourseName = sanitizeName(myModule?.title);
    const mycoursesFirstLang = myModule?.language || 'en';
    // const routeParams = myCourse?.moduleSlug
    //     ? ['/show/course', myCourse.language, myCourse.courseSlug, myCourse.moduleSlug]
    //     : ['/show/course', myCourse.id];
        const routeParams = myModule?.moduleSlug
        ? ['/show/course', myModule.language, myModule.courseSlug, myModule.moduleSlug]
        : ['/show/course/'+`${mycoursesFirstLang}`+'/'+ `${sanitizedCourseName}`];

        const transformedData = {
          "modules": [myModule]
      };

    this.router.navigate(routeParams, { relativeTo: this.route });
    this.storageservice.storeInLocalStorage("showCourseContent",JSON.stringify(transformedData) ); 
  }

  calculateTotalDuration(modules: any[],module:any,type:string) {
    let count = 0;
    if(type == 'courses'){
      for (const module of modules) {
        count += module.duration || 0;
      }
  
      var num = count;
      var hours = (num / 60);
      this.rHours = Math.floor(hours);
      var minutes = (hours - Math.floor(hours)) * 60;
      this.rMinutes = Math.round(minutes);
      let totalDuration = this.rHours+'h '+' '+this.rMinutes+'m';
      return totalDuration;
    }else{
      count = module.duration || 0;
      var num = count;
      var hours = (num / 60);
      this.rHours = Math.floor(hours);
      var minutes = (hours - Math.floor(hours)) * 60;
      this.rMinutes = Math.round(minutes);
      let totalDuration = this.rHours+'h '+' '+this.rMinutes+'m';
      return totalDuration;
      
    }
    
  }



  progressOfCourses(progressStatus: string) {
    if (progressStatus == 'progress') {
      this.message = "At the moment, you do not have any active<br/> courses. Explore our course list and<br/> continue learning.";
      this.progressStatus = 'active';
      this.completedStatus = '';
    } else {
      this.message = "You haven’t completed any courses yet.<br/> Complete or explore courses to continue.";
      this.completedStatus = 'active';
      this.progressStatus = '';
    }
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "210px";
    document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  }
  
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.body.style.backgroundColor = "white";
  }
  
}