<div class="container py- bg-white">
  <h2 class="text-left my-5" id="sectionTitle">{{ faq.section }}</h2>
  <div class="tab-content">
    <div class="accordion-item my-2" *ngFor="let f of faq.content; index as i">
      <div
        class="accordion-header py-2 pb-3"
        role="tab"
        id="header-{{ i }}"
      >
        <a
          class="d-flex text-muted"
          data-toggle="collapse"
          href="#collapse-{{ i }}"
          aria-expanded="false"
          attr.aria-controls="collapse-{{ i }}"
        >
          <i class="icon pt-1 px-2 fa fa-angle-down"></i>
          <h5 class="pl-1 mb-0 col link">{{ f.question }}</h5>
        </a>
      </div>
      <div
        id="collapse-{{ i }}"
        class="collapse accordion-body pl-2"
        role="tabpanel"
        attr.aria-labelledby="header-{{ i }}"
      >
        <div class="pl-4 pb-5"><p [innerHTML]="f.answer"></p></div>
      </div>
    </div>
  </div>
</div>
