import { Injectable } from '@angular/core';
import { LocalStorageService} from "./local-storage.service";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {distinctUntilChanged, filter} from "rxjs/operators";

export type Role = ('ROLE_ADMIN' | 'ROLE_JOB_SEEKER' | 'ROLE_COMPANY_ADMIN' | 'ROLE_COMPANY_HR' | 'ROLE_RECRUITER' | 'ROLE_RECRUITER_ADMIN' | 'ROLE_STUDENT');

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    public storageService: LocalStorageService
  ) {}

  public _role = new BehaviorSubject<Role[]>(null);
  role$: Observable<Role[]> = this._role.asObservable().pipe(filter(x => !!x), distinctUntilChanged());

  init() {
    const authDetails: AuthRoleDetails = this.retrieveAuthDetails()
    if (authDetails) {
      return this._role.next(authDetails.roles);
    } else {
      return;
    }
  }

  retrieveAuthDetails(): AuthRoleDetails {
    const authDetails: string = this.storageService.retrieveFromLocalStorage('authDetails');
    return JSON.parse(authDetails);
  }
}

export interface AuthRoleDetails {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  roles: Role[];
  token_type: string;
  username: string;
}
