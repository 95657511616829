import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {ContractType} from "../../job-preference/models/models";
import {
  AvatarImage,
  FormInputOption,
  LearningProgressSummaryItem,
  UserProfile
} from "../models/models";

@Injectable({
  providedIn: 'root'
})
export class UserProfileRestService {

  constructor(
    private http: HttpClient
  ) {
  }

  getUserProfile(): Observable<UserProfile> {
    return this.http.get<UserProfile>(`${environment.apiUrl}/user/profile`)
  }

  // I suggest we use the whole object all the time
  // Maybe that makes patch the wrong option
  patchUserProfile(userProfile: UserProfile): Observable<UserProfile> {
    return this.http.patch<UserProfile>(`${environment.apiUrl}/user/profile`, userProfile)
  }

  updateUserLeftOverPosition(leftOverData: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/user/userLeftOver`, leftOverData);
  }

  getUpdatedUserLeftOverPosition(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/user/getUserLeftOver`);
  }

  deleteUserLeftOverPosition() {
    return this.http.delete(`${environment.apiUrl}/user/deleteUserLeftOver`);
  }

  // Base 64 image ok?
  // Please advise on the filesize limit
  // Please return the avatarURL not the Base64
  postUserAvatar(avatarImage: string): Observable<any> {
    const body = {avatar: avatarImage};
    return this.http.post<any>(`${environment.apiUrl}/user/avatar`, body)
  }


  getContractTypeOptions(): Observable<ContractType[]>  {
    return this.http.get<ContractType[]>(`${environment.apiUrl}/job-preferences/contract-types`);
  }


  /*
   * Mike: These where to prefill the dropdowns and stuff - so we dont really need them yet.
   */
  // Unsure if user it the best namespace for this or not
  // Only get of course.
  getFormInputOptions(): Observable<FormInputOption[]> {
    return this.http.get<FormInputOption[]>(`${environment.apiUrl}/user/formOptions`)
  }

  // These are used for
  // Unsure if user it the best namespace for this or not
  // Only get of course.
  getLearningProgressItems(): Observable<LearningProgressSummaryItem[]> {
    return this.http.get<LearningProgressSummaryItem[]>(`${environment.apiUrl}/user/learningProgressSummaryItems`)
  }

  getAvatarImage(): Observable<AvatarImage> {
    return this.http.get<AvatarImage>(`${environment.apiUrl}/user/avatar`);
  }

  getEducationLevelsByCountry(countryCode: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/educations?country=${countryCode}`);
  }
}
