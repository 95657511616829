import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {


  transform(items: any[], searchTerm: string): any[] {
    if(!items) return [];
    if(!searchTerm) return items;

    searchTerm = searchTerm.toLowerCase();
    return items.filter( item => {
      for (let key in item) {
        if(key == 'children'){
          if(this.transform(item['displayName'],searchTerm).length > 0) return true;
        }
        else if(item['displayName'] && item['displayName'].toString().toLowerCase().includes(searchTerm)){
          return true;
        }
      }
      return false;
    });
  }
}
