<edu-default-layout>
<header class="position-relative">
    <h1 class="container">Frequently asked questions</h1>
    <nav class="nav tabnav inverted container" role="tablist">
      <a class="nav-link pb-3 px-1 mr-3 text-white show active" data-toggle="tab" role="tab" (click)="assignName('Careers in logistics')" aria-controls="careers-in-logistics" aria-selected="false" id="careers-in-logistics-tab">Careers in logistics</a>
      <a class="nav-link pb-3 px-1 mr-3 text-white show" data-toggle="tab" role="tab" (click)="assignName('Account setup')" aria-controls="account-setup" aria-selected="false" id="account-setup-tab">Account setup</a>
      <a class="nav-link pb-3 px-1 mr-3 text-white show" data-toggle="tab" role="tab" (click)="assignName('Troubleshooting')" aria-controls="troubleshooting" aria-selected="false" id="troubleshooting-tab">Troubleshooting</a>
      <a class="nav-link pb-3 px-1 mr-3 text-white show" data-toggle="tab" role="tab" (click)="assignName('Payment')" aria-controls="payment" aria-selected="false" id="payment-tab">Payment</a>
      <a class="nav-link pb-3 px-1 mr-3 text-white show" data-toggle="tab" role="tab" (click)="assignName('Corporates')" aria-controls="corporates" aria-selected="false" id="corporates-tab">Corporates</a>
    </nav>
</header>
<app-faq-section [tabName]="tabName"></app-faq-section>
<br/><br/><br/><br/><br/><br/>
</edu-default-layout>
