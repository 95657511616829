<edu-default-layout>
  <div class="info-container">
    <header>
      <h1 class="container">21CC Education Privacy statement</h1>
    </header>
    <div class="position-relative container " style="display: flex; justify-content: center;">
      <nav class="nav tabnav inverted" id="categoryNav" role="tablist">
        <a class="nav-link pb-3 px-1 mr-sm-3 text-white active" data-toggle="tab" data-category="course" role="tab"
          aria-controls="category-course" aria-selected="true" id="all-tab" (click)="languageSwitch('english')">English</a>
        <a class="nav-link pb-3 px-1 mr-sm-3 text-white" data-toggle="tab" data-category="carrer" role="tab"
          aria-controls="category-carrer" aria-selected="true" id="all-tab" (click)="languageSwitch('dutch')">Dutch</a>
      </nav>
    </div>
    <div class="container fill-height">
      <div class="english" style="margin-top: 15px;" *ngIf="showIt">
        <h2>Who are we?</h2>
        <p>
          21CC Education is an EdTech company focused on developing tools to advance the skills of employees and
          students
          through electronic learning methods that also use gaming technologies.
        </p>
        <p>
          In the Netherlands, 21CC Education does business under the name 21CC B.V. and is located at Wilhelmina van
          Pruisenweg 35, 2595 AN The Hague, and is registered in the Chamber of Commerce in The Hague under number
          61073369.
        </p>
        <p>
          In India, 21CC Education conducts business under the name 21CC Vishwa Sarvagana Pvt Ltd and is registered with
          the Ministry of Corporate Affairs under number U80302MH2019PTC320325 and is located at The New Excelsior
          Building, AK Nayak Marg, Fort, Mumbai 400001, India.
        </p>
        <p>
          We are committed to protecting the privacy and security of our users, and being transparent about how we store
          and process their information. Our Privacy Statement describes how we process your personal data and how you
          can
          exercise your privacy rights.
        </p>

        <h2>1) What personal data do we process?</h2>
        <p>
          The personal data that 21CC Education processes depends on the context of your interactions with us, the
          products and features you use, your location at the time of use, and applicable laws. However, the personal
          data
          that we collect broadly falls into the following categories:
        </p>
        <ol style="list-style-type: upper-alpha !important">
          <li>
            <h3>Information you provide to us:</h3><br />
            <p>You may provide us with certain personal information when you sign up for an account and use our
              services,
              when you consult with our customer service team, send us an email, or otherwise communicate with us. This
              may include the following:
              <br />
              - <u>Contact details</u> such as your name, e-mail address, postal address, telephone number, and driver’s
              license number. These contact details are not automatically retrieved by 21CC Education and are only
              recorded in our files after submission by the user.
              <br />
              - Completed <u>studies,</u> highest level of education and diplomas obtained.
              <br />
              - Results obtained while opening the modules, games, videos and documents on the 21CC App. These are used
              to
              compile a ‘digital passport’ of each candidate, which in turn is used to present the candidate’s own 21CC
              skill set. This digital passport can be used to match candidates with vacancies that match educational
              qualifications, skill levels and desired salary level.
              <br />
              - <u>Troubleshooting and Support Information</u>: This is information that you provide, or that we
              otherwise
              collect in connection with support questions we receive from you.
            </p>
          </li>
          <li>
            <h3>Service usage data may include:</h3><br />
            <p>
              Browser or device related information collected through your device, such as email address and IP address
              for debugging purposes. Usage data collected when you interact with our services.
            </p>
          </li>
          <li>
            <h3>Cookies:</h3><br />
            <p>
              A cookie is a small text file that is placed on your device when you first visit the website. The cookies
              we
              use are necessary for the technical operations of the website and for your ease of use. They ensure that
              the
              website works properly and, for example, they remember your preferences. This allows us to optimise our
              website. Of course you can unsubscribe from cookies by setting your internet browser in such a way that it
              no longer stores cookies. Furthermore, you can also delete any information previously saved via your
              browser
              settings.
              <br />
              Please see our cookie policy for further information.
              <br />
              Note: We do not use cookies in The 21CC App.
            </p>
          </li>
          <li>
            <h3>Information from other sources:</h3><br />
            <p>
              From time to time we may collect personal data from other sources, such as our partners, integrations or
              other third parties, for example where you have given permission to a third party to share your data with
              other entities.
            </p>
          </li>
          <li>
            <h3>Special and/or sensitive personal data processed by us</h3><br />
            <p>
              The 21CC App is intended for people aged 16 and over.
              <br />
              If you have reason to believe that a child has provided personal information through our services without
              parental or guardian consent, please contact us at <a
                href="mailto:info@21CCeducation.com">info@21CCeducation.com</a> and we will attempt to remove this
              information from our databases.
            </p>
          </li>
        </ol>

        <h2>2) Why do we need your personal data?</h2>
        <h3>
          We use personal data for the purposes, and on the legal bases, set out below:
        </h3>
        <ul style="list-style-type: none !important;">
          <li>
            <p>
              - To supply services. This means using your personal data to provide the functionality of the services,
              such
              as arranging access to an account, answering questions, etc. We perform these activities to manage our
              mutual contractual relationship.
            </p>
          </li>
          <li>
            <p>
              - To get an idea of the skills or competences of the individual user and to be able to share that
              information with (potential) recruiters, employers or teachers.
            </p>
          </li>
          <li>
            <p>
              - To communicate with you. For any inquiries or customer support, or in situations where we have not
              contracted with you, but need to communicate based on our legitimate interests in operating and supporting
              our services.
            </p>
          </li>
          <li>
            <p>
              - To continue to provide services of the highest quality. To achieve this, 21CC Education is constantly
              analyzing, maintaining, securing and optimizing its systems so that we can make adjustments to our
              platform
              where necessary.
            </p>
          </li>
          <li>
            <p>
              - To ensure proper storage of test results.
            </p>
          </li>
          <li>
            <p>
              - To provide support and improvement of our services. Your personal data may be used for the provision of
              our services, and to support and improve our services.
            </p>
          </li>
          <li>
            <p>
              - To comply with legal obligations. We may use your personal information to comply with governmental
              requests, court orders, subpoenas and other applicable legal requests. This includes any data needed by
              agents and advisors, including lawyers and accountants, to help us comply with legal, accounting, or
              security requirements based on our legitimate interests, and any information necessary to pursue, defend
              and
              resolve a lawsuit or similar legal process.
            </p>
          </li>
          <li>
            <p>
              - To conduct data analyses. On the basis of our legitimate business interests, conduct analyses to improve
              our products and services for our users.
            </p>
          </li>
          <li>
            <p>
              - Marketing. On the condition that you give your permission for this, we may use your personal data to
              provide marketing information and product recommendations about our products and services in accordance
              with
              your preferences and this Privacy Statement.
            </p>
          </li>
        </ul>
        <br />

        <p>
          We also have a presence on social media platforms, including Facebook, LinkedIn and Instagram. All
          information,
          communication or materials that you provide to us via a social media platform, you do at your own risk. We
          would
          like to point out that we cannot control the actions of these platforms and the way in which they process your
          data. Please read the privacy statements of the relevant social media platform carefully.
        </p>

        <h2>3) How long do we keep your information?</h2>

        <p>
          21CC Education will not keep your personal data for longer than is strictly necessary to realize the purposes
          for which your data is collected. The retention period therefore depends on the nature of the data and the
          purposes for which 21CC Education processes this data. We have drawn up an internal policy for this and adhere
          to it.
        </p>
        <p>
          This means, for example, we will keep your account details as long as we are providing services to you, i.e.
          as
          long as you have not terminated the agreement and/or until we have received a message from the school/training
          institute/company that you have deregistered, completed the training, or are no longer employed.
        </p>
        <p>
          Financial data will be kept for the legal period of seven (7) years.
        </p>
        <p>
          At the end of the retention period, we will delete your Information or anonymize your Information. In all
          cases
          in which we anonymize the Information, we use the remaining data for our own research and analysis to gain
          insight into the use of 21CC Education.
        </p>
        <p>
          In the context of application procedures, 21CC Education will, in principle, delete the personal data
          collected
          in the context of an application procedure within four (4) weeks after the end of that procedure, unless you
          indicate that we may keep your data for longer.
        </p>

        <h2>4) Sharing data with third parties</h2>

        <p>
          21CC Education will never sell your data to third parties and will only provide it if this is necessary for
          the
          execution of our agreement with you or to comply with a legal obligation. We sign data processing agreements
          with companies that process your data on our behalf, to ensure that they handle your data with the same level
          of
          security and confidentiality as we do. 21CC Education remains accountable for the data processing in these
          situations.
        </p>

        <h2>5) Security</h2>

        <p>
          We are committed to the security of your personal data. For this reason, we take all appropriate technical and
          organizational measures to protect your Information against loss and against any form of unlawful processing.
        </p>
        <p>
          If you feel that your information is not properly secured or there are indications of data abuse, please
          contact
          our customer service department or contact us via <a
            href="mailto:info@21cceducation.com">info@21cceducation.com</a>.
        </p>

        <h2>6) Where is your personal data processed?</h2>

        <p>
          Personal data of EU residents is stored and processed within the European Economic Area (“EEA”). Personal data
          of residents outside the EU can also be transferred or stored outside the EU. We ensure that this storage and
          processing is fully compliant with the law and that an adequate level of protection is guaranteed. We have
          signed the EU Standard Contractual Clauses (SCCs) with our partners outside Europe.
        </p>

        <h2>7) Your rights</h2>
        <p>
          We will endeavor to respond to your requests within one month or sooner. If we are unable to fully respond
          within this time period, we will inform you before the end of said month. Requests can be made by contacting
          <a href="mailto:info@21cceducation.com">info@21cceducation.com</a>.
        </p>
        <p>
          If we cannot fully determine to which personal data your request relates, we may ask you to further explain or
          specify your request.
        </p>

        <br />


        <h3>Right of inspection</h3><br />
        <p>
          You have the right to inquire whether we have processed your personal data.
        </p>
        <br />

        <h3>Right to object</h3><br />
        <p>
          You have the right, under certain circumstances, to object to the processing of your personal data.
        </p>
        <br />

        <h3>Right of rectification</h3><br />
        <p>
          You have the right to submit a request for the rectification / adjustment of any incorrect personal data
          pertaining to you. In general, it is also possible to supplement your personal data.
        </p>
        <br />

        <h3>The right to be forgotten</h3><br />
        <p>
          You have the right to request that 21CC Education delete certain personal data that applies to you (the
          so-called
          ‘right to be forgotten’). If your request is granted, we will remove the relevant personal data.
          If your personal data is used for direct marketing, you can always object to the processing. In that case, we
          will
          no longer process your personal data for those purposes.
        </p>
        <br />

        <h3>The right of data portability</h3><br />
        <p>
          You have the right to submit a request for data portability. This means that we will make your data available
          to
          you on request in a structured, common and machine-readable form.
          You may submit a request by email to <a href="mailto:Info@21cceducation.com">Info@21cceducation.com</a>.
          <br />
          We would also like to point out that you have the right to submit a complaint to the Dutch Data Protection
          Authority:
          <br />
          <a
            href="https://autoriteitpersoonsgegevens.nl/en/contact-dutch-dpa/contact-us">https://autoriteitpersoonsgegevens.nl/en/contact-dutch-dpa/contact-us</a>
          .
        </p>
        <br />

        <p>
          <strong>This Privacy Statement is subject to periodic revision. The latest revision is dated October 5,
            2021.</strong>
        </p>
      </div>
      <br />
      <div class="dutch" *ngIf="!showIt">
        <h2>Wie zijn wij?</h2>
        <p>
          21CC Education is een EdTech onderneming die gericht is op de ontwikkeling van tools om de vaardigheden van
          werknemers en studenten te bevorderen door middel van elektronische leermethoden waarin ook gebruik wordt
          gemaakt van gaming-technologieën.
        </p>
        <p>
          In Nederland doet 21CC Education zaken onder de naam 21CC B.V. en is gevestigd aan de Wilhelmina van
          Pruisenweg
          35, 2595 AN Den Haag, en staat in de Kamer van Koophandel in Den Haag geregistreerd onder het nummer 61073369.
        </p>
        <p>
          In India doet 21CC Education zaken onder de naam 21CC Vishwa Sarvagana Pvt Ltd en staat bij de Ministry of
          Corporate Affairs ingeschreven onder het nummer U80302MH2019PTC320325 en is gevestigd in The New Excelsior
          Building, A.K. Nayak Marg, Fort, Mumbai 400001, India.
        </p>
        <p>
          Wij streven ernaar de privacy en veiligheid van onze gebruikers te beschermen, en transparant te zijn over hoe
          wij hun informatie opslaan en verwerken. Onze Privacy Statement beschrijft hoe wij jouw persoonsgegevens
          verwerken en hoe je je privacy rechten kunt uitoefenen.
        </p>

        <h2>1) Welke persoonsgegevens verwerken wij?</h2>
        <p>
          De Persoonsgegevens die 21CC Education verwerkt zijn afhankelijk van de context van jouw interacties met ons,
          de
          producten en functies die je gebruikt, jouw locatie op het moment van het gebruik en de daarop van toepassing
          zijnde wetgeving. De Persoonsgegevens die wij verzamelen vallen echter in grote lijnen in de volgende
          categorieën:
        </p>
        <ol style="list-style-type: lower-alpha !important;">
          <li>
            <h3>Informatie die je aan ons verstrekt: </h3><br />
            <p>
              Je kunt bepaalde persoonsgegevens aan ons verstrekken wanneer je je aanmeldt voor een account en onze
              diensten gebruikt, als je overleg pleegt met onze klantenservice, ons een e-mail stuurt, of op een andere
              manier met ons communiceert. Deze informatie kan het volgende omvatten:
              <br />
              - Contactgegevens zoals je naam, e-mailadres, postadres, telefoonnummer, en rijbewijsnummer. Deze
              contactgegevens worden niet automatisch door 21CC Education achterhaald en worden slechts na opgave door
              de
              gebruiker in onze bestanden vastgelegd.
              <br />
              - Afgeronde studies, hoogst genoten opleiding en behaalde diploma’s.
              <br />
              - Resultaten die zijn behaald tijdens het openen van de modules, games, video's en documenten op de 21CC
              App. Deze worden gebruikt om een zogenaamd digitaal paspoort van elke kandidaat samen te stellen en die
              wordt op zijn beurt gebruikt om de kandidaat zijn eigen 21CC skill-set te presenteren. Dit digitale
              paspoort
              kan worden gebruikt om kandidaten te matchen met vacatures die passend zijn op opleidingskwalificaties,
              vaardigheidsniveaus en een eventueel gewenst salarisniveau.
              <br />
              - Probleemoplossings- en ondersteuningsgegevens: dit zijn gegevens die je verstrekt, of die wij anderszins
              verzamelen in verband met ondersteuningsvragen die wij van jouw ontvangen.
            </p>
          </li>
          <li>
            <h3>Gegevens over het gebruik van de service kunnen het volgende omvatten: </h3><br />
            <p>
              Browser- of apparaat gerelateerde informatie die via je apparaat wordt verzameld, zoals e-mailadres en
              IP-adres voor debugging-doeleinden. Gebruiksgegevens die worden verzameld wanneer je interactie hebt met
              onze diensten.
            </p>
          </li>
          <li>
            <h3>Cookies:</h3><br />
            <p>
              Een cookie is een klein tekstbestand dat bij het eerste bezoek aan de website wordt opgeslagen op jouw
              toestel. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en voor
              jouw gebruiksgemak. Zij zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld jouw
              voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. Natuurlijk kun jij je afmelden
              voor cookies door de internetbrowser zodanig in te stellen dat deze geen cookies meer opslaat. Daarnaast
              kun
              jij ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.
              <br />
              Graag verwijzen wij je naar onze cookie policy voor nadere informatie hierover.
              <br />
              In The 21CC App maken wij geen gebruik van cookies.
            </p>
          </li>
          <li>
            <h3>Informatie uit andere bronnen: </h3><br />
            <p>
              Van tijd tot tijd kunnen wij persoonsgegevens verzamelen uit andere bronnen, zoals onze partners,
              integraties of andere derde partijen, bijvoorbeeld wanneer jij toestemming aan een derde partij hebt
              gegeven
              om jouw gegevens met andere instellingen te delen.
            </p>
          </li>
          <li>
            <h3>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h3><br />
            <p>
              The 21CC App is bedoeld voor mensen van 16 jaar en ouder.
              <br />
              Als je reden hebt om aan te nemen dat een kind persoonsgegevens heeft verstrekt via onze diensten zonder
              de
              toestemming van de ouders of voogden, neem dan contact met ons op via <a
                href="mailto:info@21CCeducation.com">info@21CCeducation.com</a> en wij zullen trachten deze informatie
              uit
              onze databases te verwijderen.
            </p>
          </li>
        </ol>

        <h2>2) Waarom hebben wij deze gegevens nodig?</h2>
        <h3>
          Wij gebruiken deze informatie voor de doeleinden en op de rechtsgronden die hieronder worden aangegeven:
        </h3>
        <ul style="list-style-type: none !important;">
          <li>
            <p>
              - Het verlenen van de diensten. Dit betekent het gebruik van jouw persoonsgegevens om de functionaliteit
              van
              de diensten te kunnen leveren, zoals het regelen van toegang tot een account, het beantwoorden van vragen,
              enz. Wij voeren deze activiteiten uit om onze onderlinge contractuele relatie te beheren.
            </p>
          </li>
          <li>
            <p>
              - Om een beeld te verkrijgen van de vaardigheden of competenties van de individuele gebruikers en die
              informatie te mogen delen met (potentiële) recruiters, werkgevers of leraren.
            </p>
          </li>
          <li>
            <p>
              - Om met jou te kunnen communiceren. Bij eventuele vragen of klantenondersteuning of wanneer wij geen
              contract met jou hebben afgesloten, op grond van onze legitieme belangen bij het beheren en ondersteunen
              van
              onze diensten.
            </p>
          </li>
          <li>
            <p>
              - Om diensten van de hoogste kwaliteit te kunnen blijven leveren. Om dit te bewerkstelligen is 21CC
              Education steeds bezig om haar systemen te analyseren, te onderhouden, te beveiligen en te optimaliseren
              zodat we, waar nodig, aanpassingen in onze platform kunnen aanbrengen.
            </p>
          </li>
          <li>
            <p>
              - Om ervoor te zorgen voor de juiste opslag van testresultaten
            </p>
          </li>
          <li>
            <p>
              - Om ondersteuning en verbetering van de diensten te mogen bieden: de persoonsgegevens kunnen worden
              gebruikt om onze diensten te mogen verlenen, deze te ondersteunen en te verbeteren.
            </p>
          </li>
          <li>
            <p>
              - Om te voldoen aan wettelijke verplichtingen; wij kunnen jouw persoonsgegevens gebruiken om te voldoen
              aan
              verzoeken van overheidsinstanties, gerechtelijke bevelen, dagvaardingen en andere toepasselijke juridische
              verzoeken, met inbegrip van gegevens die nodig zijn om informatie te verstrekken aan vertegenwoordigers en
              adviseurs, waaronder advocaten en accountants, om ons te helpen te voldoen aan wettelijke, boekhoudkundige
              of beveiligingsvereisten op basis van onze legitieme belangen, en informatie die nodig is om een
              rechtszaak,
              of soortgelijke juridische procedure te vervolgen en te verdedigen.
            </p>
          </li>
          <li>
            <p>
              - Voor het voeren van gegevensanalyse: op grond van onze legitieme zakelijke belangen bij het verbeteren
              en
              verbeteren van onze producten en diensten voor onze gebruikers.
            </p>
          </li>
          <li>
            <p>
              - Marketing: indien jij hiervoor jouw toestemming verleent, kunnen wij je persoonsgegevens gebruiken om
              marketinginformatie, productaanbevelingen over onze producten en diensten aan te bieden, in
              overeenstemming
              met je voorkeuren en dit Privacy Statement.
            </p>
          </li>
        </ul>
        <br />

        <p>
          Wij zijn ook aanwezig op sociale-mediaplatforms, waaronder Facebook, LinkedIn en Instagram. Alle informatie,
          communicatie of materialen die jij via een social media-platform aan ons verstrekt, doe je op eigen risico.
          Graag maken wij je erop attent dat wij de handelingen van deze platforms en de manier waarop zij jouw gegevens
          verwerken niet kunnen controleren. Lees hiervoor aub de privacy statements van deze social media platform
          aandachtig na.
        </p>

        <h2>3) Hoe lang wij jouw gegevens bewaren.</h2>

        <p>
          21CC Education zal je persoonsgegevens niet langer bewaren dan strikt noodzakelijk is om de doelen te
          realiseren
          waarvoor je gegevens worden verzameld. De bewaartermijn is dus afhankelijk van de aard van de gegevens en de
          doelen waarvoor 21CC Education deze gegevens verwerkt. We hebben hiervoor een intern beleid opgesteld en wij
          houden ons hier ook aan.
        </p>
        <p>
          Dit betekent bijvoorbeeld jouw accountgegevens bewaren zo lang wij de dienst aan je leveren, dus zo lang je de
          overeenkomst niet hebt beëindigd en/of tot dat wij van de school/opleidingsinstituut/bedrijf bericht hebben
          ontvangen dat je bent uitgeschreven/de opleiding hebt afgerond/niet meer werkzaam bent.
        </p>
        <p>
          Financiële gegevens zullen worden bewaard voor de wettelijke periode van 7 jaar.
        </p>
        <p>
          Na afloop van de bewaartermijn verwijderen we jouw Informatie of anonimiseren wij jouw Informatie. In alle
          gevallen waarin wij de Informatie anonimiseren, gebruiken wij de resterende gegevens voor eigen onderzoek en
          analyse om inzicht te krijgen in het gebruik van 21CC Education.
        </p>
        <p>
          In het kader van sollicitatieprocedures verwijdert 21CC Education de persoonsgegevens die in het kader van een
          sollicitatieprocedure zijn verzameld in beginsel binnen vier (4) weken na het einde van die procedure, tenzij
          je
          aangeeft dat wij jouw gegevens langer mogen bewaren.
        </p>

        <h2>4) Delen met derden</h2>

        <p>
          21CC Education zal jouw gegevens nooit aan derde partijen verkopen en zal deze uitsluitend verstrekken indien
          dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke
          verplichting.
          Met bedrijven die jouw gegevens in onze opdracht verwerken sluiten wij een verwerkersovereenkomst om te zorgen
          voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. 21CC Education blijft eind
          verantwoordelijk voor deze verwerkingen.
        </p>

        <h2>5) Beveiliging</h2>

        <p>
          Wij hechten ontzettend veel waarde aan een goede beveiliging van je persoonsgegevens. Hierom treffen wij
          passende technische en organisatorische maatregelen om jouw Informatie te beveiligen tegen verlies of tegen
          enige vorm van onrechtmatige verwerking.
        </p>
        <p>
          Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik,
          neem
          dan contact op met onze klantenservice of via <a
            href="mailto:info@21cceducation.com">info@21cceducation.com</a>.
        </p>

        <h2>6) Waar worden jouw persoonsgegevens verwerkt.</h2>

        <p>
          Persoonsgegevens van inwoners van de EU worden opgeslagen en verwerkt binnen de Europese Economische Ruimte
          (“EER”) . Persoonsgegevens van inwoners buiten de EU kunnen ook buiten de EU worden opgeslagen. Wij zorgen
          ervoor dat deze opslag en verwerking volledig in overeenstemming is met de wet en dat een passend
          beschermingsniveau is gewaarborgd. Met onze partners buiten Europa hebben wij de EU Standard Contractual
          Clauses
          (SCCs) getekend.
        </p>

        <h2>7) Jouw rechten</h2>
        <p>
          Wij zullen steeds zo snel mogelijk en in ieder geval binnen één maand op jouw eventuele verzoek reageren.
          Mocht
          dit niet lukken dan zullen wij jou hierover vóór afloop van de hierboven bedoelde maand informeren. Verzoeken
          kunnen worden gedaan door contact op te nemen via <a
            href="mailto:info@21cceducation.com">info@21cceducation.com</a>.
        </p>
        <br />
        <p>
          Mochten wij niet (volledig) kunnen vaststellen op welke persoonsgegevens je verzoek betrekking heeft dan
          kunnen
          wij jou vragen om je verzoek (nader) toe te lichten of te specificeren.
        </p>

        <br />


        <h3>Inzagerecht</h3><br />
        <p>
          Je hebt het recht een verzoek in te dienen om te achterhalen of wij jouw persoonsgegevens verwerken.
        </p>
        <br />

        <h3>Recht op bezwaar</h3><br />
        <p>
          Je hebt het recht om, onder bepaalde omstandigheden, bezwaar te maken tegen de verwerking van je
          persoonsgegevens.
        </p>
        <br />

        <h3>Recht op rectificatie</h3><br />
        <p>
          Je hebt het recht om een verzoek tot rectificatie / aanpassing van onjuiste op jou betrekking hebbende
          persoonsgegevens in te dienen. In het algemeen is het ook mogelijk om je persoonsgegevens aan te vullen.
        </p>
        <br />

        <h3>Recht op beperking</h3><br />
        <p>
          Je hebt het recht een verzoek in te dienen tot beperking of blokkering van de verwerking van je
          persoonsgegevens.
        </p>
        <br />

        <h3>Recht om vergeten te worden</h3><br />
        <p>
          Je hebt het recht om 21CC Education te verzoeken tot verwijdering van (bepaalde) op jou van toepassing zijnde
          persoonsgegevens (het zogenaamde recht op vergetelheid). Indien jouw verzoek wordt toegekend dan zullen wij de
          betreffende persoonsgegevens verwijderen.
          <br />
          Indien je persoonsgegevens worden gebruikt voor direct marketing kun je altijd bezwaar maken tegen de
          verwerking.
          In dat geval zullen wij je persoonsgegevens niet meer voor die doeleinden verwerken.
        </p>
        <br />

        <h3>Recht op dataportabiliteit</h3><br />
        <p>
          Je hebt het recht een verzoek tot dataportabiliteit in te dienen. Dit betekent dat wij jou- op verzoek- jouw
          gegevens in een gestructureerde, gangbare en machine leesbare vorm aan jou ter beschikking zullen stellen.
        </p>
        <p>
          Je mag een verzoek indienen door middel van een email naar <a
            href="mailto:Info@21cceducation.com">Info@21cceducation.com</a>.
          We wijzen je erop dat je het recht hebt om een klacht in te dienen bij de Autoriteit Persoonsgegevens:
          https://autoriteitpersoonsgegevens.nl/en/contact-dutch-dpa/contact-us
        </p>
        <br />

        <p>
          <strong>Dit statement kan van tijd tot tijd worden herzien, de laatste herziening dateert 5 oktober
            2021.</strong>
        </p>
        <br />
      </div>
    </div>
  </div>
  <br/><br/><br/><br/><br/><br/>
</edu-default-layout>