import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {NgSelectModule} from "@ng-select/ng-select";
import {TranslocoModule} from "@ngneat/transloco";
import {TranslocoLocaleModule} from "@ngneat/transloco-locale";
import {InlineSVGModule} from "ng-inline-svg";
import {SharedFormModule} from "../shared-form/shared-form.module";
import {GalleryItemComponent} from "./gallery-item/gallery-item.component";
import {EuroCurrencyPipe} from "./pipes/euro-currency.pipe";
import { ToggleComponent } from './toggle/toggle.component';
import { ToggleItemComponent } from './toggle/toggle-item/toggle-item.component';
import { CardComponent } from './card/card.component';
import { FavoriteStarComponent } from './favorite-star/favorite-star.component';
import { GalleryRowComponent } from './gallery-row/gallery-row.component';
import { CheckboxRowComponent } from './checkbox-row/checkbox-row.component';
import { LoadingComponent } from './loading/loading.component';
import { SavedIndicatorComponent } from './saved-indicator/saved-indicator.component';
import { UnsuccessfulSaveIndicatorComponent } from './saved-indicator/unsuccessful-save-indicator.component';
import { PopoverComponent } from './popover/popover.component';
import {HammerModule} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import {HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import { ToHtmlPipe } from './pipes/to-html.pipe';
import { GalleryJobComponent } from './gallery-job/gallery-job.component';
import {SkillComponent} from './skill/skill.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { NoContentComponent } from './no-content/no-content.component';
import { ToSanitizedUrlPipe } from './pipes/to-sanitized-url.pipe';
import {ClipboardModule} from 'ngx-clipboard';
import { CompletionOverlayComponent } from './completion-overlay/completion-overlay.component';
import {LanguageModule} from "../core/language/language.module";
import {BackButtonComponent} from "../modules/registration/back-button/back-button.component";
import { OverlayComponent } from './overlay/overlay.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { BlogCardComponent } from './blog-card/blog-card.component';
import { LocationCardComponent } from './location-card/location-card.component';
import { PopupFormComponent} from 'src/app/modules/registration-forms/popup-form/popup-form.component';
import { GetInTouchFormForCoursesComponent} from 'src/app/modules/courses/get-in-touch-form-for-courses/get-in-touch-form-for-courses.component';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule,
    TranslocoLocaleModule,
    SharedFormModule,
    NgSelectModule,
    InlineSVGModule.forRoot(),
    HammerModule,
    ClipboardModule
  ],
  declarations: [
    EuroCurrencyPipe,
    ToggleComponent,
    ToggleItemComponent,
    CardComponent,
    FavoriteStarComponent,
    GalleryItemComponent,
    GalleryRowComponent,
    CheckboxRowComponent,
    LoadingComponent,
    SavedIndicatorComponent,
    UnsuccessfulSaveIndicatorComponent,
    PopoverComponent,
    ToHtmlPipe,
    GalleryJobComponent,
    SkillComponent,
    LoadingSpinnerComponent,
    NoContentComponent,
    ToSanitizedUrlPipe,
    CompletionOverlayComponent,
    BackButtonComponent,
    OverlayComponent,
    ProfileCardComponent,
    SpinnerComponent,
    BlogCardComponent,
    LocationCardComponent,
    PopupFormComponent,
    GetInTouchFormForCoursesComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    TranslocoLocaleModule,
    EuroCurrencyPipe,
    ToggleComponent,
    ToggleItemComponent,
    SharedFormModule,
    CardComponent,
    NgSelectModule,
    FavoriteStarComponent,
    GalleryItemComponent,
    GalleryRowComponent,
    CheckboxRowComponent,
    InlineSVGModule,
    LoadingComponent,
    SavedIndicatorComponent,
    UnsuccessfulSaveIndicatorComponent,
    PopoverComponent,
    ToHtmlPipe,
    SkillComponent,
    LoadingSpinnerComponent,
    NoContentComponent,
    ToSanitizedUrlPipe,
    ClipboardModule,
    CompletionOverlayComponent,
    LanguageModule,
    BackButtonComponent,
    OverlayComponent,
    ProfileCardComponent,
    SpinnerComponent,
    BlogCardComponent,
    LocationCardComponent,
    PopupFormComponent,
    GetInTouchFormForCoursesComponent
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
  ],
})

export class SharedModule { }
