

<ng-container *ngIf="!fallbackMode">
  <input type="date" #datePicker (change)="emitChange(datePicker.value)">
</ng-container>

<ng-container *ngIf="fallbackMode">
  <gkt-input-wrapper>
    <select #daySelect (change)="emitChange()">
      <ng-container *ngFor="let day of days">
        <option [value]="day">{{day}}</option>
      </ng-container>
    </select>
  </gkt-input-wrapper>

  <gkt-input-wrapper>
    <select #monthSelect (change)="emitChange()">
      <ng-container *ngFor="let month of months">
        <option [value]="month">{{month}}</option>
      </ng-container>
    </select>
  </gkt-input-wrapper>

  <gkt-input-wrapper>
    <select #yearSelect (change)="emitChange()">
      <ng-container *ngFor="let year of years">
        <option [value]="year">{{year}}</option>
      </ng-container>
    </select>
  </gkt-input-wrapper>
</ng-container>

