import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'edu-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {
  
  @Input('smallHeader') smallHeader: boolean;
  @Input('addContainer') addContainer: boolean;
  @Input('employeeNumber') employeeNumber: number;
  @Input('companyLogo') companyLogo: string;
  heightValue: number = 122;

  @Output() add = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (!(this.employeeNumber == undefined && this.companyLogo == undefined)) {
      if (this.employeeNumber > 0 && this.companyLogo != '') {
        this.heightValue = 255;
      } else if (this.employeeNumber > 0 && this.companyLogo == '') {
        this.heightValue = 135;
      } else if (!(this.employeeNumber > 0) && this.companyLogo != '') {
        this.heightValue = 230;
      }
    }
  }
  
}
