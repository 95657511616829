import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {IndustryMapData, IndustryMapDataOld} from "../fantasy-map.models";

@Injectable({
  providedIn: 'root'
})
export class FantasyMapRestService {
  previousFMRoute: string;

  constructor(
    private http: HttpClient
  ) { }

  getFantasyMap(industryId?: number): Observable<IndustryMapData> {
    return this.http.get<IndustryMapData>(`${environment.apiUrl}/fantasyMap/${industryId}`)
  }

  logChildFantasyOpened(industryId?: number): Observable<any> {
    return this.http.post(`${environment.apiUrl}/fantasyMap/logOpenedStatus/${industryId}`, {});
  }

}
