<div class="accordion" id="accordionExample">
  <div class="card border-radius-10">
    <h2
      class="card-header"
      id="headingTwo"
      style="margin-top: 0px; height: 200px"
      [style.background-color]="accordion.backgroundColor"
    >
      <div class="row p-3">
        <div class="col-2">
          <img
            src="{{ accordion.imagePath }}"
            alt="{{ accordion.altText }}"
            height="50px"
          />
        </div>
        <div class="col-10" style="text-align: left; padding-left: 25px;">
          <p>
            {{ accordion.heading }}<br />
            <a
              (click)="switchLinks(1)"
              *ngIf="hideLink1"
              href="#"
              class="collapsed dropdown-toggle"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapseTwo' + accordion.number"
              aria-expanded="false"
              [attr.aria-controls]="'collapseTwo' + accordion.number"
            >
              see more
              <i class="arrow down"></i>
            </a>
            <a
              (click)="switchLinks(2)"
              *ngIf="hideLink2"
              href="#"
              class="collapsed dropdown-toggle"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapseTwo' + accordion.number"
              aria-expanded="false"
              [attr.aria-controls]="'collapseTwo' + accordion.number"
            >
              see less
              <i class="arrow up"></i>
            </a>
          </p>
        </div>
        <div class="col-12">
          <p style="text-align: center; margin: 15px;">
            <a
              *ngIf="
                accordion.number == 1"
              routerLink="/{{ link1 }}"
            >
              <button class="btn btn-primary">
                {{ accordion.btnText }}
              </button></a
            >
            <a *ngIf="accordion.number == 2" href="{{ link2 }}" target="_blank">
              <button class="btn btn-primary">
                {{ accordion.btnText }}
              </button></a
            >
            <a *ngIf="accordion.number == 3" href="{{ link3 }}" target="_blank">
              <button class="btn btn-primary">
                {{ accordion.btnText }}
              </button></a
            >
            <a *ngIf="accordion.number == 4" href="{{ link4 }}" target="_blank">
              <button class="btn btn-primary">
                {{ accordion.btnText }}
              </button></a
            >
          </p>
        </div>
      </div>
    </h2>
    <div
      id="collapseTwo{{ accordion.number }}"
      class="accordion-collapse collapse card-body"
      aria-labelledby="headingTwo"
      data-bs-parent="#accordionExample"
    >
      <div class="accordion-body">
        <p>
          {{ accordion.p1 }}
        </p>
        <p>
          <b>{{ accordion.h1 }}</b>
        </p>
        <p>
          {{ accordion.p2 }}
        </p>
        <p>
          <b>{{ accordion.h2 }}</b>
        </p>
        <p>
          {{ accordion.p3 }}
        </p>
        <p>
          <b>{{ accordion.h3 }}</b>
        </p>
        <p>
          {{ accordion.p4 }}
        </p>
        <p>
          <b>{{ accordion.h4 }}</b>
        </p>
        <p>
          {{ accordion.p5 }}
        </p>
      </div>
      <div *ngIf="accordion.number == 2">
        <div class="row p-3">
          <div class="col-2">
            <img
              src="assets/images/our-approach/qrcode.jpg"
              alt="qrcode"
              width="50px"
            />
          </div>
          <div class="col-10" style="text-align: start">
            <h5 style="font-size: large;"><b>Download The 21CC Skilled App</b></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
