import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  disableButtons: boolean = false;
  hideGetInTouchFormAtBottomOfHomePage: boolean = false;

  constructor() { }

  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  scrollToSection(elementId: string): void {
    const section = document.querySelector(`#${elementId}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // section.style.marginTop = '100px'; // Optional, use if necessary
    } else {
      console.warn('Section not found!');
    }
  }
  
}
