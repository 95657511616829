<div
  id="carousel"
  class="carousel slide position-relative mb-5 mt-md-5 mt-lg-5"
  data-ride="carousel" 
  data-pause="false"
>

<div class="carousel-inner box" style="width: 90%; margin: 0 auto;">
  <div class="carousel-item active">
    <div class="row h-100 position-relative">
      <div class="main" *ngIf="!isMobileResolution">
        <div class="div-left width-33 padding-ten-percent text-start">
          <div class="widthForContent">
            <h2>Build a <span style="color: #189CD8;">stronger workforce</span> </h2>
            <span class="description">Drive productivity through cross-functional, localised, bite-sized, and gamified learning.</span>
            <div>
              <button class="custom-button" (click)="redirectUserToPage()">Buy for the team</button>
            </div> 
          </div>
          
        </div>
        <div class="div-right width-67 bannersliderimage">
          <img
            src="assets/images/home/carousel/screen-2.png"
            style="object-fit: contain;width: 90%; height: 90%;"
            alt="carousel-image"
          />
        </div>
      </div>
      <div class="main" *ngIf="isMobileResolution">
        <div class="div-right width-67 bannersliderimage">
          <img
            src="assets/images/home/carousel/screen-2.png"
            style="object-fit: contain;width: 90%; height: 90%;"
            alt="carousel-image"
          />
        </div>
        <div class="div-left width-33 padding-ten-percent row align-items-center justify-content-center text-center">
          <h2>Build a <br/> <span style="color: #189CD8;">stronger <br/> workforce</span> </h2><br/>
          <span class="description">Drive productivity through cross-functional, localised, bite-sized, and gamified learning.</span>
          <div>
            <button class="custom-button" (click)="redirectUserToPage()">Buy for the team</button>
          </div>
         </div>
      </div>
    </div>
  </div>
  <div class="carousel-item p5">
    <div class="row h-100 position-relative">
      <div class="main" *ngIf="!isMobileResolution">
        <div class="width-33 padding-ten-percent text-start">
          <div class="widthForContent">
            <h2>Fast-track <span style="color: #189CD8;">onboarding</span> and<span style="color: #189CD8;"> skilling</span></h2>
            <span class="description">Rapidly familiarise your (future) employees with the industry, company, processes, and culture.</span>
            <div>
              <button class="custom-button" (click)="takeUserToPopUpForm()">Get in touch</button>
            </div> 
          </div>
                  
        </div>
        <div class="width-67 bannersliderimage">
          <a [href]="getAppStoreUrl()" target="_blank">
            <img src="assets/images/home/carousel/screen-1-web.png" style="object-fit: contain;width: 90%; height: 90%;" alt="carousel-image" />
          </a>
        </div>
      </div>
      <div class="main" *ngIf="isMobileResolution">
        <div class="width-67 bannersliderimage">
          <a [href]="getAppStoreUrl()" target="_blank">
            <img src="assets/images/home/carousel/screen-1-mobile.png" style="object-fit: contain;width: 90%; height: 90%;" alt="carousel-image" />
          </a>
        </div>
        <div class="width-33 padding-ten-percent row align-items-center justify-content-center text-center">
          <h2>Fast-track <span style="color: #189CD8;">onboarding</span><br/> and<span style="color: #189CD8;"> skilling</span></h2><br/>
          <span class="description">Rapidly familiarise your (future) employees with the industry, company, processes, and culture.</span>
          <div>
            <button class="custom-button" (click)="takeUserToPopUpForm()">Get in touch</button>
          </div>          
        </div>
      </div>
    </div>
  </div>
  <div class="carousel-item">
    <div class="row h-100 position-relative">
      <div class="main" *ngIf="!isMobileResolution">
        <div class="width-33 padding-ten-percent text-start">
          <div class="widthForContent">
            <h2>Turn employee attrition to <span style="color: #189CD8;">retention</span> </h2>
            <span class="description">Invest in your employees by putting in their hands a digital skills passport, and reap better business results.</span>
            <div>
              <button class="custom-button" (click)="takeUserToPopUpForm()">Get in touch</button>
            </div>
          </div>
          
         </div>
        <div class="width-67 bannersliderimage">
          <img
            src="assets/images/home/carousel/screen-3.png"
            style="object-fit: contain;width: 90%; height: 90%;"
            alt="carousel-image"
            class="align-items-center justify-content-center"
          />
        </div>
      </div>
      <div class="main" *ngIf="isMobileResolution">
        <div class="width-67 bannersliderimage">
          <img
            src="assets/images/home/carousel/screen-3.png"
            style="object-fit: contain;width: 90%; height: 90%;"
            alt="carousel-image"
            class="align-items-center justify-content-center"
          />
        </div>
        <div class="width-33 padding-ten-percent row align-items-center justify-content-center text-center">
          <h2>Turn employee attrition to <br/> <span style="color: #189CD8;">retention</span> </h2><br/>
          <span class="description">Invest in your employees by putting in their hands a digital skills passport, and reap better business results.</span>
          <div>
            <button class="custom-button" (click)="takeUserToPopUpForm()">Get in touch</button>
          </div>
         </div>
      </div>
    </div>
  </div>
  <div class="carousel-item">
    <div class="row h-100 position-relative">
      <div class="main" *ngIf="!isMobileResolution">
        <div class="width-33 padding-ten-percent text-start">
          <div class="widthForContent">
            <h2>Propel growth with <span style="color: #189CD8;">data-driven insights</span> </h2>
            <span class="description">Manage talent, identify skill gaps, track learning, and map skills across your organisation.</span>
            <div>
              <button class="custom-button" (click)="takeUserToPopUpForm()">Get in touch</button>
            </div>
          </div>
          
        </div>
        <div class="width-67 bannersliderimage">
          <img
            src="assets/images/home/carousel/screen-4.png"
            style="object-fit: contain;width: 90%; height: 90%;"
            alt="carousel-image"
          />
        </div>
      </div>
      <div class="main" *ngIf="isMobileResolution">
        <div class="width-67 bannersliderimage" style="display: flex; align-items: center; justify-content: center;">
          <img
            src="assets/images/home/carousel/screen-4.png"
            style="object-fit: contain;width: 90%; height: 90%;"
            alt="carousel-image"
          />
        </div>
        <div class="width-33 padding-ten-percent row align-items-center justify-content-center text-center">
          <h2>Propel growth with <span style="color: #189CD8;">data- <br/>driven insights</span> </h2><br/>
          <span class="description">Manage talent, identify skill gaps, track learning, and map skills across your organisation.</span>
          <div>
            <button class="custom-button" (click)="takeUserToPopUpForm()">Get in touch</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <a
    class="carousel-control-prev prev"
    href="#carousel"
    role="button"
    data-slide="prev"
  >
    <span
      class="carousel-control-prev-icon position-absolute"
      aria-hidden="true"
    ></span>
    <span class="sr-only">Previous</span>
  </a>
  <a
    class="carousel-control-next next"
    href="#carousel"
    role="button"
    data-slide="next"
  >
    <span
      class="carousel-control-next-icon position-absolute"
      aria-hidden="true"
    ></span>
    <span class="sr-only">Next</span>
  </a>

  <ol class="carousel-indicators">
    <li data-target="#carousel" data-slide-to="0" class="active"></li>
    <li data-target="#carousel" data-slide-to="1"></li>
    <li data-target="#carousel" data-slide-to="2"></li>
    <li data-target="#carousel" data-slide-to="3"></li>
  </ol>
  
</div>
