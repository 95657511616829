import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NavigationService} from "../../core/navigation.service";
import {ContentCompletionService} from "../../core/content-completion.service";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";

export interface CompletionData {
  type: ('certificate' | 'badge' | 'coupon');
  name: string;
  image?: string;
}

@Component({
  selector: 'edu-completion-overlay',
  templateUrl: './completion-overlay.component.html',
  styleUrls: ['./completion-overlay.component.scss']
})

export class CompletionOverlayComponent implements OnInit, OnDestroy {

  @Input() redirectBack: boolean;
  completionData$: Observable<CompletionData>;
  typeToImageFile = {
    certificate: 'completion-certificate.png',
    badge: 'completion-reward.png',
    coupon: 'completion-reward.png'
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private contentCompletionService: ContentCompletionService,
  ) { }

  ngOnInit(): void {
    this.completionData$ = this.contentCompletionService.completionOverlay$;
  }

  continue(){
    const challengeId = +this.route.snapshot.queryParamMap.get('challengeId');
    if (challengeId) {
      // the completion overlay is being shown for a completed challenge
      this.contentCompletionService.confirmChallengeCompletionOverlay(challengeId)
        .pipe(catchError( (err) => {
          // if it errors, deactivate the completion overlay so the user isn't stuck
          this.contentCompletionService.deactivateCompletionOverlay();
          return throwError(err);
        })).subscribe(() => {
          this.resolveContinue();
        });
    } else {
      this.resolveContinue();
    }
  }

  resolveContinue(){
    this.contentCompletionService.deactivateCompletionOverlay();
    if (this.redirectBack) {
      this.navigationService.goBack();
    }
  }

  ngOnDestroy() {
    this.contentCompletionService.deactivateCompletionOverlay();
  }

}
