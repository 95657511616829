import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  Input, OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormControlName, NgForm, ValidationErrors } from '@angular/forms';
import { ValidationErrorMessage } from '../validation-service/validation-error-message.service';

@Component({
  selector: 'gkt-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss']
})
export class InputWrapperComponent implements AfterContentInit, OnChanges {

  @Input() label: string;
  @Input() hint: string;
  @Input() error: ValidationErrors;
  @Input() span: string;
  // @Input() error: Map<string, string>;
  @Input() formRef: NgForm; // this is a ref to the parent form. its currently unused

  inputEl: HTMLInputElement;

  errorMessage: string;

  isValid: boolean;


  @ContentChild(FormControlName, {static: false}) formControl;
  showFallbackDatePicker = false;


  constructor(
    private elRef: ElementRef,
    private errors: ValidationErrorMessage
  ) {  }

// TODO: this needs refactoring!
  ngAfterContentInit(): void {
    const buttonEl = this.elRef.nativeElement.querySelector('button');
    const dateSelectEl = this.elRef.nativeElement.querySelector('gky-date-select');
    const dateTextEl = this.elRef.nativeElement.querySelector('gkt-forms-date-text');
    const selectEl = this.elRef.nativeElement.querySelector('select');
    const ngSelectEl = this.elRef.nativeElement.querySelector('input.ng-input');
    const inputEl = this.elRef.nativeElement.querySelector('input');
    const areaEl = this.elRef.nativeElement.querySelector('textarea');
    const wrapperEl = this.elRef.nativeElement.querySelector('gkt-input-wrapper');
    if (wrapperEl || buttonEl) {return; } else {
      // console.log(dateSelectEl, selectEl, inputEl, wrapperEl )
      this.inputEl = dateSelectEl ? dateSelectEl : dateTextEl ? dateTextEl : selectEl ? selectEl : ngSelectEl ? ngSelectEl : inputEl;
      // this.inputEl = dateSelectEl || dateTextEl || selectEl || ngSelectEl || inputEl;
      if (areaEl) {
        this.inputEl = areaEl;
      }
      if (this.inputEl) {
        this.dynamicallyConnectLabel(this.elRef.nativeElement, 'label', this.inputEl.tagName);
      } else {
        console.log(`unable to find an element to attach label to: ${this.elRef.nativeElement.firstChild.tagName}`);
      }
    }
  }


  ngOnChanges(change: SimpleChanges) {
    if (change.error && change.error.currentValue && !change.error.firstChange) {
      this.errorMessage = this.findMessage(change.error.currentValue);
      this.setValidClass(false);
    } else {
      this.errorMessage = null;
      this.setValidClass(true);
    }
  }

  setValidClass(isValid: boolean) {
    if (!this.inputEl) {return; }
    if (isValid) {
      this.inputEl.classList.remove('invalid');
    } else {
      this.inputEl.classList.add('invalid');
    }
  }

  findMessage(error): string {
    return this.errors.getError(error);
  }

  /*
   TODO: Use
    import { uuid } from '../utils/generators';
    a_unique_string: string = uuid()
  */
  private dynamicallyConnectLabel(parentElement: HTMLElement, labelSelector: string, inputSelector: string): void {
    const randId = Math.floor(Math.random() * 10000).toString(10);
    switch (true) {
      case parentElement.querySelector(inputSelector).id !== '' : {
        if (parentElement.querySelector(labelSelector)) {
          parentElement.querySelectorAll(labelSelector).forEach(
            selected => {
              selected.setAttribute('for', parentElement.querySelector(inputSelector).id);
            }
          );
        }
        break;
      }
      case parentElement.querySelector(inputSelector).id === '' : {
        parentElement.querySelector(inputSelector).id = randId;
        if (parentElement.querySelector(labelSelector)) {
          parentElement.querySelectorAll(labelSelector).forEach(
            selected => {
              selected.setAttribute('for', randId);
            }
          );
        }
        break;
      }
    }
  }

}
