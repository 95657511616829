import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  clearLocalStorage() {
    window.localStorage.clear();
  }

  removeFromLocalStorage(key: string) {
    window.localStorage.removeItem(key);
  }

  storeInLocalStorage(key: string, value: string): void {
    try {
      window.localStorage.setItem(key, value);
    } catch {
      throw new Error(`Could not set local storage for ${key}, with value ${value}`);
    }
  }

  retrieveFromLocalStorage(key: string): string {
    const value = window.localStorage.getItem(key);
    if (!value) {
      // console.warn(`Could not find ${key} in localStorage`);
      return null;
    } else {
      return value;
    }
  }

}
