import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from '../services/blog.service';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import { MeaningfulUrlService } from 'src/app/services/meaningfulurl.service';

@Component({
  selector: 'edu-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  blog: any;
  detailsArr: any = [];
  loading: boolean = true;
  blogId:any;
  sanitizeBlogURLName:any;
  modifiedTabName:any;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private blogService: BlogService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private meaningfulUrlService: MeaningfulUrlService

  ) { }

  ngOnInit(): void {
    // this.titleService.setTitle("Blog Details - 21CC Recruitment & Training");
    const blogUrlCategory = this.route.snapshot.paramMap.get('blogUrlCategory');
    const blogSlug = this.route.snapshot.paramMap.get('blogSlug');
    // const blogId = this.route.snapshot.paramMap.get('blogId');

    this.meaningfulUrlService.getNewBlog().subscribe(info => {
      this.blogId = info.blogId;
    });


    if(this.localStorageService.retrieveFromLocalStorage('fffaiWai')){
      // url comes from FFFAI or WAI
      const fffaiwai = this.localStorageService.retrieveFromLocalStorage('fffaiWai');
      if(!this.blogId) {
        this.router.navigate(["/"+fffaiwai+"/blog/" + blogUrlCategory + "/" + blogSlug]);
        const data = blogUrlCategory + "~" + blogSlug;
        this.blogService.getBlogByBlogUrl(data).subscribe((blogData: any) => {
          this.blog = blogData['blogDetails'];
          this.loading = false;
          const title = "Blog - " + this.blog.title;
          this.titleService.setTitle(title);
        });
      }else {
        this.blogService.getBlogById(this.blogId).subscribe((blogData: any) => {
          this.blog = blogData['blogDetails'];
          const title = "Blog - " + this.blog.title;
          this.titleService.setTitle(title);
          if(blogData?.blogDetails?.blogSlug) {
            if(blogData?.blogDetails?.blogUrlCategory) {
              let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogUrlCategory)}/${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
                this.router.navigate(["/"+fffaiwai+"blog/" + urlSlug]);
            }else {
              let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
              this.router.navigate(["/"+fffaiwai+"blog/" + urlSlug]);
            }
          }
          this.loading = false;
        });
      }
    }else{
      // url comes from normal webshop

      if(!this.blogId) {
        const data = blogUrlCategory + "~" + blogSlug;
        this.blogService.getBlogByBlogUrl(data).subscribe((blogData: any) => {
          this.blog = blogData['blogDetails'];
          this.loading = false;
          const title = "Blog - " + this.blog.title;
          this.titleService.setTitle(title);
        });
      }else {
        this.blogService.getBlogById(this.blogId).subscribe((blogData: any) => {
          this.blog = blogData['blogDetails'];
          const title = "Blog - " + this.blog.title;
          this.titleService.setTitle(title);
          if(blogData?.blogDetails?.blogSlug) {
            if(blogData?.blogDetails?.blogUrlCategory) {
              let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogUrlCategory)}/${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
              this.sanitizeBlogURLName = this.sanitizeBlogName(urlSlug)  
              this.router.navigate(['/blog/' + this.sanitizeBlogURLName]);
            }else {
              // let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
              let urlSlug = blogData?.blogDetails?.title;
              this.sanitizeBlogURLName = this.sanitizeBlogName(urlSlug)
              if(blogData?.blogDetails?.category?.length == 0 ){
                this.router.navigate(['/blog/'+this.sanitizeBlogURLName]);
              }else{
                this.modifiedTabName = this.sanitizeBlogName(blogData?.blogDetails?.category[0]);
                this.router.navigate(['/blog/'+this.modifiedTabName+'/'+this.sanitizeBlogURLName]);
              }

              // this.router.navigate(['/blog/' + this.sanitizeBlogURLName]);
            }
          }
          this.loading = false;
        });
      }
    }
    
  }

  sanitizeBlogName(name){
    if (!name) return null;

    // Limit the name to 8 words
    const words = name.trim().split(/\s+/).slice(0, 8);

    // Convert to lowercase and join with dashes
    return words.map((word) => {
      // Only replace spaces with dashes, leaving other special characters like parentheses intact
      return word.replace(/\s+/g, '-').toLowerCase();
    }).join("-");
  }


  navToLatestBlog(value: any) {
    this.loading = true;
    if(isNaN(value)) {
      const data = value?.blogUrlCategory + "~" + value?.blogSlug;
      this.blogService.getBlogByBlogUrl(data).subscribe((blogData: any) => {
        this.blog = blogData['blogDetails'];
        if(blogData?.blogDetails?.blogUrlCategory) {
          let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogUrlCategory)}/${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
          // this.router.navigate(['/blog/' + urlSlug]);
          this.sanitizeBlogURLName = this.sanitizeBlogName(urlSlug)
              this.router.navigate(['/blog/' + this.sanitizeBlogURLName]);
        }else {
          let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
          // this.router.navigate(['/blog/' + urlSlug]);
          this.sanitizeBlogURLName = this.sanitizeBlogName(urlSlug)  
              this.router.navigate(['/blog/' + this.sanitizeBlogURLName]);
        }
        this.loading = false;
      });
    }else {
      this.blogService.getBlogById(value).subscribe((blogData: any) => {
        this.blog = blogData['blogDetails'];
        if(blogData?.blogDetails?.blogSlug) {
          if(blogData?.blogDetails?.blogUrlCategory) {
            let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogUrlCategory)}/${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
            // this.router.navigate(['/blog/' + urlSlug]);
            this.sanitizeBlogURLName = this.sanitizeBlogName(urlSlug)  
              this.router.navigate(['/blog/' + this.sanitizeBlogURLName]);
          }else {
            let urlSlug = `${encodeURIComponent(blogData?.blogDetails?.blogSlug)}`;
            // this.router.navigate(['/blog/' + urlSlug]);
            this.sanitizeBlogURLName = this.sanitizeBlogName(urlSlug)  
            // console.log('enter 7',urlSlug)
              this.router.navigate(['/blog/' + this.sanitizeBlogURLName]);
          }
        }
        this.loading = false;
      });
    }
  }

}
