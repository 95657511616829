import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import { RegistrationFormsService } from 'src/app/modules/registration-forms/services/registration-forms.service';
import { CustomValidators } from 'src/app/shared-form/validation-service/custom-validators';

@Component({
  selector: 'edu-bottom-nav-bar',
  templateUrl: './bottom-nav-bar.component.html',
  styleUrls: ['./bottom-nav-bar.component.scss']
})
export class BottomNavBarComponent implements OnInit {
  
  fffaiRole: any;
  fffai: boolean = false;
  isFormSubmitted: boolean = false;
  newsLetterSubscriptionForm: UntypedFormGroup;
  showIt = 'fffai';
  domainOwnerName:any;

  constructor(
    private router: Router,
    private registrationFormsService: RegistrationFormsService,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.fffaiRole = localStorage.getItem('fffaiRole');

    this.domainOwnerName = this.localStorageService.retrieveFromLocalStorage("domainOwnerName");
    if(this.domainOwnerName){
        if(this.domainOwnerName == 'FFFAI Domain Owner' || this.domainOwnerName == 'WAI Domain Owner' ){
          this.fffai = true;
        }else{
          this.fffai = false;
        }
     }


    if(this.router.url.includes('wai')){
      this.showIt = 'wai';
    }else{
      this.showIt = 'fffai';
    }
    // this.fffai = this.router.url.includes('fffai') ? true : false;
    this.createForm();
  }

  createForm() {
    this.newsLetterSubscriptionForm = this.formBuilder.group({
      email: ['', [
        Validators.required,
        CustomValidators.email,
        this.registrationFormsService.noWhitespaceValidator
        ]]
    });
  }

  submitNewsLetterSubscription() {
    this.newsLetterSubscriptionForm.markAllAsTouched();
    this.handleButtonClickToGA('submitNewsLetter');
    if (this.newsLetterSubscriptionForm.valid) {
      const email = this.newsLetterSubscriptionForm?.value?.email?.trim();
      const timestamp = this.registrationFormsService.getTimeStamp();
      let formData = {
        email: email,
        timeStamp: timestamp
      };
      this.isFormSubmitted = true;
      setTimeout(() => {
        this.isFormSubmitted = false;
      }, 5000);
      this.newsLetterSubscriptionForm.reset();
      this.handleButtonClickToGA('submitNewsLetterSuccess');
      this.registrationFormsService.subscribeToNewsletter(formData).subscribe((data) => {});
    }
  }

  handleButtonClickToGA(parameter: string) {
    gtag('event', 'click', {
      event_category: 'newsletter_submit_button_click',
      event_label: 'Newsletter Submit Button Click'
    });
    switch(parameter) {
      case 'submitNewsLetter':
        gtag('event', 'form_start', {
          event_category: 'newsletter_form_start',
          event_label: 'Newsletter Form Start'
        });
        break;
      case 'submitNewsLetterSuccess':
        gtag('event', 'form_submit', {
          event_category: 'newsletter_form_submit',
          event_label: 'Newsletter Form Submit'
        });
        break;
    }
  }

}
