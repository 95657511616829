import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Translation} from "@ngneat/transloco";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, distinctUntilChanged, filter, map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {IsoLangData} from '../language/language.service';

@Injectable({providedIn: 'root'})
export class LocalLangPreloader {

  private _languages = new BehaviorSubject<IsoLangData[]>(null);
  languages$: Observable<IsoLangData[]> = this._languages.asObservable().pipe(filter(x => !!x), distinctUntilChanged());

  public localTranslations: Map<string, Map<string, string>> = new Map();

  constructor(
    private http: HttpClient
  ) { }

  public getServerLanguages(fallbackLang: string | string[]): Observable<IsoLangData[]> {
    const singleFallbackLang = Array.isArray(fallbackLang) ? fallbackLang[0] : fallbackLang;
    return this.http.get<{ languages: IsoLangData[] }>(`${environment.apiUrl}/languages`)
      .pipe(
        map(langs => langs.languages),
        // Checks that the language from the server is 3 chars or less (in case of backoffice error)
        map(languages => languages.filter(lang => lang.code.length <= 3)),
        catchError(
          () => {
            console.error(`No languages are available on the server, reverting to ${singleFallbackLang}`);
            return of<Array<any>>([{code: singleFallbackLang, isoName: 'English', localName: 'English', flag: ''}]);
          }
        )
      );
  }

  public cacheFallbackLangFiles(langCodes: string[]): Promise<any> {
    const langCalls: Promise<Translation>[] = langCodes
      .map(langCode => {
        return this.http.get<Translation>(`assets/i18n/${langCode}.json`).toPromise()
      })
    return Promise.all(langCalls)
      .then(
        langFiles => {
          langFiles.forEach((langFile, i) => {
            const langMap: Map<string, string> = this.flattenJSONToMap(langFile)
            const langCode = langCodes[i];
            this.localTranslations.set(langCode, langMap)
          })
        }
      )
      .catch((error) => {
        console.warn(`Language pre-fetchFailed`, error)
      })
  }

  private flattenJSONToMap(object): Map<string, string> {
    var flattendObj = {};
    const flattenObject = (obj, keyName) => {
      Object.keys(obj).forEach(key => {

        var newKey = keyName ? `${keyName}.${key}` : `${key}`
        if (typeof obj[key] === "object") {
          // calling the function again
          flattenObject(obj[key], newKey);
        } else {
          flattendObj[newKey] = obj[key];
        }
      });
    };
    const objectToMap = (obj): Map<string, string> => {
      const keys = Object.keys(obj);
      const map = new Map<string, string>();
      for(let i = 0; i < keys.length; i++){
        //inserting new key value pair inside map
        map.set(keys[i], obj[keys[i]]);
      }
      return map;
    }

    flattenObject(object, null)
    return objectToMap(flattendObj);
  }

  storeIsoLangDataObjects(availableLangs){
    this._languages.next(availableLangs);
  }

}
