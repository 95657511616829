import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import { CoursesDataService } from '../courses/services/courses-data.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'edu-module-details',
  templateUrl: './module-details.component.html',
  styleUrls: ['./module-details.component.scss']
})
export class ModuleDetailsComponent implements OnInit {

  course: any = [];
  loading: boolean = true;
  currentDate: any = new Date();
  avgRating: number = 0;
  finalAvgPercentage: number = 0;
  checkShoppingCartBundles:boolean;
  shoppingCartWithoutBundleId:any;
  shoppingCartWithoutBundleIdResult:any;
  totalTime =0;
  rHours:any;
  rMinutes:any;
  courseDetail:any;
  moduleCarousel:any;
  moduleVideoURL: SafeResourceUrl | undefined;


  constructor(
    private coursesDataService: CoursesDataService,
    private route: ActivatedRoute,
    private router: Router,
    public localStorage: LocalStorageService,
    private titleService: Title,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    // console.log("inside course details");
    this.courseDetail =  this.localStorage.retrieveFromLocalStorage("moduleDetail");
    this.course = JSON.parse(this.courseDetail);
    if(this.course){
      this.moduleCarousel = this.course?.module_preview.screenshots;
      this.moduleVideoURL = this.sanitizeUrl(this.course?.module_preview.video);
      console.log('this.moduleVideoURL-------',this.moduleVideoURL);
    }
    // console.log(this.course);
    this.shoppingCartWithoutBundleId=localStorage.getItem("shopping_cart_without_bundle_id");
    this.titleService.setTitle("Module Details - 21CC Recruitment & Training");

    const moduleLang = this.route.snapshot.paramMap.get('courseLang');
    const courseSlug = this.route.snapshot.paramMap.get('courseSlug');
    const moduleSlug = this.route.snapshot.paramMap.get('moduleSlug');
    const moduleId = this.route.snapshot.paramMap.get('moduleId');
    this.loading = false;
    // if(!moduleId) {
    //   const data = moduleLang + "~" + courseSlug + "~" + moduleSlug;
    //   this.coursesDataService.getCourseByCourseUrl(data).subscribe((module)=>{
    //     this.shoppingCartWithoutBundleIdResult=this.shoppingCartWithoutBundleId?.includes(module?.id);
    //     // console.log(this.shoppingCartWithoutBundleIdResult)
    //     for(var j = 0; j < module?.ratings?.length; j++) {
    //       this.totalAvgRating(Number(module.ratings[j].stars));
    //     }
    //     this.course = module;


    //     this.totalTime = this.course?.modules?.reduce((total, module) => total + module.duration, 0);

    //     this.timeConvert(this.totalTime);

    //     var shoppingCartBundles = localStorage.getItem('shopping-cart-bundles');
    //     if(shoppingCartBundles){
    //       this.checkShoppingCartBundles = shoppingCartBundles?.includes(this.course?.id);
    //     }else{
    //       this.checkShoppingCartBundles = false;
    //     }

    //     this.totalAvgPercentageRatings(module?.ratings?.length);
    //     this.loading = false;
    //     let urlSlug = `${encodeURIComponent(moduleLang)}/${encodeURIComponent(module?.courseSlug)}/${encodeURIComponent(module?.moduleSlug)}`;
    //     this.router.navigate(['/course/' + urlSlug]);
    //   });
    // }else {
    //   this.coursesDataService.getCourseByCourseId(moduleId).subscribe((module)=>{
    //     this.shoppingCartWithoutBundleIdResult=this.shoppingCartWithoutBundleId?.includes(module?.id);
    //     // console.log(this.shoppingCartWithoutBundleIdResult)
    //     for(var j = 0; j < module?.ratings?.length; j++) {
    //       this.totalAvgRating(Number(module.ratings[j].stars));
    //     }
    //     this.course = module;

    //     this.totalTime = this.course?.modules?.reduce((total, module) => total + module.duration, 0);

    //     this.timeConvert(this.totalTime);
    //     var shoppingCartBundles = localStorage.getItem('shopping-cart-bundles');
    //     if(shoppingCartBundles){
    //       this.checkShoppingCartBundles = shoppingCartBundles?.includes(this.course?.id);
    //     }else{
    //       this.checkShoppingCartBundles = false;
    //     }
    //     this.totalAvgPercentageRatings(module?.ratings?.length);
    //     this.loading = false;
    //     if(module?.courseSlug) {
    //       let urlSlug = `${encodeURIComponent(module?.language)}/${encodeURIComponent(module?.courseSlug)}/${encodeURIComponent(module?.moduleSlug)}`;
    //       this.router.navigate(['/course/' + urlSlug]);
    //     }
    //   });
    // }
  }

  

  sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  timeConvert(n: any) {
    var num = n;
    var hours = (num / 60);
    this.rHours = Math.floor(hours);
    var minutes = (hours - Math.floor(hours)) * 60;
    this.rMinutes = Math.round(minutes);
  }

  totalAvgRating(rateValue: number) {
    this.avgRating += rateValue;
  }

  totalAvgPercentageRatings(totalRatings: number) {
    this.finalAvgPercentage = (this.avgRating / totalRatings) * 20;
  }

  createRzpayOrder(data) {
    if(!localStorage.getItem('shopping-cart')?.includes(this.course?.id)) this.addToCart(this.course?.id);
    this.router.navigateByUrl('/checkout');
    // call api to create order_id
    //this.payWithRazor(order_id);
  }

  // addToCart(courseId: any){
  //   if (localStorage.getItem('authDetails')) {
  //     var existingEntries = JSON.parse(localStorage.getItem("shopping-cart"));
  //     if (existingEntries == null) existingEntries = [];
  //     var entry = courseId;
  //     existingEntries.push(entry);
  //     localStorage.setItem("shopping-cart", JSON.stringify(existingEntries));
  //   } else {
  //     this.router.navigate(['/registration/start-with-login']);
  //   }
  // }

  addToCart(courseId: string) {
    var existingEntries = JSON.parse(localStorage.getItem("shopping-cart"));
    if (existingEntries == null) existingEntries = [];
    var entry = courseId;
    existingEntries.push(entry);
    localStorage.setItem("shopping-cart", JSON.stringify(existingEntries));
    var existingEntries = JSON.parse(localStorage.getItem("shopping-cart"));
    this.coursesDataService.callComponentMethod();
  }

  getFormattedLanguage(language: string): string {
    if (!language || language.trim() === '') {
      return 'English';
    } else if (language === 'en') {
      return 'English';
    } else if (language === 'hi') {
      return 'हिंदी';
    } else if (language === 'ta') {
      return 'தமிழ்';
    } else {
      return language; // Return the original value if it doesn't match any condition
    }
  }
  

}
