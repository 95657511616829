import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'edu-csr-landing-page',
  templateUrl: './csr-landing-page.component.html',
  styleUrls: ['./csr-landing-page.component.scss'],
})
export class CsrLandingPageComponent implements OnInit {
  isFormSubmitted: boolean = false;
  showThankYouMessage: boolean = false;
  isFormSubmittedDiabled: boolean = false;
  showCourseCataloguePopUp: boolean = false;
  
  closeCourseCataloguePopUp(value: boolean) {
    this.showCourseCataloguePopUp = value;
  }

  showPopUpForm() {
    this.showCourseCataloguePopUp = true;
    
  }
  constructor() {}

  ngOnInit(): void {}
}
