import {Injectable} from '@angular/core';
import {AvailableLangs, TranslocoService} from "@ngneat/transloco";
import {of} from "rxjs";
import {catchError} from "rxjs/operators";
import {LanguageRestService} from "./language-rest.service";
import {LocalStorageService} from "../local-storage.service";
import {LocalLangPreloader} from "../transloco/local-lang-preloader.service";

/*

Only the 2 methods here should be used. The transloco service should not be used for setting or getting the active lang.
Do nto user TranslocoService.setActiveLang() or getActiveLang()

The usersActiveLang is stored in 3 places, which is far from ideal but necessary.
 - server is the SOT
 - LS is used when the user is not logged in
 - Transloco is used throughout the app code.
 */

export interface IsoLangData {
  isoName: string;
  localName: string;
  code: string;
  flag?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private storeInLS = (lang) => this.storageService.storeInLocalStorage('activeLang', lang);
  private retrieveFromLS = () => this.storageService.retrieveFromLocalStorage('activeLang')

  constructor(
    private storageService: LocalStorageService,
    private restService: LanguageRestService,
    private translocoService: TranslocoService,
    private localLangPreloaderService: LocalLangPreloader,
  ) {}

  /**
   * Updates the activeLang in localStorage and on the server
   * before updating the Transloco service
   * @param {string} activeLang
   */
  public setActiveLang(activeLang: string) {
    this.storeInLS(activeLang);
    this.restService.setUserLanguage(activeLang).subscribe();
    this.translocoService.setActiveLang(activeLang);
  }

  public setActiveLangWithoutAuth(activeLang: string) {
    this.storeInLS(activeLang);
    this.translocoService.setActiveLang(activeLang);
  }

  public isLanguageAvailable(langCode): boolean {
    const availableLangs = LanguageService.availableLangsToStrings(this.translocoService.getAvailableLangs());
    return availableLangs.includes(langCode);
  }

  public static availableLangsToStrings(availableLangs: AvailableLangs): string[] {
    let result = availableLangs;
    if (typeof availableLangs[0] !== 'string') {
      // @ts-ignore
      result = availableLangs.map(l => l.label);
    }
    return result as string[];
  }

  public initWithAuth(): void {
    this.restService.getUserLanguage()
      .pipe(
        catchError(() => {
          return of(this.getLocalLang());
        })
      )
      .subscribe(
        userLang => {
          const localLang = this.retrieveFromLS();
          this.setRelevantLanguage(localLang, userLang);
        }
      );
  }

  public initNoAuth(): void {
    this.translocoService.setActiveLang(this.getLocalLang());
  }

  public convertToIsoDataLangObj(availableLanguagesCodes: string[]) {
    let isoLangDataObj: IsoLangData[];
    this.localLangPreloaderService.languages$.subscribe(languages => {
      isoLangDataObj = languages.filter(lang => availableLanguagesCodes.includes(lang.code));
    });
    return isoLangDataObj;
  }

  private getLocalLang() {
    let localLang = this.retrieveFromLS();
    if (!this.isLanguageAvailable(localLang)) {
      console.warn(`${localLang} not available, using en`);
      localLang = 'en';
    }
    return localLang;
  }

  private setRelevantLanguage(localLang, userLang){
    if (localLang !== userLang) {
      if (!localLang) {
        // no local language detected; set language to the one saved in the DB
        this.setActiveLang(userLang);
      } else if (!userLang){
        // possibly a new user; language was set during registration
        this.setActiveLang(localLang);
      } else {
        // a different language was set during registration; prioritize it
        this.setActiveLang(localLang);
      }
    } else {
      if (!userLang && !localLang) {
        // no language detected; setting language to default (en)
        this.setActiveLang('en');
      } else {
        // local and DB languages match
        this.setActiveLang(userLang);
      }
    }
  }
}
