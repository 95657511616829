<div class="vertical-center">
    <div class="container mx-auto mt-1" *ngIf="!isFormSubmitted">
        <button class="close-btn" (click)="closePopUp()">&times;</button>
        <edu-get-in-touch-form-for-courses [popUpFormEvent]="popUpFormEvent" (isFormSubmittedEvent)="isFormSubmittedEventReceived($event)"></edu-get-in-touch-form-for-courses>
    </div>
    <div class="thank-you-message" *ngIf="isFormSubmitted">
        <button class="close-btn" (click)="closePopUp()">&times;</button>
        <h1>Thank you!</h1>
        <img class="bottom-image" src="assets/images/fffai/confirmation-checkmark.svg" width="120" height="120"
            alt="Confirmation Checkmark">
        <!-- <p>We'll be in touch soon!</p> -->
        <button class="btn-submit" (click)="downloadCourseCatalogue()">Continue</button>
    </div>
</div>