import { HTTP_INTERCEPTORS, HttpClientModule, HttpHandler } from '@angular/common/http';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HeadersInterceptorService } from './core/interceptors/headers.interceptor.service';
// import { GktAuthModule, TokenInterceptorService } from 'gkt-auth-library';
import { AngularAuthModule, TokenInterceptorService } from 'angular-auth-library';
import {SharedLayoutModule} from "./shared-layout/shared-layout.module";
import { initializeApp } from "firebase/app";
import { HomeComponent } from "./modules/home/home.component";
import { ContactUsComponent } from "./modules/contact-us/contact-us.component";
// import { CoursesComponent } from "./modules/courses/courses.component";
import { FaqComponent } from "./modules/faq/faq.component";
import { NewsComponent } from "./modules/news/news.component";
import { OurApproachComponent } from "./modules/our-approach/our-approach.component";
import { ProductsComponent } from "./modules/products/products.component";
import { RefundPolicyComponent } from "./modules/refund-policy/refund-policy.component";
import { TermsOfUseComponent } from "./modules/terms-of-use/terms-of-use.component";
import { WhoWeAreComponent } from "./modules/who-we-are/who-we-are.component";
import { NewsDetailsComponent } from "./modules/news/news-details/news-details.component";
import { CareerDetailsComponent } from "./modules/contact-us/career-details/career-details.component";
import { CarouselComponent } from "./shared/carousel/carousel.component";
import { AccordionComponent } from "./shared/accordion/accordion.component";
import { CareerCardComponent } from "./shared/career-card/career-card.component";
import { FaqSectionComponent } from "./shared/faq-section/faq-section.component";
import { KnowMoreComponent } from "./shared/know-more/know-more.component";
import { NewsCardComponent } from "./shared/news-card/news-card.component";
import { SafeHtmlPipe } from "./shared/pipes/safe-html.pipe";
import { ProductCardComponent } from "./shared/product-card/product-card.component";
import { WhyCardComponent } from "./shared/why-card/why-card.component";
import { PrivacyPolicyWebsiteComponent } from "./modules/privacy-policy-website/privacy-policy-website.component";
// import { ProductCardsComponent } from "./shared/product-cards/product-cards.component";
import { CourseCategoryDetailsComponent } from './modules/course-category-details/course-category-details.component';
import { ModuleDetailsComponent } from './modules/module-details/module-details.component';
import { SharedModule } from './shared/shared.module';
import { CourseDetailsComponent } from './modules/course-details/course-details.component';
import { MyCoursesComponent } from './modules/my-courses/my-courses.component';
import { ShowCourseComponent } from './modules/show-course/show-course.component';
import { CheckoutComponent } from './modules/checkout/checkout.component';
import { CareerContactComponent } from './modules/career-contact/career-contact/career-contact.component';
import { FffaiComponent } from './modules/fffai/fffai.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';

initializeApp(environment.firebase);
import { RazorpayAuthService } from './services/razorpay-auth.service';
import { SearchPipe } from './modules/courses/search.pipe';
import { BlogComponent } from './modules/blog/blog.component';
import { BlogDetailsComponent } from './modules/blog/blog-details/blog-details.component';
import { GIZFormComponent } from './modules/registration-forms/giz-form/giz-form.component';
import { FffaiFormComponent } from './modules/registration-forms/fffai-form/fffai-form.component';
// import { PopupFormComponent } from './modules/registration-forms/popup-form/popup-form.component';
import { GetInTouchFormComponent } from './modules/registration-forms/get-in-touch-form/get-in-touch-form.component';
import { CsrLandingPageComponent } from './modules/csr-landing-page/csr-landing-page.component';
import { WaiComponent } from './modules/wai/wai.component';
import { SelectPlanComponent } from './modules/select-plan/select-plan.component';
import { EskillComponent } from './modules/eskill/eskill.component';
// import { AdminComponent } from './modules/admin/admin.component';
import { CustomHttpClient } from 'src/app/core/interceptors/CustomHttpClient.service';
import { PortsComponent } from './modules/ports/ports.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';


// noinspection AngularInvalidImportedOrDeclaredSymbol
@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedLayoutModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    MatSliderModule,
    MatSelectModule,
    NgSelectModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    // AngularAuthModule.forRoot({
    //   setPasswordUrl: environment.auth.setPasswordUrl,
    //   requestResetUrl: environment.auth.resetPasswordUrl,
    //   registrationUrl: environment.auth.registrationUrl,
    //   loginPageRoute: environment.auth.loginPageRoute,
    //   loginUrl: environment.auth.loginUrl,
    //   refreshTokenUrl: environment.auth.refreshTokenUrl,
    //   skipAuthUrlFragments: environment.auth.skipAuthUrlFragments,
    // }),
    // AngularAuthModule.forRoot({
    //   baseUrl: environment.baseUrl,
    //   setPasswordPath: environment.auth.setPasswordPath,
    //   requestResetPath: environment.auth.resetPasswordPath,
    //   registrationPath: environment.auth.registrationPath,
    //   loginPageRoute: environment.auth.loginPageRoute,
    //   loginPath: environment.auth.loginPath,
    //   refreshTokenPath: environment.auth.refreshTokenPath,
    //   skipAuthUrlPathPartials: environment.auth.skipAuthUrlPathPartials
    // })

    AngularAuthModule.forRoot({
      baseUrl: environment.baseUrl,
      setPasswordPath: environment.auth.setPasswordPath,
      requestResetPath: environment.auth.resetPasswordPath,
      registrationPath: environment.auth.registrationPath,
      loginPageRoute: environment.auth.loginPageRoute,
      loginPath: environment.auth.loginPath,
      refreshTokenPath: environment.auth.refreshTokenPath,
      skipAuthUrlPathPartials: environment.auth.skipAuthUrlPathPartials
    }),
    RecaptchaV3Module,
    RecaptchaModule,
    RecaptchaFormsModule,
    
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    ContactUsComponent,
    // CoursesComponent,
    FaqComponent,
    NewsComponent,
    OurApproachComponent,
    PrivacyPolicyWebsiteComponent,
    ProductsComponent,
    RefundPolicyComponent,
    TermsOfUseComponent,
    WhoWeAreComponent,
    NewsDetailsComponent,
    CareerDetailsComponent,
    CarouselComponent,
    AccordionComponent,
    CareerCardComponent,
    FaqSectionComponent,
    KnowMoreComponent,
    NewsCardComponent,
    SafeHtmlPipe,
    ProductCardComponent,
    WhyCardComponent,
    // ProductCardsComponent,
    CourseCategoryDetailsComponent,
    ModuleDetailsComponent,
    CourseDetailsComponent,
    MyCoursesComponent,
    ShowCourseComponent,
    CheckoutComponent,
    CareerContactComponent,
    FffaiComponent,
    SearchPipe,
    BlogComponent,
    BlogDetailsComponent,
    GIZFormComponent,
    FffaiFormComponent,
    // PopupFormComponent,
    GetInTouchFormComponent,
    CsrLandingPageComponent,
    WaiComponent,
    SelectPlanComponent,
    EskillComponent,
    PortsComponent,
    // AdminComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // Don't import services here. Use this syntax in the service @Injector({providedIn: 'root'})
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: CustomHttpClient,
      useFactory: (handler: HttpHandler) => new CustomHttpClient(handler),
      deps: [HttpHandler],
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.auth.v2captchaSiteKey,
      } as RecaptchaSettings,
    },
    { provide: RECAPTCHA_V3_SITE_KEY, 
      useValue: environment.auth.v3captchaSiteKey },
    RazorpayAuthService,
    Title,
    SearchPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
