import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import contactData from '../../../assets/data/contact.json';
import { Router, NavigationEnd } from '@angular/router';
import { ViewportScroller } from '@angular/common';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contacts: any = contactData;
  tabName: string = "All";

  constructor(private titleService: Title, private router: Router,private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
       window.scrollTo({
          top: -20,
          behavior: 'smooth'
        });
    // console.log('coming inot contact us');
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     window.scrollTo({
    //       top: 0,
    //       behavior: 'smooth'
    //     });
    //   }
    // });
    this.titleService.setTitle("Contact Us - 21CC Recruitment & Training");
  }

  scrollToTop(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
  assignName(name: string){
    this.tabName = name;
  }

}
