import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Pipe({
  name: 'toSanitizedUrl'
})
export class ToSanitizedUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string): SafeResourceUrl {
    if (value) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }
  }

}
