<nav class="navbar navbar-expand-md bg-white w-100 shadow-below">
    <img *ngIf="isLoggedIn; else other_link" class="mb-2" style="height: 40px;width: 90px;" src="../../../../assets/images/wailogo.png" />
  
    <a *ngIf="isLoggedIn; else other_link" class="logo mb-2" routerLink="/wai/my-profile"></a>
    <ng-template #other_link>
      <a class="logo mb-2" routerLink="/wai"></a>
    </ng-template>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#header" aria-controls="header"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="header">
      <ul class="navbar-nav ml-auto" id="items-for-navbar">
  
        <!-- <li class="nav-item d-md-none dropdown" *ngIf="isLoggedIn">
          <a class="nav-link dropdown-toggle" href="#" id="dropdownMenuLink3" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">Profile</a>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink3" style="margin-right: 5%;"> -->
        <li class="nav-item" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/my-profile" *ngIf="isAdmin || isManager" routerLinkActive="active"
          #myCourses="routerLinkActive">
          <a class="nav-link">My Profile</a>
        </li>
        <li class="nav-item" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/learner-my-profile" *ngIf="isUser" routerLinkActive="active" #myCourses="routerLinkActive">
          <a class="nav-link">My Profile</a>
        </li>
        <!-- <li class="nav-item" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/add-users" *ngIf="isAdmin || isManager" routerLinkActive="active"
          #myCourses="routerLinkActive">
          <a class="nav-link">Add Users</a>
        </li> -->
        <li class="nav-item" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/company-details" *ngIf="isAdmin || isManager" routerLinkActive="active"
          #myCourses="routerLinkActive">
          <a class="nav-link">Company Details</a>
        </li>
        <li class="nav-item" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/company-users" *ngIf="isAdmin || isManager" routerLinkActive="active"
          #myCourses="routerLinkActive">
          <a class="nav-link">Company Learners</a>
        </li>
        <li class="nav-item" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
        routerLink="/wai/industry/0" routerLinkActive="active" #myCourses="routerLinkActive" *ngIf="isAdmin" >
        <a class="nav-link">View as Learner</a>
        </li>
        <li class="nav-item" [ngClass]="{ color: purchaseHistory.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/purchase-history" *ngIf="isAdmin || isManager" routerLinkActive="active"
          #purchaseHistory="routerLinkActive">
          <a class="nav-link">Purchase History</a>
        </li>
        <!-- <li class="nav-item" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
          *ngIf="isAdmin || isManager" routerLinkActive="active" #myCourses="routerLinkActive">
          <a class="nav-link" (click)="generateReport()">Generate Report</a>
        </li> -->
        <!-- <li class="nav-item" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }" *ngIf="isUser"
          routerLink="/wai/courses" routerLinkActive="active" #myCourses="routerLinkActive">
          <a class="nav-link">My Courses</a>
        </li>
        <li class="nav-item" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/purchase-history" *ngIf="isAdmin || isManager || isUser" routerLinkActive="active"
          #myCourses="routerLinkActive">
          <a class="nav-link">Purchase History</a>
        </li> -->
        <div class="nav-item" *ngIf="isUser" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/my-courses" routerLinkActive="active" #myCourses="routerLinkActive">
          <a class="nav-link">My Courses</a>
        </div>
        <div class="nav-item" *ngIf="isUser" [ngClass]="{ color: fantasyMap.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/industry/0" routerLinkActive="active" #fantasyMap="routerLinkActive">
          <a class="nav-link">Fantasy Map</a>
        </div>
        <div class="nav-item" *ngIf="isUser" [ngClass]="{ color: certificates.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/user-certificates" routerLinkActive="active" #certificates="routerLinkActive">
          <a class="nav-link">My Certificates</a>
        </div>
        <div class="nav-item" *ngIf="isUser" [ngClass]="{ color: leaderboard.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/scores" routerLinkActive="active" #leaderboard="routerLinkActive">
          <a class="nav-link">Leaderboard</a>
        </div>
        <div class="nav-item" *ngIf="isUser" [ngClass]="{ color: skills.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/skills" routerLinkActive="active" #skills="routerLinkActive">
          <a class="nav-link">Skills Passport</a>
        </div>
        <li class="nav-item" [ngClass]="{ color: news.isActive ? '#189CD8' : '#262D5F' }" routerLink="/wai/blog"
          routerLinkActive="active" #news="routerLinkActive">
          <a class="nav-link" id="news">Blog</a>
        </li>
        <li class="nav-item" [ngClass]="{ color: contact.isActive ? '#189CD8' : '#262D5F' }"
          routerLink="/wai/contact-us" routerLinkActive="active" #contact="routerLinkActive">
          <a class="nav-link" id="contact">Contact Us</a>
        </li>
        <li class="nav-item d-none d-md-block" style="border-bottom: none; padding-top: 2px;"
          *ngIf="isLoggedIn && isUser">
          <a class="nav-link position-relative shopping-cart" routerLink="/wai/checkout">
            <img src="assets/images/box.svg" alt="box" />
            <div class="indicator badge-pill badge-danger small d-none" *ngIf="totalItems == 0">2</div>
            <div class="indicator badge-pill badge-danger small" *ngIf="totalItems > 0">{{ totalItems }}</div>
          </a>
        </li>
        <li class="nav-item" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
          *ngIf="isAdmin || isManager || isUser" routerLink="/wai/login" (click)="logout()" routerLinkActive="active"
          #myCourses="routerLinkActive">
          <a class="nav-link">Log out</a>
        </li>
        <!-- </ul>
          <br/><br/><br/><br/>
        </li> -->
        <li class="nav-item" style="border-bottom: none" *ngIf="!isLoggedIn">
          <a class="nav-link" routerLink="/wai/login">Login</a>
        </li>
        <li class="nav-item empty user d-none d-md-block" *ngIf="isLoggedIn" style="border-bottom: none">
          <!-- <a
            data-toggle="collapse"
            data-target="#userFoldOut"
            aria-expanded="false"
            aria-controls="userFoldOut"
          >
            <div class="avatar-container">
              <div>
                <img
                  [hidden]="!(avatarImageSrc$ | async)"
                  [src]="avatarImageSrc$ | async"
                  alt="current users avatar"
                />
              </div>
            </div>
          </a> -->
          <!-- <div class="avatar-container">
            <div>
              <img
                [hidden]="!(avatarImageSrc$ | async)"
                [src]="avatarImageSrc$ | async"
                alt="current users avatar"
              />
            </div>
          </div> -->
  
        </li>
      </ul>
    </div>
  </nav>
  <div class="collapse row col-3 bg-white" id="userFoldOut">
    <div class="wrapper pt-3">
      <div class="w-100 pb-20 pt-42 mt-1" *ngIf="isAdmin || isManager"
        [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }" routerLink="/wai/my-profile"
        routerLinkActive="active" #myCourses="routerLinkActive">
        <a class="nav-link">My Profile</a>
      </div>
      <div class="w-100 pb-20 pt-42 mt-1" *ngIf="isUser" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
        routerLink="/wai/learner-my-profile" routerLinkActive="active" #myCourses="routerLinkActive">
        <a class="nav-link">My Profile</a>
      </div>
      <div class="w-100 mt-1 pb-20" *ngIf="isAdmin || isManager"
        [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }" routerLink="/wai/company-details"
        routerLinkActive="active" #myCourses="routerLinkActive">
        <a class="nav-link">Company Details</a>
      </div>
      <!-- <div class="w-100 mt-1 pb-20" *ngIf="isAdmin || isManager"
        [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }" routerLinkActive="active"
        #myCourses="routerLinkActive">
        <a class="nav-link" (click)="generateReport()">Generate Report</a>
      </div> -->
      <div class="w-100 mt-1 pb-20" *ngIf="isAdmin || isManager"
        [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }" routerLink="/wai/company-users"
        routerLinkActive="active" #myCourses="routerLinkActive">
        <a class="nav-link">Company Learners</a>
      </div>
      <!-- <div class="w-100 mt-1 pb-20" *ngIf="isUser" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
        routerLink="/wai/courses" routerLinkActive="active" #myCourses="routerLinkActive">
        <a class="nav-link">My Courses</a>
      </div>
      <div class="w-100 mt-1 pb-20" *ngIf="isAdmin || isManager || isUser"
        [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }" routerLink="/wai/purchase-history"
        routerLinkActive="active" #myCourses="routerLinkActive">
        <a class="nav-link">Purchase History</a>
      </div> -->
      <div class="w-100 mt-1 pb-20" *ngIf="isUser" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
        routerLink="/wai/my-courses" routerLinkActive="active" #myCourses="routerLinkActive">
        <a class="nav-link">My Courses</a>
      </div>
      <div class="w-100 mt-1 pb-20" *ngIf="isUser" [ngClass]="{ color: fantasyMap.isActive ? '#189CD8' : '#262D5F' }"
        routerLink="/wai/industry/0" routerLinkActive="active" #fantasyMap="routerLinkActive">
        <a class="nav-link">Fantasy Map</a>
      </div>
      <div class="w-100 mt-1 pb-20" *ngIf="isUser" [ngClass]="{ color: certificates.isActive ? '#189CD8' : '#262D5F' }"
        routerLink="/wai/user-certificates" routerLinkActive="active" #certificates="routerLinkActive">
        <a class="nav-link">My Certificates</a>
      </div>
      <div class="w-100 mt-1 pb-20" *ngIf="isUser" [ngClass]="{ color: leaderboard.isActive ? '#189CD8' : '#262D5F' }"
        routerLink="/wai/scores" routerLinkActive="active" #leaderboard="routerLinkActive">
        <a class="nav-link">Leaderboard</a>
      </div>
      <div class="w-100 mt-1 pb-20" *ngIf="isUser" [ngClass]="{ color: skills.isActive ? '#189CD8' : '#262D5F' }"
        routerLink="/wai/skills" routerLinkActive="active" #skills="routerLinkActive">
        <a class="nav-link">Skills Passport</a>
      </div>
      <div class="w-100 mt-1 pb-20" style="border-bottom: none; padding-top: 2px;" *ngIf="isLoggedIn && isUser">
        <a class="nav-link position-relative shopping-cart" routerLink="/wai/checkout">
          <img src="assets/images/box.svg" alt="box" />
          <div class="indicator badge-pill badge-danger small d-none" *ngIf="totalItems == 0"></div>
          <div class="indicator badge-pill badge-danger small" *ngIf="totalItems > 0">{{ totalItems }}</div>
        </a>
      </div>
      <div class="w-100 mt-1 pb-20" [ngClass]="{ color: myCourses.isActive ? '#189CD8' : '#262D5F' }"
        routerLink="/wai/login" routerLinkActive="active" (click)="logout()" #myCourses="routerLinkActive">
        <a class="nav-link">Log out</a>
      </div>
    </div>
  </div>