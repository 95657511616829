

<edu-default-layout>
    <edu-spinner *ngIf="loading"></edu-spinner>
    <header class="courses">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <h1 class="container">Ports and Terminals</h1>
          </div>
          <div class="col-md-6 d-flex justify-content-start justify-content-md-end">
          </div>
        </div>
      </div>
    </header>
    <div class="position-relative container" style="display: flex; justify-content: center">
      <nav class="nav tabnav inverted" id="categoryNav" role="tablist">
      </nav>
    </div>

    <div class="container-fluid changingResolutionForPage" style="position: relative">
      <br /><br />
      <div class="row justify-content-between career-index">
        <div class="col-xs-12 col-sm-12 col-lg-6 col-md-12 px-0 forMobileCenterAlign">
          <br/>
         <h3 class="port-title" style="margin-bottom: 0px;">Empower your workforce</h3> 
         <h3 class="port-title2"> with our skilling solutions</h3> 
         <p class="port-title-desc">Cutting-edge training for port workers to enhance safety,
          efficiency and productivity.</p>
          <br/>
          <button class="btn btn-primary getintouchBtn" style="height: 50px;" (click)="scrollToSection()">Get in touch</button>
         
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-6 col-md-12 forMobileCenterAlign">
          <br/>
          <img src="assets/images/ports/top_most.png" class="image_ports_top_most ports-mt-imag">
        </div>
      </div>
      <br/><br/>
      <br /><br />
      <div class="row justify-content-between career-index">
        <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12">
          <h3 class="port-subtitle">Our Value Proposition
          </h3>
          <br/>
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-6 col-md-12 px-0 forMobileCenterAlign">
          <img src="assets/images/ports/port_game.png" class="image_ports ports-mt-imag">
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-6 col-md-12 px-0 forMobileCenterAlign">
          <br/>
          <br/>
          <p class="port-title-desc">21CC Skilled provides comprehensive,
            interactive training modules tailored
            for port operations. Our app features:
            </p>
            <ul style="list-style-type:disc;">
              <li class="port-title-desc">A mobile-first approach,</li>
              <li class="port-title-desc">Gamified learning,</li>
              <li class="port-title-desc">Multiple language support,</li>
              <li class="port-title-desc">Robust reporting
              </li>
            </ul> 
        </div>
      </div> 
      <br/>
      <br/>
      <br /><br />
      <div class="row justify-content-between career-index">
        <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12">
          <h3 class="port-subtitle">Key Benefits
          </h3>
          <br/>
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-6 col-md-12 px-0 forMobileCenterAlign">
          <br/>
          <br/>
          <p class="port-title-desc port-mt-20"><img src="assets/images/ports/green-tick.png" style="width: 30px;height: auto;"/><span style="vertical-align: super;">Improved safety compliance</span>  </p>
          <p class="port-title-desc port-mt-20"><img src="assets/images/ports/green-tick.png" style="width: 30px;height: auto;"/><span style="vertical-align: super;">Increased operational efficiency</span> </p>
          <p class="port-title-desc port-mt-20"><img src="assets/images/ports/green-tick.png" style="width: 30px;height: auto;"/><span style="vertical-align: super;">Reduced training time and costs</span> </p>
          <p class="port-title-desc port-mt-20"> <img src="assets/images/ports/green-tick.png" style="width: 30px;height: auto;"/><span style="vertical-align: super;">Enhanced worker skills and performance</span> </p>

        </div>
        <div class="col-xs-12 col-sm-12 col-lg-6 col-md-12 px-0 forMobileCenterAlign">
          <img src="assets/images/ports/cfs.png" class="image_ports ports-mt-imag">
        </div>

      </div> 
      <br/>
      <br/>
      <br /><br />
    </div>
    <section>
      <div class="container-fluid changingResolutionForPage">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12">
            <h3 class="port-subtitle">Success Stories
            </h3>
            <br/>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-4 col-md-12 forMobileCenterAlign">
            <img src="assets/images/ports/POA.png" class="project-image">
          </div>
          <div class="col-xs-12 col-sm-12 col-lg-8 col-md-12 forMobileCenterAlign">
            <div class="col" >
              <img src="assets/images/ports/poa_logo.png" class="poa_img">
              <img src="assets/images/ports/elearning.png" style="width: auto;height: 55px;">
              <img src="assets/images/ports/nl_flag.png" style="width: auto;height: 55px;">
            </div>
            <br/>
            <p class="port-title-desc">
              In close collaboration with operational and training experts from
              the Port of Amsterdam, we have developed 24 modules that
              explain processes, procedures, laws, operational activities, and
              communication with skippers, agents, and partners.
            </p>
            <br/>
            <p class="port-title-desc">
              The e-learning supports the learning process alongside practical
              training and has ensured that people pass their official exams
              faster, have a better understanding of what is expected of them,
              and are more flexibly deployable.
            </p>
          </div>
        </div>
        <br/>
        <br/>
        <br /><br />
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-4 col-md-12 forMobileCenterAlign">
            <img src="assets/images/ports/port_map.png" class="project-image">
          </div>
          <div class="col-xs-12 col-sm-12 col-lg-8 col-md-12 forMobileCenterAlign">
            <div class="col" >
              <img src="assets/images/ports/moerdijk.png" style="width: auto;height: 70px;">
              <img src="assets/images/ports/elearning.png" style="width: auto;height: 55px;">
              <img src="assets/images/ports/nl_flag.png" style="width: auto;height: 55px;">
            </div>
            <br/>
            <p class="port-title-desc">
              21CC works closely with the municipality of Moerdijk, Port of
              Moerdijk, and 40 affiliated companies on the industrial estate to
              familiarize people with the companies, jobs, and training
              opportunities in this region. The 21CC Skilled app plays a crucial
              role in making information accessible to job seekers, students, and
              pupils.
            </p>
            <br/>
            <p class="port-title-desc">
              Companies can use the app to stimulate innovation through
              interactive knowledge transfer, such as e-learning, storytelling,
              scenarios, and games. This helps students understand how to
              handle digitalization, sustainability, and robotics before starting
              their careers.
            </p>
          </div>
        </div>
        <br/>
        <br/>
        <br /><br />
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-4 col-md-12 forMobileCenterAlign">
            <img src="assets/images/ports/sealock.png" class="project-image">
          </div>
          <div class="col-xs-12 col-sm-12 col-lg-8 col-md-12 forMobileCenterAlign">
            <div class="col" >
              <img src="assets/images/ports/infra.png" style="width: auto;height: 70px;">
              <img src="assets/images/ports/elearning.png" style="width: auto;height: 55px;">
              <img src="assets/images/ports/nl_flag.png" style="width: auto;height: 55px;">
            </div>
            <br/>
            <p class="port-title-desc">
              For the IJmuiden Sea Lock and the locks of the famous Afsluitdijk,
              21CC, in close collaboration with the construction consortia OpenIJ
              and Levvel, has converted the manuals into 70 interactive, visual,
              and practical e-learning modules. These modules help the
              operators of these locks quickly become familiar with all technical
              and safety aspects so that they can perform their duties
              effectively from the opening.
            </p>
            <br/>
          </div>
        </div>
        <br/>
        <br/>
        <br /><br />

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-4 col-md-12 forMobileCenterAlign">
            <img src="assets/images/ports/msk.png" class="project-image">
          </div>
          <div class="col-xs-12 col-sm-12 col-lg-8 col-md-12 forMobileCenterAlign">
            <div class="col" >
              <img src="assets/images/ports/msk_logo.png" class="msk_img">
              <img src="assets/images/ports/elearning.png" style="width: auto;height: 55px;">
              <img src="assets/images/ports/ind_flag.png" style="width: auto;height: 55px;">
            </div>
            <br/>
            <p class="port-title-desc">
              21CC designed an elearning solution for Maersk, covering safety
              protocols to be followed by employees, drivers and contract
              workers while conducting their operations in ports and container
              freight stations.
            </p>
            <br/>
            <p class="port-title-desc">
              The solution comprised of 3 2D animated videos covering the
              step-by-step procedures to be followed in order to safeguard
              employees and partners in the vicinity of large equipment present
              in ports and container freight stations.
            </p>
          </div>
        </div>
        <br/>
        <br/>
        <br /><br />
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-4 col-md-12 forMobileCenterAlign">
            <img src="assets/images/ports/dp1.png" class="project-image">
          </div>
          <div class="col-xs-12 col-sm-12 col-lg-8 col-md-12 forMobileCenterAlign">
            <div class="col" >
              <img src="assets/images/ports/dp_logo.png" class="msk_img">
              <img src="assets/images/ports/elearning.png" style="width: auto;height: 55px;">
              <img src="assets/images/ports/ind_flag.png" style="width: auto;height: 55px;">
            </div>
            <br/>
            <p class="port-title-desc">
              21CC developed 2 courses for DP World titled Know your Business
              and Understand your Business, covering every category of their
              business. Part of these courses, were 2 elearning modules on Ports
              and Terminals.

            </p>
            <br/>
            <p class="port-title-desc">
              Our user interactive elearning solutions not only gave learners a
              clear view of DP World’s business, but also guided them on these
              entities operate to ensure DP World’s business goals are achieved.
            </p>
          </div>
        </div>
        <br/>
        <br/>
        <br /><br />
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-4 col-md-12 forMobileCenterAlign">
            <img src="assets/images/ports/dp2.png" class="project-image">
          </div>
          <div class="col-xs-12 col-sm-12 col-lg-8 col-md-12 forMobileCenterAlign">
            <div class="col" >
              <img src="assets/images/ports/dp_logo.png" class="msk_img">
              <img src="assets/images/ports/game_keypad.png" style="width: auto;height: 55px;">
              <img src="assets/images/ports/ind_flag.png" style="width: auto;height: 55px;">
            </div>
            <br/>
            <p class="port-title-desc">
              21CC developed a Workplace Safety course for DP World, to guide
              its employees with safety protocols associated with port
              operations. Part of this solution was a interactive game, where
              learners will be provided with a set of common port operations.
              The learners will then choose if the operations shown on screen is
              safe or not.
            </p>
            <br/>
            <p class="port-title-desc">
              A detailed 2D port map was created from scratch, with help from
              subject matter experts to capture the true essence of an
              operational port. By the end of this game, learners will gain the
              ability to spot safety hazards when working in ports.
            </p>
          </div>
        </div>
      </div>
    </section>
    <br/>
    <br/>
    <br /><br />
    <section>
      <div class="container-fluid changingResolutionForPage">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12">
            <h3 class="port-subtitle">Our Skilling Blueprint
            </h3>
            <br/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12" style="display: flex; justify-content: center;">
           <img src="assets/images/ports/21CC_SKBP_General.png" class="skilling-bluprint-img"/>
          </div>
        </div>
      </div>
    </section>
    <br/>
    <br/>
    <section class="wrapper p-sm-5">
      <br />
      <div class="sections p-1 p-sm-5">
        <div class="text-center title mb-5">
        <h3 class="port-subtitle">
          Customer Feedback
        </h3>  
        </div>
        <br/>
        <!-- Small Screen Carousel -->
        <div class="d-block d-md-none">
          <div id="smallScreenCarousel" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <!-- First Card -->
                <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-3 col-sm-3" style="max-width: 19%;">
                          <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                        </div>
                        <div class="col-9 col-sm-9 ml-3">
                          <!-- Person name and designation -->
                          <h5 class="card-title m-0">Renu Bohra</h5>
                          <h6 class="card-subtitle mb-3">Chief People Officer - India & Indian Subcontinent</h6>
                        </div>
                      </div>
                      <!-- Feedback content -->
                      <p class="card-text">The programme by 21CC is helping our facility managers get the new employees
                        up to speed much more quickly. We have decided to let all 2,500 contractual employees of DB
                        Schenker
                        in India do their onboarding with this innovative platform, 21CC Skilled.</p>
                      <img class="img-logo" src="../../../assets/images/home/I1_DB_Schenker.png" alt="Client Logo">
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <!-- Second Card -->
                <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-3 col-sm-3" style="max-width: 19%;">
                          <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                        </div>
                        <div class="col-9 col-sm-9 ml-3">
                          <!-- Person name and designation -->
                          <h5 class="card-title m-0">Marcel Wouterse</h5>
                          <h6 class="card-subtitle mb-3">Director</h6>
                        </div>
                      </div>
                      <!-- Feedback content -->
                      <p class="card-text">The response from our drivers have been exceptional and the gamification of
                        topics such as fuel efficiency, sustainability etc. has helped improve the level of engagement
                        like we have not seen before.</p>
                      <img class="company-logo" src="../../../assets/images/home/N7_Emons-logo.png" alt="Client Logo">
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <!-- Third Card -->
                <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-3 col-sm-3" style="max-width: 19%;">
                          <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                        </div>
                        <div class="col-9 col-sm-9 ml-3">
                          <!-- Person name and designation -->
                          <h5 class="card-title m-0">Manon Nijhuis</h5>
                          <h6 class="card-subtitle mb-3">Marketing and Communication Manager</h6>
                        </div>
                      </div>
                      <!-- Feedback content -->
                      <p class="card-text">With the help of gamified e-learning we are able to showcase to the learner –
                        the beauty as well the growth in the logistics sector and we hope to expand this even further in
                        the future!</p>
                      <img class="company-logo" src="../../../assets/images/home/N1_port-of-Twente.png" alt="Client Logo">
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <!-- Fourth Card -->
                <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-3 col-sm-3" style="max-width: 19%;">
                          <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                        </div>
                        <div class="col-9 col-sm-9 ml-3">
                          <!-- Person name and designation -->
                          <h5 class="card-title m-0">Kathy M. Roberts</h5>
                          <h6 class="card-subtitle mb-3">VP Training and Quality Assurance - North America</h6>
                        </div>
                      </div>
                      <!-- Feedback content -->
                      <p class="card-text">We have seen a marked improvement in productivity by improving their skills and
                        knowledge of the job with effective e-learning.</p>
                      <img class="company-logo" src="../../../assets/images/home/wfslogo.png" alt="Client Logo">
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <!-- <ol class="carousel-indicators d-block d-md-none  d-flex justify-content-center">
              <li data-target="#customerFeedbackCarousel" data-slide-to="0" class="active"></li>
              <li data-target="#customerFeedbackCarousel" data-slide-to="1"></li>
              <li data-target="#customerFeedbackCarousel" data-slide-to="2"></li>
              <li data-target="#customerFeedbackCarousel" data-slide-to="3"></li>
            </ol> -->
            <!-- Carousel Controls -->
            <a class="carousel-control-prev" href="#smallScreenCarousel" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#smallScreenCarousel" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <!-- Large Screen Carousel -->
        <div class="d-none d-md-block">
          <div id="largeScreenCarousel" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <!-- First Card -->
                  <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-1">
                            <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                          </div>
                          <div class="col-sm-10 ml-3">
                            <!-- Person name and designation -->
                            <h5 class="card-title m-0">Renu Bohra</h5>
                            <h6 class="card-subtitle mb-3">Chief People Officer - India & Indian Subcontinent</h6>
                          </div>
                        </div>
                        <!-- Feedback content -->
                        <p class="card-text">The programme by 21CC is helping our facility managers get the new employees
                          up to speed much more quickly. We have decided to let all 2,500 contractual employees of DB
                          Schenker
                          in India do their onboarding with this innovative platform, 21CC Skilled.</p>
                        <img class="img-logo" src="../../../assets/images/home/I1_DB_Schenker.png" alt="Client Logo">
                      </div>
                    </div>
                  </div>
                  <!-- Second Card -->
                  <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-1">
                            <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                          </div>
                          <div class="col-sm-10 ml-3">
                            <!-- Person name and designation -->
                            <h5 class="card-title m-0">Marcel Wouterse</h5>
                            <h6 class="card-subtitle mb-3">Director</h6>
                          </div>
                        </div>
                        <!-- Feedback content -->
                        <p class="card-text">The response from our drivers have been exceptional and the gamification of
                          topics such as fuel efficiency, sustainability etc. has helped improve the level of engagement
                          like we have not seen before.</p>
                        <img class="company-logo" src="../../../assets/images/home/N7_Emons-logo.png" alt="Client Logo">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  <!-- Third Card -->
                  <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-1">
                            <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                          </div>
                          <div class="col-sm-10 ml-3">
                            <!-- Person name and designation -->
                            <h5 class="card-title m-0">Manon Nijhuis</h5>
                            <h6 class="card-subtitle mb-3">Marketing and Communication Manager</h6>
                          </div>
                        </div>
                        <!-- Feedback content -->
                        <p class="card-text">With the help of gamified e-learning we are able to showcase to the learner –
                          the beauty as well the growth in the logistics sector and we hope to expand this even further in
                          the future!</p>
                        <img class="company-logo" src="../../../assets/images/home/N1_port-of-Twente.png"
                          alt="Client Logo">
                      </div>
                    </div>
                  </div>
                  <!-- Fourth Card -->
                  <div class="col-md-12 col-lg-6 mb-4 mb-lg-1">
                    <div class="card">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-1">
                            <img class="svg-icon" src="../../../assets/images/home/right-quotation-sign-svgrepo-com.svg">
                          </div>
                          <div class="col-sm-10 ml-3">
                            <!-- Person name and designation -->
                            <h5 class="card-title m-0">Kathy M. Roberts</h5>
                            <h6 class="card-subtitle mb-3">VP Training and Quality Assurance - North America</h6>
                          </div>
                        </div>
                        <!-- Feedback content -->
                        <p class="card-text">We have seen a marked improvement in productivity by improving their skills
                          and knowledge of the job with effective e-learning.</p>
                        <img class="company-logo" src="../../../assets/images/home/wfslogo.png" alt="Client Logo">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="carousel-control-prev" href="#largeScreenCarousel" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#largeScreenCarousel" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <br /><br />
      </div>

    </section>

          <!-- get in touch form -->
    <section id="get-in-touch-container">
        <edu-get-in-touch-form  [popUpFormEvent]="popUpFormEvent"
          (isFormSubmittedEvent)="isFormSubmittedEventReceived($event)"
          *ngIf="!isFormSubmitted"
        ></edu-get-in-touch-form>
    </section>
    <br/>
    <br />
    <br />
    <section>
      <div class="container-fluid changingResolutionForPage">
        <div class="row">
          <div class="col-md-12 col-lg-3 col-xs-12 col-sm-12">

          </div>
          <div class="col-md-12 col-lg-6 col-xs-12 col-sm-12">
           <p style="margin-left: 50px;" class="port-title-desc">To gain more information about our skilling solutions, get in touch:
          </p>
          <br/>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-lg-5 col-md-12 forMobileCenterAlign">
              <!-- <a href="mailto:dave.dundas@21cceducation.com"> -->
              <a href="https://mail.google.com/mail/?view=cm&fs=1&to=dave.dundas@21cceducation.com">
                 <img src="assets/images/ports/contact.png" class="contact-image">
              </a>
            </div>
            <div class="col-xs-12 col-sm-12 col-lg-2 col-md-12"></div>
            <div class="col-xs-12 col-sm-12 col-lg-5 col-md-12 forMobileCenterAlign">
              <h3 class="port-subtitle3">Dave Dundas
              </h3>
              <p class="port-title-desc">
                +1 (713) 304-1779
              </p>
              <br/>
              <p class="port-title-desc">
              dave.dundas@21cceducation.com
              </p>
            </div>
          </div>
          </div>
          <div class="col-md-12 col-lg-3 col-xs-12 col-sm-12">

          </div>
        </div>

      </div>
    </section>
    <br /><br /><br /><br /><br /><br />
  </edu-default-layout>