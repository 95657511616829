<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.1.3/dist/css/bootstrap.min.css"
    integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css">
<edu-default-layout>
    <edu-spinner *ngIf="loading"></edu-spinner>
    <header class="news-article flex-column align-items-start">
        <div class="container mt-4">
            <div class="row">
                <div class="col-lg-8">
                    <h2 class="text-left mt-2">
                        {{ blog?.title }}
                    </h2>
                    <div class="text-white">
                        {{ blog?.author }}
                    </div>
                    <br />
                    <div class="text-white mb-3">
                        <i class="fa fa-clock "></i>
                        {{ blog?.publishedDate }}
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="container mt-4">
        <div class="row">
            <div class="col-lg-8">
                <img [src]="blog?.thumbnail" alt="image" class="w-100" onerror="this.onerror=null;this.src='assets/images/logo.svg'">
                <p class="innerhtml-class-blog-details mt-4" [innerHTML]="blog?.details | safeHtml"></p>
            </div>
            <div class="col-lg-4">
                <h5>Latest blog</h5>
                <div class="row">
                    <div class="news-article col-12 col-md-6 col-lg-12" *ngFor="let recommended of blog?.recommended">
                        <div class="card flex-shrink-0 mt-3" *ngIf="recommended?.blogSlug; else searchById" (click)="navToLatestBlog(recommended)">
                            <img class="img-top" [src]="recommended?.thumbnail | toSanitizedUrl"
                                style="background-position: center"
                                onerror="this.onerror=null;this.src='assets/images/logo.svg';" />
                            <section class="title px-3 py-2">
                                <h5>{{ recommended?.title }}</h5>
                            </section>
                            <section class="highlight px-3 py-2 text-muted mt-3 overflow-hidden">{{
                                recommended?.highlight }}</section>
                            <br />
                            <section class="p-3 text-muted">
                                <i class="fa fa-clock"></i>
                                {{ recommended?.publishedDate }}
                            </section>
                        </div>
                        <ng-template #searchById>
                            <div class="card flex-shrink-0 mt-3" (click)="navToLatestBlog(recommended?.id)">
                                <img class="img-top" [src]="recommended?.thumbnail | toSanitizedUrl"
                                    style="background-position: center"
                                    onerror="this.onerror=null;this.src='assets/images/logo.svg';" />
                                <section class="title px-3 py-2">
                                    <h5>{{ recommended?.title }}</h5>
                                </section>
                                <section class="highlight px-3 py-2 text-muted mt-3 overflow-hidden">{{
                                    recommended?.highlight }}</section>
                                <br />
                                <section class="p-3 text-muted">
                                    <i class="fa fa-clock"></i>
                                    {{ recommended?.publishedDate }}
                                </section>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br /><br /><br /><br /><br /><br />
</edu-default-layout>