import { Injectable } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged} from 'rxjs/operators';

export interface GaServiceOptions {
  /**
   * Only send page views when in production mode to stop devs increasing view count
   * default: true
   */
  onlyProduction: boolean;
  /**
   * Type of analytics collection
   * GA: Google Analytics
   * GTM: Google Tag Manager
   */
  analyticsType: ('GA'|'GTM');
  /**
   * Reference to applications environmants.ts file
   * this.file is usually located in
   * src/environments/environment.ts
   */
  environmentRef: {production: boolean};
}

@Injectable({
  providedIn: 'root'
})

/*
https://developers.google.com/analytics/devguides/collection/analyticsjs/
Mike: Things to add:
 better error messages
 a check on init that window.ga or window.datalayer is set
 A debug mode that fakes the window.ga or window.datalayer
      (<any>window).ga = () => {
      return {
        set: (x)=>console.log(x),
        send: (x)=>console.log(x),
      }
    }
    Add Nikola's changes as done in sharepeople where te header code is dynamically added,
    The GTM-XXXXX identifier should be passed in the configuration object
 */

/**
 * @class GoogleAnalyticsService
 *
 * This service allows page views to be sent each time the user navigates between pages
 * Please see this link to explain why this is necessary:
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
 *
 * How to use:
 * The HTML snippet provided by google should be added to the index.html file
 *
 * This service should be initialised in the app.component constructor:
 *
 import { Component } from '@angular/core';
 import {environment} from "../environments/environment";
 import {GoogleAnalyticsService} from "./core/google-analytics.service";

 @Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
 export class AppComponent {
  constructor(
    gaService: GoogleAnalyticsService
  ) {
    gaService.init({
      analyticsType: 'GA',
      environmentRef: environment,
      onlyProduction: false
    })
  }
}
 */

export class GoogleAnalyticsService {

  // set some defaults
  private gaServiceOptions: GaServiceOptions = {
    onlyProduction: true,
    analyticsType: 'GA',
    environmentRef: {
      production: false,
    }
  };

  constructor(
    private router: Router
  ) {}

  /**
   * @param {GaServiceOptions} gaServiceOptions
   */
  init(gaServiceOptions: GaServiceOptions) {
    this.gaServiceOptions = gaServiceOptions;
    Object.freeze<GaServiceOptions>(gaServiceOptions);
    if (this.isCollectionAllowed()) {
      this.subscribeToNavigationEvents();
    }
  }

  private isCollectionAllowed() {
    if (!this.gaServiceOptions.onlyProduction) {
      return true;
    } else if (this.gaServiceOptions.environmentRef.production) {
      return true;
    } else {
      console.info('No GA or GTM collection - Not a production build');
      return false;
    }
  }

  private subscribeToNavigationEvents() {
    this.router.events
      .pipe(distinctUntilChanged())
      .subscribe(
        ev => {
          if (ev instanceof NavigationEnd) {
            this.filterGaOrGtm(ev.urlAfterRedirects);
          }
        }
      );
  }

  private filterGaOrGtm(url: string) {
    if (this.gaServiceOptions.analyticsType === "GA") {
      this.sendGAPageView(url)
    } else if (this.gaServiceOptions.analyticsType === "GTM") {
      this.sendGTMVirtualPageView(url)
    }
  }


  // For GA
  private sendGAPageView(url) {
    // console.('GA-pageView', url);
    (<any>window).ga('set', 'page', url);
    (<any>window).ga('send', 'pageview');
  }

  // For GTM
  private sendGTMVirtualPageView(url: string) {
    // console.log('GTM-virtualPageView', url);
    // @ts-ignore
    const dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'virtualPageView',
      page: url
    });
  }



  // Custom Events Examples (GA)
  private sendTestEvent() {
    // console.log('GA-testEvent');
    (window as any).ga('send', {
      hitType: 'event',
      eventCategory: 'TestCategory',
      eventAction: 'testAction',
      eventLabel: 'testLabel'
    });
  }

  private sendLoadingTime(componentName: string, time: number) {
    // console.log('GA-loadingTime: ', componentName, time, 'ms');
    (window as any).ga('send', {
      hitType: 'event',
      eventCategory: 'ComponentLoading',
      eventAction: 'loading',
      eventLabel: componentName,
      eventValue: time
    });
  }

  private sendException(errorMessage, isFatal= false) {
    // console.log('GA-Exception');
    (window as any).ga('send', 'exception', {
      exDescription: errorMessage,
      exFatal: isFatal
    });
  }
}
