import {HttpClientModule} from "@angular/common/http";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {TranslocoLocaleModule} from "@ngneat/transloco-locale";
import {FantasyMapRestService} from "../modules/fantasy-map/services/fantasy-map-rest.service";
import {SharedFormModule} from "../shared-form/shared-form.module";
import {LanguageModule} from "./language/language.module";
import {TranslocoRootModule} from "./transloco/transloco-root.module";
import {GoogleAnalyticsService} from "./google-analytics.service";
import {LocalStorageService} from "./local-storage.service";
import { NavigationService } from './navigation.service';
import { UserService } from "./user.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    LanguageModule,
    FormsModule,
    SharedFormModule,
    TranslocoLocaleModule.init({
      langToLocaleMapping: {
        en: 'en-GB',
        hi: 'hi-HI',
        nl: 'nl-NL'
      }})
  ],
  providers: [
    GoogleAnalyticsService,
    LocalStorageService,
    FantasyMapRestService,
    NavigationService,
    UserService
  ],
  exports: [
    HttpClientModule,
    ReactiveFormsModule,
    SharedFormModule,
    LanguageModule
  ]
})
export class CoreModule { }
