import {Injectable} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {catchError, distinctUntilChanged, filter, map, take} from "rxjs/operators";
import {NavigationService} from "../../../core/navigation.service";
import {ContractType, JobRole} from "../../job-preference/models/models";
import {JobPrefsRestService} from "../../job-preference/services/job-prefs-rest.service";
import {Education, UserProfile} from "../models/models";
import {UserProfileRestService} from "./user-profile-rest.service";
import {DataSavingService} from "../../../core/data-saving.service";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  private _userProfile = new BehaviorSubject<UserProfile>(null);
  userProfile$: Observable<UserProfile> = this._userProfile.asObservable().pipe(filter(x => !!x), distinctUntilChanged());

  private _education = new BehaviorSubject<Education[]>(null);
  education$: Observable<Education[]> = this._education.asObservable().pipe(filter(x => !!x), distinctUntilChanged());

  private _contractTypeOptions = new BehaviorSubject<ContractType[]>(null);
  contractTypeOptions$: Observable<ContractType[]> = this._contractTypeOptions.asObservable().pipe(filter(x => !!x), distinctUntilChanged());

  private _jobRoleOptions = new BehaviorSubject<JobRole[]>(null);
  jobRoleOptions$: Observable<JobRole[]> = this._jobRoleOptions.asObservable().pipe(filter(x => !!x), distinctUntilChanged());

  constructor(
    private restService: UserProfileRestService,
    private jobPrefRestService: JobPrefsRestService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private navigationService: NavigationService,
    private dataSavingService: DataSavingService,
    private route: ActivatedRoute,
  ) {}

  init() {
    if (this.isAlreadyInitialised()) { return; }
    this.restService.getUserProfile()
      .subscribe(
        (profile: UserProfile) => {
          this._userProfile.next(profile);
          this._education.next(profile.education);
        }
      );
    this.contractTypeOptions$ = this.getContractTypeOptions$();
    this.jobRoleOptions$ = this.getJobRoleOptions$();
  }

  isAlreadyInitialised() {
    return this._userProfile.getValue() && this._education.getValue();
  }

  updateProfileData(updatedUserProfile: UserProfile, ...redirectTo) {
    this.restService.patchUserProfile(updatedUserProfile)
      .pipe(catchError( (origErr) => {
        this.dataSavingService.addUnsuccessfulSaveIndicator();
        return throwError(origErr);
      }))
      .subscribe(
        (profile: UserProfile) => {
          this.dataSavingService.addSavedIndicator();
          this._userProfile.next(profile);
          if (redirectTo && redirectTo.length) {
            this.router.navigate(redirectTo);
          } else {
            // this.navigationService.goBack();
            // alert('changes updated successfully');
            // ,{ relativeTo: this.route, skipLocationChange: true }
            this.router.navigate(['/user-profile'],{ relativeTo: this.route, skipLocationChange: true });
          }
        });
  }

  updateUserLeftOverPosition(leftOverData: string) {
    return this.restService.updateUserLeftOverPosition(leftOverData);
  }

  getUpdatedUserLeftOverPosition() {
    return this.restService.getUpdatedUserLeftOverPosition();
  }

  deleteUserLeftOverPosition() {
    return this.restService.deleteUserLeftOverPosition();
  }

  // updateProfileData(updatedUserProfile: UserProfile, ...redirectTo) {
  //   this.restService.patchUserProfile(updatedUserProfile)
  //     .pipe(catchError( (origErr) => {
  //       this.dataSavingService.addUnsuccessfulSaveIndicator();
  //       return throwError(origErr);
  //     }))
  //     .subscribe(
  //       (profile: UserProfile) => {
  //         this.dataSavingService.addSavedIndicator();
  //         this._userProfile.next(profile);
  //         if (redirectTo && redirectTo.length) {
  //           this.router.navigate(redirectTo);
  //         } else {
  //           this.navigationService.goBack();
  //         }
  //       });
  // }

  updateUserAlias(updatedUserProfile){
    return this.restService.patchUserProfile(updatedUserProfile)
      .pipe(
        catchError( (origErr) => {
        this.dataSavingService.addUnsuccessfulSaveIndicator();
        return throwError(origErr);
        }),
        map(
          profile => {
            return this._userProfile.next(profile);
          }
        )
      );
  }

  getAvatarImageSrc(): Observable<SafeResourceUrl> {
    return this.restService.getAvatarImage()
      .pipe(
        map(
          avatarObject  => {
            return this.sanitizer.bypassSecurityTrustResourceUrl(`data:${avatarObject.type};base64, ${avatarObject.avatar}`)
          }
        )
      )
  }

  saveAvatarImage(base64Image: string) {
    return this.restService.postUserAvatar(base64Image);
  }

  getValueUserProfile(): UserProfile {
    return this._userProfile.getValue();
  }

  getEducationById(educationId: number): Education {
    return this.getValueUserProfile().education
      .find(i => i.id === educationId)
  }

  getWorkExperienceById(workExperienceId) {
    return this.getValueUserProfile().workExperience
      .find(i => i.id === workExperienceId);
  }

  getContractTypeOptions$(): Observable<ContractType[]> {
    return this.restService.getContractTypeOptions()
  }

  getJobRoleOptions$(): Observable<ContractType[]> {
    return this.jobPrefRestService.getJobRoles()
  }

  getValueContractTypeOptions(): ContractType[] {
    return this._contractTypeOptions.getValue();
  }

  addOrUpdateItemBasedOnId<T>(originalEducations: T[], currentEducation: T): T[] {
    if(!currentEducation['id']) {
      return [...originalEducations, currentEducation]
    }
    return originalEducations.map(edu => edu['id'] === currentEducation['id'] ? currentEducation : edu);
  }

  goToIndexPage() {
    this.navigationService.goBack()
  }

  getEducationLevelsByCountry(countryCode: string) {
    return this.restService.getEducationLevelsByCountry(countryCode);
  }

  // checkUserProfileCompletion(jobId) {
  //   console.log('entered 2');
  //   console.log(jobId);
  //   this.userProfile$
  //     .pipe(take(1))
  //     .subscribe(userProfile => {
  //       console.log('entered 3');
  //       console.log(userProfile);
  //       if (!userProfile.user.birthDate || !userProfile.user.firstName || !userProfile.user.lastName || !userProfile.user.education) {
  //         this.router.navigate([`/user-profile/complete-profile`], {state: {jobId}});
  //       } else {
  //         console.log('entered 4');
  //         this.router.navigate([`/careers/job-details/${jobId}/apply`]);
  //       }
  //     });
  // }

  checkUserProfileCompletion(jobId) {
    console.log('entered 2');
    console.log(jobId);
  
    // Subscribe to userProfile$ and handle its emissions
    this.userProfile$
      .pipe(take(1)) // Take only the first emission
      .subscribe(userProfile => {
        console.log('entered 3');
        console.log(userProfile);
  
        // Check if the userProfile is defined and has the required fields
        if (
          userProfile &&
          userProfile.user &&
          userProfile.user.birthDate &&
          userProfile.user.firstName &&
          userProfile.user.lastName &&
          userProfile.user.education
        ) {
          console.log('Profile is complete. Navigating to job details apply.');
          this.router.navigate([`/careers/job-details/${jobId}/apply`]);
        } else {
          console.log('Profile is incomplete. Navigating to complete-profile.');
          this.router.navigate([`/user-profile/complete-profile`], { state: { jobId } });
        }
      });
  }

}
