import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoursesRestService {

  constructor(
    private http: HttpClient
  ) { }

  getCourses(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/web-shop/courses`);
  }

  specificPackageID(packageId: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/package/${packageId}`);
  }

  getCoursesDataForTeams(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/packages`);
  }

  getcompanySpecificPackages(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/companySpecificPackages`);
  }

  getCourseGalleryList(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/galleryList`);
  }

  getEnrolledCourses(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/user/web-shop/courses`);
  }

  getEnrolledCourse(courseId: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/user/web-shop/courses/${courseId}`);
  }

  getEnrolledCourseByUrl(data: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/user/web-shop/courses/${data}`);
  }

  getCourseByCourseUrl(data: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/web-shop/courses/${data}`);
  }

  getCourseByCourseId(courseId: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/web-shop/courses/${courseId}`);
  }

  getCoursesByCategory(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/web-shop/groups`);
  }

  getCoursesByJob(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/web-shop/jobs`);
  }

  rateCourse(courseId: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/user/web-shop/courses/${courseId}/rating`, {});
  }

  getDiscount(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/user/web-shop/cart`);
  }

  getGroups(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/web-shop/groups`);
  }

  getGalleryData(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/gallery/${id}`);
  }

  getUserCountryPricing(country: string, localItems: any): Observable<any> {
    const body = {
      courseIds: localItems,
      country: country
    }
    return this.http.post<any>(`${environment.apiUrl}/web-shop/userPricing`, {body});
  }

}
