import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import { WindowRefService } from 'src/app/shared/window-ref.service';
import { CoursesDataService } from '../courses/services/courses-data.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HighlightTitleService } from 'src/app/shared-layout/highlight-title.service';
import { MeaningfulUrlService } from 'src/app/services/meaningfulurl.service';

@Component({
  selector: 'edu-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit,OnDestroy {

  bundle: any = [];
  bundleCourses: any = [];
  totalTime: number = 0;
  rHours: any = 0;
  rMinutes: any = 0;
  loading: boolean = true;
  avgRating: number = 0;
  finalAvgPercentage: number = 0;
  shoppingCartWithoutBundleId:any;
  shoppingCartWithoutBundleIdResult:any;
  isCoursesFromTeams:any;
  isPurchased:boolean = false;
  isAuthDetails:any;
  courseId:any;
  courseName:any;
  modifiedCourseName:any
  courseMeaningfulUrlName:any;
  bundleVideoURL: SafeResourceUrl | undefined;
  sanitizedModuleName:any;

  constructor(
    private coursesDataService: CoursesDataService,
    private route: ActivatedRoute,
    private router: Router,
    private winRef: WindowRefService,
    public localStorage: LocalStorageService,
    private titleService: Title,
    private sanitizer: DomSanitizer,
    private highlightTitleService: HighlightTitleService,
    private meaningfulUrlService: MeaningfulUrlService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    // this.titleService.setTitle("Course Details - 21CC Recruitment & Training");
    this.isAuthDetails = this.localStorage.retrieveFromLocalStorage("authDetails");
    this.highlightTitleService.setIsSelectPlanActive(true);
    this.shoppingCartWithoutBundleId=localStorage.getItem("shopping_cart_without_bundle_id");

    const courseLang = this.route.snapshot.paramMap.get('courseLang');
    const courseSlug = this.route.snapshot.paramMap.get('courseSlug');
    this.isCoursesFromTeams = this.localStorage.retrieveFromLocalStorage("isCoursesFromTeams");

    if(this.localStorage.retrieveFromLocalStorage("isPurchased") == 'true'){
      this.isPurchased = true;
    }else{
      this.isPurchased = false;
    }

    if(this.isCoursesFromTeams== 'yes'){
        this.meaningfulUrlService.getNewCourseForTeamID().subscribe(info => {
          this.courseId = info;
        });

        if(this.courseId){
          this.coursesDataService.specificPackageID(this.courseId.courseForTeamID).subscribe((specificPackage: any) => {
            setTimeout(() => {
              this.bundle = specificPackage.result;
              this.bundleVideoURL = this.sanitizeUrl(this.bundle?.previewUrl);
              this.courseName=this.bundle.name;
              this.courseMeaningfulUrlName = this.bundle?.meaningfulUrlName;
              this.localStorage.storeInLocalStorage("packageId",this.bundle.id);
              for (var i = 0; i < this.bundle?.modules?.length; i++) {
                this.bundleCourses.push(this.bundle.modules[i]);
              };
            
              this.totalTime = this.bundle?.modules?.reduce((total, module) => total + module.duration, 0);
        
              this.timeConvert(this.totalTime);          
    
              const title = "Course - " + this.bundle?.name;
              this.titleService.setTitle(title);
              this.loading = false;
    
    
            }, 0);
          });
        }else{
          const courseForTeamIDStore =  this.localStorage.retrieveFromLocalStorage("courseForTeamIDStore");
          const retreivecourseForTeamIDStore =  JSON.parse(courseForTeamIDStore);
          this.coursesDataService.specificPackageID(retreivecourseForTeamIDStore.courseForTeamID).subscribe((specificPackage: any) => {
            setTimeout(() => {
              this.bundle = specificPackage.result;
              this.bundleVideoURL = this.sanitizeUrl(this.bundle?.previewUrl);
              this.courseName=this.bundle.name;
              this.courseMeaningfulUrlName = this.bundle?.meaningfulUrlName;
              this.localStorage.storeInLocalStorage("packageId",this.bundle.id);
              for (var i = 0; i < this.bundle?.modules?.length; i++) {
                this.bundleCourses.push(this.bundle.modules[i]);
              };
            
              this.totalTime = this.bundle?.modules?.reduce((total, module) => total + module.duration, 0);
        
              this.timeConvert(this.totalTime);          
    
              const title = "Course - " + this.bundle?.name;
              this.titleService.setTitle(title);
              this.loading = false;
    
    
            }, 0);
          });
        }


      
    }else{
      this.meaningfulUrlService.getNewCourseID().subscribe(info => {
        this.courseId = info;
      });
      if (!this.courseId.courseID) {

        const courseIdStore =  this.localStorage.retrieveFromLocalStorage("courseIDStore");
        const retreivecourseIdStore =  JSON.parse(courseIdStore);
        this.coursesDataService.getCourseByCourseId(retreivecourseIdStore.courseID).subscribe((course: any) => {
          this.loadcourseDetails(course);
        });

      } else {
        this.coursesDataService.getCourseByCourseId(this.courseId.courseID).subscribe((course: any) => {
          
          this.loadcourseDetails(course);
        });
      }
    }

  }

  loadcourseDetails(course){
    this.bundle = course;
    this.bundleVideoURL = this.sanitizeUrl(this.bundle?.previewUrl);
          this.courseName=course.name;
          this.courseMeaningfulUrlName = course?.meaningfulUrlName;
          const moduleFirstLang = this.bundle?.modules?.[0]?.language || 'en';
          for (var i = 0; i < this.bundle?.modules?.length; i++) {
            this.bundleCourses.push(this.bundle.modules[i]);
          };
        
          this.totalTime = this.bundle?.modules?.reduce((total, module) => total + module.duration, 0);
    
          this.timeConvert(this.totalTime);          

          const title = "Course - " + this.bundle?.name;
          this.titleService.setTitle(title);
          if(course?.courseSlug) {
            let urlSlug = `${encodeURIComponent(course?.language)}/${encodeURIComponent(course?.courseSlug)}`;
            this.router.navigate(['/course/' +moduleFirstLang+'/'+ urlSlug]);
          }
          this.loading = false;
  }

  isExpanded(course: any): boolean {
    // Check the aria-expanded attribute for the course
    // Return true if it's "true", otherwise return false
    return course && course['aria-expanded'] === 'true';
  }

  navigateToModuleDetails(moduleDetail){
    // this.router.navigate(['/module/'+moduleDetail?.id]);
    const sanitizeName = (name) => {
      if (!name) return null;
  
      // Limit the name to 8 words
      const words = name.trim().split(/\s+/).slice(0, 8);
  
      // Convert to lowercase and join with dashes
      return words.map((word) => word.toLowerCase()).join("-");
    };

    const modifiedCoursesanitizeName = (name) => {
      if (!name) return null;
  
      // Limit the name to 8 words
      const words = name.trim().split(/\s+/).slice(0, 8);
  
      // Convert to lowercase and join with dashes
      // return words.map((word) => word.toLowerCase()).join("-");
       // Replace spaces with dashes and allow special characters like parentheses or emojis
      return words.map((word) => {
        // Only replace spaces with dashes, leaving other special characters like parentheses intact
        return word.replace(/\s+/g, '-').toLowerCase();
      }).join("-");
      };

      if(moduleDetail?.meaningfulUrlName){
        this.sanitizedModuleName = moduleDetail?.meaningfulUrlName;
      }else{
        this.sanitizedModuleName = sanitizeName(moduleDetail?.title);
      }
    
    const moduleSpecifictLang = moduleDetail?.language || 'en';
    if(this.courseMeaningfulUrlName){
      this.modifiedCourseName = this.courseMeaningfulUrlName;
    }else{
      this.modifiedCourseName = modifiedCoursesanitizeName(this.courseName);
    }
    
    this.router.navigate(['/course/'+`${moduleSpecifictLang}`+'/'+this.modifiedCourseName +'/'+this.sanitizedModuleName]);
    this.localStorage.storeInLocalStorage("moduleDetail",JSON.stringify(moduleDetail));
  }

  navigateToPlan(){
    if(this.isAuthDetails){
      this.router.navigate(['/admin-registration/manage-learners']);
    }else{
      
    this.router.navigate(['/select-plan']);
    }

  }


  // routerLink="/module/{{ course?.id }}"

  getFormattedLanguage(courseDetailsData: any) {
    let language = courseDetailsData?.modules[0]?.language;
    if (!language || language.trim() === '') {
      return 'English';
    } else if (language === 'en') {
      return 'English';
    } else if (language === 'hi') {
      return 'हिंदी';
    } else if (language === 'ta') {
      return 'தமிழ்';
    } else {
      return language; // Return the original value if it doesn't match any condition
    }
  }

  totalAvgRating(rateValue: number) {
    this.avgRating += rateValue;
  }

  totalAvgPercentageRatings(totalRatings: number) {
    this.finalAvgPercentage = (this.avgRating / totalRatings) * 20;
  }

  sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
  timeConvert(n: any) {
    var num = n;
    var hours = (num / 60);
    this.rHours = Math.floor(hours);
    var minutes = (hours - Math.floor(hours)) * 60;
    this.rMinutes = Math.round(minutes);
  }

  addToCart(courseId: string) {
      var existingEntries = JSON.parse(localStorage.getItem("shopping-cart"));
      if (existingEntries == null) existingEntries = [];
      var entry = courseId;
      existingEntries.push(entry);
      localStorage.setItem("shopping-cart", JSON.stringify(existingEntries));
      var existingEntries = JSON.parse(localStorage.getItem("shopping-cart"));
      this.coursesDataService.callComponentMethod();
  }

  createRzpayOrder(data) {
    if (!localStorage.getItem('shopping-cart')?.includes(this.bundle?.id)) this.addToCart(this.bundle?.id);
    this.router.navigateByUrl('/checkout');
    // call api to create order_id
    //this.payWithRazor(order_id);
  }

  payWithRazor(val) {
    const options: any = {
      key: 'rzp_live_6fBtcEXoe2DS9O',
      secret: 'H6CBbjMpb6dd4y3PQUqzuin3',
      amount: 125500, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: '', // company name or product name
      description: '',  // product description
      image: './assets/logo.png', // company logo or product image
      order_id: val, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      console.log(response);
      console.log(options);
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }

  ngOnDestroy(): void {
    this.highlightTitleService.setIsSelectPlanActive(false);
  }

}
