<edu-default-layout>
<edu-spinner *ngIf="loading"></edu-spinner>
<header class="course-category">
    <h1 class="container">
        {{courseTitle}}
    </h1>
    <!-- Modal -->
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered d-flex justify-content-center" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="embed-responsive embed-responsive-16by9"
                        data-video-host='videos-21cc.kpoint.com' data-kvideo-id='gcc-video id'
                        data-video-params='{"search": "false"}'>
                        <iframe class="embed-responsive-item" src=""> allowFullScreen webkitallowFullScreen
                            mozallowFullScreen rel='nofollow' style='border: 0px;'></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--  -->
</header>
<!-- <div class="container product-cards">
    <h2 class="row col text-left mb-4">
        Bundles
    </h2>
    <div class="container-fluid row col overflow-auto pb-4" id="specialBundleContainer">
        <div class="flex-nowrap">
            <edu-product-cards routerLink="/course-details/{{ courseId }}"></edu-product-cards>
        </div>
    </div>
</div> -->

<!-- <div class="container product-cards pt-5">
    <div class="container-fluid row col overflow-auto pb-4" id="specialBundleContainer">
        <div class="flex-nowrap">
            <a href="#" data-toggle="modal" data-target="#exampleModalCenter">
                <div class="card about-us-card border-radius-10">
                    <div class="center">
                        <img src="">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title text-center">
                            Play Demo Video
                        </h5>
                    </div>
                </div>
            </a>
        </div>
    </div>

</div> -->


<div class="container product-cards">
    <h2 class="row col text-left d-md-flex justify-content-between mb-4 mt-4">
        <div>Individual courses ({{coursesByCategory.length}})</div>
    </h2>
    <div class="container-fluid row col overflow-auto pb-4">
        <div class="card flex-shrink-0" *ngFor="let course of coursesByCategory">
            <a routerLink="/module-details/{{ course?.id }}" *ngIf="course.imageUrl">
                <img class="product-image" [src]="course.imageUrl | toSanitizedUrl"/>
            </a>
            <!-- routerLink="/course-details/{{ course?.id }}" -->
            <a style="padding: 0px !important;" (click)="navigateToCourseDetails(course?.id)" *ngIf="!course.imageUrl">
                <div *ngFor="let imageOfBundle of bundleImages">
                    <div class="img-top" style="background-image: url('{{ imageOfBundle[course?.id] }}');"></div>
                </div>
            </a>
            <section class="title">
              <a style="line-height: 1.1;" routerLink="/module-details/{{ course?.id }}">{{ course?.displayName }}</a>
            </section>
            <section class="duration-skills small">
              <span>0h {{ course?.duration }}m</span>
              <span>{{ course?.skills?.length }} skills</span>
            </section>
            <section class="rating">
                <span class="fa fa-star + ' ' + {{ rating }}"></span>
                <span class="fa fa-star + ' ' + {{ rating }}"></span>
                <span class="fa fa-star + ' ' + {{ rating }}"></span>
                <span class="fa fa-star + ' ' + {{ rating }}"></span>
                <span class="fa fa-star + ' ' + {{ rating }}"></span>
              ({{ course?.averageRatingScore?.toFixed(1) }})
            </section>
            <section class="price">           
              <h5 class="current">₹{{ course?.priceCurrent }}</h5>
              <!-- <span class="previous small">₹{{ course?.previousPrice }}</span> -->
            </section>
            <section class="text-right text-success add-to-cart" style="cursor: pointer;" *ngIf="!localStorage.retrieveFromLocalStorage('shopping-cart')?.includes(course?.id)" (click)="addToCart(course?.id)">+ Add to cart</section>
            <section class="text-right text-success add-to-cart text-muted" *ngIf="localStorage.retrieveFromLocalStorage('shopping-cart')?.includes(course?.id)">Added to cart</section>
          </div>
    </div>
</div>
<br/><br/><br/><br/><br/><br/>
</edu-default-layout>