<div class="spinner" *ngIf="loading">
    <div class="overlay"></div>
    <div class="loader"></div>
</div>
<div class="form-container">
    <div class="giz-container">
        <br /><br />
        <div class="giz-form">
            <div class="top-image-container">
                <img class="top-image1" src="assets/images/giz/vet-toolbox.svg" width="86" height="24"
                    alt="VET Toolbox">
                <img class="top-image2" src="assets/images/giz/21cc-education.svg" width="55" height="23"
                    alt="21CC Education">
            </div>
        </div>
        <div class="giz-form" *ngIf="!isFormSubmitted">
            <h1><b>Register for VET Toolbox Skills 4 Riders training programme</b></h1>
            <p>
                VET Toolbox is launching Skills 4 Riders training programme in April/May 2023 to skill delivery riders
                and
                make them ready for the digital economy in Nigeria. The programme will train riders on Digital Literacy,
                Financial Literacy, Road Safety and Customer Orientation. Sign up now and don't miss out on this
                exciting
                opportunity to skill up!
            </p>
            <form [formGroup]="gizForm" (ngSubmit)="submitForm()">
                <div class="form-group" id="firstNameDiv">
                    <label for="firstName">First name</label>
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': gizForm.get('firstName')?.invalid && (gizForm.get('firstName')?.dirty || gizForm.get('firstName')?.touched) }"
                        formControlName="firstName" id="firstName" aria-describedby="firstNameHelp"
                        (keypress)="onlyAlphabets($event)" (keydown)="startWithAlphabetOnly($event)" placeholder="Type...">
                    <div class="form-text text-danger"
                        *ngIf="gizForm.get('firstName')?.errors && (gizForm.get('firstName')?.dirty || gizForm.get('firstName')?.touched)">
                        <div class="form-text text-danger" *ngIf="gizForm.get('firstName')?.errors.required">Please
                            complete
                            this required field.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="lastName">Last name</label>
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': gizForm.get('lastName')?.invalid && (gizForm.get('lastName')?.dirty || gizForm.get('lastName')?.touched) }"
                        formControlName="lastName" id="lastName" aria-describedby="lastNameHelp"
                        (keypress)="onlyAlphabets($event)" (keydown)="startWithAlphabetOnly($event)" placeholder="Type...">
                    <div class="form-text text-danger"
                        *ngIf="gizForm.get('lastName')?.errors && (gizForm.get('lastName')?.dirty || gizForm.get('lastName')?.touched)">
                        <div class="form-text text-danger" *ngIf="gizForm.get('lastName')?.errors.required">Please
                            complete
                            this required field.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="age">Age</label>
                    <input type="tel" class="form-control"
                        [ngClass]="{ 'is-invalid': gizForm.get('age')?.invalid && (gizForm.get('age')?.dirty || gizForm.get('age')?.touched) }"
                        formControlName="age" id="age" aria-describedby="ageHelp" (keypress)="numberOnly($event)"
                        placeholder="Type..." maxlength="2">
                    <div class="form-text text-danger"
                        *ngIf="gizForm.get('age')?.errors && gizForm.get('age')?.touched">
                        <div class="form-text text-danger" *ngIf="gizForm.get('age')?.errors.required || gizForm.get('age')?.errors.minlength">Please complete
                            this
                            required field.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="gender">Gender</label>
                    <select class="form-control"
                        [ngClass]="{ 'is-invalid': gizForm.get('gender')?.invalid && (gizForm.get('gender')?.dirty || gizForm.get('gender')?.touched) }"
                        formControlName="gender" (change)="changeGender($event)" id="gender">
                        <option value="" disabled selected hidden><span class="option-hint">-Select-</span></option>
                        <option><span class="option-value">Female</span></option>
                        <option><span class="option-value">Male</span></option>
                        <div class="form-text text-danger"
                            *ngIf="gizForm.get('gender')?.errors && (gizForm.get('gender')?.dirty || gizForm.get('gender')?.touched)">
                            <div class="form-text text-danger" *ngIf="gizForm.get('gender')?.errors.required">Please
                                complete
                                this
                                required field.</div>
                            <div class="form-text text-danger" *ngIf="gizForm.get('gender')?.errors.whitespace">Please
                                enter valid data.</div>
                        </div>
                    </select>
                </div>
                <div class="form-group">
                    <label for="address">Address</label>
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': gizForm.get('address')?.invalid && (gizForm.get('address')?.dirty || gizForm.get('address')?.touched) }"
                        formControlName="address" id="address" aria-describedby="addressHelp" (keypress)="noSpaceAllowedInStart($event)" (keydown)="startWithAlphabetOnly($event)" placeholder="Type...">
                    <div class="form-text text-danger"
                        *ngIf="gizForm.get('address')?.errors && (gizForm.get('address')?.dirty || gizForm.get('address')?.touched)">
                        <div class="form-text text-danger" *ngIf="gizForm.get('address')?.errors.required">Please
                            complete
                            this required field.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="state">State</label>
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': gizForm.get('state')?.invalid && (gizForm.get('state')?.dirty || gizForm.get('state')?.touched) }"
                        formControlName="state" id="state" aria-describedby="stateHelp" placeholder="Type...">
                    <div class="form-text text-danger"
                        *ngIf="gizForm.get('state')?.errors && (gizForm.get('state')?.dirty || gizForm.get('state')?.touched)">
                        <div class="form-text text-danger" *ngIf="gizForm.get('state')?.errors.required">Please complete
                            this
                            required field.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="country">Country</label>
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': gizForm.get('country')?.invalid && (gizForm.get('country')?.dirty || gizForm.get('country')?.touched) }"
                        formControlName="country" id="country" aria-describedby="countryHelp" placeholder="Type...">
                    <div class="form-text text-danger"
                        *ngIf="gizForm.get('country')?.errors && (gizForm.get('country')?.dirty || gizForm.get('country')?.touched)">
                        <div class="form-text text-danger" *ngIf="gizForm.get('country')?.errors.required">Please
                            complete
                            this required field.</div>
                        <div class="form-text text-danger" *ngIf="gizForm.get('country')?.errors.whitespace">Please
                            enter valid data.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="phone">Phone number</label>
                    <div class="row">
                        <div *ngIf="!isMobileResolution" class="col-3">
                            <input type="tel" class="form-control"
                                [ngClass]="{ 'is-invalid': gizForm.get('countryCode')?.invalid && (gizForm.get('countryCode')?.dirty || gizForm.get('countryCode')?.touched) }"
                                formControlName="countryCode" id="countryCode" aria-describedby="countryCodeHelp"
                                (keypress)="numberOnly($event)" (keydown)="keepPlusSign($event)">
                        </div>
                        <div *ngIf="isMobileResolution" class="col-4">
                            <input type="tel" class="form-control"
                                [ngClass]="{ 'is-invalid': gizForm.get('countryCode')?.invalid && (gizForm.get('countryCode')?.dirty || gizForm.get('countryCode')?.touched) }"
                                formControlName="countryCode" id="countryCode" aria-describedby="countryCodeHelp"
                                (keypress)="numberOnly($event)" (keydown)="keepPlusSign($event)">
                        </div>
                        <div class="col" style="padding-left: 0 !important;">
                            <input type="tel" class="form-control"
                                [ngClass]="{ 'is-invalid': gizForm.get('phone')?.invalid && (gizForm.get('phone')?.dirty || gizForm.get('phone')?.touched) }"
                                formControlName="phone" id="phone" aria-describedby="phoneHelp"
                                (keypress)="numberOnly($event)" placeholder="Type...">
                        </div>
                    </div>
                    <div class="form-text text-danger"
                        *ngIf="gizForm.get('phone')?.errors && gizForm.get('phone')?.touched; else alternateErrorMessage">
                        <div class="form-text text-danger" *ngIf="gizForm.get('phone')?.errors.required">Please complete
                            this
                            required field.</div>
                        <div class="form-text text-danger" *ngIf="gizForm.get('phone')?.errors.whitespace">Please enter
                            valid data.</div>
                        <div class="form-text text-danger" *ngIf="gizForm.get('phone')?.errors.minlength">Your phone
                            number
                            must contain at least 9 characters.</div>
                        <div class="form-text text-danger" *ngIf="gizForm.get('phone')?.errors.maxlength">Your phone
                            number
                            cannot exceed 14 characters.</div>
                    </div>
                    <ng-template #alternateErrorMessage>
                        <div class="form-text text-danger"
                            *ngIf="gizForm.get('countryCode')?.errors && (gizForm.get('countryCode')?.dirty || gizForm.get('countryCode')?.touched)">
                            <div class="form-text text-danger" *ngIf="gizForm.get('countryCode')?.errors.required">
                                Please
                                complete
                                this
                                required field.</div>
                        </div>
                    </ng-template>
                </div>
                <div class="form-group">
                    <label for="email">Email address</label>
                    <input type="text" class="form-control"
                        [ngClass]="{ 'is-invalid': gizForm.get('email')?.invalid && (gizForm.get('email')?.dirty || gizForm.get('email')?.touched) }"
                        formControlName="email" id="email" aria-describedby="emailHelp" (keypress)="avoidSpaces($event)"
                        placeholder="Type...">
                    <div class="form-text text-danger"
                        *ngIf="gizForm.get('email')?.errors && gizForm.get('email')?.touched">
                        <div class="form-text text-danger" *ngIf="gizForm.get('email')?.errors.required">Please complete
                            this
                            required field.</div>
                        <div class="form-text text-danger" *ngIf="gizForm.get('email')?.errors?.invalidEmail">Please enter a
                            valid email address format.</div>
                    </div>
                </div>
                <div class="form-check">
                    <input type="checkbox" class="form-check-input" formControlName="checkInput" id="checkInput">
                    <label class="form-check-label" for="checkInput">I agree to the <a
                            href="https://21cceducation.com/privacy-policy" target="_blank">Privacy Policy</a> and <a
                            href="https://21cceducation.com/terms-of-use" target="_blank">Terms &
                            Conditions</a>.</label>
                    <div class="form-text text-danger"
                        *ngIf="gizForm.get('checkInput')?.errors && (gizForm.get('checkInput')?.dirty || gizForm.get('checkInput')?.touched)">
                        <div class="form-text text-danger" *ngIf="gizForm.get('checkInput')?.errors">Please
                            complete
                            this
                            required field.</div>
                    </div>
                </div>
                <button type="submit" class="submit-btn">Submit</button>
            </form>
        </div>
        <div class="thank-you-message" *ngIf="isFormSubmitted">
            <img class="bottom-image" src="assets/images/giz/confirmation-checkmark.svg" width="120" height="120"
                alt="Confirmation Checkmark">
            <h1>Thank you for registering!</h1>
            <p>
                We'll be in touch when the VET Toolbox<br /> Skills 4 Riders programme is about<br /> to start.
            </p>
        </div>
        <div class="bottom-image-container1">
            <img class="bottom-image" src="assets/images/giz/eu-german-logo.png" height="50"
                alt="Co-funded by the European Union And German Cooperation">
        </div>
        <div class="bottom-image-container2">
            <img class="bottom-image" src="assets/images/giz/giz-logos.png" height="80" alt="British Council">
        </div>
        <br /><br />
    </div>
</div>