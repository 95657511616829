import { Component, ElementRef, forwardRef, ViewChild} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateConversionService} from './date-conversion.service';
import {DateTextValidator} from './date-text-validator';

@Component({
  selector: 'gkt-date-text',
  templateUrl: './date-text.component.html',
  styleUrls: ['./date-text.component.css'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DateTextValidator,
      multi: true
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTextComponent),
      multi: true
    }
  ]
})
export class DateTextComponent implements ControlValueAccessor {

  constructor(
    private dateConversionService: DateConversionService
  ) { }

  textDateValue: string;

  @ViewChild('textDate', {static: false, read: ElementRef}) textDate;
  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.textDate.disabled = isDisabled;
  }

  writeValue(isoDate: string): void {
    if (isoDate !== null && isoDate !== undefined) {
      this.textDateValue = this.dateConversionService.convertISOtoNL(isoDate);
    }
  }

  updateValue() {
    const inputValue = this.textDate.nativeElement.value;
    const isoString = this.dateConversionService.convertNLtoISO(inputValue);
    this.propagateChange(isoString);
  }
}

