<edu-default-layout>
<header>
  <h1 class="container">Refund Policy</h1>
</header>
<div class="container fill-height">
  <h2>
    Thank you for using 21CC Education!
    <h2>21CC Refund Policy</h2>
    <p>
      For details on our refund deadlines and policies, please refer to the
      information below; note that our policies differ for subscription payments
      vs. one-time course and Specialization purchases and that payment options
      may vary from one Service to another. Please also note that we treat
      violations of our Terms of Use very seriously, and we have no obligation
      to offer refunds to learners who are found to be in violation of these
      terms, even if their requests are made within the designated refund
      period. Similarly, we have no obligation to offer late refunds to learners
      who do not pass a course or Specialization, or who are otherwise
      unsatisfied with their final grade.
    </p>
    <p>
      <strong>
        Each course on the 21CC website is available individually for purchase.
        Each course will be allocated a date of expiry from at the time of the
        beginning of the course. If a learner wishes to cancel his/her
        enrollment, he/she must do so within 7 days from the day of activation.
        In this case, the learner is liable for a refund amounting to 50% of the
        course fee.
      </strong>
    </p>
    <p>
      Certificates awarded for completion of a course also has a date of expiry.
      If a learner wishes to take the course again after expiry, he/she should
      contact at info@21cceducation.com. Repeat subscription to this course will
      be awarded at a lesser fee that the original fee. Similarly, if a learner
      wishes to renew his certificate, he/she should apply for renewal of
      certificate at info@21cceducation.com. Depending on the past performance
      and requirement of the candidate, a new certificate could be provided
      against the payment of renewal fee.
    </p>
    <h2>Third Party Marketplaces</h2>
    <p>
      Notwithstanding the foregoing, if you purchase or subscribe for a course
      or Specialization, or purchase any other paid Service, through a third
      party marketplace (e.g., in-app purchases through the Apple App Store or
      purchases made through certain alternative payment services), the refund
      policy applicable to that third party marketplace will apply, unless
      otherwise explicitly stated by 21CC. Except as otherwise explicitly stated
      by 21CC, the third party marketplace will be solely responsible for making
      refunds under its refund policy, and 21CC will have no refund obligations.
      Coursera disclaims any responsibility or liability related to any third
      party marketplace’s refund policy or the third party’s compliance or
      noncompliance with such policy.
    </p>
    <p>Last Updated: September 25th, 2017</p>
  </h2>
</div>
<br/><br/><br/><br/><br/><br/>
</edu-default-layout>

