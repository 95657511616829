import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HighlightTitleService {

  private isSelectPlanActiveSubject = new BehaviorSubject<boolean>(false);
  isSelectPlanActive$ = this.isSelectPlanActiveSubject.asObservable();

  private isSelectRegistrationActiveSubject = new BehaviorSubject<boolean>(false);
  isSelectRegistrationActive$ = this.isSelectRegistrationActiveSubject.asObservable();

  private isLoginActiveSubject = new BehaviorSubject<boolean>(false);
  isLoginActive$ = this.isLoginActiveSubject.asObservable();

  private isSingupActiveSubject = new BehaviorSubject<boolean>(false);
  isSingupActive$ = this.isSingupActiveSubject.asObservable();

  constructor() { }

  setIsSelectPlanActive(isActive: boolean) {
    this.isSelectPlanActiveSubject.next(isActive);
  }

  setIsSelectRegistrationActive(isActive: boolean) {
    this.isSelectRegistrationActiveSubject.next(isActive);
  }

  setIsLoginActive(isActive: boolean) {
    this.isLoginActiveSubject.next(isActive);
  }

  setIsSignupActive(isActive: boolean) {
    this.isSingupActiveSubject.next(isActive);
  }
  
}
