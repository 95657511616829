import { Directive, ElementRef, HostListener, Input } from '@angular/core';

export enum TextTransformType {
  toLowerCase = 'toLowerCase',
  toUpperCase = 'toUpperCase'
}

@Directive({
  selector: '[gktTextTransform]'
})

/*
    Usage notes:
    <input type='text' [TextTransform]='toUpperCase'>
 */

export class TextTransformDirective {

  // Typing like this (string | string) allows the IDE to show the available options to the developer
  // tslint:disable-next-line:no-input-rename
  @Input('gktTextTransform') transformType: ('toLowerCase'| 'toUpperCase');


  constructor(
    private elementRef: ElementRef
  ) {}

  @HostListener('keyup') upcase() {
    const hostElem = this.elementRef.nativeElement;
    if (hostElem.value && this.transformType === TextTransformType.toLowerCase) {
      hostElem.value = hostElem.value.toLowerCase();
    }
    if (hostElem.value && this.transformType === TextTransformType.toUpperCase) {
      hostElem.value = hostElem.value.toUpperCase();
    }
  }

}
