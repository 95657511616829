import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, takeUntil, tap} from "rxjs/operators";
import {Gallery, GalleryItem} from '../models/gallery.models';
import {GalleryRestService} from './gallery-rest.service';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  previousFMRoute: string;
  private _gallery: BehaviorSubject<Gallery> = new BehaviorSubject(null);
  public gallery$: Observable<Gallery> = this._gallery.asObservable().pipe(distinctUntilChanged(), filter(x => !!x));

  private _currentGalleryItem: BehaviorSubject<GalleryItem> = new BehaviorSubject(null);
  public currentGalleryItem$: Observable<GalleryItem> = this._currentGalleryItem.asObservable().pipe(distinctUntilChanged(), filter(x => !!x));

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$: Observable<boolean> = this._loading.asObservable().pipe(distinctUntilChanged());

  private _galleryIsEmpty: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public galleryIsEmpty$: Observable<boolean> = this._galleryIsEmpty.asObservable().pipe(distinctUntilChanged());

  public destroy$ = new Subject();

  private _galleryIsChallenge: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public galleryIsChallenge$: Observable<boolean> = this._galleryIsChallenge.asObservable().pipe(distinctUntilChanged());

  private _claimRewardStatus: BehaviorSubject<('unavailable' | 'available' | 'claimed')> = new BehaviorSubject<('unavailable' | 'available' | 'claimed')>(null);
  public claimRewardStatus$: Observable<('unavailable' | 'available' | 'claimed')> = this._claimRewardStatus.asObservable();

  constructor(
    private galleryRestService: GalleryRestService
  ) { }



  getGallery() {
    this._galleryIsEmpty.next(false);
    this._loading.next(true);
    return this.galleryRestService.getGallery()
      .pipe(takeUntil(this.destroy$),
        tap(gallery => {
          this.checkEmptyGallery(gallery);
          this._gallery.next(gallery);
          this._loading.next(false);
        })
      );
  }

  getGalleryByJob(jobId: number) {
    this._galleryIsEmpty.next(false);
    this._loading.next(true);
    return this.galleryRestService.getGalleryByJob(jobId)
      .pipe(takeUntil(this.destroy$),
        tap(gallery => {
          this.checkEmptyGallery(gallery);
          this._gallery.next(gallery);
          this._loading.next(false);
        })
      );
  }

  getGalleryById(galleryId: number) {
    this._galleryIsEmpty.next(false);
    this._loading.next(true);
    const galleryDataObservable = forkJoin({
      content: this.galleryRestService.getGalleryContentById(galleryId),
      userData: this.galleryRestService.getGalleryUserDataById(galleryId)
    });
    return galleryDataObservable
      .pipe(takeUntil(this.destroy$),
        map(galleryData => {
          // adding `galleryData.userData` properties to `galleryData.content` so that it makes up a full `GalleryItem` object
          const categories = ['videos', 'docs', 'games', 'modules', 'jobs'];
          categories.forEach(category => {
            if (galleryData.content[category] && galleryData.content[category].length) {
              galleryData.userData[category]
                .forEach(userDataItem => {
                   galleryData.content[category]
                     .map(content => content.id === userDataItem.id ? Object.assign(content, userDataItem) : content);
                });
            }
          });
          this.checkEmptyGallery(galleryData.content);
          if (galleryData.content){
            this._gallery.next(galleryData.content);
            this._loading.next(false);
          }
          return galleryData.content;
        }));
  }


  checkEmptyGallery(gallery) {
    if (gallery.challengeStatus) {
      this._galleryIsEmpty.next(false);
    } else {
      const {docs, games, modules, videos, jobs} = gallery;
      if (!docs?.length && !games?.length && !modules?.length && !videos?.length && !jobs?.length) {
        this._galleryIsEmpty.next(true);
      } else {
        this._galleryIsEmpty.next(false);
      }
    }
  }

  getChallenge(challengeId: number) {
    this._galleryIsEmpty.next(false);
    this._loading.next(true);
    return this.galleryRestService.getChallenge(challengeId)
      .pipe(takeUntil(this.destroy$),
        tap(challenge => {
          this.checkEmptyGallery(challenge);
          if (challenge){
            this._gallery.next(challenge);
            this._loading.next(false);
          }
        }));
  }

  sendContentRating(feedBack: string, rating: number, contentId: number, contentType: any){
    return this.galleryRestService.sendContentRating(feedBack, rating, contentId, contentType);
  }

  getAllRatingInfo(contentId: number, contentType: any){
    return this.galleryRestService.getAllRatingInfo(contentId, contentType);
  }

  setGalleryIsChallenge(isChallenge: boolean) {
    this._galleryIsChallenge.next(isChallenge);
  }

  setClaimRewardStatus(status: ('unavailable' | 'available' | 'claimed')) {
    this._claimRewardStatus.next(status);
  }

  setCurrentGalleryItem(galleryItem: GalleryItem){
    this._currentGalleryItem.next(galleryItem);
  }

  getModuleDetails(gallerynewId){
    return this.galleryRestService.getModuleDetails(gallerynewId);
  }

  updateContentStars(userStars, maxStars){
    const currentGalleryItem: GalleryItem = this._currentGalleryItem.getValue();
    const updatedGalleryItem = {...currentGalleryItem, userStars, maxStars };
    this._currentGalleryItem.next(updatedGalleryItem);
  }

  logOpenedGalleryStatus(galleryId: number) {
    return this.galleryRestService.logOpenedGalleryStatus(galleryId);
  }

  logOpenedChallengeStatus(challengeId: number) {
    return this.galleryRestService.logOpenedChallengeStatus(challengeId);
  }

  registrationOfEskill(registrationEskillObj: any) {
    return this.galleryRestService.registrationOfEskill(registrationEskillObj);
  }


}
