<div *ngIf="!fffai && !wai" class="container mt-4 news-index">
    <div class="row overflow-hidden">
        <div class="news-article col-12 col-sm-6 col-md-4" *ngFor="let blog of listOfBlog"
            data-category="$categorySlug">
            <!-- [routerLink]="blog?.blogSlug ? '/blog/' + blog?.blogUrlCategory + '/' + blog?.blogSlug : '/blog/' + blog?.id" -->
            <div *ngIf="blog?.blogUrlCategory; else searchWithCategory" class="card flex-shrink-0 mt-3" (click)="navigateToBlog(blog,blog?.blogUrlCategory, blog?.blogSlug, blog?.id,blog?.title)">
                <img class="img-top" [src]="blog?.thumbnail | toSanitizedUrl" style="background-position: center"
                    onerror="this.onerror=null;this.src='assets/images/logo.svg'" />
                <section class="title px-3 py-2">
                    <h5>{{ blog?.title }}</h5>
                </section>
                <section class="highlight px-3 py-2 text-muted mt-3 overflow-hidden" [innerHTML]="blog?.highlight">
                </section>
                <br />
                <section class="p-3 text-muted">
                    <i class="fa fa-clock "></i>
                    {{ blog?.publishedDate }}
                </section>
            </div>
            <ng-template #searchWithCategory>
                <!-- [routerLink]="blog?.blogSlug ? '/blog/' + blog?.blogSlug : '/blog/' + blog?.id" -->
                <div class="card flex-shrink-0 mt-3" 
                (click)="navigateToBlog(blog,null, blog?.blogSlug, blog?.id, blog?.title)" >
                    <img class="img-top" [src]="blog?.thumbnail | toSanitizedUrl" style="background-position: center"
                        onerror="this.onerror=null;this.src='assets/images/logo.svg'" />
                    <section class="title px-3 py-2">
                        <h5>{{ blog?.title }}</h5>
                    </section>
                    <section class="highlight px-3 py-2 text-muted mt-3 overflow-hidden" [innerHTML]="blog?.highlight">
                    </section>
                    <br />
                    <section class="p-3 text-muted">
                        <i class="fa fa-clock "></i>
                        {{ blog?.publishedDate }}
                    </section>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="load-more">
        <button class="btn btn-outline-primary" *ngIf="showLoadMore" (click)="loadBlog()">Load More</button>
    </div>
</div>

<div *ngIf="fffai" class="container mt-4 news-index">
    <div class="row overflow-hidden">
        <div class="news-article col-12 col-sm-6 col-md-4" *ngFor="let blog of listOfBlog"
            data-category="$categorySlug">
            <div *ngIf="blog?.blogUrlCategory; else searchWithCategory" class="card flex-shrink-0 mt-3" 
            [routerLink]="blog?.blogSlug ? '/fffai/blog/' + (blog?.blogUrlCategory ? blog?.blogUrlCategory + '/' : '') + blog?.blogSlug : '/fffai/blog/' + blog?.id">
                <img class="img-top" [src]="blog?.thumbnail | toSanitizedUrl" style="background-position: center"
                    onerror="this.onerror=null;this.src='assets/images/logo.svg'" />
                <section class="title px-3 py-2">
                    <h5>{{ blog?.title }}</h5>
                </section>
                <section class="highlight px-3 py-2 text-muted mt-3 overflow-hidden" [innerHTML]="blog?.highlight">
                </section>
                <br />
                <section class="p-3 text-muted">
                    <i class="fa fa-clock "></i>
                    {{ blog?.publishedDate }}
                </section>
            </div>
            <ng-template #searchWithCategory>
                <div class="card flex-shrink-0 mt-3" [routerLink]="blog?.blogSlug ? '/fffai/blog/' + blog?.blogSlug : '/fffai/blog/' + blog?.id">
                    <img class="img-top" [src]="blog?.thumbnail | toSanitizedUrl" style="background-position: center"
                        onerror="this.onerror=null;this.src='assets/images/logo.svg'" />
                    <section class="title px-3 py-2">
                        <h5>{{ blog?.title }}</h5>
                    </section>
                    <section class="highlight px-3 py-2 text-muted mt-3 overflow-hidden" [innerHTML]="blog?.highlight">
                    </section>
                    <br />
                    <section class="p-3 text-muted">
                        <i class="fa fa-clock "></i>
                        {{ blog?.publishedDate }}
                    </section>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="load-more">
        <button class="btn btn-outline-primary" *ngIf="showLoadMore" (click)="loadBlog()">Load More</button>
    </div>
</div>

<div *ngIf="wai" class="container mt-4 news-index">
    <div class="row overflow-hidden">
        <div class="news-article col-12 col-sm-6 col-md-4" *ngFor="let blog of listOfBlog"
            data-category="$categorySlug">
            <div *ngIf="blog?.blogUrlCategory; else searchWithCategory" class="card flex-shrink-0 mt-3" 
            [routerLink]="blog?.blogSlug ? '/wai/blog/' + blog?.blogUrlCategory + '/' + blog?.blogSlug : '/wai/blog/' + blog?.id">
                <img class="img-top" [src]="blog?.thumbnail | toSanitizedUrl" style="background-position: center"
                    onerror="this.onerror=null;this.src='assets/images/logo.svg'" />
                <section class="title px-3 py-2">
                    <h5>{{ blog?.title }}</h5>
                </section>
                <section class="highlight px-3 py-2 text-muted mt-3 overflow-hidden" [innerHTML]="blog?.highlight">
                </section>
                <br />
                <section class="p-3 text-muted">
                    <i class="fa fa-clock "></i>
                    {{ blog?.publishedDate }}
                </section>
            </div>
            <ng-template #searchWithCategory>
                <div class="card flex-shrink-0 mt-3" [routerLink]="blog?.blogSlug ? '/wai/blog/' + blog?.blogSlug : '/wai/blog/' + blog?.id">
                    <img class="img-top" [src]="blog?.thumbnail | toSanitizedUrl" style="background-position: center"
                        onerror="this.onerror=null;this.src='assets/images/logo.svg'" />
                    <section class="title px-3 py-2">
                        <h5>{{ blog?.title }}</h5>
                    </section>
                    <section class="highlight px-3 py-2 text-muted mt-3 overflow-hidden" [innerHTML]="blog?.highlight">
                    </section>
                    <br />
                    <section class="p-3 text-muted">
                        <i class="fa fa-clock "></i>
                        {{ blog?.publishedDate }}
                    </section>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="load-more">
        <button class="btn btn-outline-primary" *ngIf="showLoadMore" (click)="loadBlog()">Load More</button>
    </div>
</div>