<edu-default-layout>
<div style="margin-top: 100px !important;">
  <div class="container">
    <h1 class="text-center"><b>Our Approach</b></h1>
    <div class="mb-3">
      <img src="../../../assets/images/our-approach/Approach-web.png" alt="" style="width: 100%;" class="d-none md-2 d-sm-block">
      <img src="../../../assets/images/our-approach/Approach-mobile.png" alt="" style="width: 100%;" class="d-sm-none">
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 pb-4">
        <app-accordion [accordion]="accordion[0]"></app-accordion>
      </div>
      <div class="col-md-6 pb-4">
        <app-accordion [accordion]="accordion[1]"></app-accordion>
      </div>
    </div>
    <div class="container pb-5" style="padding: 0px !important;">
      <div class="row justify-content-center">
        <div class="col-md-6 pb-4">
          <app-accordion [accordion]="accordion[2]"></app-accordion>
        </div>

        <div class="col-md-6 p5-4">
          <app-accordion [accordion]="accordion[3]"></app-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
<br/><br/><br/><br/><br/><br/>
</edu-default-layout>

