import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Gallery, GalleryUserData} from '../models/gallery.models';
import * as CryptoJS from 'crypto-js';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class GalleryRestService {

  private apiUrl = `${environment.apiUrl}/lti`;

    private oauthConsumerKey = `${environment.oauthConsumerKey}`;
    private accessKeySecret = `${environment.accessKeySecret}`;


  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  // example given by nsdc
  // private token = 
  // "pLJSGxi+ZJPSnsrJPObRuuIyN7tvqT3tLOe38ql+JVZEMul8y41HAgPu60ZovMzhuEAZ+119BASu/q91/HBDf27+vOJ6WWQzAmPqsbPR3VaUu2rYvU1YV4Pa/nVbzEnDWDjJDsZrbO+7n/QmRqSyvxvDi/iT2BgtGreaUpHojoe496NLApkOSB14XejlxnopjpSXtqSjYP0iQin16w333BcMefB5v2k5o34W6Cdv/aWb4Yt8tMv4EkDjpHAaeN3LJsF8pfrPyrKUtkIADzvqBJthPN0WWdgn88G3DWauYAum3MwqUMOWebiWLpTSySjVv1q6oXrIi1uIpacsD0C0TRQZrFjS6r/12SpEjDt+hyvFliSD/4sdyWNcNUG6RdFGTHZqG3+LT4Gycdvt725sfgrms4WwRRFQW1yzduDldCBslA6qAt99jU5j1oGtZoBGuADUhJe6mglEu09rxlyB6YWvS4kQHMK0j6K4hUtXvEA=";
  // private CRYPTO_KEY = "oI1mdI3yp2etMvjNEwV7uA==";
  // private CRYPTO_IV = "ptVfhaSqSKNz1d2iI9emUQ==";

  // live token in uat
  // private token =  "7ie8WtwXVPLZxcwHTd1ubvsqRISsggz/oq9zbgZlk5zBExD08oW+M08fEDVxJzvjcit3rQ/QNof1D62GncHrdDeaLJw6jp28qanQPYB9g3vTbCf6wHC40Pgvrl8x07IAoxQ3IruPc+wpyYku6gyAAzTWRriPrnfLYUNVuGHmAcvRozNvPCWRva54n0Ah/2oi3aEZKAAXFKRn4mJ4jXKrDZtCxICWrLl21Z2YMQ3m3ll1K5JUJwT9Jf/l/d3hxPHlDQDtElBmfU2zi3+3opuiGq+2MksM8R3NcF/arUX/fbvXE1/o8Rge0+6UPax8/WTxShgKU9vIeqhtUXwygGmD2kDXoS9NAEJQ/TVD+lFTvg9PcC9RrG819F71hpDLTjIdIdDCi/GD/PXJ7kJthSvxktBJ9BX7ShraVfME3edPvL28f1D5y0WdolbO1PIo0GTVhgIb1riXMAwcz/JFJIjz01En4MPmmaLE/kpBv3bLnI4="
  // private CRYPTO_KEY = "5JfYL8tj2fGIyvz0Czo5Gg==";
  // private CRYPTO_IV = "+SCsNkjC8HGwco+xg2jS5g==";

  private  CRYPTO_IV= `${environment.CRYPTO_IV}` ;
  private CRYPTO_KEY  = `${environment.CRYPTO_KEY}`;

  decryptData(sid_token): string {
    try {
      const key = CryptoJS.enc.Base64.parse(this.CRYPTO_KEY);
      const iv = CryptoJS.enc.Base64.parse(this.CRYPTO_IV);
      const encrypted = CryptoJS.enc.Base64.parse(sid_token);
      const decrypted = CryptoJS.AES.decrypt({ ciphertext: encrypted }, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      const decryptedData = CryptoJS.enc.Utf8.stringify(decrypted);
      return decryptedData;
    } catch (error) {
      console.error('Decryption error:', error.message);
      return error.message;
    }
  }

  private generateOAuthSignature(method: string, url: string, params: any): string {
    // Step 1: Sort parameters by key
    const sortedParams = Object.keys(params).sort().reduce((result, key) => {
      result[key] = params[key];
      return result;
    }, {});

    // Step 2: Create parameter string
    const parameterString = this.createParameterString(sortedParams);

    // Step 3: Create base string
    const baseString = method.toUpperCase() + '&' + encodeURIComponent(url) + '&' + encodeURIComponent(parameterString);

    // Step 4: Generate HMAC-SHA1 signature
    const signingKey = this.accessKeySecret + '&';
    // console.log('baseString::::::: ',baseString)
    // console.log('signingKey::::::: ',signingKey)
    const hmacDigest = CryptoJS.HmacSHA1(baseString, signingKey);
    
    const signature = CryptoJS.enc.Base64.stringify(hmacDigest);

    return signature;
  }


  private createParameterString(params: any): string {
    let paramKeys = Object.keys(params);
    let paramPairs = paramKeys.map(key => `${key}=${encodeURIComponent(params[key])}`);
    return paramPairs.join('&');
  }

  public launchLti(decryptedDataForLti): void {
    let oauthNonce = this.generateNonce();
    let oauthTimestamp = Math.floor(Date.now() / 1000).toString();
    // console.log('decryptedDataForLti::::::::::: ',decryptedDataForLti)
    // Ensure the decryptedDataForLti is parsed as an object
    const decryptedDataForLtiObj = typeof decryptedDataForLti === 'string' ? JSON.parse(decryptedDataForLti) : decryptedDataForLti;
    // console.log('decryptedDataForLtiObj::::::::::: ', decryptedDataForLtiObj)

    let candidate_name = decryptedDataForLtiObj.candidate_name;
    let last_name = decryptedDataForLtiObj.last_name;
    let candidate_id = decryptedDataForLtiObj.candidate_id;
    let userMobile = "+91"+ decryptedDataForLtiObj.mobile;
    let userEmail = decryptedDataForLtiObj.candidate_email;
    // console.log('reassigned ::::::::::: ', candidate_name, last_name, candidate_id)
    let params = {
      oauth_callback: 'about:blank',
      oauth_consumer_key: this.oauthConsumerKey,
      oauth_nonce: oauthNonce,
      oauth_signature_method: 'HMAC-SHA1',
      oauth_timestamp: oauthTimestamp,
      oauth_version: '1.0',
      lti_message_type: 'basic-lti-launch-request',
      lti_version: 'LTI-1p0',
      custom_person_phone_mobile: userMobile,
      user_id: candidate_id,
      roles: 'student',
      lis_person_name_given: candidate_name,
      lis_person_name_family: last_name,
      lis_person_name_full: candidate_name,
      lis_person_contact_email_primary: userEmail,
      tool_consumer_instance_name: 'SIDH',
    };
    // console.log('params:::::::: ',params);

    let oauthSignature = this.generateOAuthSignature('POST', this.apiUrl, params);

    params['oauth_signature'] = oauthSignature;

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let options = {
      headers: headers
    };

    let body = new HttpParams({ fromObject: params });

    this.http.post(this.apiUrl, body.toString(), options)
      .subscribe(
        (response) => {
          console.log('Success:', response);
          // Handle response here
          
        },
        (error) => {
          console.error('Error:', error);
          // Handle error here
        }
      );
  }

  // private generateNonce(): string {
  //   return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  // }

  private generateNonce(): string {
    const randomBytes = CryptoJS.lib.WordArray.random(32);
    return randomBytes.toString(CryptoJS.enc.Hex);
  }

  getGallery(): Observable<Gallery> {
    return this.http.get<Gallery>(`${environment.apiUrl}/gallery`);
  }

  getGalleryByJob(jobId): Observable<Gallery> {
    return this.http.get<Gallery>(`${environment.apiUrl}/gallery/job/${jobId}`);
  }

  getGalleryById(galleryId): Observable<Gallery> {
    return this.http.get<Gallery>(`${environment.apiUrl}/gallery/${galleryId}`);
  }

  getGalleryContentById(galleryId): Observable<Gallery> {
    return this.http.get<Gallery>(`${environment.apiUrl}/gallery/${galleryId}/content`);
  }

  getModuleDetails(gallerynewId): Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}/user/module/details/${gallerynewId}`);
  }

  getGalleryUserDataById(galleryId): Observable<GalleryUserData> {
    return this.http.get<GalleryUserData>(`${environment.apiUrl}/gallery/${galleryId}/userData`);
  }

  sendContentRating(feedBack, rating, contentId, contentType): Observable<any> {
    const body = {feedBack: feedBack, rating: rating, contentId: contentId, contentType: contentType};
    return this.http.patch<any>(`${environment.apiUrl}/contentRating`, {body});
  }

  getAllRatingInfo(contentId, contentType): Observable<any> {
    let body = contentId + "~" + contentType;
    return this.http.get<any>(`${environment.apiUrl}/getAllRatingInfo/${body}`);
  }

  getChallenge(challengeId): Observable<Gallery> {
    return this.http.get<Gallery>(`${environment.apiUrl}/challenge/${challengeId}`);
  }

  confirmChallengeCompletionOverlay(challengeId: number): Observable<any>{
    return this.http.put<any>(`${environment.apiUrl}/challenge/completionPopup/${challengeId}`, {});
  }

  logOpenedGalleryStatus(galleryId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/gallery/logOpenedStatus/${galleryId}`, {});
  }

  logOpenedChallengeStatus(challengeId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/challenge/logOpenedStatus/${challengeId}`, {});
  }

  registrationOfEskill(registrationEskillObj): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/lti`, registrationEskillObj);
  }
  
}
