import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import { RegistrationFormsService } from 'src/app/modules/registration-forms/services/registration-forms.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/shared-form/validation-service/custom-validators';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'edu-get-in-touch-form-for-courses',
  templateUrl: './get-in-touch-form-for-courses.component.html',
  styleUrls: ['./get-in-touch-form-for-courses.component.scss']
})
export class GetInTouchFormForCoursesComponent implements OnInit {

  @ViewChild('formElement') formElement: ElementRef;

  @Output() isFormSubmittedEvent = new EventEmitter<boolean>();
  @Input() popUpFormEvent: boolean;

  popUpForm: UntypedFormGroup;
  textEnteredInFirstName: string = '';
  textEnteredInLastName: string = '';
  textEnteredInAdditionalInfo: string = '';
  isMobileResolution: boolean;
  countriesData: any;
  showThankYouMessage: boolean = false;
  isFormSubmitted: boolean = false;
  disableSubmit: boolean = false;

  constructor(
    private registrationFormsService: RegistrationFormsService,
    private sharedService: SharedService 
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.retrieveCountryData();
    this.isMobileResolution = this.registrationFormsService.getResolution();
  }

  retrieveCountryData() {
    this.registrationFormsService.fetchCountryData().subscribe(
      (countryData: any[]) => {
        this.countriesData = countryData;
      },
      error => {
        console.error('Error fetching country data:', error);
      }
    );
  }

  createForm() {
    this.popUpForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', []),
      lastName: new UntypedFormControl('', []),
      country: new UntypedFormControl('', []),
      company: new UntypedFormControl('', []),
      countryCode: new UntypedFormControl('+91', [
        this.registrationFormsService.noWhitespaceValidator
      ]),
      phone: new UntypedFormControl('', [
        Validators.minLength(9),
        Validators.maxLength(14),
        this.registrationFormsService.noWhitespaceValidator
      ]),
      email: new UntypedFormControl('', [
        Validators.required,
        CustomValidators.email,
        this.registrationFormsService.noWhitespaceValidator
      ]),
      industry: new UntypedFormControl('', []),
      jobFunction: new UntypedFormControl('', []),
      noOfEmployees: new UntypedFormControl('', []),
      additionalInfo: new UntypedFormControl('', []),
      typeOfEnquiry: new UntypedFormControl('', [])
    });
  }

  numberOnly(event: any): boolean {
    return this.registrationFormsService.numberOnly(event);
  }

  keepPlusSign(event: any) {
    this.registrationFormsService.keepPlusSign(event);
  }

  noSpaceAllowedInStart(event: any) {
    if (this.textEnteredInAdditionalInfo.length < 1) {
      this.registrationFormsService.avoidSpaces(event);
    }
  }

  onlyAlphabets(event: any) {
    if (event.srcElement.id == "firstName") {
      if (this.textEnteredInFirstName.length < 1) {
        this.registrationFormsService.avoidSpaces(event);
      }
    } else {
      if (this.textEnteredInLastName.length < 1) {
        this.registrationFormsService.avoidSpaces(event);
      }
    }
    this.registrationFormsService.onlyAlphabets(event);
  }

  startWithAlphabetOnly(event: any) {
    if (event.target.id == "firstName") {
      this.textEnteredInFirstName = this.registrationFormsService.getValue(event);
    } else if (event.target.id == "lastName") {
      this.textEnteredInLastName = this.registrationFormsService.getValue(event);
    } else {
      this.textEnteredInAdditionalInfo = this.registrationFormsService.getValue(event);
    }
  }

  avoidSpaces(event: any) {
    this.registrationFormsService.avoidSpaces(event);
  }

  handleButtonClickToGA(parameter: string) {
    gtag('event', 'click', {
      event_category: 'get_in_touch_submit_button_click',
      event_label: 'Get In Touch Submit Button Click'
    });
    switch(parameter) {
      case 'submitGetInTouch':
        gtag('event', 'form_start', {
          event_category: 'get_in_touch_form_start',
          event_label: 'Get In Touch Form Start'
        });
        break;
      case 'submitGetInTouchSuccess':
        gtag('event', 'form_submit', {
          event_category: 'get_in_touch_form_submit',
          event_label: 'Get In Touch Form Submit'
        });
        break;
    }
  }

  // onSubmit(firstName: any, lastName: any, country: any, company: any, phoneNumber: any, email: any, industry: any, jobFunction: any, noOfEmployees: any, additionalInfo: any): void {
  //   const integrationToken = 'secret_xaophWAm2GemMeu1TQdl6OgnWHxSd0rKxxcj1ZkSvIq';
  //   const databaseId = '6ba70698d87a4c27bf810b6762c9673d';

  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     Authorization: `Bearer ${integrationToken}`,
  //     'Notion-Version': '2022-06-28'
  //   });

  //   const formData = {
  //     parent: { database_id: databaseId },
  //     properties: {
  //       'First Name': { title: [{ text: { content: firstName } }] },
  //       'Last Name': { title: [{ text: { content: lastName } }] },
  //       Country: { title: [{ text: { content: country } }] },
  //       Company: { title: [{ text: { content: company } }] },
  //       'Phone Number': { title: [{ text: { content: phoneNumber } }] },
  //       Email: { title: [{ text: { content: email } }] },
  //       Industry: { title: [{ text: { content: industry } }] },
  //       'Job Function': { title: [{ text: { content: jobFunction } }] },
  //       'Number Of Employees': { title: [{ text: { content: noOfEmployees } }] },
  //       'Additional Info': { title: [{ text: { content: additionalInfo } }] }
  //     },
  //   };    

  //   this.http
  //     .post(`https://api.notion.com/v1/pages/${databaseId}/children`, formData, { headers })
  //     .subscribe(
  //       () => {
  //         console.log('Form data successfully sent to Notion');
  //       },
  //       error => {
  //         console.error('Error while sending form data to Notion:', error);
  //       }
  //     );
  // }

  submitForm() {
    this.popUpForm.markAllAsTouched();
    this.handleButtonClickToGA('submitGetInTouch');
    if (this.popUpForm.valid) {
      const firstName = this.popUpForm?.value?.firstName?.trim();
      const lastName = this.popUpForm?.value?.lastName?.trim();
      const country = this.popUpForm?.value?.country?.trim();
      const company = this.popUpForm?.value?.company?.trim();
      const countryCode = this.popUpForm?.value?.countryCode?.trim();
      const phone = this.popUpForm?.value?.phone?.trim();
      const email = this.popUpForm?.value?.email?.trim();
      const industry = this.popUpForm?.value?.industry?.trim();
      const jobFunction = this.popUpForm?.value?.jobFunction?.trim();
      const noOfEmployees = this.popUpForm?.value?.noOfEmployees?.trim();
      const additionalInfo = this.popUpForm?.value?.additionalInfo?.trim();
      const typeOfEnquiry = this.popUpForm?.value?.typeOfEnquiry?.trim();

      //this.onSubmit(firstName, lastName, country, company, countryCode + phone, email, industry, jobFunction, noOfEmployees, additionalInfo);

      if (!!email) {
        if (phone) {
          if (phone.length <= 14 && phone.length >= 9 && countryCode.length >= 2) {
            this.submitFormToBackend(firstName, lastName, country, company, phone, countryCode, email, industry, jobFunction, noOfEmployees, additionalInfo, typeOfEnquiry);
          }else {
            this.scrollToForm();
            return
          }
        } else {
          this.submitFormToBackend(firstName, lastName, country, company, phone, countryCode, email, industry, jobFunction, noOfEmployees, additionalInfo, typeOfEnquiry);
        }     
      }
    } else {
      this.scrollToForm();
      return;
    }
  }
  scrollToForm() {
    if (this.formElement && this.formElement.nativeElement) {
      this.formElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  submitFormToBackend(firstName: any, lastName: any, country: any, company: any, phone: any, countryCode: any, email: any, industry: any, jobFunction: any, noOfEmployees: any, additionalInfo: any, typeOfEnquiry: any) {
    this.isFormSubmitted = true;
    this.disableSubmit = true;
    let phoneNumber = phone ? countryCode + phone : null;
    const timestamp = this.registrationFormsService.getTimeStamp();
    let formData = {
      firstName: firstName,
      lastName: lastName,
      country: country,
      company: company,
      phoneNumber: phoneNumber,
      email: email,
      industry: industry,
      jobFunction: jobFunction,
      noOfEmployees: noOfEmployees,
      additionalInfo: additionalInfo,
      timeStamp: timestamp,
      typeOfEnquiry: typeOfEnquiry,
    };
    localStorage.setItem('popUpFormSubmitted', 'true');
    if(this.popUpFormEvent) {
      this.sharedService.hideGetInTouchFormAtBottomOfHomePage = true;
      this.isFormSubmittedEvent.emit(true);
    }else {
      this.showThankYouMessage = true;
      setTimeout(() => {
        this.isFormSubmittedEvent.emit(true);
      }, 5000);
    }
    this.sharedService.disableButtons = true;
    this.handleButtonClickToGA('submitGetInTouchSuccess');
    window['lintrk']('track', { conversion_id: 14050065 });
    this.registrationFormsService.submitPopUpForm(formData).subscribe(
      (response) =>
        (error: any) => console.log(error)
    )
  }
}
