import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {Subject} from 'rxjs';

function _window() : any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class RazorpayAuthService {
  
  get nativeWindow() : any {
    return _window();
 }
  constructor() { }
}
