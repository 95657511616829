import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { RegistrationFormsRestService } from './registration-forms-rest.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationFormsService {

  constructor(
    private registrationFormsRestService: RegistrationFormsRestService
  ) { }

  getResolution() {
    if (window.innerWidth < 768) {
      return true;
    } else {
      return false;
    }
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 43 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
    return true;
  }

  noWhitespaceValidator(control: UntypedFormControl) {
    const isSpace = (control.value || '').match(/\s/g);
    return isSpace ? { 'whitespace': true } : null;
  }

  keepPlusSign(event: any) {
    if (event.key === 'Backspace' && event.target.value.length <= 1) {
      event.preventDefault();
    }
  }

  avoidSpaces(event: any) {
    const key = event.keyCode;
    if (key === 32) {
      event.preventDefault();
    }
  }

  onlyAlphabets(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode !== 43 && (charCode < 48 || charCode > 57)) {
      return true;
    } else {
      event.preventDefault();
    }
  }

  getTimeStamp() {
    let date = new Date();
  
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let seconds = ("0" + date.getSeconds()).slice(-2);
  
    let timezoneOffset = date.getTimezoneOffset();
    let timezoneHours = Math.floor(Math.abs(timezoneOffset) / 60);
    let timezoneMinutes = Math.abs(timezoneOffset) % 60;
    let timezonePrefix = timezoneOffset < 0 ? "+" : "-";
  
    let timestamp = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds + " GMT" + timezonePrefix + timezoneHours + ":" + ("0" + timezoneMinutes).slice(-2);
    return timestamp;
  }  

  getValue(event: any) {
    return event.target.value;
  }

  fetchCountryData() {
    return this.registrationFormsRestService.fetchCountryData();
  }

  submitRegistrationForm(formData: any) {
    return this.registrationFormsRestService.submitRegistrationForm(formData);
  }

  submitPopUpForm(formData: any) {
    return this.registrationFormsRestService.submitPopUpForm(formData);
  }

  subscribeToNewsletter(formData: any) {
    return this.registrationFormsRestService.subscribeToNewsletter(formData);
  }

}
