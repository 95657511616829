<edu-default-layout>
    <header class="show-course-header">
        <div class="container text-left d-flex justify-content-between">
            <div>
                <h2 *ngIf="enrolledCourse?.name" class="text-left mt-2 text-dark">{{ enrolledCourse?.name }} - {{moduleTitle}}</h2>
                <h2 *ngIf="!enrolledCourse?.name" class="text-left mt-2 text-dark">{{moduleTitle}}</h2>
                <div class="status in-progress">
                    <img src="assets/images/my-courses/progress.svg" class="mr-1" />
                    <span class="pt-2">{{moduleStatus}}</span>
                </div>

            </div>
            <div class="mt-5">
                <button class="btn btn btn-outline-primary" data-toggle="modal" data-target="#ratingModal">Leave a
                    rating
                </button>
            </div>
        </div>
    </header>
    <div class="container text-left d-flex justify-content-between">
        <div>
            <h2 class="text-left mt-2 text-dark"></h2>
        </div>
    </div>
    <div class="container-fluid bg-dark">
        <div class="container">
            <div class="aspect-ratio">
                <iframe class="w-100 h-100 border-0"></iframe>
            </div>
        </div>
        <br/>
        
    </div>
    <div class="container-fluid position-relative shadow-below">
        <!-- <a class="nav-link pb-3 px-1 mr-3 active">About course</a> -->
        <br/>

        <div class="courseContainer" style="display: flex; overflow-x: scroll;
            max-width: 100%;
            white-space: nowrap;">
                <div *ngFor="let course of enrolledCourse.modules" style="width: 133px; margin-right: 8px;
                margin-top: 8px; margin-bottom: 10px;">
                    <div class="card-box" >
                        <section (click)="showModuleDetails(course.id,course.title,course.description,course.currentStatus)">
                            <div class="top">
                                <div class="top-image-wrapper">
                                    <img src="{{course.banner}}" alt="image" class="top-image"/>
                                </div>
                                
                            </div>
                            <div class="bottom">
                                <span class="title">
                                    {{course.title | slice:0:42}}
                                </span>
                                <div class="info-wrapper">
                                    <span class="status {{course.currentStatus}}">{{course.currentStatus}}</span>
                                </div>
                            </div>
                        </section>
        
                    </div>
                </div>
    
        </div>


        <br/>
        <br/> 
        
        <div class="container">
            <h2 class="text-left mb-2 descTitle">Description</h2>
            <p class="text-muted">{{ moduleDescription }}</p>
            <!-- <h2 class="text-left mb-2">What you will learn </h2>
            <ul class="list-unstyled text-muted topics"></ul> -->
            <div *ngIf="skillDescription?.length > 0">
                <h2 class="text-left mb-2 descTitle" >Skills you will gain</h2>
                <ul class="list-inline list-unstyled skills">
                    <li class="rounded-pill border border-primary mt-1 px-4 py-2 list-inline-item" 
                        style="font-size: medium;" *ngFor = "let data of skillDescription">{{data}}</li>
                </ul>
               
                <!-- <p class="skill-text"></p> -->
            </div>

        </div>

        <br/>
        <br/> 

    </div>



    <div class="modal fade" id="ratingModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog rating-dialog modal-dialog-centered">
            <form method="post">
                <input type="hidden" id="g-token" name="g-token" />
                <div class="modal-content">
                    <div class="modal-header px-4">
                        <h5 class="modal-title page1" *ngIf="showRate">How would you rate this course?</h5>
                        <h5 class="modal-title page2" *ngIf="!showRate">Thanks for helping us grow</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body px-4">
                        <div class="form-group text-center text-primary mt-4 page1" *ngIf="showRate">
                            <div id="ratingExplanation" class="mb-2">{{ title }}</div>
                            <div class="star-rating">
                                <input id="star-5" type="radio" (click)="starAmount('star5')" name="rating" value="star-5" />
                                <label for="star-5" (mouseenter) ="mouseEnter('star5')" (mouseleave) ="mouseLeave()" title="5 stars">
                                    <i class="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                &nbsp;
                                <input id="star-4" type="radio" (click)="starAmount('star4')" name="rating" value="star-4" />
                                <label for="star-4" (mouseenter) ="mouseEnter('star4')" (mouseleave) ="mouseLeave()" title="4 stars">
                                    <i class="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                &nbsp;
                                <input id="star-3" type="radio" (click)="starAmount('star3')" name="rating" value="star-3" />
                                <label for="star-3" (mouseenter) ="mouseEnter('star3')" (mouseleave) ="mouseLeave()" title="3 stars">
                                    <i class="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                &nbsp;
                                <input id="star-2" type="radio" (click)="starAmount('star2')" name="rating" value="star-2" />
                                <label for="star-2" (mouseenter) ="mouseEnter('star2')" (mouseleave) ="mouseLeave()" title="2 stars">
                                    <i class="active fa fa-star" aria-hidden="true"></i>
                                </label>
                                &nbsp;
                                <input id="star-1" type="radio" name="rating" (click)="starAmount('star1')" value="star-1" />
                                <label for="star-1" (mouseenter) ="mouseEnter('star1')" (mouseleave) ="mouseLeave()" title="1 star">
                                    <i class="active fa fa-star" aria-hidden="true"></i>
                                </label>
                            </div>
                        </div>
                        <div class="form-group page1" *ngIf="showRate">
                            <label for="personalExperience">
                                Write us about your own personal experience taking this course.
                            </label>
                            <textarea class="form-control" id="personalExperience" name="text" rows="3"></textarea>
                        </div>
                        <div class="text-muted page2" *ngIf="!showRate">
                            Your review will be public within 24 hours
                        </div>
                        <div class="card m-4 p-3 page2 flex flex-row p-3" *ngIf="!showRate">
                            <div class="">
                                <a class="d-block text-center player-badge bg-primary rounded-circle text-white pt-2 "
                                    data-toggle="collapse" data-target="#userFoldOut" aria-expanded="false"
                                    aria-controls="userFoldOut">
                                    T P </a>
                            </div>
                            <div class="flex-1 ml-3">
                                <div>
                                    <!-- Testing Prasad  -->
                                </div>
                                <br/>
                                <div>
                                    <section class="rating">
                                        <span class="fa fa-star + ' ' + {{ star1 }}"></span>
                                        <span class="fa fa-star + ' ' + {{ star2 }}"></span>
                                        <span class="fa fa-star + ' ' + {{ star3 }}"></span>
                                        <span class="fa fa-star + ' ' + {{ star4 }}"></span>
                                        <span class="fa fa-star + ' ' + {{ star5 }}"></span>
                                    </section>
                                </div>
                                <div class="text-muted mt-2" id="displayPersonalExperience">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer px-4">
                        <button type="button" class="btn btn-primary page1" id="ratingContinue" (click)="previewRating('1')" *ngIf="showRate">Continue</button>
                        <button type="button" class="btn btn-empty page2" id="back" (click)="previewRating('2')" *ngIf="!showRate">Back</button>
                        <button type="submit" name="btnSubmit" class="btn btn-primary page2" (click)="submitRating(enrolledCourse?.id)" *ngIf="!showRate">Save and close</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</edu-default-layout>