import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { LanguageService } from "./core/language/language.service";
import { NavigationService } from './core/navigation.service';
import { NetworkStatusService } from "./core/network-status.service";
import { ScreenOrientation, ScreenViewService } from "./core/screen-view.service";
import { UserService } from "./core/user.service";
import { getMessaging, onMessage } from "firebase/messaging";
import { ActivatedRoute, NavigationEnd, Router, RouterState } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = '21CC Recruitment & Training';
  message: any;

  screenOrientation$: Observable<ScreenOrientation>;

  constructor(
    public navigationService: NavigationService,
    private screenViewService: ScreenViewService,
    private userService: UserService,
    private networkStatusService: NetworkStatusService,
    public languageService: LanguageService,
    private router: Router,
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.handleRouteEvents();
  }

  ngOnInit(): void {
    this.screenOrientation$ = this.screenViewService.screenOrientation$;
    this.navigationService.init();
    this.networkStatusService.init();
    this.screenViewService.init();
    this.userService.init();
    this.languageService.initNoAuth();
    this.listen();
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message = payload;
    });
  }

  onDeactivate() {
    document.body.scrollTop = 0;
    // Alternatively, you can scroll to top by using this other call:
    // window.scrollTo(0, 0)
  }

  onActivate() {
    //document.body.scrollTop = 0;
    // Alternatively, you can scroll to top by using this other call:
    //  window.scrollTo(0, 0)
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  handleRouteEvents() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
        this.titleService.setTitle(title);
        gtag('event', 'page_view', {
          page_title: title,
          page_path: event.urlAfterRedirects,
          page_location: this.document.location.href
        });
        fbq('track', 'PageView');
      }
    });
  }

  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }
}
