import {Location} from '@angular/common';
import {Injectable, NgZone} from '@angular/core';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
// import { Plugins} from '@capacitor/core';
import { App } from '@capacitor/app';
import { Title } from '@angular/platform-browser';
import {SharedLayoutService} from "../shared-layout/shared-layout.service";
import {LocalStorageService} from "./local-storage.service";
import { GalleryService } from '../modules/gallery/services/gallery.service';
import { MediaPlayerService } from '../modules/media-player/services/media-player.service';


@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private location: Location,
    private router: Router,
    private titleService: Title,
    private layoutService: SharedLayoutService,
    private zone: NgZone,
    private localStorageService: LocalStorageService,
    private mediaPlayerService: MediaPlayerService
  ) {}

  // To e removed when title service is extended to emit an observable on change
  private _title$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  title: Observable<string> = this._title$.asObservable().pipe(distinctUntilChanged(), filter(x => !!x));

  public routesStack: NavigationEnd[] = [];
  private navigationEnd$: Observable<NavigationEnd>;

  redirectToRoot: boolean;

  private lastItemInRouteStack = () => this.routesStack[this.routesStack.length - 1];

  public init() {
    this.navigationEnd$ = this.router.events
      .pipe(
        filter(ev => ev instanceof(NavigationEnd)),
        map(navEnd => navEnd as NavigationEnd)
      );
    this.hijackAndroidBackButton();
    this.listenToRouteChanges();
    this.scrollToTopOnNavigation();
  }

  private scrollToTopOnNavigation() {
    this.navigationEnd$.subscribe((event: NavigationEnd) => {
      setTimeout(() => {
        const topElement = document.getElementById('fm-top-nav-bar');
        if (topElement) {
          topElement.scrollIntoView({ behavior: 'smooth' });
        } else {
          window.scrollTo(0, 0);
        }
      }, 100); // Adjust the timeout value as needed
    });
  }
  
  public setTitle(title: string) {
    // The components should use the title service directly.
    // The title service should be extended to expose an observable of currentTitle
    this._title$.next(title);
    this.titleService.setTitle(title);
  }

  public goBack() {

    //closing sideMenu if opened
    let bool = this.layoutService.isShowSideMenu();
    if(bool){
      this.layoutService.hideSideMenu();
    }

    //exit from app when press android back button on rootFM and start page.
    if((this.router.url.includes('start') || this.router.url.includes('industry')) && this.routesStack.length <= 1){
      document.addEventListener('backbutton', function(event){
        event.preventDefault();
        (navigator as any).app.exitApp(); // exit the app
      });
    }
    
    if (this.routesStack.length) {
     if((this.router.url.includes('play')) && ((this.router.url.includes('module')))){
      const sendModuleClosedId = this.router.url.split('/')[3];
      this.mediaPlayerService.getModuleCompletionStatus(sendModuleClosedId);
     }
      if (this.redirectToRoot) {
        this.removeCurrentPageFromBackHistory();
        const userOnRegistration = this.router.url.includes('registration');
        if (userOnRegistration){
          this.router.navigate(['/registration/start-with-login']);
        } else {
          this.router.navigate(['/industry']);
        }
      } else {
        const prevUrl = this.routesStack.pop(); // remove current page
        const prevPage: NavigationEnd = this.routesStack[this.routesStack.length - 1];
        const prevPageUrl: string = decodeURIComponent(prevPage.urlAfterRedirects);
        const splitUrlObj = this.splitUrlAndParams(prevPageUrl);
        this.router.navigate([splitUrlObj.strippedUrl], {queryParams: splitUrlObj.queryParams, queryParamsHandling: 'merge', state: {prevUrl}});
      }
    } else {
      console.log('Cannot go back any more');
    }
  }


  /**
   * Takes an url string and returns an object with:
   * @param url the full url to be split
   * @return an object containing the `strippedUrl` (the root url without params)
   * and `queryParams` (an object with each param as a key-value pair)
   */
  splitUrlAndParams(url: string){
    const queryParams = {};
    const strippedUrl = url.split('?')[0];
    const strippedParamsSequence = url.split('?')[1];
    if (strippedParamsSequence) {
      const individualParamsArr = strippedParamsSequence.split('&');
      individualParamsArr.forEach(param => {
        if (param) {
          const splitQueryParam = param.split('=');
          const key = splitQueryParam[0];
          const value = splitQueryParam[1];
          queryParams[key] = value;
        }
      });
      Object.assign(queryParams, {portrait: null});
    }
    return {
      strippedUrl,
      queryParams
    };
  }

  // Used when you want to skip a page when pressing back.
  public removeCurrentPageFromBackHistory() {
    this.routesStack.pop();
  }

  public previousPageUrl(): string{
    const lastRoute = this.routesStack[this.routesStack.length - 2]
    return lastRoute ? lastRoute.urlAfterRedirects : '';
  }

  // when the back button is used, don't include the previous page if it includes this url fragment
  // Used to stop toggles being included in back history (highscores for example)
  public skipBackForPreviousUrlsIncluding(urlFragment: string) {
    if (this.previousPageUrl().includes(urlFragment)) {
      // console.log('removeing from history: ', this.location.path());
      this.removeCurrentPageFromBackHistory();
    }
  }

  private listenToRouteChanges() {
    this.navigationEnd$
      .subscribe(
        (navEnd: NavigationEnd) => {
          this.updateRouteStack(navEnd);
          this.checkHideBackButton();
          this.resetObservables();
        }
      );
  }

  /**
   * Reset to defaults
   */
  private resetObservables() {
    this.setTitle('');
  }

  private checkHideBackButton() {
    const previousUrl = this.routesStack[this.routesStack.length - 2];
    // reset redirectToRoot
    this.redirectToRoot = false;
    if (!previousUrl) {
      if (this.router.url.includes('registration')){
        this.redirectToRoot = true;
        return;
      } else if (!this.router.url.includes('industry')) {
        this.redirectToRoot = true;
        return;
      } else {
        this.layoutService.hideBackButton();
        return;
      }
    }
    const prevUrlHasRegistrationFragment = previousUrl.urlAfterRedirects.includes('registration');
    const shouldHide = this.routesStack.length <= 1 || prevUrlHasRegistrationFragment;
    if (shouldHide) {
      this.layoutService.hideBackButton();
    } else {
      this.layoutService.showBackButton();
      this.redirectToRoot = false;
    }
  }

  private updateRouteStack(navEnd: NavigationEnd) {
    // console.log("Before load routeStack: ", this.routesStack);

    if(navEnd.urlAfterRedirects.includes('/fffai/login')){
        // Remove all previous pages with URLs containing 'fffai'
      this.routesStack = this.routesStack.filter(route => !route.urlAfterRedirects.includes('fffai'));
      this.routesStack.push(navEnd);
    }else if(navEnd.urlAfterRedirects.includes('/wai/login')){
          // Remove all previous pages with URLs containing 'fffai'
        this.routesStack = this.routesStack.filter(route => !route.urlAfterRedirects.includes('wai'));
        this.routesStack.push(navEnd);
    }else{
      if(!navEnd.urlAfterRedirects.includes('/careers/job-details/') && !navEnd.urlAfterRedirects.includes('/gallery/video/') && !navEnd.urlAfterRedirects.includes('/media/video/')) {
        this.localStorageService.removeFromLocalStorage("JOB_OPENING_SHOW_PAGE");
      }
      if(
        this.lastItemInRouteStack() &&
        this.lastItemInRouteStack().urlAfterRedirects !== navEnd.urlAfterRedirects
      ) {
        this.routesStack.push(navEnd);
      } else {
        this.routesStack.pop();
        this.routesStack.push(navEnd);
      }
      // console.log("After load routeStack: ", this.routesStack);
    } 

  }

  private hijackAndroidBackButton() {
    const handler = App.addListener("backButton", (data) => {
      console.log('back CB', {...data});
      console.log('this.routesStack', this.routesStack)
      this.zone.run(() => this.goBack())
    })
    console.log('handler', {...handler})
  }

  clearStackIfIndustry(){
    this.routesStack.splice(0,4);
    console.log(`root stack here`);
    console.log(this.routesStack);
  }

  // unused
  // clearStackIfRootFM(navEnd: NavigationEnd) {
  //   if (navEnd.urlAfterRedirects.includes('gallery/0')) {
  //     const currentPage = this.routesStack[this.routesStack.length -1];
  //     this.routesStack = [currentPage];
  //     console.log('Rootstack Cleared, new stack:', this.routesStack);
  //   }
  // }

  // not used but something similar could be useful
  public setBackToLocation(absoluteUrl: string): void { // this will need to insert a page before last item(current page) depending on timeing
    // the url must be absolute - eg /galley/571 not ../571
    // console.log('setting back to:', absoluteUrl);
    if (absoluteUrl && !absoluteUrl.startsWith('/')) {
      console.error('Use an absolute url with setBackLocation(). eg "/galley/571" not "../571"')
    }
  }

}
