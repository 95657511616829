import { Injectable } from '@angular/core';
import {TranslocoService} from "@ngneat/transloco";

@Injectable({
  providedIn: 'root'
})
export class LocaleHelpersService {

  /*
  This is a very basic service to keep this logic in one place. This will need updating as noew countries are added
  and probably by  transloco-locale package or something similar in the long-run.
   */

  constructor(
    private transloco: TranslocoService
  ) { }

  // eg: NLD => €
  currencyFromCountryCode(countryCode: string) {
    switch (countryCode) {
      case 'IND':
        return '₹';
        break;
      case 'NLD':
        return '€';
        break;
      case 'MYS':
        return 'RM';
        break;
      case 'SGP':
        return '$';
        break;
      case 'KEN':
        return 'KSh';
        break;
      default:
        return '$';
    }
  }

  // eg: NLD => Netherlands
  nameFromCountryCode(countryCode) {
    const india = this.transloco.translate('locale.india');
    const netherlands = this.transloco.translate('locale.netherlands');
    const malaysia = this.transloco.translate('locale.malaysia');
    const singapore = this.transloco.translate('locale.singapore');
    const kenya = this.transloco.translate('locale.kenya');
    switch (countryCode) {
      case 'IND':
        return india;
        break;
      case 'NLD':
        return netherlands;
        break;
      case 'MYS':
        return malaysia;
        break;
      case 'SGP':
        return singapore;
        break;
      case 'KEN':
        return kenya;
        break;
    }
  }

  // eg: nl-NL => €
  currencyFromLocale(locale) {
    return locale === 'in-IN' ? '₹' : '€';

  }

  // eg: EUR => €
  currencyFromCurrencyCode(currencyCode: string) {
    switch (currencyCode) {
      case 'INR':
        return '₹';
        break;
      case 'EUR':
        return '€';
        break;
      case 'USD':
        return '$';
        break;
      case 'GBP':
        return '£';
        break;
      case 'MYR':
        return 'MR';
        break;
      case 'SGD':
        return '$';
        break;
      case 'KES':
        return 'KSh';
        break;
      default:
        return '$';
    }
  }
}
