import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BlogCardComponent } from 'src/app/shared/blog-card/blog-card.component';
import { BlogService } from './services/blog.service';

@Component({
  selector: 'edu-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  tabName: string = '';
  imgSrc: string;
  showArticle: boolean = false;
  categories: any = [];
  loading: boolean = true;

  constructor(
    private titleService: Title,
    private blogService: BlogService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Blog - 21CC Recruitment & Training");
    this.blogService.getAllCategories().subscribe((allCategories: any) => {
      this.categories = allCategories;
    });
  }

  assignName(name: string) {
    this.tabName = name;
    if (name == "In The News") {
      this.showArticle = true;
    } else {
      this.showArticle = false;
    }
  }

  receiveloadingEvent(receivedData: boolean) {
    this.loading = receivedData;
  }

  onClick(event: any) {
    const imgElem = event.target;
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.imgSrc = srcAttr.nodeValue;
  }

}
