import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JobPreferences, ContractType, JobRole } from '../models/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobPrefsRestService {


  constructor(
    private http: HttpClient
  ) { }

  getJobPreferences(): Observable<JobPreferences> {
    return this.http.get<JobPreferences>(`${environment.apiUrl}/job-preferences`);
  }

  patchJobPreferences(jobPreferences: JobPreferences): Observable<JobPreferences> {
    return this.http.patch<JobPreferences>(`${environment.apiUrl}/job-preferences`, jobPreferences);
  }

  getJobRoles(): Observable<JobRole[]>  {
    return this.http.get<JobRole[]>(`${environment.apiUrl}/job-preferences/job-roles`);
  }

  getContractTypes(): Observable<ContractType[]>  {
    return this.http.get<ContractType[]>(`${environment.apiUrl}/job-preferences/contract-types`);
  }

}

