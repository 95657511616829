<ng-container *ngIf="(completionData$ | async) as completionData">
  <ng-container *transloco="let t; read: 'shared.completion.' + completionData.type.toLowerCase()">
    <div class="content-wrapper">
      <h1 class="title">{{t('title')}}</h1>
      <p class="sub-text">{{t('sub-text', {name: completionData.name})}}</p>
      <ng-container *ngIf="completionData.image; else fallbackImage">
        <img src="{{completionData.image}}" class="completion-image">
      </ng-container>
      <ng-template #fallbackImage>
        <img src="assets/images/{{typeToImageFile[completionData?.type.toLowerCase()]}}" class="completion-image">
      </ng-template>
      <gkt-input-wrapper class="button-wrapper">
        <button (click)="continue()">{{t('button')}}</button>
      </gkt-input-wrapper>
    </div>
  </ng-container>
</ng-container>
