import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import { GameEvent } from "../game-player/game-player.component";
import {GalleryContent, PdfSummary, SaveGameData, VideoSummary} from "../models/models";

@Injectable({
  providedIn: 'root'
})
export class MediaPlayerRestService {

  constructor(
    private http: HttpClient
  ) { }


  getPdf(pdfId): Observable<PdfSummary> {
    return this.http.get<PdfSummary>('assets/fakeApi/media/getPdf.json')
      .pipe(map(pdfs => pdfs[pdfId-1]));
  }

  getVideo(videoId): Observable<VideoSummary> {
    return this.http.get<VideoSummary>('assets/fakeApi/media/getVideo.json')
      .pipe(map(videos => {
        return videos[videoId-1]
      }));
  }

  getGameContent(id: number): Observable<GalleryContent> {
    return this.http.get<GalleryContent>(`${environment.apiUrl}/user/game/${id}`);
  }

  setGameResult(id: number, data: SaveGameData): Observable<GalleryContent> {
    return this.http.post<GalleryContent>(`${environment.apiUrl}/user/game/${id}`, data);
  }

  postGameEvent(id: number, data: GameEvent): Observable<null> {
    return this.http.post<null>(`${environment.apiUrl}/user/game-event/${id}`, data);
  }

  notifyGameClosed(id: number): Observable<null> {
    return this.http.post<null>(`${environment.apiUrl}/user/game/${id}/stop`, null);
  }

  getModuleContent(moduleId: number): Observable<GalleryContent> {
    return this.http.get<GalleryContent>(`${environment.apiUrl}/user/module/${moduleId}`);
  }

  getModuleCompletionStatus(id: any): Observable<null> {
    return this.http.get<null>(`${environment.apiUrl}/user/web-shop/sync-module-status/${id}`);
  }

  notifyMediaOpened(mediaId: number): Observable<null>{
    return this.http.post<null>(`${environment.apiUrl}/media/getOpenedStatus/${mediaId}`, null);
  }

  notifyMediaClosed(mediaId: number): Observable<null>{
    return this.http.post<null>(`${environment.apiUrl}/media/getClosedStatus/${mediaId}`,null);
  }
}
