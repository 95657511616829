import { Injectable } from '@angular/core';
import { NetworkStatusService } from 'src/app/core/network-status.service';
import { TranslocoService } from '@ngneat/transloco';
// import { AuthDetails, GktAuthService } from 'gkt-auth-library';
import { AuthDetails, AngularAuthService } from 'angular-auth-library';
import { distinctUntilChanged, filter } from "rxjs/operators";
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { AdminRegistrationRestService } from './admin-registration.rest.service';
import { HttpClient } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class AdminRegistrationService {

  constructor( private networkStatus: NetworkStatusService,
    private gktAuthService: AngularAuthService,
    private transloco: TranslocoService,
    private adminRegistrationRestService: AdminRegistrationRestService,
    private http:HttpClient) { }

    checkSimilarCompanyExists(companyName: any) {
      return this.adminRegistrationRestService.checkSimilarCompanyExists(companyName);
    }

    createLearners(dynamicArray: any, type: any): any {
      return this.adminRegistrationRestService.createLearners(dynamicArray, type);
    }

    enrolCompanyUsers(dynamicArray: any, type: any): any {
      return this.adminRegistrationRestService.enrolCompanyUsers(dynamicArray, type);
    }

    bulkAssignPackage(transformedData:any){
      return this.adminRegistrationRestService.bulkAssignPackage(transformedData);
    }

    getPlanDetails() {
      return this.adminRegistrationRestService.getPlanDetails();
    }

    getCompanyDetails() {
      return this.adminRegistrationRestService.getCompanyDetails();
    }

    getPurchaseHistory() {
      return this.adminRegistrationRestService.getPurchaseHistory();
    }

  

    getFilterList() {
      return this.adminRegistrationRestService.getFilterList();
    }

    editUserDetails(firstName, lastName, email, phoneNumber,userId, jobroleFromDD,departmentFromDD,locationdd,threeplFromDD) {
      return this.adminRegistrationRestService.editUserDetails(firstName, lastName, email, phoneNumber, userId, jobroleFromDD,departmentFromDD,locationdd,threeplFromDD);
    }

    checkEmailAndPhoneExists(phoneAndEmail:any){
      return this.adminRegistrationRestService.checkEmailAndPhoneExists(phoneAndEmail);
    }

    checkExactCompanyExists(memberId: any,companyName:any): any {
      return this.adminRegistrationRestService.checkExactCompanyExists(memberId,companyName);
    }

    checkCompanyWebsiteIstaken(websiteUrl:any): any {
      return this.adminRegistrationRestService.checkCompanyWebsiteIstaken(websiteUrl);
    }

    deleteUser(profileId):any{
      return this.adminRegistrationRestService.deleteUser(profileId);
    }
    createCompany(memberId: string, companyName: string, websiteUrl:any, companyDescription:any,country:any, domainowner): any {
      return this.adminRegistrationRestService.createCompany(memberId, companyName, websiteUrl,companyDescription,country, domainowner);
    }

    createNewAdminUserDetails(firstName: string, lastName: string, email: string, password: any, companyId: any, phone: any) {
      return this.adminRegistrationRestService.createCompanyAdmin(firstName, lastName, email, password, companyId, phone);
    }

    createSubscription(customerEmail, planId, razorpayPlanId, planTitle, planPrice, planLicenses, planPeriod, planCurrency){
      return this.adminRegistrationRestService.createSubscription(customerEmail, planId, razorpayPlanId, planTitle, planPrice, planLicenses, planPeriod, planCurrency);
    }

    savePayment(customerEmail, razorpayPaymentId,subscriptionId,planId){
      return this.adminRegistrationRestService.savePayment(customerEmail, razorpayPaymentId,subscriptionId,planId);
    }

    registerAdminCompanyName(emailId, companyName): Observable<any> {
      const body = {
      "005dfcb4-e0f2-425c-a412-ad0b458fb39d": companyName,
      "cebf3e63-6b37-4426-b25c-b22700c31cd2": emailId
    };
      return this.http.post<any>(`https://noteforms.com/api/forms/create-an-account-to-complete-your-purchase-df0rpv/answer`, body)
    }



}
