<h1><b>Get in touch</b></h1>
<form [formGroup]="popUpForm" #formElement (ngSubmit)="submitForm()">
    <p *ngIf="!popUpFormEvent" style="text-align: center;">We'd love to hear from you!<br/> Fill in your details to connect with us or to request a free consultation.</p>
    <p *ngIf="popUpFormEvent" style="text-align: center;">Please fill this form to download our course catalogue.</p>
    <div class="form-row justify-content-center">
        <!-- <p style="text-align: center;">This form is one of the easiest ways to contact us for any query that you may
            have or to request a free consultation. We'd love to hear from you!</p> -->
        <div class="form-group col-md-4 pr-md-5 mb-3">
            <label for="firstName">First name</label>
            <input type="text" class="form-control" formControlName="firstName" id="firstName"
                aria-describedby="firstNameHelp" (keypress)="onlyAlphabets($event)"
                (keydown)="startWithAlphabetOnly($event)">
        </div>
        <div class="form-group col-md-4 mb-3">
            <label for="lastName">Last name</label>
            <input type="text" class="form-control" formControlName="lastName" id="lastName"
                aria-describedby="lastNameHelp" (keypress)="onlyAlphabets($event)"
                (keydown)="startWithAlphabetOnly($event)">
        </div>
    </div>
    <div class="form-row justify-content-center">
        <div class="form-group col-md-4 pr-md-5 mb-3">
            <label for="country">Country</label>
            <ng-select formControlName="country" [items]="countriesData" bindLabel="name" bindValue="alpha3Code"
                placeholder="Select a country" [searchable]="true">
                <ng-template ng-option-tmp let-item="item">
                    <div class="option-container">
                        <img [src]="item.flags.png" alt="Flag of {{ item.name }}" width="50px" />
                        <span class="option-name">{{ item.name }}</span>
                    </div>
                </ng-template>
            </ng-select>
        </div>
        <div class="form-group col-md-4 mb-3">
            <label for="company">Company</label>
            <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': popUpForm.get('company')?.invalid && (popUpForm.get('company')?.dirty || popUpForm.get('company')?.touched) }"
                formControlName="company" id="company" aria-describedby="companyHelp"
                (keydown)="startWithAlphabetOnly($event)">
        </div>
    </div>
    <div class="form-row justify-content-center">
        <div class="form-group col-md-4 pr-md-5 mb-3">
            <label for="phone">Phone number</label>
            <div class="row">
                <div *ngIf="!isMobileResolution" class="col-md-4">
                    <input type="tel" class="form-control"
                        [ngClass]="{ 'is-invalid': popUpForm.get('countryCode')?.invalid && (popUpForm.get('countryCode')?.dirty || popUpForm.get('countryCode')?.touched) }"
                        formControlName="countryCode" id="countryCode" aria-describedby="countryCodeHelp"
                        (keypress)="numberOnly($event)" (keydown)="keepPlusSign($event)">
                </div>
                <div *ngIf="isMobileResolution" class="col-md-4 mb-3">
                    <input type="tel" class="form-control"
                        [ngClass]="{ 'is-invalid': popUpForm.get('countryCode')?.invalid && (popUpForm.get('countryCode')?.dirty || popUpForm.get('countryCode')?.touched) }"
                        formControlName="countryCode" id="countryCode" aria-describedby="countryCodeHelp"
                        (keypress)="numberOnly($event)" (keydown)="keepPlusSign($event)">
                </div>
                <div class="col">
                    <input type="tel" class="form-control"
                        [ngClass]="{ 'is-invalid': popUpForm.get('phone')?.invalid && (popUpForm.get('phone')?.dirty || popUpForm.get('phone')?.touched) }"
                        formControlName="phone" id="phone" aria-describedby="phoneHelp" (keypress)="numberOnly($event)">
                </div>
            </div>
            <div class="form-text text-danger"
                *ngIf="popUpForm.get('phone')?.errors && popUpForm.get('phone')?.touched">
                <div class="form-text text-danger" *ngIf="popUpForm.get('phone')?.errors.whitespace">Please
                    enter
                    valid data.</div>
                <div class="form-text text-danger" *ngIf="popUpForm.get('phone')?.errors.minlength">Your phone
                    number
                    must contain at least 9 characters.</div>
                <div class="form-text text-danger" *ngIf="popUpForm.get('phone')?.errors.maxlength">Your phone
                    number
                    cannot exceed 14 characters.</div>
            </div>
        </div>
        <div class="form-group col-md-4 mb-3">
            <label for="email">Email address<span class="text-danger">*</span></label>
            <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': popUpForm.get('email')?.invalid && (popUpForm.get('email')?.dirty || popUpForm.get('email')?.touched) }"
                formControlName="email" id="email" aria-describedby="emailHelp" (keypress)="avoidSpaces($event)">
            <div class="form-text text-danger"
                *ngIf="popUpForm.get('email')?.errors && popUpForm.get('email')?.touched">
                <div class="form-text text-danger" *ngIf="popUpForm.get('email')?.errors.required">Please
                    complete
                    this
                    required field.</div>
                <div class="form-text text-danger" *ngIf="popUpForm.get('email')?.errors?.invalidEmail">Please
                    enter a
                    valid email address format.</div>
            </div>
        </div>
    </div>
    <div class="form-row justify-content-center">
        <div class="form-group col-md-4 pr-md-5 mb-3">
            <label for="industry">Industry</label>
            <input type="text" class="form-control" formControlName="industry" id="industry"
                aria-describedby="industryHelp" (keydown)="startWithAlphabetOnly($event)">
        </div>
        <div class="form-group col-md-4 mb-3">
            <label for="jobFunction">Job function</label>
            <input type="text" class="form-control" formControlName="jobFunction" id="jobFunction"
                aria-describedby="jobFunctionHelp" (keydown)="startWithAlphabetOnly($event)">
        </div>
    </div>
    <div class="form-row justify-content-center">
        <div class="form-group col-md-4 pr-md-5 mb-3">
            <label for="noOfEmployees">Indicate number of employees</label>
            <input type="text" class="form-control" formControlName="noOfEmployees" id="noOfEmployees"
                aria-describedby="noOfEmployeesHelp" (keypress)="numberOnly($event)">
        </div>
        <div class="form-group col-md-4 mb-3">
            <label for="typeOfEnquiry">Type of enquiry</label>
            <select type="text" class="form-control" formControlName="typeOfEnquiry" id="typeOfEnquiry"
            aria-describedby="typeOfEnquiry">
                <option value="csr" selected="selected">General</option>
                <option value="csr">CSR</option>
            </select>
        </div>
    </div>
    <div class="form-row justify-content-center">
        <div class="form-group col-md-2"></div>
        <div class="form-group col-md-8">
            <label for="additionalInfo">Tell us about your skilling needs</label>
            <textarea type="text" class="form-control resize-none" formControlName="additionalInfo" id="additionalInfo"
                aria-describedby="additionalInfoHelp" (keydown)="startWithAlphabetOnly($event)" rows="4"></textarea>
        </div>
        <div class="form-group col-md-2"></div>
    </div>
    <div class="btn-submit-container form-row">
        <div class="vertical-align-container">
            <img *ngIf="showThankYouMessage" class="bottom-image" src="assets/images/fffai/confirmation-checkmark.svg"
                width="30" height="30" alt="Confirmation Checkmark">
            <!-- <p *ngIf="showThankYouMessage">We'll be in touch soon!</p> -->
        </div>
        <button type="submit" class="btn-submit" *ngIf="!disableSubmit">Submit</button>
        <button type="submit" class="btn-submit-disabled" *ngIf="disableSubmit">Submit</button>
    </div>
</form>