import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {LanguageSwitcherComponent} from "./language-switcher/language-switcher.component";



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule
  ],
  exports: [
    LanguageSwitcherComponent
  ],
  declarations: [
    LanguageSwitcherComponent
  ]
})
export class LanguageModule { }
