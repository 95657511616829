<div class="card contact-card">
    <div class="card-body">
        <!-- <h3 class="card-title text-left mt-3 mx-3 mb-4" [style.background-image]="contacts.title == 'India' ? 'url(' + 'assets/images/contact-us/flags/in.svg' + ')' : 'url(' + 'assets/images/contact-us/flags/nl.svg' + ')'">
            {{ contacts.title }}
        </h3> -->
        <h3 class="card-title text-left mt-3 mx-3 mb-4" 
                [style.background-image]="
                    contacts.title === 'India' ? 'url(' + 'assets/images/contact-us/flags/in.svg' + ')' : 
                    contacts.title === 'Netherlands' ? 'url(' + 'assets/images/contact-us/flags/nl.svg' + ')' : 
                    contacts.title === 'USA' ? 'url(' + 'assets/images/contact-us/flags/us.svg' + ')' : 
                    ''">
                {{ contacts.title }}
            </h3>
        <section class="text-muted pb-2 mt-2 mx-3 address">
            <span class="icon" [inlineSVG]="'assets/images/icons/location-marker.svg'"></span>
            <ul class="list-unstyled" *ngIf="contacts.title == 'India'">
                <li>315, Maker Chambers V</li>
                <li>Nariman Point</li>
                <li>Mumbai, Maharashtra 400021</li>
                <li>India</li>
            </ul>
            <ul class="list-unstyled" *ngIf="contacts.title == 'Netherlands'">
                <li>The Hague Tech</li>
                <li>Wilhelmina van Pruisenweg 35</li>
                <li>2595 AN The Hague</li>
                <li>The Netherlands</li>
            </ul>
            <ul class="list-unstyled" *ngIf="contacts.title == 'USA'">
                <li>
                    231 Greenforest Drive,
                </li>
                <li>
                    Spring, TX 77388
                </li>
                <li>
                    <br/>
                </li>
                <li>
                    <br/>
                </li>
                <br/>
            </ul>
        </section>
        <section class="text-muted pb-2 mt-2 mx-3 email">
            <span class="icon" [inlineSVG]="'assets/images/icons/icon-mail2.svg'"></span>
            <a style="  word-break: break-all;" [href]="'mailto:' + contacts.email" target="_blank">
                {{ contacts.email }}
            </a>
        </section>
        <br/>
        <section class="text-muted pb-2 mt-2 mx-3 phone">
            <span class="icon" [inlineSVG]="'assets/images/icons/icon-phone.svg'"></span>
            {{ contacts.phone }}
        </section>
        <br/>

            <section  class="text-muted pb-2 mx-3 whatsapp">
                <br *ngIf="!contacts.countryCode">
                <br *ngIf="!contacts.countryCode">
                <span *ngIf="contacts.countryCode" class="icon" [inlineSVG]="'assets/images/icons/icon-whatsapp.svg'"></span>
               
                <a *ngIf="contacts.countryCode" [href]="'https://wa.me/' + contacts.countryCode + contacts.whatsapp + '?text=Hi'" target="_blank" class="text-align-center mt-2 pt-2">{{contacts.countryCode}} {{contacts.whatsapp}}</a>
            </section>


        <!-- <ng-template #spaceOut>
            <br/><br/>
        </ng-template> -->
        <br/>
        <div id="mapIndia" #mapIndia class="map w-100 mt-3" [hidden]="contacts.title != 'India'"></div>
        <div id="mapNetherlands" #mapNetherlands class="map w-100 mt-3" [hidden]="contacts.title != 'Netherlands'"></div>
        <div id="usa" #mapUSA class="map w-100 mt-3" [hidden]="contacts.title != 'USA'"></div>
    </div>
</div>