<label>{{label}}
    <span *ngIf="span" class="mandatoryStar">&nbsp;{{span}}</span>
</label>
<ng-content select="(select)" ></ng-content>
<ng-content select="(button)"></ng-content>
<ng-content select="(input)" ></ng-content>
<ng-content select="(gkt-input-wrapper)" ></ng-content>
<ng-content select="(gkt-date-select)" ></ng-content>
<ng-content select="(gkt-forms-date-text)" ></ng-content>
<ng-content select="(textarea)" ></ng-content>
<ng-content select="(ng-select)"></ng-content>
<label class="fake-input"></label>
<!--maybe not sensible to rely on the erorMessage in case the validation eror service fials-->
 <!--consider using the formControl from the select to get the validity state-->
<p *ngIf="errorMessage" class="error">{{errorMessage}}</p>
<p *ngIf="hint" class="hint">{{hint}}</p>
