import {BreakpointObserver} from '@angular/cdk/layout';
import {Injectable} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {BehaviorSubject, from, Observable, Subject, of} from 'rxjs';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';

export type ScreenOrientation = ('landscape-primary' |'landscape' | 'portrait' | 'any');

@Injectable({
  providedIn: 'root'
})
export class ScreenViewService {

  platform: any;
  url: any;

  private _screenOrientation = new BehaviorSubject<ScreenOrientation>(null);
 screenOrientation$: Observable<ScreenOrientation> = this._screenOrientation.asObservable().pipe(filter(x => !!x), distinctUntilChanged());

 private destroy$ = new Subject();
  constructor(
    private metaService: Meta,
    private breakpointObserver: BreakpointObserver
  ) { }

  init() {
   this.checkOrientation();
   this.setCanScale(false);
   this.getOS();
   this.inAppReviewLinkAssign();
  }

  public setCanScale(canScale: boolean): void {
    // this.metaService.removeTag('name=viewport');
    if (canScale) {
      this.metaService.updateTag({
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, user-scalable=yes'
      }, 'name=viewport');
    } else {
      this.metaService.updateTag({
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
      }, 'name=viewport');
    }
  }

  setOrientation(orientation: ScreenOrientation): Observable<any> {
    if (window.hasOwnProperty('cordova')) {
      return from(window.screen.orientation.lock(orientation));
    }
    return of(false);
  }

  unlockOrientation() {
    if (window.hasOwnProperty('cordova')) {
      this.setOrientation(this._screenOrientation.getValue());
      window.screen.orientation.unlock();
    }
  }

  checkOrientation(): Observable<ScreenOrientation>{
    this.breakpointObserver.observe([
      '(orientation: portrait)',
      '(orientation: landscape)',
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
      if (result.breakpoints['(orientation: landscape)']){
        this._screenOrientation.next('landscape');
      } else {
        this._screenOrientation.next('portrait');
      }
    });
    return this.screenOrientation$;
  }

  getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
    this.platform = os;
    return os;
  }

  inAppReviewLinkAssign(){
    let link;
    if(this.platform == 'Android' || this.platform == 'Windows' || this.platform == 'Linux'){
      link = "https://play.google.com/store/apps/details?id=com.education21cc.logistics&hl=en";
    }else if(this.platform == 'iOS' || this.platform == 'Mac OS'){
      link = "https://apps.apple.com/nl/app/the-21cc-app/id1512910768";
    }
    this.url = link;
    return link;
  }

  isRateUsClickedInSideBar(parameter: any){
    return parameter;
  }

}
