<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.1.3/dist/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css">
<edu-default-layout>
<header class="news-article flex-column align-items-start">
    <div class="container">
        <h2 class="text-left mt-2">
            {{ career.title }}
        </h2>
        <div class="text-white">
            {{ career.author }}
        </div>
        <br/>
        <div class="text-white">
            <i class="fa fa-clock"></i>
            {{ career.date }}
        </div>
    </div>
</header>

<div class="container mt-4">
    <div class="row">
        <div class="col-lg-8">
            <img [src]="career.image" alt="image" class="w-100">
            <div *ngFor="let detail of career.details">
              <p class="mt-4" [innerHTML]="detail | safeHtml"></p>
            </div>
            <a [href]="career.forms" target="_blank">Apply Now</a>
        </div>
        <div class="col-lg-4">
            <h5>Other Careers</h5>
            <div class="row">
                <div class="new-article col-12 col-md-6 col-lg-12" *ngFor="let recentCareer of recentCareersData">
                    <div class="card flex-shrink-0 mt-3">
                        <a href="/career-details/{{ recentCareer.title }}">
                            <img class="card-img-top" [src]="recentCareer.image" [alt]="recentCareer.title">
                        </a>
                        <section class="author-and-category d-flex justify-content-between px-3 mt-3">
                          <div class="author">
                            {{ recentCareer.author }} 
                          </div>
                          <div class="category text-muted">
                            {{ recentCareer.category }}
                          </div>
                        </section>
                        <section class="title px-3 py-2">
                          <a href="/career-details/{{ recentCareer.title }}">
                            <h6>{{ recentCareer.title }}</h6>
                          </a>
                        </section>
                        <section class="highlight px-3 py-2 text-muted mt-3 overflow-hidden" style="overflow: hidden; line-height: 1.2">{{ recentCareer.highlight }}</section>       
                        <section class="p-3 text-muted">
                          <i class="fa fa-clock "></i>
                            {{ recentCareer.date }}
                        </section>
                      </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br/><br/><br/><br/><br/><br/>
</edu-default-layout>