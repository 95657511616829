import {NgModule} from '@angular/core';
import {TextTransformDirective} from "./directives/text-transform.directive";
import {InputWrapperComponent} from './input-wrapper/input-wrapper.component';
import {CommonModule} from '@angular/common';
import {DateSelectComponent} from './date-select/date-select.component';
import { DateTextComponent } from './date-text/date-text.component';
import { FormsModule } from '@angular/forms';
import { AllowedCharsDirective } from './directives/allowed-chars.directive';

/*
  Add components, directives to the named arrays below, not to the NgModule() decorator directly.
 */
const components: any = [
  InputWrapperComponent,
  DateSelectComponent,
  DateTextComponent,
];

const directives:any = [
  AllowedCharsDirective,
  TextTransformDirective,
];

const pipes: any = [];

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [...components, ...directives, ...pipes],
  exports: [...components, ...directives, ...pipes]
})
export class SharedFormModule { }
