import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'edu-popup-form',
  templateUrl: './popup-form.component.html',
  styleUrls: ['./popup-form.component.scss']
})
export class PopupFormComponent implements OnInit {

  @Output() showCourseCataloguePopUpEvent = new EventEmitter<boolean>();

  isFormSubmitted: boolean = false;
  popUpFormEvent: boolean = true;

  constructor() { }

  ngOnInit(): void { }

  downloadCourseCatalogue() {
    const boolValue = false;
    window.open('../../../../assets/documents/catalogue-21cc-february-2023.pdf', '_blank');
    this.showCourseCataloguePopUpEvent.emit(boolValue);
  }

  closePopUp() {
    const boolValue = false;
    this.showCourseCataloguePopUpEvent.emit(boolValue);
  }

  isFormSubmittedEventReceived(boolValue: boolean) {
    this.isFormSubmitted = boolValue;
  }

}
