<edu-default-layout>
<div class="gradient-color">
    <br/><br/><br/><br/><br/><br/>
    <div class="container-fluid d-flex justify-content-center align-items-center vh-150 margin-top-7-percent">
        <div class="fill-height pt-md-5 d-flex align-items-center">
            <div class="container">
                <!-- <h1 class="row col text-white pt-5 mt-2">Our locations</h1> -->
                <div class="row">
                    <div class="col-md-4">
                        <app-location-card [contacts]="contacts[0]"></app-location-card>
                    </div>
                    <div class="col-md-4">
                        <app-location-card [contacts]="contacts[1]"></app-location-card>
                    </div>
                    <div class="col-md-4 ">
                        <app-location-card [contacts]="contacts[2]"></app-location-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br/><br/><br/><br/><br/><br/>
</div>
</edu-default-layout>
