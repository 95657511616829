import {AfterViewInit, ChangeDetectorRef, Component, forwardRef, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {formatDate} from '@angular/common';

/*
This is a date-picker component based on select boxes.
This is designed to be used as a fallback for when a browser does not have
an implementation of <input type=date> (looking at you Safari)
 */

@Component({
  selector: 'gkt-date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateSelectComponent),
      multi: true
    }
  ]
})
export class DateSelectComponent implements ControlValueAccessor, AfterViewInit {

  days = Array.from(Array(31), (e, i) => i + 1);
  months = Array.from(Array(12), (e, i) => i + 1);
  years = Array.from(Array(60), (e, i) => i + 1950);

  @ViewChild('daySelect', {static: false}) daySelect;
  @ViewChild('monthSelect', {static: false}) monthSelect;
  @ViewChild('yearSelect', {static: false}) yearSelect;
  @ViewChild('datePicker', {static: false}) nativeDate;

  initValues;

  fallbackMode = false;

  propagateChange = (_: any) => {};

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit(): void {
    this.fallbackMode = !this.browserSupportsDateInputEl();
    this.cdr.detectChanges();
    if (this.initValues) {
      this.setCorretElement(this.initValues);
    }
  }

  // this value must always be a isoDateString (yyyy-mm-dd)
  writeValue(value: string) {
    if (value !== null && value !== undefined) {
      this.validateIncomingValue(value);
      this.setCorretElement(value);
    }
  }

  setCorretElement(value) {
    this.fallbackMode ? this.setSelectElements(value) : this.setNativeDateElement(value);
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  setSelectElements(value: string) {
    const ymdValues = value.split('-');
    if (this.daySelect) {
      this.daySelect.nativeElement.value = +ymdValues[2];
      this.monthSelect.nativeElement.value = +ymdValues[1];
      this.yearSelect.nativeElement.value = +ymdValues[0];
    } else {
      this.initValues = value;
    }
  }

  setNativeDateElement(value: string) {
    if (this.nativeDate) {
      this.nativeDate.nativeElement.value = value;
    } else {
      this.initValues = value;
    }
  }

  emitChange(dateString?: string) {
    let isoDate;
    if (dateString) {
      isoDate = dateString;
    } else {
      const day = this.daySelect.nativeElement.value;
      const month = this.monthSelect.nativeElement.value;
      const year = this.yearSelect.nativeElement.value;
      isoDate = `${year}-${month}-${day}`;
    }
    this.propagateChange(isoDate);
  }

  private browserSupportsDateInputEl() {
    const intendedType = this.nativeDate.nativeElement.attributes.type.value;
    const browserType = this.nativeDate.nativeElement.type;
    if (intendedType === 'date' && browserType === 'text') {
      console.error('Native dateicker not suported');
      return false;
    } else {
      return true;
    }
  }

  // Todo: this method is duplicated in the date-text component
  private validateIncomingValue(value: string) {
    try {
      formatDate(value, 'yyyy-MM-dd', 'nl');
      const regex = RegExp(/^[1,2]{1}[0-9]{3}-[0,1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}$/);
      if (!regex.test(value)) {
        throw new Error('Date string is not ISO date format. Failed regex match');
      }
    } catch (e) {
      console.error(`The value ${value} is not a valid ISO date string eg yyyy-MM-dd`);
      throw e;
    }
  }
}
