import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {
  TranslocoMissingHandler,
  TranslocoMissingHandlerData,
} from "@ngneat/transloco";
import {LocalLangPreloader} from "./local-lang-preloader.service";

/**
 *  This class is used when a single translation is not available in the current translations available to Transloco.
 *  the order of fallback is:
 *  0. The correct translation from the server (this has failed and that is why the handle method was called
 *  1. Check the locally available language (rom assets/i18n) and see if the translation is there
 *  2. Check the locally available fallback language (probably English)
 *  3. No translation found - display the key that is missing.
 */

@Injectable({providedIn: 'root'})
export class EduMissingKeyHandler implements TranslocoMissingHandler {

  constructor(
    private http: HttpClient,
    private localLangPreloader: LocalLangPreloader
  ) {}

  handle(key: string, config: TranslocoMissingHandlerData, params): string {
    const localTranslations = this.localLangPreloader.localTranslations
    const activeLang: string = config.activeLang;
    const fallbackLang = Array.isArray(config.fallbackLang) ? config.fallbackLang[0] : config.fallbackLang;

    let stringToReturn;

    if(this.isLocallyAvailable(activeLang, key)){
      const activeLangFallback = localTranslations.get(activeLang);
      stringToReturn = activeLangFallback.get(key);
    } else if(this.isLocallyAvailable(fallbackLang, key)) {
      const activeLangFallback = localTranslations.get(fallbackLang);
      stringToReturn = activeLangFallback.get(key);
    } else {
      // We must display the key as the translation is not available from any source
      stringToReturn = key;
    }
    if (Object.entries(params).length) {
      stringToReturn = this.replaceTranslationParams(stringToReturn, params);
    }
    return stringToReturn;
  }

  replaceTranslationParams(str, paramsObj){
    if (str.includes('{{') && str.includes('}}')){
      const stringWithoutCurlyBraces = str.replaceAll('{{', '').replaceAll('}}', '');
      const re = new RegExp(Object.keys(paramsObj).join('|'), 'gi');
      return stringWithoutCurlyBraces.replace(re, (matched) => paramsObj[matched.toLowerCase()]);
    }
  }

  isLocallyAvailable(langCode: string, key: string): boolean {
    const localTranslations = this.localLangPreloader.localTranslations;
    return localTranslations.has(langCode) && localTranslations.get(langCode).has(key);
  }

}
