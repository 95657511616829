import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'edu-ports',
  templateUrl: './ports.component.html',
  styleUrls: ['./ports.component.scss']
})
export class PortsComponent implements OnInit{
  @ViewChild('smallScreenCarousel', { static: false }) carousel: ElementRef;
  loading: boolean = true;
  @Output() navigateToSection = new EventEmitter<void>();
  isFormSubmitted: boolean = false;
  showThankYouMessage: boolean = false;
  isFormSubmittedDiabled: boolean = false;
  popUpFormEvent: boolean = false;
  @ViewChild('getInTouchContainer') getInTouchContainer: ElementRef;

  constructor(
    private router: Router,
    // private home: HomeComponent,
    private viewportScroller: ViewportScroller,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.loading= false;
  }

  takeUserToPopUpForm(){

    this.router.navigate(['/home'], { fragment: 'get-in-touch-container' }).then(() => {
      setTimeout(() => {
        // this.elementRef.nativeElement.querySelector('#get-in-touch-container');
        const element = document.getElementById('get-in-touch-container');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100); // Adjust the delay if necessary
    });
  }

  isFormSubmittedEventReceived(boolValue: boolean) {
    this.isFormSubmitted = boolValue;
  }

  scrollToSection() {
    console.log('Scrolling to section');
    const section = this.elementRef.nativeElement.querySelector('#get-in-touch-container');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    section.style.marginTop = "100px";
  }

}
