import { Component,Input,OnInit } from '@angular/core';
import { Observable } from "rxjs";
import { NavigationService } from "../../core/navigation.service";
import { ScreenOrientation, ScreenViewService } from "../../core/screen-view.service";
import { SharedLayoutService } from "../shared-layout.service";
import { ContentCompletionService } from "../../core/content-completion.service";
import { CompletionData } from "../../shared/completion-overlay/completion-overlay.component";
import { Router } from '@angular/router';


@Component({
  selector: 'edu-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  fffaiRole: any;
  screenOrientation$: Observable<ScreenOrientation>;
  completionOverlay$: Observable<CompletionData>;
  showIt: string = "website";
  showBottom: boolean = true;
  fffai:any;
  @Input() hideBackButton: boolean = false;

  constructor(
    public navigationService: NavigationService,
    private screenViewService: ScreenViewService,
    private layoutService: SharedLayoutService,
    private contentCompletionService: ContentCompletionService,
    private router: Router
  ) {
    this.screenOrientation$ = this.screenViewService.screenOrientation$;
    this.completionOverlay$ = this.contentCompletionService.completionOverlay$;
    this.fffaiRole = localStorage.getItem('fffaiRole');
    if (this.router.url.includes('fffai') || this.router.url.includes('wai')) {
      this.fffai = true;
      if(this.router.url.includes('fffai')){
        this.showIt = "fffai";
      }else{
        this.showIt = "wai";
      }
    }else{
      this.fffai = false;
      this.showIt = "website";

    }
    if (this.router.url.includes('scores/highscores')) {
      // console.log("yes hide it")
      this.showBottom = false;
    }
  }

  ngOnInit() {
    
  }

  hideSideMenu() {
    this.layoutService.hideSideMenu();
  }

  toggleSideMenu() {
    console.log('clicking sidebar')
    this.layoutService.toggleSideMenu();
  }
}