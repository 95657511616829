import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-location-card',
  templateUrl: './location-card.component.html',
  styleUrls: ['./location-card.component.scss']
})
export class LocationCardComponent implements OnInit, AfterViewInit {

  @Input() contacts: any;
  @ViewChild('mapIndia', { static: false }) gmapIndia: ElementRef;
  @ViewChild('mapNetherlands', { static: false }) gmapNetherlands: ElementRef;
  @ViewChild('mapUSA', { static: false }) gmapUSA: ElementRef;
  map: google.maps.Map;
  
  latIndia = 18.923927;
  lngIndia = 72.822125;

  latNetherlands = 52.079288958781724;
  lngNetherlands = 4.341896956023583;

  latUSA = 30.042852523691703
  lngUSA = -95.4347897618945

  coordinatesIndia = new google.maps.LatLng(this.latIndia, this.lngIndia);
  coordinatesNetherlands = new google.maps.LatLng(this.latNetherlands, this.lngNetherlands);
  coordinatesUSA = new google.maps.LatLng(this.latUSA, this.lngUSA);

  mapOptionsIndia: google.maps.MapOptions = {
    center: this.coordinatesIndia,
    zoom: 18,
    mapTypeControl: false
  };

  mapOptionsNetherlands: google.maps.MapOptions = {
    center: this.coordinatesNetherlands,
    zoom: 18,
    mapTypeControl: false
  };

  mapOptionsUSA: google.maps.MapOptions = {
    center: this.coordinatesUSA,
    zoom: 18,
    mapTypeControl: false
  };

  constructor() { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.mapInitializerIndia();
    this.mapInitializerNetherlands();
    this.mapInitializerUSA();
  }

  mapInitializerIndia() {
    this.map = new google.maps.Map(this.gmapIndia.nativeElement,
      this.mapOptionsIndia);

    let marker = new google.maps.Marker({
      position: this.coordinatesIndia,
      map: this.map,
    });

    marker.setMap(this.map);
  }

  mapInitializerNetherlands() {
    this.map = new google.maps.Map(this.gmapNetherlands.nativeElement,
      this.mapOptionsNetherlands);

    let marker = new google.maps.Marker({
      position: this.coordinatesNetherlands,
      map: this.map,
    });

    marker.setMap(this.map);
  }

  mapInitializerUSA(){
    this.map = new google.maps.Map(this.gmapUSA.nativeElement,
      this.mapOptionsUSA);

    let marker = new google.maps.Marker({
      position: this.coordinatesUSA,
      map: this.map,
    });

    marker.setMap(this.map);
  }

}
