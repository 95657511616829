import { Injectable } from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
// import {AuthDetails, GktAuthService} from 'gkt-auth-library';
import {AuthDetails, AngularAuthService} from 'angular-auth-library';

import {BehaviorSubject, EMPTY, Observable, of} from 'rxjs';
import {NetworkStatusService} from '../../../core/network-status.service';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {distinctUntilChanged, filter,tap} from "rxjs/operators";
import {CountryPhoneCodeInfo, RegistrationExtraInfo} from "../registration.models";
import {LocalStorageService} from "../../../core/local-storage.service";
import {LanguageService} from "../../../core/language/language.service";
import {UserService} from "../../../core/user.service";
import {Router} from "@angular/router";
import {NavigationService} from "../../../core/navigation.service";


// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import {
//   Plugins,
//   PushNotification,
//   PushNotificationToken,
//   PushNotificationActionPerformed } from '@capacitor/core';

// const { PushNotifications } = Plugins;


interface RestrictedAuthDetails extends AuthDetails {
  message: string;
  access_token: string;
}

@Injectable({
  providedIn: 'root'
})

export class RegistrationService {

  /*
  This service is the only place that should, use the gkt-auth-library.
   */

  _USERNAME$ = new BehaviorSubject<string>(null);
  username$: Observable<string> = this._USERNAME$.asObservable();

  _registrationWithInvitationCode = new BehaviorSubject<boolean>(null);
  registrationWithInvitationCode$: Observable<boolean> = this._registrationWithInvitationCode.asObservable().pipe(filter(x => !!x), distinctUntilChanged());

  _selectedCountryCode = new BehaviorSubject<CountryPhoneCodeInfo>(null);
  selectedCountryCode$: Observable<CountryPhoneCodeInfo> = this._selectedCountryCode.asObservable();

  _selectedCountryCodeFOrEditProfile = new BehaviorSubject<CountryPhoneCodeInfo>(null);
  selectedCountryCodeFOrEditProfile$: Observable<CountryPhoneCodeInfo> = this._selectedCountryCodeFOrEditProfile.asObservable();

  private _registrationMode = new BehaviorSubject<('registration'|'login')>(null);
  registrationMode$: Observable<('registration'|'login')> = this._registrationMode.asObservable().pipe(distinctUntilChanged());

  private _waiRoleMode = new BehaviorSubject<('admin'|'learner')>(null);
  waiRoleMode$: Observable<('admin'|'learner')> = this._waiRoleMode.asObservable().pipe(distinctUntilChanged());


  private _loginOrRegisterWith = new BehaviorSubject<('email'|'phone')>(null);
  loginOrRegisterWith$: Observable<('email'|'phone')> = this._loginOrRegisterWith.asObservable().pipe(distinctUntilChanged());

  constructor(
    private gktAuthService: AngularAuthService,
    private networkStatus: NetworkStatusService,
    private transloco: TranslocoService,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private languageService: LanguageService,
    private userService: UserService,
    private navigationService: NavigationService,
    private router: Router,
    
  ) { }


  // createAccount(username: string, extraInfo: RegistrationExtraInfo): Observable<RestrictedAuthDetails> {
  //   return this.gktAuthService.createAccount(username, extraInfo)
  //     .pipe(
  //       this.checkConnection()
  //     ) as Observable<RestrictedAuthDetails>;
  // }

  createAccount(username: string, extraInfo: RegistrationExtraInfo): Observable<RestrictedAuthDetails> {
    return this.gktAuthService.createAccount(username, extraInfo)
      .pipe(
        tap(() => console.log('service ------------- ExtraInfo:::::::::::::::', extraInfo)),
        this.checkConnection()
      ) as Observable<RestrictedAuthDetails>;
  }

  resetPassword(username: string): Observable<any> {
    return this.gktAuthService.requestResetPassword(username);
  }

  sendOTP(username: string, token: string) {
    return this.http.post(`${environment.apiUrl}/sendOtp`, {username, gCaptchaToken: token});
  }

  setNewPassword(username: string, password: string, otp: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/password`, {username, password, otp});
  }

  login(username, password): Observable<AuthDetails> {
    return this.gktAuthService.login(username, password)
      .pipe(
        // this.checkConnection(),
        tap(() => {
          this.languageService.initWithAuth();
          this.userService.init();
        })
      );
  }

  verifyAccount(activationCode: string): Observable<boolean> {
    console.error('Method not used yet');
    return of(false);
  }

  setUsername(username: string) {
    this._USERNAME$.next(username);
  }

  checkNetworkStatus(): boolean {
    return this.networkStatus.currentState().connected;
  }

  private checkConnection() {
    const that = this;
    return function<T> (source: Observable<T>): Observable<T> {
      return new Observable(subscriber => {
        source.subscribe({
          next(value) {
            subscriber.next(value);
          },
          error(error) {
            if (!that.checkNetworkStatus()) {
              const message = that.transloco.translate('registration.check-connection');
              alert(message);
              subscriber.error(EMPTY);
            }
            subscriber.error(error);
          },
          complete() {
            subscriber.complete();
          }
        });
      });
    };
  }

  otpLogin(username: string, password: string){
    return this.http.post(`${environment.apiUrl}/otpLogin`, {username, password});
  }

  checkUsername(username: string){
    return this.http.post(`${environment.apiUrl}/registration/username`, {username});
  }

  checkInvitationCode(invitationCode: string){
    return this.http.post(`${environment.apiUrl}/registration/invitationCode`, {invitationCode});
  }

  checkOtp(username: string, otp: string){
    return this.http.post(`${environment.apiUrl}/registration/otp`, {username, otp});
  }

  getEducationLevels(selectedCountry?: string){
    return this.http.get(`${environment.apiUrl}/educations${selectedCountry ? '?country=' + selectedCountry : ''}`);
  }

  updateFcmToken(currentToken: string){
    const body = {currentToken: currentToken, url: `${environment.frontEndUrl}/messaging`};
    return this.http.patch<any>(`${environment.apiUrl}/token`, {body});
  }

  setRegistrationMode(mode: ('registration' | 'login')) {
    this._registrationMode.next(mode);
  }

  getRegistrationMode() {
    return this._registrationMode.getValue();
  }

  setWaiRoleMode(mode: ('admin' | 'learner')) {
    this._waiRoleMode.next(mode);
  }

  getWaiRoleMode() {
    return this._waiRoleMode.getValue();
  }

  setLoginOrRegisterWith(mode: ('email' | 'phone')) {
    this._loginOrRegisterWith.next(mode);
  }

  getLoginOrRegisterWith() {
    return this._loginOrRegisterWith.getValue();
  }

  registerPackage(courseId:any){
    const body = {
      galleryId:null, 
      moduleId:null, 
      gameId:null,
      webshopPackageId: courseId
      }
    return this.http.post(`${environment.apiUrl}/user/web-shop/enroll-course`, body);
  }

  // registerCourseForUser(courseId:any){
  //   const body = {
  //     galleryId:courseId, 
  //     moduleId:null, 
  //     gameId:null,
  //     webshopPackageId: null
  //     }
  //   return this.http.post(`${environment.apiUrl}/user/web-shop/enroll-course`, body);
  // }

  // Update registerCourseForUser to differentiate course and module
    registerCourseForUser(id: any, type: string) {
      const body = {
        galleryId: type === 'course' ? id : null, 
        moduleId: type === 'module' ? id : null, 
        gameId: null,
        webshopPackageId: null
      };
      return this.http.post(`${environment.apiUrl}/user/web-shop/enroll-course`, body);
    }

  registermoduleForUser(courseId:any){
    const body = {
      galleryId:null, 
      moduleId:courseId, 
      gameId:null,
      webshopPackageId: null
      }
    return this.http.post(`${environment.apiUrl}/user/web-shop/enroll-course`, body);
  }

  softLogin(credentials: AuthDetails){
    this.localStorageService.storeInLocalStorage('authDetails', JSON.stringify(credentials));
    this.languageService.initWithAuth();
    this.userService.init();
    this.navigationService.clearStackIfIndustry();
    this.router.navigate(['/industry']);
  }
}
