import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {UserSettings} from "../../modules/user-settings/models/models";

@Injectable({
  providedIn: 'root'
})
export class LanguageRestService {

  constructor(
    private http: HttpClient
  ) { }

  /*
  This currently uses the userSettings endpoint but should use its own specific endpoint in the future.
   */

  getUserLanguage(): Observable<string> {
    return this.http.get<UserSettings>(`${environment.apiUrl}/user/settings`)
      .pipe(
        map(userSettings => userSettings.language)
      );
  }

  setUserLanguage(langCode: string): Observable<string> {
    const userSettings = {language: langCode}
    return this.http.patch<UserSettings>(`${environment.apiUrl}/user/settings`, userSettings)
      .pipe(
        map(userSettings => userSettings.language)
      );
  }
}
