import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class CustomValidators {

  static passwordStrength(minLength: number = 8): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const password: string = control.value;

      // Check if the password meets the desired criteria
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasNumbers = /\d/.test(password);
      const hasSpecialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(password);

      const isValid =
        hasUpperCase &&
        hasLowerCase &&
        hasNumbers &&
        hasSpecialCharacters &&
        password.length >= minLength;

        console.log(isValid);
      if (isValid) {
        return null;
      } else {
        return { invalidPassword: true };
      }
    };
  }


    // Custom validator function to check for forbidden characters
  static forbiddenCharactersValidator(forbiddenChars: RegExp) {
    return (control: AbstractControl): ValidationErrors | null => {
      const forbidden = forbiddenChars.test(control.value);
      return forbidden ? {invalidCharacters : true} : null;
    };
  }

  static minMaxLengthNumber(minLength, maxLength): ValidatorFn {
    return (control: AbstractControl) => {
      const nameString = control.value;
      if (!nameString) {
        return null;
      }

      if (nameString.length<minLength || nameString>maxLength) {
        return {
          invalidNumberString: {
            min: minLength,
            max: maxLength
          }
        }
      } else {
        return null;
      }
    }
  }

  static onlyNumbersOrNullAllowed(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;

      if (value === null || value === '') {
        return null; // Null value is allowed
      } else{
        const regex: RegExp = /^[0-9]+$/;

        if (!regex.test(value)) {
          return { onlyNumbersOrNullAllowed: true };
        }else{
          return null;
        }
      }
      
    };
  }

  
  static nameValidator(control: AbstractControl): { [key: string]: boolean } {
    const nameRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (control.value && nameRegexp.test(control.value)) {
       return { invalidNameForSpecialCharacter: true };
    }
  }

  static email(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const email = control.value;
    const regex: RegExp = /[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-+]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})/;

    if (regex.test(email)) {
      return null;
    } else {
      return { invalidEmail: true };
    }
  }

  static notBlank(control: AbstractControl): ValidationErrors | null {
    const input = control.value;
    const inputNoWhiteSpace = input.trim('');
    if (!input) {
      return null;
    }
    if (inputNoWhiteSpace.length === 0) {
      return { notBlank: true };
    } else {
      return null;
    }
  }

  static selectOne(control: AbstractControl): ValidationErrors | null {
    const input = control.value;
    const values = (Object.values(input));
    if (values.includes(true)){
      return null;
    } else {
      return { selectOne: true };
    }
  }

  static postcode(country?): ValidatorFn | null {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      const postCode = control.value;
      if (country === 'IND'){
        const regex: RegExp = /^[0-9]{6}$/i;
        if (!regex.test(postCode)) {
          return { invalidPostcode: true };
        } else {
          return null;
        }
      } else if (country === 'NLD'){
        const regex: RegExp = /^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/i;
        if (!regex.test(postCode)) {
          return { invalidPostcode: true };
        } else {
          return null;
        }
      } else {
        const regex: RegExp = /^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/i;
        if (!regex.test(postCode)) {
          return { invalidPostcode: true };
        } else {
          return null;
        }
      }
    };
  }

  static phone(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const phoneNumber = control.value.replace(/ /g, '');
    const regex: RegExp = /^[+]?[0-9]{10,12}$/;
    if (regex.test(phoneNumber)) {
      return null;
    } else {
      return { invalidPhone: true };
    }
  }

  static phoneWithoutCountryCode(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const phoneNumber = control.value.trim();
    const regex: RegExp = /^[0-9]{8,10}$/;
    if (regex.test(phoneNumber)) {
      return null;
    } else {
      return { invalidPhoneWithoutCountryCode: true };
    }
  }

  static phoneCountryCode(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const countryCode = control.value.replace(/ /g, '');
    const regex: RegExp = /^[+]?[0-9]{1,4}$/;
    if (regex.test(countryCode)) {
      return null;
    } else {
      return { invalidCountryCode: true };
    }
  }

  static verificationCode(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const verificationCode = control.value;
    const regex: RegExp = /^^[0-9]{6}$$/;
    if (regex.test(verificationCode)) {
      return null;
    } else {
      return { invalidVerificationCode: true };
    }
  }


  static valueIsTrue(control: AbstractControl): ValidationErrors | null {
    return control.value === true ? null : {isNotTrue: true};
  }

  static MatchPasswords( firstControlName, secondControlName): ValidationErrors | null {
    return (AC: AbstractControl) => {
      const firstControlValue = AC.get(firstControlName).value;
      const secondControlValue = AC.get(secondControlName).value;

      if (firstControlValue !== secondControlValue) {
        return { matchPasswordsError: true };
      } else {
        return null;
      }
    };
  }

  static MatchPasswordsForAdmin(firstControlName, secondControlName): ValidationErrors | null {
    return (AC: AbstractControl) => {
      const firstControlValue = AC.get(firstControlName).value;
      const secondControlValue = AC.get(secondControlName).value;
  
      if (firstControlValue !== secondControlValue) {
        AC.get(secondControlName).setErrors({ matchPasswordsError: true }); // Set error for second control
        return { matchPasswordsError: true };
      } else {
        // Clear errors if passwords match
        AC.get(secondControlName).setErrors(null);
        return null;
      }
    };
  }

  static minMaxYears(minYears, maxYears): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return;
      }
      const ISOdateString = control.value;
      const year = parseInt(ISOdateString.split('-')[0], 10);
      if (!year) {
        return null;
      }

      if ( year > maxYears || year < minYears ) {
          return {
            yearRangeError: {
              given: year,
              max: maxYears,
              min: minYears
            }
          };
        } else {
          return null;
        }
    };
  }

  static minMaxLength(minLength, maxLength): ValidatorFn {
    return (control: AbstractControl) => {
      const nameString = control.value;
      if (!nameString) {
        return null;
      }

      if (nameString.length<minLength || nameString>maxLength) {
        return {
          invalidNameString: {
            min: minLength,
            max: maxLength
          }
        }
      } else {
        return null;
      }
    }
  }

  static minlength(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const phoneNumber = control.value;
    const regex: RegExp = /^[0-9]{10}$/;
    if (regex.test(phoneNumber)) {
      return null;
    } else {
      return { 
        minlengthrequired: true
       };
    }
  }

  static strongPassword(control: AbstractControl){
    const value = control.value;
    const regEx: RegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    if (!regEx.test(value)) {
      return { invalidStrongPassword: true };
    }
  }

  static dateNotInTheFuture(control: AbstractControl){
    const value = control.value;
    const dateInputted = Date.parse(value);
    const now = Date.now();
    if (now < dateInputted) {
      return { invalidDate: true };
    }
  }

  static nonNumberRegex(regex: RegExp): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const forbidden = regex.test(control.value);
      // console.log('nonNumberRegex::::::::::')
      // console.log(forbidden ? null : {'nonNumberRegex': {value: control.value}})
      return forbidden ? null : { onlyNumbersNotallowed: true }};
    };

  // static minMaxLengthCharacter(minLength: number, maxLength: number): ValidatorFn {
  //   return (control: AbstractControl) => {
  //     const nameString = control.value;
  //     if (!nameString) {
  //       return null;
  //     }
  
  //     const regex: RegExp = /^[a-zA-Z0-9]+$/;
  
  //     if (!regex.test(nameString)) {
  //       return { invalidCharacterName: true };
  //     }
  
  //     if (nameString.length < minLength || nameString.length > maxLength) {
  //       return {
  //         invalidNameLength: {
  //           min: minLength,
  //           max: maxLength
  //         }
  //       };
  //     } else {
  //       return null;
  //     }
  //   };
  // }

  static minMaxLengthCharacter(minLength: number, maxLength: number): ValidatorFn {
    return (control: AbstractControl) => {
      const stringValue: string = control.value;
  
      if (!stringValue) {
        return null;
      }
  
      // Check if the length of the string falls within the specified range
      if (stringValue.length < minLength || stringValue.length > maxLength) {
        return {
          invalidNameLength: {
            min: minLength,
            max: maxLength
          }
        };
      } else {
        return null;
      }
    };
  }

  static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
    if((control.value as string).indexOf(' ') >= 0){
        return {cannotContainSpace: true}
    }
    return null;
}
}
