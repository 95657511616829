import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CoursesRestService } from './courses-rest.service';

@Injectable({
  providedIn: 'root'
})
export class CoursesDataService {

  constructor(
    private coursesRestService: CoursesRestService
  ) { }

  getCoursesData() {
    return this.coursesRestService.getCourses();
  }

  specificPackageID(PackageID) {
    return this.coursesRestService.specificPackageID(PackageID);
  }

  getCoursesDataForTeams() {
    return this.coursesRestService.getCoursesDataForTeams();
  }

  getcompanySpecificPackages() {
    return this.coursesRestService.getcompanySpecificPackages();
  }

  getCourseGalleryList() {
    return this.coursesRestService.getCourseGalleryList();
  }

  getEnrolledCourses() {
    return this.coursesRestService.getEnrolledCourses();
  }

  getEnrolledCourse(courseId: string) {
    return this.coursesRestService.getEnrolledCourse(courseId);
  }

  getEnrolledCourseByUrl(data: string) {
    return this.coursesRestService.getEnrolledCourseByUrl(data);
  }

  getCourseByCourseUrl(data: string) {
    return this.coursesRestService.getCourseByCourseUrl(data);
  }

  getCourseByCourseId(courseId: string) {
    return this.coursesRestService.getCourseByCourseId(courseId);
  }

  getCoursesByCategory() {
    return this.coursesRestService.getCoursesByCategory();
  }

  getCoursesByJob() {
    return this.coursesRestService.getCoursesByJob();
  }

  rateCourse(courseId: any) {
    return this.coursesRestService.rateCourse(courseId);
  }

  getDiscount() {
    return this.coursesRestService.getDiscount();
  }

  getGroups() {
    return this.coursesRestService.getGroups();
  }

  getUserCountryPricing(country: string, localItems: any) {
    return this.coursesRestService.getUserCountryPricing(country, localItems);
  }

  // Observable string sources
  private componentMethodCallSource = new Subject<any>();

  // Observable string streams
  componentMethodCalled$ = this.componentMethodCallSource.asObservable();

  // Service message commands
  callComponentMethod() {
    this.componentMethodCallSource.next();
  }

  getGalleryData(id: any) {
    return this.coursesRestService.getGalleryData(id);
  }
}
