import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {GalleryContent, PdfSummary, SaveGameData, VideoSummary} from '../models/models';
import {MediaPlayerRestService} from './media-player-rest.service';
import {Router} from '@angular/router';
import { GameEvent } from '../game-player/game-player.component';
import {ContentCompletionService} from "../../../core/content-completion.service";
import {GalleryService} from "../../gallery/services/gallery.service";

@Injectable({
  providedIn: 'root'
})
export class MediaPlayerService {

  constructor(
    private restService: MediaPlayerRestService,
    private router: Router,
    private contentCompletionService: ContentCompletionService,
    private galleryService: GalleryService
  ) { }

  getPdf(pdfId): Observable<PdfSummary> {
    return this.restService.getPdf(pdfId);
  }

  getVideo(videoId): Observable<VideoSummary> {
    return this.restService.getVideo(videoId);
  }

  getModuleContent(moduleId: number): Observable<GalleryContent> {
    return this.restService
      .getModuleContent(moduleId)
      .pipe(
        catchError(err => {
          // this.router.navigate(['/gallery/error', {id: moduleId}]);
          console.log("error", err);
          return throwError(err);
        })
      );
  }

  getModuleCompletionStatus(id) {
    return this.restService
      .getModuleCompletionStatus(id)
      .subscribe(
        (res: any) => {
          if (res.completionPopup){
            this.contentCompletionService.activateCompletionOverlay(res.completionPopup);3
            this.galleryService.updateContentStars(res.userStars, res.maxStars);
          }
          // todo: handle failing
        }
      );
  }

  getGameContent(id: number): Observable<GalleryContent> {
    return this.restService
      .getGameContent(id)
      .pipe(
        catchError(err => {
          this.router.navigate(['/gallery/error', {id}]);
          return throwError(err);
        })
      );
  }

  setGameResult(id: number, data: SaveGameData) {
    this.restService
      .setGameResult(id, data)
      .subscribe(
        () => {
          // todo: handle failing
        }
      );
  }

  addGameEvent(id: number, data: GameEvent) {
    this.restService
      .postGameEvent(id, data)
      .subscribe(
        () => {},
        (err) => {
          if (err.status === 400) {
            console.error(`Log Game Event error: code '${data.code}' not known to game id ${id}`)
          } else {
            throwError(err)
          }
        }
      );
  }

  notifyGameClosed(id: number) {
    this.restService
      .notifyGameClosed(id)
      .subscribe(
        (res: any) => {
          this.galleryService.updateContentStars(res.userStars, res.maxStars);
          // todo: handle failing
        }
      );
  }

  notifyMediaOpened(id: number) {
   this.restService
      .notifyMediaOpened(id)
      .subscribe(
        () => {
          // todo: handle failing
        }
      );
  }

  notifyMediaClosed(id: number) {
   this.restService
      .notifyMediaClosed(id)
      .subscribe(
        (res: any) => {
          this.galleryService.updateContentStars(res.userStars, res.maxStars);
          // todo: handle failing
        }
      );
  }
}
