<input
  #textDate
  type="text"
  (keyup)="updateValue()"
  [(ngModel)]="textDateValue"
  gktFormsAllowedChars="/[\d\/-]/"
>
<!--<input-->
  <!--type="text"-->
  <!--[gktFormsAllowCharType]="['number']"-->
  <!--(change)="updateValue()"-->
  <!--[(ngModel)]="textDateValue"-->
<!--&gt;-->
