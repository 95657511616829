import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NavigationService} from '../../core/navigation.service';
import {SharedLayoutService} from '../shared-layout.service';
import {LocalStorageService} from '../../core/local-storage.service';
import {DriverJsService} from "../../core/driver-js.service";
import { ScreenViewService } from 'src/app/core/screen-view.service';
import {takeUntil} from "rxjs/operators";
import {UserService} from "../../core/user.service";
// import {GktAuthService} from "gkt-auth-library";
import {AngularAuthService} from 'angular-auth-library';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'edu-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  showSideMenu$: Observable<boolean>;
  link: string;
  @ViewChild('sideMenu') sideMenuRef;
  fffaiRole:any;
  totalItems: number = 0;
  showIt: string = "fffai";
  domainOwnerName:any;
  fffai:boolean=false;

  constructor(
    private navigationService: NavigationService,
    private layoutService: SharedLayoutService,
    private localStorageService: LocalStorageService,
    private driverJsService: DriverJsService,
    private screenViewService: ScreenViewService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private userService: UserService,
    private storageService: LocalStorageService,
    private authService: AngularAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.showSideMenu$ = layoutService.showSideMenu$;
  }

  hideRewardsButton = false;
  destroy$ = new Subject();

  ngOnInit(){
    if(this.router.url.includes('fffai')){
      this.showIt = "fffai";
    }else{
      this.showIt = "wai";
    }

    this.domainOwnerName = this.localStorageService.retrieveFromLocalStorage("domainOwnerName");
    if(this.domainOwnerName){
        if(this.domainOwnerName == 'FFFAI Domain Owner' || this.domainOwnerName == 'WAI Domain Owner' ){
          this.fffai = true;
        }else{
          this.fffai = false;
        }
     }


    this.fffaiRole = localStorage.getItem('fffaiRole');
    this.link = this.screenViewService.inAppReviewLinkAssign();
    this.userService.role$
      .pipe(takeUntil(this.destroy$))
      .subscribe(roles => {
        return roles.map(role => {
          if (role !== 'ROLE_STUDENT'){
            this.hideRewardsButton = true;
          }
        });
      });
      if (localStorage.getItem('shopping-cart')) {
        var item = localStorage.getItem('shopping-cart').slice(0, localStorage.getItem('shopping-cart').length - 1);
        var arrOfItemsId = item.split(',');
        this.totalItems = arrOfItemsId.length;
      }
  }

  ngAfterViewInit() {
    // this makes sure that the 'active' class is added since there are cases where driver-js mistakenly removes it
    this.showSideMenu$.subscribe(show => {
      if (this.sideMenuRef) {
        if (show) {
          this.renderer.addClass(this.sideMenuRef.nativeElement, 'active');
        } else {
          this.renderer.removeClass(this.sideMenuRef.nativeElement, 'active');
        }
      }
    });
  }

  logout() {
    if (!localStorage.getItem('fffaiRole')) {
      this.storageService.storeInLocalStorage('isLoggedOut', "true");
    }

        // this.authService.logout();
        this.storageService.removeFromLocalStorage('username');
        this.storageService.removeFromLocalStorage('authDetails');
        this.storageService.removeFromLocalStorage('learnerSSOData');
        this.storageService.removeFromLocalStorage('companyId');
        this.storageService.removeFromLocalStorage('fffaiRole');
        this.storageService.clearLocalStorage();
        // localStorage.clear();
        this.removePreviousPages();

    // if(this.router.url.includes('wai')){
    //   const loginUrl = '/wai/login';
    //   this.router.navigateByUrl(loginUrl, { replaceUrl: true });
    // }else if(this.router.url.includes('fffai')){
    //   const loginUrl = '/fffai/login';
    //   this.router.navigateByUrl(loginUrl, { replaceUrl: true });
    // } else {
    //   const loginUrl = '/registration/start'
    //   this.router.navigateByUrl(loginUrl, { replaceUrl: true });
    // }

    if(this.domainOwnerName == 'WAI Domain Owner'){
      const loginUrl = '/wai/login';
      this.router.navigateByUrl(loginUrl, { replaceUrl: true });
    }else if(this.domainOwnerName == 'FFFAI Domain Owner'){
      const loginUrl = '/fffai/login';
      this.router.navigateByUrl(loginUrl, { replaceUrl: true });
    } else {
      const loginUrl = '/registration/start-with-login'
      this.router.navigateByUrl(loginUrl, { replaceUrl: true });
    }


  }

  removePreviousPages() {
    const currentUrl = this.location.path();
    window.history.replaceState({}, '', currentUrl);
    this.location.forward();
  }

  toggleSideMenu() {
    this.layoutService.toggleSideMenu();
  }

  initAppGuide(){
    this.toggleSideMenu();
    this.driverJsService.activateDriverJs();
  }

  isRateUsClicked(){
    this.screenViewService.isRateUsClickedInSideBar(true);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

}
