import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  
  @Input() accordion: any;
  link1: string = '';
  link2: string = '';
  link3: string = '';
  link4: string = '';
  hideLink1: boolean = true;
  hideLink2: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if(this.accordion.number == 1) {
      this.link1 = 'contact-us';
    }
    if(this.accordion.number == 2) {
      var userAgent = window.navigator.userAgent,
          platform = window.navigator.platform,
          macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
          windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
          iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        if (
          macosPlatforms.indexOf(platform) !== -1 ||
          iosPlatforms.indexOf(platform) !== -1
        ) {
          this.link2 = 'https://apps.apple.com/us/app/the-21cc-app/id1512910768';
        } else if (
          windowsPlatforms.indexOf(platform) !== -1 ||
          /Android/.test(userAgent)
        ) {
          this.link2 =
            'https://play.google.com/store/apps/details?id=com.education21cc.logistics&hl=en&gl=US&pli=1';
        }
    }
    if(this.accordion.number == 3 || this.accordion.number == 4) {
      this.link3 = 'https://21cceducation.com/industry/0';
      this.link4 = 'https://21cceducation.com/industry/0';
    }
  }

  switchLinks(value: any) {
    if (value == 1) {
      this.hideLink1 = false;
      this.hideLink2 = true;
    } else if (value == 2) {
      this.hideLink1 = true;
      this.hideLink2 = false;
    }
  }
}
