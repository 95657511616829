/*
This service takes a validation error object and returns a string for the first validation error that it finds.
It prioritises the required error over others.
 */

import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class ValidationErrorMessage {

  errorsMap: Map<string, (x) => string>;
  constructor(
    private transloco: TranslocoService
  ) {
    this.errorsMap = new Map([
      ['required', this.required],
      ['invalidEmail', this.invalidEmail],
      ['invalidPhone', this.invalidPhone],
      ['invalidPhoneWithoutCountryCode', this.invalidPhoneWithoutCountryCode],
      ['invalidCountryCode', this.invalidCountryCode],
      ['invalidPostcode', this.invalidPostcode],
      ['minlength', this.min],
      ['matchPasswordsError', this.matchPasswordsError],
      ['dateLengthError', this.dateLengthError],
      ['dateRangeError', this.dateRangeError],
      ['yearRangeError', this.yearRangeError],
      ['notBlank', this.notBlank],
      ['isNotTrue', this.isNotTrue],
      ['selectOne', this.selectOne],
      ['invalidVerificationCode', this.invalidVerificationCode],
      ['invalidInvitationCode', this.invalidInvitationCode],
      ['invalidNameString', this.invalidNameString],
      ['onlyNumbersNotallowed', this.onlyNumbersNotallowed],
      ['invalidNameLength', this.invalidNameLength],
      ['invalidStrongPassword', this.invalidStrongPassword],
      ['invalidDate', this.invalidDate],
      ['cannotContainSpace', this.cannotContainSpace],
      ['minlengthrequired',this.minlengthrequired],
      ['invalidPassword',this.invalidpassword],
      ['invalidNameForSpecialCharacter',this.invalidNameForSpecialCharacter],
      ['invalidNumberString',this.invalidNumberString],
      ['invalidCharacters',this.invalidCharacters], 
      ['onlyNumbersOrNullAllowed',this.onlyNumbersOrNullAllowed], 

    ]);
  }

  public getError(errorsObject: ValidationErrors) {
    let errorKey;
    let errorFn;

    if (errorsObject.hasOwnProperty('required')) {
      errorKey = 'required';
    } else {
      errorKey = Object.keys(errorsObject)[0];
    }

    if (this.errorsMap.get(errorKey)) {
      errorFn = this.errorsMap.get(errorKey);
      return errorFn(errorsObject[errorKey]);
    } else {
      console.error('There is a validation error message missing for: ', errorKey);
      return null;
    }
  }


  private required = (): string => {
    const translation = this.transloco?.translate('shared.validation.required');
    return translation;
  }

  private invalidNameForSpecialCharacter = (): string => {
    const translation = 'Special characters are not allowed';
    return translation;
  }

  private invalidNumberString = (error): string => {
    const translation = this.transloco?.translate('shared.validation.invalid-number', {min: error.min, max: error.max});
    return translation;
  }

  private invalidPhone = (): string => {
    const translation = this.transloco?.translate('shared.validation.invalid-phone');
    return translation;
  }

  private invalidPhoneWithoutCountryCode = (): string => {
    const translation = this.transloco?.translate('shared.validation.invalid-phone-without-country-code');
    return translation;
  }

  private invalidCountryCode = (): string => {
    const translation = this.transloco?.translate('shared.validation.invalid-country-code');
    return translation;
  }

  private invalidEmail = (): string => {
    const translation = this.transloco?.translate('shared.validation.invalid-email');
    return translation;
  }

  private invalidPostcode = (): string => {
    const translation = this.transloco?.translate('shared.validation.invalid-postcode');
    return translation;
  }

  private min = (error): string => {
    const requiredMin = error.requiredLength;
    const translation = this.transloco?.translate('shared.validation.min', {min: requiredMin});
    return translation;
  }

  private matchPasswordsError = (): string => {
    const translation = this.transloco?.translate('shared.validation.match-passwords');
    return translation;
  }

  private dateLengthError = (): string => {
    const translation = this.transloco?.translate('shared.validation.invalid-date-format');
    return translation;
  }

  private dateRangeError = (error): string => {
    const dateType = ['Day', 'Month'][error.index];
    const translation = this.transloco?.translate('shared.validation.date-range-error', {dateType});
    return translation;
  }

  private yearRangeError = (error): string => {
    const translation = this.transloco?.translate('shared.validation.year-range-error', {min: error.min, max: error.max});
    return translation;
  }

  private notBlank = (): string => {
    const translation = this.transloco?.translate('shared.validation.not-blank');
    return translation;
  }

  private isNotTrue = (): string => {
    const translation = this.transloco?.translate('shared.validation.is-not-true');
    return translation;
  }

  private selectOne = (): string => {
    const translation = this.transloco?.translate('shared.validation.select-one');
    return translation;
  }

  private invalidVerificationCode = (): string => {
    const translation = this.transloco?.translate('shared.validation.invalid-verification-code');
    return translation;
  }

  private invalidInvitationCode = (): string => {
    const translation = this.transloco?.translate('shared.validation.invalid-invitation-code');
    return translation;
  }

  private invalidNameString = (error): string => {
    const translation = this.transloco?.translate('shared.validation.invalid-name', {min: error.min, max: error.max});
    return translation;
  }

  private onlyNumbersNotallowed = (): string => {
    const translation = this.transloco?.translate('Only numbers are not allowed,try it with alphanumeric');
    return translation;
  }

  private invalidCharacters = (): string => {
    const translation = this.transloco?.translate('Invalid characters, special character and numbers are not allowed');
    return translation;
  }
  
  private onlyNumbersOrNullAllowed = (): string => {
    const translation = this.transloco?.translate('Numbers only allowed');
    return translation;
  }
  

  private invalidNameLength = (error): string => {
    const translation = this.transloco?.translate('shared.validation.invalid-name', {min: error.min, max: error.max});
    return translation;
  }

  private invalidStrongPassword(): string {
    // Since the error message is too long, we display the error manually/separately instead
    return '';
  }

  private invalidDate = (): string => {
    const translation = this.transloco?.translate('shared.validation.invalid-date' );
    return translation;
  }

  private cannotContainSpace = (): string => {
    const translation = this.transloco?.translate('shared.validation.cannotContainSpace');
    return translation;
  }

  private minlengthrequired = (): string => {
    const translation = this.transloco?.translate('shared.validation.minlengthrequired');
    return translation;
  }

  private invalidpassword = (): string => {
    const translation = this.transloco?.translate('Invalid password');
    return translation;
  }

}
