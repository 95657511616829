import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import careerData from '../../../../assets/data/career.json';

@Component({
  selector: 'app-contact-details',
  templateUrl: './career-details.component.html',
  styleUrls: ['./career-details.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class CareerDetailsComponent implements OnInit {
  
  careers: any = careerData;
  career: any;
  recentCareersData: any;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle("Career Details - 21CC Recruitment & Training");
    const id = this.route.snapshot.paramMap.get('id');
    for (var i = 0; i < this.careers.length; i++) {
      if (this.careers[i].title == id) {
        this.career = this.careers[i];
      }
    }
    this.recentCareersData = this.recentCareers(this.careers, 2);
  }

  recentCareers(careers: any, num = 1) {
      const id = this.route.snapshot.paramMap.get('id');
      const res = [];
      for (let i = 0; i < num; ) {
        const random = Math.floor(Math.random() * this.careers.length);
        if (res.indexOf(this.careers[random]) !== -1 || this.careers[random].title == id) {
          continue;
        }
        res.push(this.careers[random]);
        i++;
      }
      return res;
    };
}
