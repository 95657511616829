import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CompletionData} from '../shared/completion-overlay/completion-overlay.component';
import {distinctUntilChanged} from 'rxjs/operators';
import {GalleryRestService} from "../modules/gallery/services/gallery-rest.service";

@Injectable({
  providedIn: 'root'
})
export class ContentCompletionService {

  /**
   * Service used to manage the 'completion' screens, usually triggered when users complete challenges or earn rewards;
   */

  private _completionOverlay: BehaviorSubject<CompletionData> = new BehaviorSubject<CompletionData>(null);
  completionOverlay$: Observable<CompletionData> = this._completionOverlay.asObservable().pipe(distinctUntilChanged());

  constructor(
    private galleryRestService: GalleryRestService
  ) { }

  activateCompletionOverlay(data: CompletionData){
    this._completionOverlay.next(data);
  }

  deactivateCompletionOverlay(){
    this._completionOverlay.next(null);
  }

  confirmChallengeCompletionOverlay(challengeId: number){
    return this.galleryRestService.confirmChallengeCompletionOverlay(challengeId);
  }
}
