import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import allfaqs from '../../../assets/data/faq.json';

@Component({
  selector: 'app-faq-section',
  templateUrl: './faq-section.component.html',
  styleUrls: ['./faq-section.component.scss']
})
export class FaqSectionComponent implements OnInit, OnChanges {

  @Input() tabName: any;
  faqs: any = allfaqs;
  faq: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    switch(this.tabName){
      case "Careers in logistics":
        this.faq = this.faqs[0];
        break
      case "Account setup":
        this.faq = this.faqs[1];
        break
      case "Troubleshooting":
        this.faq = this.faqs[2];
        break
      case "Payment":
        this.faq = this.faqs[3];
        break
      case "Corporates":
        this.faq = this.faqs[4];
        break
      default:
        this.faq = this.faqs[0];
    }
  }

}
