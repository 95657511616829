import { HttpClient } from '@angular/common/http';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import {Injectable, Injector} from '@angular/core';
import {throwError} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {LanguageService} from "../language/language.service";

/*
Based on https://github.com/ngneat/transloco/blob/master/src/app/loaders/http.loader.ts
 */
/**
 * This class loads the correct language file when the user changes language.
 */
@Injectable({ providedIn: 'root' })
export class EduHttpLoader implements TranslocoLoader {

  constructor(
    private http: HttpClient,
    private injector: Injector
  ) {}

  getTranslation(langPath: string, scope) {
    return this.http.get<Translation>(`${environment.apiUrl}/language/${langPath}`)
      .pipe(
        catchError(() => {
          console.error(`No server translation available for ${langPath}, falling back to local ${langPath} translations`)
          return this.http.get<Translation>(`/assets/i18n/${langPath}.json`)
            .pipe(
              catchError(
                () => {
                  console.error(`Local translations for ${langPath} also missing. Final fallback to local English file`);
                  return this.http.get<Translation>(`/assets/i18n/en.json`)
                    .pipe(
                      tap({complete: () => this.injector.get(LanguageService).setActiveLang('en')}),
                      catchError(
                        err => {
                          console.error(`A serious error has occurred. No translation files can be found. Please report this to the support team`)
                          return throwError(err)
                        }
                      )
                    )
                }
              )
            )
        })
      )
  }
}
