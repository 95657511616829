<edu-default-layout>
    <edu-spinner *ngIf="loading"></edu-spinner>
    <header class="news position-relative">
        <h1>Blog</h1>
        <nav class="nav tabnav inverted container" id="categoryNav" role="tablist">
            <a class="nav-link pb-3 px-1 mr-sm-3 active" data-toggle="tab" (click)="assignName('All')"
                data-category="all" role="tab" aria-controls="category-all" aria-selected="true" id="tab-all">
                <h5 class="blogHeading">All</h5>
            </a>
            <a class="nav-link pb-3 px-1 mr-sm-3" data-toggle="tab" (click)="assignName(category?.categoryName)"
                [attr.data-category]="category" role="tab" [attr.aria-controls]="'category-' + category"
                aria-selected="true" [id]="'tab-' + category" *ngFor="let category of categories">
                <h5 class="blogHeading">{{ category?.categoryName }}</h5>
            </a>
            <a class="nav-link pb-3 px-1 mr-sm-3" data-toggle="tab" (click)="assignName('In The News')"
                data-category="in-the-news" role="tab" aria-controls="in-the-news">
                <h5 class="blogHeading">In The News</h5>
            </a>
        </nav>
    </header>
    <edu-blog-card *ngIf="!showArticle" [tabName]="tabName" (loadingEvent)="receiveloadingEvent($event)"></edu-blog-card>
    <div class="container mt-4 news-index ">
        <div class="row overflow-hidden">
            <div class="news-article newss" id="tests" data-category="news-media" *ngIf="showArticle">
                <div class="row tests">
                    <div class="column">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/22.jpeg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/3.jpeg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/4.jpeg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/5.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/18.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/7.jpg" alt="image">
                    </div>
                    <div class="column">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/25.jpeg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/8.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/11.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/12.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/21.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/20.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/6.jpeg" alt="image">
                    </div>
                    <div class="column">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/9.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/15.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/16.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/17.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/19.jpg" alt="image">
                    </div>
                    <div class="column">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/10.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/23.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/1.jpg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/2.jpeg" alt="image">
                        <img (click)="onClick($event)" data-toggle="modal" data-target="#myModal" class="imgCursor"
                            src="assets/images/news/news_page/14.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <img src="{{imgSrc}}" style="max-width:100%;cursor:pointer" />
        </div>
    </div>
    <br /><br /><br /><br /><br /><br />
</edu-default-layout>