import {Injectable} from '@angular/core';
import {formatDate} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateConversionService {

  constructor() { }

  public convertISOtoNL(isoDateString: string) {
    const dateArray = this.splitDateToArray(isoDateString);
    if (dateArray.length) {
      this.validateAsISO(isoDateString);
    }
    dateArray.reverse();
    return dateArray.join('-');
  }

  public convertNLtoISO(nlDateString: string): string {
    const dateArray = this.splitDateToArray(nlDateString);
    dateArray.reverse();
    const isoDateString = dateArray.join('-');
    if (dateArray.length === 3 && this.validateNLDate(nlDateString)) {
      this.validateAsISO(isoDateString);
    }
    return isoDateString;
  }

  private splitDateToArray(dateString: string): string[] {
    const split = dateString.split(/[^A-Za-z0-9]/);
    if (split.length !== 3) {
      return [];
    }
    return split;
  }

  validateNLDate(NlDateString: string) {
    const regex = RegExp(/^[0-9]{2}\W[0-9]{2}\W[0-9]{4}$/);
    return regex.test(NlDateString);
  }

  // suggest only using this on incoming values
  private validateAsISO(value: string): void {
    try {
      formatDate(value, 'yyyy-MM-dd', 'nl');
      const regex = RegExp(/^[1,2]{1}[0-9]{3}-[0,1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}$/);
      if (!regex.test(value)) {
        console.error('Date string is not ISO date format. Failed regex match');
      }
    } catch (e) {
      console.error(`The value ${value} is not a valid ISO date string eg yyyy-MM-dd`);
    }
  }
}
