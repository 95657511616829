<!-- <edu-top-nav-bar *ngIf="showIt == 'website'"></edu-top-nav-bar>
<edu-fffai-navbar *ngIf="showIt == 'fffai'"></edu-fffai-navbar>
<div class="sub-pages">
  <ng-content></ng-content>
  <edu-saved-indicator></edu-saved-indicator>
  <edu-unsuccessful-save-indicator></edu-unsuccessful-save-indicator>
</div>
<edu-completion-overlay *ngIf="(completionOverlay$ | async)"></edu-completion-overlay>
<edu-bottom-nav-bar *ngIf="showBottom"></edu-bottom-nav-bar> -->

<edu-top-nav-bar id="fm-top-nav-bar" *ngIf="showIt == 'website'" [hideBackButton]="hideBackButton"></edu-top-nav-bar>
<div *ngIf="showIt == 'fffai'">
  <edu-fffai-navbar *ngIf="fffaiRole != 'ROLE_STUDENT'"></edu-fffai-navbar>
</div>
<div *ngIf="showIt == 'wai'">
  <edu-wai-navbar *ngIf="fffaiRole != 'ROLE_STUDENT'"></edu-wai-navbar>
</div>

<section *ngIf="fffaiRole == 'ROLE_STUDENT'">
  <div style="position: absolute;" class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="hamburger-align">
          <div style="z-index: 4;" class="hamburger-menu" (click)="toggleSideMenu()" >
            <div class="icon-hamburger" [inlineSVG]="'assets/images/icons/icon-hamburger.svg'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>



<div class="sub-pages">
  <ng-content></ng-content>
  <!-- <edu-saved-indicator></edu-saved-indicator>
  <edu-unsuccessful-save-indicator></edu-unsuccessful-save-indicator> -->
</div>
<edu-sidebar-menu
    [class.landscape]="(screenOrientation$|async) == 'landscape'"
  ></edu-sidebar-menu>
<edu-completion-overlay *ngIf="(completionOverlay$ | async)"></edu-completion-overlay>
<edu-bottom-nav-bar [ngClass]="fffai ? 'bottom-container-fffai': 'bottom-container-webshop'"></edu-bottom-nav-bar>
