import {CommonModule} from "@angular/common";
import {NgSelectComponent} from "@ng-select/ng-select";
import {
  TRANSLOCO_LOADER,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  TRANSLOCO_MISSING_HANDLER, TranslocoService,
} from '@ngneat/transloco';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {map, tap} from "rxjs/operators";
import { environment } from '../../../environments/environment';
import {EduHttpLoader} from "./edu-http-loader.service";
import {LocalLangPreloader} from "./local-lang-preloader.service";
import {EduMissingKeyHandler} from "./missing.handler";
import {IsoLangData} from '../language/language.service';

/*   MODULE CONFIGURATION    */
const localJsonFiles = ['en', 'nl', 'hi'];
const listLocalLangFilesEvenIfNotAvailableOnServer = true;
/*   MODULE CONFIGURATION    */

export let serverLangs;

export function preloadLangData(
  languagePreloadService: LocalLangPreloader,
  translocoService: TranslocoService) {
  return (): Promise<any> => {
    const languagesOnServer: Promise<any> = languagePreloadService.getServerLanguages(
      translocoService.config.fallbackLang
    )
      .pipe(
        map(serverLangs => {
          const localLangsExcludingServerLangs = localJsonFiles.filter(lang => !serverLangs.map(langs => langs.code).includes(lang));
          return listLocalLangFilesEvenIfNotAvailableOnServer ? [...serverLangs, ...localLangsExcludingServerLangs] : serverLangs;
        }),
        tap((availableLangs: any[]) => {
          // storing the full language objects retrieved from the server; these will be used again in the language-switcher component:
          languagePreloadService.storeIsoLangDataObjects(availableLangs);

          translocoService.setAvailableLangs(availableLangs.map(langs => !langs.code ? langs : langs.code));
        })
      ).toPromise();

    const languagesPreloaded = languagePreloadService.cacheFallbackLangFiles(localJsonFiles);

    // We should get the available languages from the server here.
    return Promise.all([languagesOnServer, languagesPreloaded]);
  };
}

@NgModule({
  exports: [
    TranslocoModule
  ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en'], // this will be updated on load. see preloadLangData()
        defaultLang: 'en',
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
      })
    },
    { /// https://stackblitz.com/edit/angular-configuration-with-app-initializer
      provide: APP_INITIALIZER,
      useFactory: preloadLangData,
      deps: [LocalLangPreloader, TranslocoService, TRANSLOCO_CONFIG],
      multi: true
    },
    { provide: TRANSLOCO_LOADER, useClass: EduHttpLoader },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: EduMissingKeyHandler}
  ]

})
export class TranslocoRootModule {}

