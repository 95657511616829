
<ng-container *ngIf="showIt == 'fffai'">
  <ng-container *ngIf="fffaiRole != 'ROLE_STUDENT'">
    <ng-container *transloco="let t; read: 'shared.sidebar-menu'">
      <div class="background" [class.active]="(showSideMenu$ | async)" (click)="toggleSideMenu()"></div>
      <div class="sidenav" [class.active]="(showSideMenu$ | async)" #sideMenu>
        <div class="menu-bar">
          <p id="menu">{{t('menu')}}</p>
          <div class="hamburger-menu" (click)="toggleSideMenu()">
            <span class="icon-hamburger" [inlineSVG]="'assets/images/icons/icon-hamburger.svg'"></span>
          </div>
        </div>
        <ul>
          <li class="menu-option" routerLink="/user-profile" (click)="toggleSideMenu()">
            <div id="profile" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-profile.svg'"></div>
              {{t('profile')}}
            </div>
          </li>
          <li class="menu-option" routerLink="/user-certificates" (click)="toggleSideMenu()">
            <div id="certificates" class="menu-option-wrapper">
              <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-certificate.svg'"></div>
              {{t('certificates')}}
            </div>
          </li>
          <li *ngIf="!hideRewardsButton" class="menu-option" routerLink="/rewards" (click)="toggleSideMenu()">
            <div id="rewards" class="menu-option-wrapper">
              <div class="icon reward" [inlineSVG]="'assets/images/icons/icon-gift.svg'"></div>
              {{t('rewards')}}
            </div>
          </li>
          <li class="menu-option" routerLink="/skills" (click)="toggleSideMenu()">
            <div id="skills-passport" class="menu-option-wrapper">
              <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-skills.svg'"></div>
              {{t('skills-passport')}}
            </div>
          </li>
          <li class="menu-option" routerLink="fffai/user-settings" (click)="toggleSideMenu()">
            <div id="icon-settings" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-settings.svg'"></div>
              {{t('settings')}}
            </div>
          </li>
          <li class="menu-option" routerLink="/contact" (click)="toggleSideMenu()">
            <div id="contact" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-phone.svg'"></div>
              {{t('contact')}}
            </div>
          </li>
          <li class="menu-option" (click)="initAppGuide()">
            <div id="intro-tour" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-flag.svg'"></div>
              {{t('guide')}}
            </div>
          </li>
          <a [href]="link" target="_blank">
          <li class="menu-option" (click)="isRateUsClicked()">
            <div id="rateus" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-star.svg'"></div>
              {{t('rateus')}}
            </div>
          </li>
        </a>
        </ul>
      </div>
    </ng-container>
  </ng-container>
  
  
  <ng-container *ngIf="fffaiRole == 'ROLE_STUDENT'">
    <ng-container  *transloco="let t; read: 'shared.sidebar-menu'">
  
      <div class="background" [class.active]="(showSideMenu$ | async)" (click)="toggleSideMenu()"></div>
      <div class="sidenav" [class.active]="(showSideMenu$ | async)" #sideMenu>
        <div class="menu-bar">
          <p id="menu">{{t('menu')}}</p>
          <div class="hamburger-menu" (click)="toggleSideMenu()">
            <span class="icon-hamburger" [inlineSVG]="'assets/images/icons/icon-hamburger.svg'"></span>
          </div>
        </div>
        <ul>
          <li class="menu-option" routerLink="/fffai/learner-my-profile" (click)="toggleSideMenu()">
            <div id="profile" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-profile.svg'"></div>
              My Profile
            </div>
          </li>
          <li class="menu-option" routerLink="/fffai/user-certificates" (click)="toggleSideMenu()">
            <div id="certificates" class="menu-option-wrapper">
              <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-certificate.svg'"></div>
              {{t('certificates')}}
            </div>
          </li>
          <!-- <li *ngIf="!hideRewardsButton" class="menu-option" routerLink="/fffai/my-courses" (click)="toggleSideMenu()">
            <div id="rewards" class="menu-option-wrapper">
              <div class="icon reward" [inlineSVG]="'assets/images/icons/icon-gift.svg'"></div>
              My Courses
            </div>
          </li> -->
          <li class="menu-option" routerLink="/fffai/skills" (click)="toggleSideMenu()">
            <div id="skills-passport" class="menu-option-wrapper">
              <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-skills.svg'"></div>
              {{t('skills-passport')}}
            </div>
          </li>
          <!-- <li class="menu-option" routerLink="/user-settings" (click)="toggleSideMenu()">
            <div id="icon-settings" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-settings.svg'"></div>
              {{t('settings')}}
            </div>
          </li> -->
          <li class="menu-option" routerLink="/fffai/contact" (click)="toggleSideMenu()">
            <div id="contact" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-phone.svg'"></div>
              {{t('contact')}}
            </div>
          </li>
          <!-- <li class="menu-option" routerLink="/fffai/blog" (click)="toggleSideMenu()">
            <div id="skills-passport" class="menu-option-wrapper">
              <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-skills.svg'"></div>
              Blog
            </div>
          </li> -->
          <li class="menu-option" (click)="logout()" >
            <div id="intro-tour" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-logout.svg'"></div>
              Log out
            </div>
          </li>
          <!-- <a [href]="link" target="_blank">
          <li class="menu-option" routerLink="/fffai/checkout" (click)="toggleSideMenu()">
            <a class="nav-link position-relative shopping-cart" >
              <img src="assets/images/box.svg" alt="box" />
              <div class="indicator badge-pill badge-danger small d-none" *ngIf="totalItems == 0">2</div>
              <div class="indicator badge-pill badge-danger small" *ngIf="totalItems > 0">{{ totalItems }}</div>
            </a>
          </li>
          </a> -->
        </ul>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="showIt != 'fffai'">
  <ng-container *ngIf="fffaiRole != 'ROLE_STUDENT'">
    <ng-container *transloco="let t; read: 'shared.sidebar-menu'">
      <div class="background" [class.active]="(showSideMenu$ | async)" (click)="toggleSideMenu()"></div>
      <div class="sidenav" [class.active]="(showSideMenu$ | async)" #sideMenu>
        <div class="menu-bar">
          <p id="menu">{{t('menu')}}</p>
          <div class="hamburger-menu" (click)="toggleSideMenu()">
            <span class="icon-hamburger" [inlineSVG]="'assets/images/icons/icon-hamburger.svg'"></span>
          </div>
        </div>
        <ul>
          <li class="menu-option" routerLink="/user-profile" (click)="toggleSideMenu()">
            <div id="profile" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-profile.svg'"></div>
              {{t('profile')}}
            </div>
          </li>
          <li class="menu-option" routerLink="/user-certificates" (click)="toggleSideMenu()">
            <div id="certificates" class="menu-option-wrapper">
              <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-certificate.svg'"></div>
              {{t('certificates')}}
            </div>
          </li>
          <li *ngIf="!hideRewardsButton" class="menu-option" routerLink="/rewards" (click)="toggleSideMenu()">
            <div id="rewards" class="menu-option-wrapper">
              <div class="icon reward" [inlineSVG]="'assets/images/icons/icon-gift.svg'"></div>
              {{t('rewards')}}
            </div>
          </li>
          <li class="menu-option" routerLink="/skills" (click)="toggleSideMenu()">
            <div id="skills-passport" class="menu-option-wrapper">
              <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-skills.svg'"></div>
              {{t('skills-passport')}}
            </div>
          </li>
          <li class="menu-option" routerLink="wai/user-settings" (click)="toggleSideMenu()">
            <div id="icon-settings" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-settings.svg'"></div>
              {{t('settings')}}
            </div>
          </li>
          <li class="menu-option" routerLink="/contact" (click)="toggleSideMenu()">
            <div id="contact" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-phone.svg'"></div>
              {{t('contact')}}
            </div>
          </li>
          <li class="menu-option" (click)="initAppGuide()">
            <div id="intro-tour" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-flag.svg'"></div>
              {{t('guide')}}
            </div>
          </li>
          <a [href]="link" target="_blank">
          <li class="menu-option" (click)="isRateUsClicked()">
            <div id="rateus" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-star.svg'"></div>
              {{t('rateus')}}
            </div>
          </li>
        </a>
        </ul>
      </div>
    </ng-container>
  </ng-container>
  
  
  <ng-container *ngIf="fffaiRole == 'ROLE_STUDENT'">
    <ng-container  *transloco="let t; read: 'shared.sidebar-menu'">
  
      <div class="background" [class.active]="(showSideMenu$ | async)" (click)="toggleSideMenu()"></div>
      <div class="sidenav" [class.active]="(showSideMenu$ | async)" #sideMenu>
        <div class="menu-bar">
          <p id="menu">{{t('menu')}}</p>
          <div class="hamburger-menu" (click)="toggleSideMenu()">
            <span class="icon-hamburger" [inlineSVG]="'assets/images/icons/icon-hamburger.svg'"></span>
          </div>
        </div>
        <ul>
          <li class="menu-option" routerLink="/wai/learner-my-profile" (click)="toggleSideMenu()">
            <div id="profile" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-profile.svg'"></div>
              My Profile
            </div>
          </li>
          <li class="menu-option" routerLink="/wai/user-certificates" (click)="toggleSideMenu()">
            <div id="certificates" class="menu-option-wrapper">
              <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-certificate.svg'"></div>
              {{t('certificates')}}
            </div>
          </li>
          <!-- <li *ngIf="!hideRewardsButton" class="menu-option" routerLink="/fffai/my-courses" (click)="toggleSideMenu()">
            <div id="rewards" class="menu-option-wrapper">
              <div class="icon reward" [inlineSVG]="'assets/images/icons/icon-gift.svg'"></div>
              My Courses
            </div>
          </li> -->
          <li class="menu-option" routerLink="/wai/skills" (click)="toggleSideMenu()">
            <div id="skills-passport" class="menu-option-wrapper">
              <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-skills.svg'"></div>
              {{t('skills-passport')}}
            </div>
          </li>
          <!-- <li class="menu-option" routerLink="/user-settings" (click)="toggleSideMenu()">
            <div id="icon-settings" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-settings.svg'"></div>
              {{t('settings')}}
            </div>
          </li> -->
          <li class="menu-option" routerLink="/wai/contact" (click)="toggleSideMenu()">
            <div id="contact" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-phone.svg'"></div>
              {{t('contact')}}
            </div>
          </li>
          <!-- <li class="menu-option" routerLink="/fffai/blog" (click)="toggleSideMenu()">
            <div id="skills-passport" class="menu-option-wrapper">
              <div class="icon passport" [inlineSVG]="'assets/images/icons/icon-skills.svg'"></div>
              Blog
            </div>
          </li> -->
          <li class="menu-option" (click)="logout()" >
            <div id="intro-tour" class="menu-option-wrapper">
              <div class="icon" [inlineSVG]="'assets/images/icons/icon-logout.svg'"></div>
              Log out
            </div>
          </li>
          <!-- <a [href]="link" target="_blank">
          <li class="menu-option" routerLink="/fffai/checkout" (click)="toggleSideMenu()">
            <a class="nav-link position-relative shopping-cart" >
              <img src="assets/images/box.svg" alt="box" />
              <div class="indicator badge-pill badge-danger small d-none" *ngIf="totalItems == 0">2</div>
              <div class="indicator badge-pill badge-danger small" *ngIf="totalItems > 0">{{ totalItems }}</div>
            </a>
          </li>
          </a> -->
        </ul>
      </div>
    </ng-container>
  </ng-container>
</ng-container>