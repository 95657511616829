import { Injectable } from '@angular/core';
import { BlogRestService } from './blog-rest.service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(
    private blogRestService: BlogRestService
  ) { }

  getAllBlog(offset: number) {
    return this.blogRestService.getAllBlog(offset);
  }

  getBlogByBlogUrl(data: string) {
    return this.blogRestService.getBlogByBlogUrl(data);
  }

  getBlogById(blogId: string) {
    return this.blogRestService.getBlogById(blogId);
  }

  getAllCategories() {
    return this.blogRestService.getAllCategories();
  }

}
