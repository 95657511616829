import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {LocalStorageService} from './local-storage.service';
import Driver from 'driver.js';
import {TranslocoService} from '@ngneat/transloco';
import {SharedLayoutService} from '../shared-layout/shared-layout.service';
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class DriverJsService {

  _initDriverJs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initDriverJs$: Observable<boolean> = this._initDriverJs.asObservable().pipe(distinctUntilChanged());
  userIsStudent = false;
  public destroy$ = new Subject();

  constructor(
    private localStorageService: LocalStorageService,
    private transloco: TranslocoService,
    private layoutService: SharedLayoutService,
    private userService: UserService
  ) {
    this.userService.role$
      .pipe(takeUntil(this.destroy$))
      .subscribe(roles => {
        return roles.map(role => {
          if (role === 'ROLE_STUDENT'){
            this.userIsStudent = true;
          }
        });
      });
  }

  activateDriverJs(){
    this.localStorageService.storeInLocalStorage('showWizard', 'true');
    this._initDriverJs.next(true);
  }

  deactivateDriverJs(){
    this.localStorageService.removeFromLocalStorage('showWizard');
    this._initDriverJs.next(false);
  }

  initDriverJs(){
    if (this.localStorageService.retrieveFromLocalStorage('showWizard') === 'true'){
      const driver = new Driver({
        closeBtnText: '',
        nextBtnText: '',
        prevBtnText: '',
        doneBtnText: '',
        allowClose: false,
        onNext: (e) => {
          const activeElement = e.getNode() as HTMLElement;
          if (activeElement.id === 'inbox') {
            driver.reset();
            driver.preventMove();
            this.layoutService.toggleSideMenu();
            const nextStep: number = this.userIsStudent ? 4 : 5;
            setTimeout(() => driver.start(nextStep), 1000);
          }
        },
        onPrevious: (e) => {
          const activeElement = e.getNode() as HTMLElement;
          if (activeElement.id === 'profile') {
            driver.reset();
            driver.preventMove();
            setTimeout(() => {
              // hack - for unknown reasons, when capacitor wraps the app, toggleSideMenu is called twice; this keeps it from doing so:
              if (this.layoutService.isShowSideMenu()){
                this.layoutService.toggleSideMenu();
              }
              const prevStep: number = this.userIsStudent ? 3 : 4;
              driver.start(prevStep);
            }, 1000);
          }
        },
        onReset: (e) => {
          const activeElement = e.getNode() as HTMLElement;
          let sideMenuOptionNames = ["profile", "certificates", "rewards", "skills-passport", "icon-settings", "contact", "intro-tour", "rateus"];
          if(sideMenuOptionNames.includes(activeElement.id)){
            this.layoutService.hideSideMenu();  
          }       
        }
      });

      setTimeout(() => {
        driver.defineSteps([
          {
            element: '#home',
            popover: {
              title: this.transloco.translate('shared.wizard.home'),
              description: this.transloco.translate('shared.wizard.home-description'),
              position: 'top',
            }
          },
          {
            element: '#library',
            popover: {
              title: this.transloco.translate('shared.wizard.library'),
              description: this.transloco.translate('shared.wizard.library-description'),
              position: 'top',
            }
          },
          {
            element: '#scores',
            popover: {
              title: this.transloco.translate('shared.wizard.leaderboard'),
              description: this.transloco.translate('shared.wizard.leaderboard-description'),
              position: 'top-center'

            }
          },
          {
            element: '#jobs',
            popover: {
              title: this.transloco.translate('shared.wizard.jobs'),
              description: this.transloco.translate('shared.wizard.jobs-description'),
              position: 'top-right',
            }
          },
          {
            element: '#inbox',
            popover: {
              title: this.transloco.translate('shared.wizard.inbox'),
              description: this.transloco.translate('shared.wizard.inbox-description'),
              position: 'top-right'
            }
          },
          {
            element: '#profile',
            popover: {
              title: this.transloco.translate('shared.wizard.profile'),
              description: this.transloco.translate('shared.wizard.profile-description'),
              position: 'bottom-center',
            }
          },
          {
            element: '#certificates',
            popover: {
              title: this.transloco.translate('shared.wizard.certificates'),
              description: this.transloco.translate('shared.wizard.certificates-description'),
              position: 'bottom-center'

            }
          },
          {
            element: '#rewards',
            popover: {
              title: this.transloco.translate('shared.wizard.rewards'),
              description: this.transloco.translate('shared.wizard.rewards-description'),
              position: 'bottom-center'

            }
          },
          {
            element: '#skills-passport',
            popover: {
              title: this.transloco.translate('shared.wizard.skills-passport'),
              description: this.transloco.translate('shared.wizard.skills-passport-description'),
              position: 'bottom-center'

            }
          },
          {
            element: '#icon-settings',
            popover: {
              title: this.transloco.translate('shared.wizard.settings'),
              description: this.transloco.translate('shared.wizard.settings-description'),
              position: 'bottom-center',
            }
          },
          {
            element: '#contact',
            popover: {
              title: this.transloco.translate('shared.wizard.contact'),
              description: this.transloco.translate('shared.wizard.contact-description'),
              position: 'bottom-center',
            }
          },
          {
            element: '#intro-tour',
            popover: {
              title: this.transloco.translate('shared.wizard.app-guide'),
              description: this.transloco.translate('shared.wizard.app-guide-description'),
              position: 'bottom-center',
            },
            
          },
          {
            element: '#rateus',
            popover: {
              title: this.transloco.translate('shared.wizard.rateus'),
              description: this.transloco.translate('shared.wizard.rateus-description'),
              position: 'bottom-center',
            },
            
          },
        ] );
        driver.start();
        this.deactivateDriverJs();
        this.destroy$.next();
      }, 1000);
    }
  }
}

