import { Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/core/local-storage.service';
import { LocaleHelpersService } from 'src/app/core/locale-helpers.service';
import { CoursesDataService } from 'src/app/modules/courses/services/courses-data.service';
import { FffaiService } from 'src/app/modules/fffai/services/fffai.service';
import { UserProfile } from 'src/app/modules/user-profile/models/models';
import { UserProfileRestService } from 'src/app/modules/user-profile/services/user-profile-rest.service';
import { UserProfileService } from 'src/app/modules/user-profile/services/user-profile.service';

@Component({
  selector: 'edu-wai-navbar',
  templateUrl: './wai-navbar.component.html',
  styleUrls: ['./wai-navbar.component.scss']
})
export class WaiNavbarComponent implements OnInit {

  userProfile$: Observable<UserProfile>;
  avatarImageSrc$: Observable<SafeResourceUrl>;
  countryName: string;
  isAdmin: boolean = false;
  isManager: boolean = false;
  isUser: boolean = false;
  isLoggedIn: boolean | undefined;
  userInfo: any;

  smallHeader: boolean;
  addContainer: boolean;
  employeeNumber: number;
  companyLogo: string;
  heightValue: number = 122;

  totalItems: number = 0;
  @Input() avatarLoaded: boolean;

  constructor(
    private userProfileRestService: UserProfileRestService,
    private userProfileService: UserProfileService,
    private localeHelpers: LocaleHelpersService,
    private courseDataService: CoursesDataService,
    private router: Router,
    private fffaiService: FffaiService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('authDetails')) {
      this.isLoggedIn = true;
      this.userProfile$ = this.userProfileService.userProfile$;
      this.avatarImageSrc$ = this.userProfileService.getAvatarImageSrc();
      this.userProfile$.subscribe(profile => this.countryName = this.nameFromCountryCode(profile.user.country));
      this.userProfileRestService.getUserProfile().subscribe(data => {
        this.userInfo = data;
      });
    } else {
      this.isLoggedIn = false;
    }
    if (localStorage.getItem('authDetails')) {
      const authDetails = JSON.parse(localStorage.getItem('authDetails'));
      if (authDetails.roles.includes('ROLE_COMPANY_ADMIN')) {
        this.isAdmin = true;
      }

      if (authDetails.roles.includes('ROLE_LEARNING_AND_DEVELOPMENT_MANAGER')) {
        this.isManager = true;
      }

      if (authDetails.roles.includes('ROLE_STUDENT')) {
        this.isUser = true;
      }
    }
    if (!(this.employeeNumber == undefined && this.companyLogo == undefined)) {
      if (this.employeeNumber > 0 && this.companyLogo != '') {
        this.heightValue = 255;
      } else if (this.employeeNumber > 0 && this.companyLogo == '') {
        this.heightValue = 135;
      } else if (!(this.employeeNumber > 0) && this.companyLogo != '') {
        this.heightValue = 230;
      }
    }

    if (localStorage.getItem('shopping-cart')) {
      var item = localStorage.getItem('shopping-cart').slice(0, localStorage.getItem('shopping-cart').length - 1);
      var arrOfItemsId = item.split(',');
      this.totalItems = arrOfItemsId.length;
    }

    this.courseDataService.componentMethodCalled$.subscribe(() => {
      this.ngOnInit();
    });
  }

  nameFromCountryCode(countryCode) {
    return this.localeHelpers.nameFromCountryCode(countryCode);
  }

  logout() {
    this.fffaiService.logout();
    this.localStorageService.removeFromLocalStorage('fffaiWai');
    localStorage.removeItem('authDetails');
    localStorage.removeItem('username');
    localStorage.removeItem('fffaiRole');
    this.router.navigate(['/wai/login']);
    localStorage.removeItem('adminSSOData');
    localStorage.removeItem('companyId');
    //   if (!localStorage.getItem('authDetails')) {
    //     this.router.navigate(['/fffai/account-details']);
    // } else {
    //   alert("still in localstorage");
    // }
  }
}
