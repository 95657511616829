import {UntypedFormControl} from '@angular/forms';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class DateTextValidator {
  validate(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const dateToCheck: string = control.value;
    const dateArray = dateToCheck.split(/\W/).reverse();
    if (dateArray.length !== 3) {
      return {
        dateLengthError: {
          invalidLength: true,
          given: dateArray.length,
          required: 3
        }
      };
    }
    const dayError = this.checkValue(dateArray[0], 0, 31, 0);
    const monthError = this.checkValue(dateArray[1], 0, 12, 1);

    if (dayError) {
      return dayError;
    } else if (monthError) {
      return monthError;
    } else {
      return null;
    }
  }

  checkValue(val, min, max, idx) {
    val = parseInt(val, 10);
    if ( val > max || val < min ) {
      return {
        dateRangeError: {
          given: val.value,
          max: max,
          min: min,
          index: idx
        }
      };
    } else {
      return null;
    }
  }
}
